import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ComboBox, CurrencyField, Flex, toast } from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import { formatCurrency, parseCurrency } from "@adaptive/design-system/utils";
import {
  useAddMarkupsMutation,
  useUpdateFixedBudgetLineMarkupsMutation,
} from "@api/budgets";
import { handleErrors } from "@api/handle-errors";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import { useGetBudgetLinesQuery } from "@shared/api/budgets";
import { CURRENCY_FORMAT, useJobSettings } from "@src/jobs";
import { CURRENCY_FIELD_FORM_FORMAT_PROPS } from "@src/jobs/constants";
import { useJobInfo } from "@store/jobs";
import { getCostCodeAccountValues } from "@utils/get-cost-code-account-values";
import { z } from "zod";

import type { AmountFormProps, ItemOption } from "./types";

type OnInputHandler = Exclude<
  ComponentProps<typeof CurrencyField>["onInput"],
  undefined
>;

const amountFormSchema = z
  .object({
    value: z.number(),
  })
  .required();

type AmountFields = z.infer<typeof amountFormSchema>;

const AMOUNT_INITIAL_VALUES: AmountFields = {
  value: 0,
};

const MISSING_ITEM_ERROR = "Item is required to add markup";

export const AmountForm = ({
  onClose,
  onFormChange,
  markup,
  formId,
}: AmountFormProps) => {
  const [item, setItem] = useState<ItemOption>();
  const [currencyFieldValid, setCurrencyFieldValid] = useState(false);

  const [addMarkups] = useAddMarkupsMutation();
  const [updateFixedMarkups] = useUpdateFixedBudgetLineMarkupsMutation();

  const { job } = useJobInfo();
  const { data: budgetLines = [] } = useGetBudgetLinesQuery({
    customerId: job.id,
  });
  const { ownersAmountEnabled } = useJobSettings();
  const costCodeAccountsSimplified = useJobsCostCodeAccountSimplified({
    disableBudgetLines: budgetLines,
  });
  const markupKey = useMemo(
    () => (ownersAmountEnabled ? "ownersValue" : "value"),
    [ownersAmountEnabled]
  );
  const onAddMarkup = useCallback(
    async (value: number) => {
      if (!item) {
        throw new Error(MISSING_ITEM_ERROR);
      }

      await addMarkups({
        ...getCostCodeAccountValues(item),
        [markupKey]: value,
        customer: job.id,
        markupType: "fixed_amount",
        isSeparateLine: true,
      }).unwrap();

      const budgetLine = budgetLines.find(
        (line) => line.jobCostMethod.url === item.value
      );
      if (budgetLine) {
        toast.success(
          `"${item?.label}" budget line converted to a ${formatCurrency(
            value,
            CURRENCY_FORMAT
          )} flat fee markup`
        );
      } else {
        toast.success(
          `Markup ${item?.label} added for ${formatCurrency(
            value,
            CURRENCY_FORMAT
          )}`
        );
      }
    },
    [item, addMarkups, markupKey, job.id, budgetLines]
  );

  const onEditMarkup = useCallback(
    async (value: number) => {
      if (!item || !markup) {
        throw new Error(MISSING_ITEM_ERROR);
      }
      try {
        await updateFixedMarkups({
          ...getCostCodeAccountValues(item),
          [markupKey]: value,
          customer: job.id,
          id: markup.id,
        }).unwrap();
        toast.success(
          `Markup ${item?.label} updated for ${formatCurrency(
            value,
            CURRENCY_FORMAT
          )}`
        );
      } catch (e) {
        handleErrors(e);
      }
    },
    [item, job.id, markup, markupKey, updateFixedMarkups]
  );

  const { setValues, ...form } = useForm<AmountFields>({
    id: formId,
    schema: amountFormSchema,
    onSubmit: async ({ value }) => {
      if (!job.id) {
        return toast.error("Job ID is required to add markup");
      }
      try {
        if (markup) {
          await onEditMarkup(value);
        } else {
          await onAddMarkup(value);
        }
        form.reset();
        setItem(undefined);
        setCurrencyFieldValid(false);
        onClose?.();
      } catch (e: unknown) {
        handleErrors(e);
      }
    },
    initialValues: AMOUNT_INITIAL_VALUES,
  });

  useEffect(() => {
    if (markup) {
      setItem({
        value: markup.jobCostMethod.url,
        label: markup.jobCostMethod.displayName,
        id: markup.jobCostMethod.id,
      });
      setValues({ value: markup[markupKey] });
      setCurrencyFieldValid(true);
    }
  }, [setValues, markup, markupKey]);

  useEffect(() => {
    onFormChange?.(form.isValid && !!item?.id && currencyFieldValid);
  }, [form.id, form.isValid, item, currencyFieldValid, onFormChange]);

  const onChange = useEvent((_, item) => {
    setItem(item);
  });

  const onInput = useEvent<OnInputHandler>((e) => {
    const parsedValue = parseCurrency(e.currentTarget.value);
    setCurrencyFieldValid(!!parsedValue);
  });
  return (
    <form {...form.props} id={formId ?? `${markup ? "edit-" : ""}amount`}>
      <Flex wrap gap="xl" align="center" width="full">
        <Flex grow={true}>
          <ComboBox
            placeholder="ex: Builder's Fee"
            label="Item Name"
            required
            value={item || ""}
            data={costCodeAccountsSimplified.data}
            loading={costCodeAccountsSimplified.status === "loading"}
            onChange={onChange}
          />
        </Flex>
        <Flex maxWidth="200px">
          <CurrencyField
            {...CURRENCY_FIELD_FORM_FORMAT_PROPS}
            label="Amount"
            placeholder="ex: 10,000.00"
            onInput={onInput}
            required
            messageVariant="relative"
            {...form.register({ name: "value", type: "currency" })}
          />
        </Flex>
      </Flex>
    </form>
  );
};
