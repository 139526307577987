import React from "react";
import {
  Button,
  Card,
  ComboBox,
  DateField,
  Flex,
  Loader,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { useForm } from "@adaptive/design-system/hooks";
import type { DocumentType } from "@api/vendors/types";
import { DOCUMENT_TYPE_OPTIONS } from "@src/vendors/constants";
import { useDocumentAction } from "@store/vendors";
import { z } from "zod";

export type VendorDocument = {
  id: string | number;
  type: DocumentType;
  expirationDate: Date | null;
};

export type VendorDocumentEditFormProps = {
  onSubmit: (values: VendorDocument) => Promise<void>;
  initialValues: {
    id: VendorDocument["id"];
    type: VendorDocument["type"];
    expirationDate: VendorDocument["expirationDate"];
  };
};

const schema = z.object({
  type: z.enum([
    "INSURANCE",
    "W9",
    "CONTRACT",
    "OTHER",
    "WORKERS_COMP",
    "GENERAL_LIABILITY",
    "COMBINED_COI",
  ]),
  expirationDate: z.date().nullable(),
});
type Fields = z.infer<typeof schema>;

export const VendorDocumentEditForm = ({
  onSubmit,
  initialValues,
}: VendorDocumentEditFormProps) => {
  const id = initialValues?.id ?? "";

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      await onSubmit({
        id,
        ...values,
      });
    },
    initialValues,
  });

  const { toggleEditDocument } = useDocumentAction();

  const onCancel = useEvent(async () => {
    toggleEditDocument(id);
  });

  return (
    <Card>
      <Flex direction="column" gap="xl">
        <Flex justify="space-between" align="center" gap="xl">
          <ComboBox
            {...form.register("type")}
            required
            label="Document type"
            size="sm"
            disabled={form.isSubmitting}
            data={DOCUMENT_TYPE_OPTIONS}
          />
          <DateField
            size="sm"
            label="Expiration Date"
            {...form.register({
              name: "expirationDate",
              type: "date",
            })}
            disabled={form.isSubmitting}
          />
        </Flex>
      </Flex>
      <Flex gap="xl">
        <Button
          block
          color="neutral"
          variant="ghost"
          size="sm"
          disabled={form.isSubmitting}
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          block
          size="sm"
          disabled={!form.isValid || form.isSubmitting}
          onClick={() => form.submit()}
        >
          {form.isSubmitting ? <Loader /> : "Save"}
        </Button>
      </Flex>
    </Card>
  );
};
