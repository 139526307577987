import type { Dispatch, SetStateAction } from "react";
import type { MarkupResponse } from "@api/budgets";
import type { InvoicesInvoice } from "@api/invoices";
import type { BudgetLineItems, GetCustomerCategoriesResponse } from "@api/jobs";
import { noop } from "@utils/noop";
import { createContext } from "use-context-selector";

type JobContextType = {
  markups: MarkupResponse[];
  settings: {
    categoriesEnabled: boolean;
    clientChangeTrackingEnabled: boolean;
    changeTrackingEnabled: boolean;
    ownersAmountEnabled: boolean;
  };
  categories: GetCustomerCategoriesResponse;
  refetchJob: () => Promise<void>;
  permissions: { canManage: boolean; canEditInitialBudget: boolean };
  invoiceCreate: () => Promise<void>;
  invoiceIsLoading: boolean;
  categoriesIsLoading: boolean;
  budgetSelectedLines: BudgetLineItems[];
  setBudgetSelectedLines: Dispatch<SetStateAction<BudgetLineItems[]>>;
  invoiceSelectedDraws: InvoicesInvoice[];
  setInvoiceSelectedDraws: Dispatch<SetStateAction<InvoicesInvoice[]>>;
};

export const JobContext = createContext<JobContextType>({
  markups: [],
  settings: {
    categoriesEnabled: false,
    clientChangeTrackingEnabled: false,
    changeTrackingEnabled: false,
    ownersAmountEnabled: false,
  },
  categories: [],
  refetchJob: noop,
  permissions: { canManage: false, canEditInitialBudget: false },
  invoiceCreate: noop,
  invoiceIsLoading: false,
  categoriesIsLoading: false,
  budgetSelectedLines: [],
  setBudgetSelectedLines: noop,
  invoiceSelectedDraws: [],
  setInvoiceSelectedDraws: noop,
});
