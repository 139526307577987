import React, { useMemo } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Loader,
  Table,
  type TableColumn,
  Text,
  Textarea,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  createFormContext,
  useDeepMemo,
  UseDialogReturn,
  useEvent,
} from "@adaptive/design-system/hooks";
import {
  handleErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { postVendorPoRequest } from "@api/vendor-requests";
import { summarizeResults } from "@utils/all-settled";
import * as analytics from "@utils/analytics";
import { idSchema } from "@utils/schema";
import { type AnyZodObject, z } from "zod";

import { STRINGS } from "./constants";
import { PoSignatureRequestDialogV2 } from "./po-signature-request-dialogV2";
import { type PurchaseOrder } from "./request-vendor-po-signature";

export type PoRequestDialogProps = {
  dialog: UseDialogReturn;
  multiple: boolean;
  onSubmit?: () => void;
  purchaseOrders: PurchaseOrder[];
};

export type GetColumnsProps = {
  includeCustomMessage: boolean;
  dialogHide: () => void;
};

const partialVendorSchema = z.object({
  id: idSchema,
  displayName: z.string(),
});

const partiaPoSchema = z.object({
  message: z.string().nullish(),
  id: idSchema,
  docNumber: z.string(),
  totalAmount: z.union([z.string(), z.number()]),
  vendor: partialVendorSchema,
});

const partialSchema = z.object({
  applyMessageIndex: z.number().nullish(),
  includeCustomMessage: z.boolean(),
});

const setEmailSchema = partialSchema.extend({
  data: z.array(
    partiaPoSchema.extend({
      email: z.string().email("An email address is required"),
    })
  ),
});
type Fields = z.input<typeof setEmailSchema>;

const { useForm, FormConsumer, FormProvider } = createFormContext<Fields>();

const getColumns = ({ includeCustomMessage, dialogHide }: GetColumnsProps) => {
  const columns: TableColumn<Fields["data"][number]>[] = [
    {
      id: "doc_number",
      name: "PO #",
      width: "fill",
      align: includeCustomMessage ? "start" : "center",
      render: (row) => <Text size="sm">{row.docNumber}</Text>,
      minWidth: "content",
    },
    {
      id: "total-amount",
      name: "Amount",
      width: "fill",
      align: includeCustomMessage ? "start" : "center",
      render: (row) => <Text size="sm">{row.totalAmount}</Text>,
      minWidth: "content",
    },
    {
      id: "displayName",
      name: "Vendor name",
      width: "fill",
      align: includeCustomMessage ? "start" : "center",
      render: (row) => <Text size="sm">{row.vendor.displayName}</Text>,
      minWidth: "content",
    },
    {
      id: "email",
      name: "Email",
      width: 280,
      align: includeCustomMessage ? "start" : "center",
      render: (row, index) => (
        <FormConsumer>
          {(form) => (
            <TextField
              key={row.id}
              size="sm"
              type="email"
              required
              disabled={form.isSubmitting}
              placeholder="Enter an email address"
              messageVariant="hidden"
              {...form.register(`data.${index}.email`)}
            />
          )}
        </FormConsumer>
      ),
    },
  ];

  if (includeCustomMessage) {
    columns.push({
      id: "message",
      name: "Message",
      align: includeCustomMessage ? "start" : "center",
      width: 380,
      render: (row, index) => (
        <FormConsumer>
          {(form) => (
            <Flex gap="md" direction="column">
              <Textarea
                minHeight={70}
                maxHeight={70}
                disabled={form.isSubmitting}
                placeholder="Optional message included in the request email"
                messageVariant="hidden"
                {...form.register({
                  name: `data.${index}.message`,
                  type: "string",
                  onChange: (value) => {
                    if (form.values.applyMessageIndex === null) return;

                    if (form.values.applyMessageIndex !== index) {
                      return form.setValue("applyMessageIndex", null);
                    }

                    form.values.data.map((_, i) => {
                      if (i === index) return;

                      form.setValue(`data.${i}.message`, value);
                    });
                  },
                })}
              />
              <Checkbox
                label="Apply to all requests"
                checked={form.values.applyMessageIndex !== null}
                disabled={
                  (form.values.applyMessageIndex !== null &&
                    form.values.applyMessageIndex !== index) ||
                  form.isSubmitting
                }
                onChange={(checked) => {
                  form.setValue("applyMessageIndex", checked ? index : null);

                  if (checked) {
                    form.values.data.map((_, i) =>
                      form.setValue(
                        `data.${i}.message`,
                        form.values.data[index].message
                      )
                    );
                  }
                }}
              />
            </Flex>
          )}
        </FormConsumer>
      ),
    });
  }

  columns.push({
    id: "action",
    name: "Actions",
    align: includeCustomMessage ? "start" : "center",
    width: 115,
    render: (_, index) => (
      <FormConsumer>
        {(form) => (
          <Button
            size="sm"
            color="neutral"
            variant="ghost"
            disabled={form.isSubmitting}
            onClick={() => {
              const lastElement = form.values.data.length === 1;
              form.remove("data", index);
              if (lastElement) dialogHide();
            }}
            aria-label="Remove email"
            data-testid="po-email-remove-button"
          >
            Remove
          </Button>
        )}
      </FormConsumer>
    ),
  });

  return columns;
};

export const PoRequestDialog = ({
  dialog,
  multiple,
  onSubmit,
  purchaseOrders = [],
}: PoRequestDialogProps) => {
  const initialValues = useMemo<Partial<Fields>>(
    () => ({
      data: purchaseOrders.map((purchaseOrder) => ({
        email: purchaseOrder.vendor.email || "",
        docNumber: purchaseOrder.doc_number || "",
        totalAmount: purchaseOrder.total_amount || 0,
        id: purchaseOrder.id,
        vendor: {
          displayName: purchaseOrder.vendor.display_name,
          id: purchaseOrder.vendor.id,
        },
      })),
    }),
    [purchaseOrders]
  );

  const enhancedInitialValues = useMemo(
    () => ({
      data: initialValues.data?.forEach((e) => (e.message = "")) || [],

      applyMessageIndex: null,
      includeCustomMessage: false,
      ...initialValues,
    }),
    [initialValues]
  );

  const form = useForm<Fields>({
    /**
     * @todo figure out how to adjust this type issue without casting it
     */
    schema: setEmailSchema as unknown as AnyZodObject,
    async onSubmit({ data }) {
      const requests = data.map(async (po) => {
        try {
          await postVendorPoRequest({
            vendor_id: po.vendor.id,
            vendor_email: po.email,
            message: po.message,
            purchase_order_id: po.id,
          });
        } catch (error) {
          throw transformErrorToCustomError({
            error,
            extra: {
              docNumber: `PO#${po.docNumber || po.id}`,
            },
            render: (message) =>
              message == "PO already signed"
                ? `${STRINGS.PO_ALREADY_SIGNED_ERROR_TOAST}:`
                : `${message} on the following purchase orders:`,
          });
        }
        if (multiple) {
          analytics.track("purchaseOrderBatchActions", {
            action: "send-po-signature-request",
            location: "dialog",
            purchaseOrderIds: data.map(({ id }) => id),
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      const enhancedErrors = parseCustomErrors<{ docNumber: string }>({
        errors: errorResponses,
        render: ({ isFirst, message, docNumber }) =>
          `${message}${isFirst ? "" : ","} #${docNumber}`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 2 })
        );
      }
      if (success) {
        const plural = success > 1;
        toast.success(
          `Purchase order${plural ? "s" : ""} ${plural ? "were" : "was"} sent for signature`
        );
      }

      onSubmit?.();

      await dialog.hide();
    },
    initialValues: enhancedInitialValues,
  });

  const onChangeIncludeCustomMessage = useEvent((checked: boolean) => {
    if (checked) return;

    form.values.data.map((_, index) =>
      form.setValue(`data.${index}.message`, "")
    );
    form.setValue(`applyMessageIndex`, null);
  });

  const data = useDeepMemo(() => form.values.data, [form.values.data]);

  const dialogHide = dialog.hide;

  const columns = useMemo(
    () =>
      getColumns({
        includeCustomMessage: form.values.includeCustomMessage,
        dialogHide,
      }),
    [form.values.includeCustomMessage, dialogHide]
  );

  if (window.PO_SENDING_CHANGES_ENABLED)
    return (
      <PoSignatureRequestDialogV2
        dialog={dialog}
        multiple={multiple}
        onSubmit={onSubmit}
        purchaseOrders={purchaseOrders}
      />
    );

  return (
    <>
      {multiple ? (
        <Dialog
          size="auto"
          show={dialog.isVisible}
          variant="dialog"
          onClose={dialog.hide}
        >
          <DialogHeader>
            <Flex direction="column">
              <Text size="xl" weight="bold">
                {STRINGS.REQUEST_DIALOG_HEADER_MULTIPLE}
              </Text>
              <Text size="md">{STRINGS.REQUEST_DIALOG_CONTENT_MULTIPLE}</Text>
            </Flex>
          </DialogHeader>
          <DialogContent>
            <Flex
              as="form"
              gap="2xl"
              minWidth={form.values.includeCustomMessage ? "1220px" : "950px"}
              justify="center"
              direction="column"
              {...form.props}
            >
              <Checkbox
                label="Include a custom message"
                {...form.register({
                  name: "includeCustomMessage",
                  type: "boolean",
                  onChange: onChangeIncludeCustomMessage,
                })}
              />
              <FormProvider form={form}>
                <Table
                  id="po-request-dialog-table"
                  size="sm"
                  data={data}
                  columns={columns}
                  maxHeight="500px"
                />
              </FormProvider>
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Flex width="full">
              <Button
                block
                color="neutral"
                variant="text"
                onClick={dialog.hide}
              >
                Cancel
              </Button>
            </Flex>
            <Tooltip
              as={Flex}
              width="full"
              message={
                !form.isValid
                  ? "Email is required to send signature requests"
                  : ""
              }
              placement="left"
            >
              <Button
                type="submit"
                form={form.id}
                block
                disabled={form.isSubmitting || !form.isValid}
              >
                {form.isSubmitting ? <Loader /> : "Send"}
              </Button>
            </Tooltip>
          </DialogFooter>
        </Dialog>
      ) : (
        <Dialog
          size="auto"
          show={dialog.isVisible}
          variant="dialog"
          onClose={dialog.hide}
        >
          <DialogHeader>
            <Flex direction="column">
              <Text size="xl" weight="bold">
                {STRINGS.REQUEST_DIALOG_HEADER_SINGLE}
              </Text>
              <Text size="md">{form.values.data?.[0]?.vendor.displayName}</Text>
            </Flex>
          </DialogHeader>
          <DialogContent>
            <Flex
              as="form"
              gap="sm"
              justify="center"
              minWidth="480px"
              direction="column"
              {...form.props}
            >
              <TextField
                type="email"
                label="Email address"
                required
                placeholder="Enter the vendor's email address"
                {...form.register(`data.0.email`)}
              />
              <Textarea
                label="Message"
                minHeight={100}
                maxHeight={300}
                placeholder="Optional message included in the request email"
                messageVariant="hidden"
                {...form.register(`data.0.message`)}
              />
            </Flex>
          </DialogContent>
          <DialogFooter>
            <Flex width="full">
              <Button
                block
                color="neutral"
                variant="text"
                onClick={dialog.hide}
              >
                Cancel
              </Button>
            </Flex>
            <Tooltip
              as={Flex}
              width="full"
              message={
                !form.isValid ? "Email is required to send PO request" : ""
              }
              placement="left"
            >
              <Button
                type="submit"
                form={form.id}
                block
                disabled={form.isSubmitting || !form.isValid}
              >
                {form.isSubmitting ? <Loader /> : "Send"}
              </Button>
            </Tooltip>
          </DialogFooter>
        </Dialog>
      )}
    </>
  );
};
