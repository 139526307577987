import { QueryItem } from "@components/table-filter/formatters";
import {
  getApi,
  getUrlBuilder,
  handleResponse,
  transformPayloads,
} from "@utils/api";

import {
  workflowApprovalsResponseSchema,
  workflowSchema,
  workflowsResponseSchema,
} from "./response/schema";
import type {
  Workflow,
  WorkflowApprovalsResponse,
  WorkflowsApprovalsCreateRequest,
  WorkflowsApprovalsUpdateRequest,
  WorkflowsRequest,
  WorkflowsResponse,
} from "./types";

export const api = getApi([transformPayloads]);
const urlBuilderTemplate = getUrlBuilder("/api/workflowtemplates/");
const urlBuilderWorkflow = getUrlBuilder("/api/workflows/");

export const get = async (filters: QueryItem[]) => {
  const queryParams = new URLSearchParams();
  queryParams.append("order_by", "-created_at");

  filters.forEach((filter: QueryItem) => {
    queryParams.append(filter.dataIndex, `${filter.value?.toString()}`);
  });

  return await api
    .get<WorkflowsResponse>(
      `${urlBuilderTemplate.forCollection()}?${queryParams.toString()}`
    )
    .then((resp) => handleResponse(resp.data, workflowsResponseSchema))
    .then((data) => data.results);
};
export const create = async (payload: WorkflowsRequest) =>
  await api
    .post<Workflow>(urlBuilderTemplate.forCollection(), payload)
    .then((resp) => handleResponse(resp.data, workflowSchema));

export const put = async (payload: WorkflowsRequest) =>
  await api
    .put<Workflow>(urlBuilderTemplate.forItem(payload), payload)
    .then((resp) => handleResponse(resp.data, workflowSchema));

export const destroy = async (payload: { id: string }) =>
  await api.delete(urlBuilderTemplate.forItem(payload));

export const createApprovals = async (
  payload: WorkflowsApprovalsCreateRequest
) =>
  api
    .post<WorkflowApprovalsResponse>(
      urlBuilderWorkflow.forCollection(),
      payload
    )
    .then((resp) => handleResponse(resp.data, workflowApprovalsResponseSchema));

export const putApprovals = async (payload: WorkflowsApprovalsUpdateRequest) =>
  await api
    .put<WorkflowApprovalsResponse>(
      urlBuilderWorkflow.forItem(payload),
      payload
    )
    .then((resp) => handleResponse(resp.data, workflowApprovalsResponseSchema));

export const destroyWorkflow = async (payload: { id: string }) =>
  await api.delete(urlBuilderWorkflow.forItem(payload));

export const workflowApi = {
  get,
  create,
  put,
  putApprovals,
  createApprovals,
  destroy,
  destroyWorkflow,
};
