import React, {
  type ComponentPropsWithoutRef,
  type CSSProperties,
  type ReactNode,
  useEffect,
} from "react";
import {
  CompositeRow,
  MenuItem,
  MenuItemCheckbox,
  MenuItemRadio,
  useMenuContext,
} from "@ariakit/react";
import {
  DragDropContext,
  Draggable,
  type DraggableProvided,
  type DraggableStateSnapshot,
  Droppable,
  type OnDragEndResponder,
} from "@hello-pangea/dnd";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { suffixify } from "../../utils/suffixify";
import { Icon } from "../icon";
import { Wrapper } from "../wrapper";

import { useDropdown } from "./dropdown-context";
import checkboxStyles from "../checkbox/checkbox.module.css";
import radioStyles from "../radio/radio.module.css";
import styles from "./dropdown.module.css";

type DropdownSelectableItemRowProps = {
  children: ReactNode;
  snapshot: DraggableStateSnapshot;
  draggable: DraggableProvided;
};

const DropdownSelectableItemRow = ({
  children,
  snapshot,
  draggable: {
    draggableProps: { style, ...draggableProps },
    ...draggable
  },
}: DropdownSelectableItemRowProps) => (
  <CompositeRow
    ref={draggable.innerRef}
    role="row"
    style={style as CSSProperties}
    className={cn(styles["dropdown-selectable-item-draggable-item"], {
      [styles["-dragging"]]: snapshot.isDragging,
    })}
    {...draggableProps}
  >
    {children}
    <Icon
      as={MenuItem}
      name="grip-dots-vertical"
      role={undefined}
      render={<button />}
      variant="solid"
      hideOnClick={false}
      className={styles["dropdown-selectable-item-draggable-handle"]}
      {...draggable.dragHandleProps}
      tabIndex={-1}
    />
  </CompositeRow>
);

type DefaultComponent = "div";

type Data = {
  label: ReactNode;
  value: string;
  disabled?: boolean;
};

type OwnProps = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "value" | "onChange" | "children"
> & {
  name: string;
  data?: Data[];
};

export type DropdownSelectableItemSingleOnChangeHandler = (
  value: string,
  option: Data
) => void;

export type DropdownSelectableItemSingleProps = OwnProps & {
  value?: string;
  multiple?: never;
  onChange?: DropdownSelectableItemSingleOnChangeHandler;
  onOrderChange?: never;
};

export type DropdownSelectableItemMultipleOnChangeHandler = (
  value: string[],
  option: Data[]
) => void;

export type DropdownSelectableItemOnOrderChangeHandler = (data: Data[]) => void;

export type DropdownSelectableItemMultipleProps = OwnProps & {
  value?: string[];
  multiple: true;
  onChange?: DropdownSelectableItemMultipleOnChangeHandler;
  onOrderChange?: DropdownSelectableItemOnOrderChangeHandler;
};

type DropdownSelectableItemProps =
  | DropdownSelectableItemSingleProps
  | DropdownSelectableItemMultipleProps;

export const DropdownSelectableItem = ({
  data = [],
  name,
  value,
  onChange,
  multiple,
  className,
  onOrderChange,
  ...props
}: DropdownSelectableItemProps) => {
  const menuContext = useMenuContext();

  const { hideOnClick, setSetValues, setInternalValue } = useDropdown();

  const internalValue = menuContext?.useState("values")?.[name] || "";

  const Component = multiple ? MenuItemCheckbox : MenuItemRadio;

  const onDragEnd = useEvent<OnDragEndResponder>(({ source, destination }) => {
    if (!destination) return;

    const sourceIndex = Number(source.index);
    const destinationIndex = Number(destination.index);

    const newData = [...data];

    newData.splice(destinationIndex, 0, newData.splice(sourceIndex, 1)[0]);

    onOrderChange?.(newData);
  });

  const renderContent = (item: Data) => (
    <Component
      className={cn(
        className,
        styles["dropdown-selectable-item"],
        styles["-neutral"],
        styles["-interactive"]
      )}
      hideOnClick={hideOnClick ?? (multiple ? false : true)}
      role={multiple ? "checkbox" : "radio"}
      name={name}
      value={item.value}
      disabled={item.disabled}
      {...props}
    >
      <div
        className={
          multiple
            ? cn(checkboxStyles["wrapper"], {
                [checkboxStyles["-checked"]]: (
                  internalValue as (string | number)[]
                ).includes(item.value),
                [checkboxStyles["-disabled"]]: item.disabled,
              })
            : cn(radioStyles["wrapper"], {
                [radioStyles["-checked"]]: internalValue === item.value,
                [radioStyles["-disabled"]]: item.disabled,
              })
        }
      >
        <div
          className={
            multiple ? checkboxStyles["checkbox"] : radioStyles["radio"]
          }
        />
      </div>
      {item.label}
    </Component>
  );

  const setValues = useEvent(
    (values: Record<string, string | false | string[]>) => {
      const nextValue = values[name];

      if (nextValue === false) return;

      if (value === undefined) setInternalValue?.({ [name]: nextValue });

      if (Array.isArray(nextValue)) {
        (onChange as DropdownSelectableItemMultipleOnChangeHandler)?.(
          nextValue,
          data.filter((item) => nextValue.includes(item.value))
        );
      } else {
        (onChange as DropdownSelectableItemSingleOnChangeHandler)?.(
          nextValue,
          data.find((item) => item.value === nextValue)!
        );
      }
    }
  );

  useEffect(() => {
    setSetValues?.(() => setValues);
  }, [setValues, setSetValues]);

  useEffect(() => {
    if (value === undefined) return;

    setInternalValue?.({ [name]: value });
  }, [name, setInternalValue, value]);

  return (
    <Wrapper
      when={!!onOrderChange}
      render={(children) => (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={suffixify(name, "droppable")!}
            renderClone={(provided, snapshot, rubric) => (
              <DropdownSelectableItemRow
                snapshot={snapshot}
                draggable={provided}
              >
                {renderContent(data[rubric.source.index])}
              </DropdownSelectableItemRow>
            )}
          >
            {(droppable) => (
              <div
                ref={droppable.innerRef}
                className={styles["dropdown-selectable-item-droppable-area"]}
              >
                {children}
                {droppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    >
      {data.map((item, i) => (
        <Wrapper
          key={suffixify(name, i)}
          when={!!onOrderChange}
          render={(children) => (
            <Draggable
              index={i}
              draggableId={suffixify(name, i)!}
              disableInteractiveElementBlocking
            >
              {(draggable, snapshot) => (
                <DropdownSelectableItemRow
                  snapshot={snapshot}
                  draggable={draggable}
                >
                  {children}
                </DropdownSelectableItemRow>
              )}
            </Draggable>
          )}
        >
          <Wrapper
            when={!onOrderChange}
            render={(children) => (
              <CompositeRow role="row">{children}</CompositeRow>
            )}
          >
            {renderContent(item)}
          </Wrapper>
        </Wrapper>
      ))}
    </Wrapper>
  );
};
