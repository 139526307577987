import type { AnyFunction, DefinitelyFunction, NarrowedTo } from "./types";

/**
 * Helper function to check if a value is an array.
 * @example
 * is.array([1, 2, 3]); // true
 * is.array('hello'); // false
 */
const isArray = <T>(
  value: ArrayLike<unknown> | T
): value is NarrowedTo<T, ReadonlyArray<unknown>> => Array.isArray(value);

/**
 * Helper function to check if a value is a boolean.
 * @example
 * is.boolean(true); // true
 * is.boolean('hello'); // false
 */
const isBoolean = <T>(value: T | boolean): value is NarrowedTo<T, boolean> =>
  typeof value === "boolean";

/**
 * Helper function to check if a value is a true.
 * @example
 * is.true(true); // true
 * is.true(false); // false
 * is.true('hello'); // false
 */
const isTrue = <T>(value: T | true): value is NarrowedTo<T, true> =>
  isBoolean(value) && value === true;

/**
 * Helper function to check if a value is a false.
 * @example
 * is.false(false); // true
 * is.false(true); // false
 * is.false('hello'); // false
 */
const isFalse = <T>(value: T | false): value is NarrowedTo<T, false> =>
  isBoolean(value) && value === false;

/**
 * Helper function to check if a value is a date.
 * @example
 * is.date(new Date()); // true
 * is.date('hello'); // false
 */
const isDate = (value: unknown) => value instanceof Date;

/**
 * Helper function to check if a value is empty.
 * @example
 * is.empty('hello'); // false
 * is.empty(''); // true
 * is.empty([]); // true
 * is.empty({}); // true
 * is.empty(null); // true
 * is.empty(undefined); // true
 * is.empty(0); // false
 */
const isEmpty = (value: object | number | string | undefined | null) => {
  if (isNullish(value)) {
    return true;
  }

  if (isString(value)) {
    return value.length === 0;
  }

  if (typeof value !== "object") {
    return false;
  }

  if (isArray(value)) {
    return value.length === 0;
  }

  return Object.keys(value).length === 0;
};

/**
 * Helper function to check if a value is NaN.
 * @example
 * is.nan(NaN); // true
 * is.nan(0); // false
 */
const isNaN = (value: unknown): boolean =>
  typeof value === "number" && Number.isNaN(value);

/**
 * Helper function to check if a value is null.
 * @example
 * is.null(null); // true
 * is.null(undefined); // false
 * is.null('hello'); // false
 * is.null(0); // false
 */
const isNull = (value: unknown): value is null => value === null;

/**
 * Helper function to check if a value is nullish.
 * @example
 * is.nullish(null); // true
 * is.nullish(undefined); // true
 * is.nullish('hello'); // false
 * is.nullish(0); // false
 */
const isNullish = <T>(
  value: T | null | undefined
): value is NarrowedTo<T, null | undefined> =>
  isNull(value) || isUndefined(value);

/**
 * Helper function to check if a value is a number.
 * @example
 * is.number(0); // true
 * is.number('hello'); // false
 * is.number(NaN); // false
 */
const isNumber = <T>(value: T | number): value is NarrowedTo<T, number> =>
  typeof value === "number" && !isNaN(value);

/**
 * Helper function to check if a value is an object.
 * @example
 * is.object({}); // true
 * is.object([]); // false
 * is.object('hello'); // false
 */
const isObject = <T>(
  value: Readonly<Record<PropertyKey, unknown>> | T
): value is NarrowedTo<T, Record<PropertyKey, unknown>> => {
  if (typeof value !== "object" || isNull(value)) {
    return false;
  }

  const proto = Object.getPrototypeOf(value);
  return isNull(proto) || proto === Object.prototype;
};

/**
 * Helper function to check if a value is a string.
 * @example
 * is.string('hello'); // true
 * is.string(0); // false
 * is.string([]); // false
 */
const isString = <T>(value: T | string): value is NarrowedTo<T, string> =>
  typeof value === "string";

/**
 * Helper function to check if a value is undefined.
 * @example
 * is.undefined(undefined); // true
 * is.undefined(null); // false
 * is.undefined('hello'); // false
 * is.undefined(0); // false
 */
const isUndefined = (value: unknown): value is undefined => value === undefined;

/**
 * Helper function to check if a value is function.
 * @example
 * is.function(() => {}); // true
 * is.function(function() {}); // true
 * is.function('hello'); // false
 * is.function(0); // false
 */
const isFunction = <T>(data: AnyFunction | T): data is DefinitelyFunction<T> =>
  typeof data === "function";

/**
 * Complete util for quick/safe type checks on values.
 * Inspired by https://github.com/remeda/remeda/
 */
export const is = {
  array: isArray,
  boolean: isBoolean,
  true: isTrue,
  false: isFalse,
  date: isDate,
  empty: isEmpty,
  function: isFunction,
  nan: isNaN,
  null: isNull,
  nullish: isNullish,
  number: isNumber,
  object: isObject,
  string: isString,
  undefined: isUndefined,
};
