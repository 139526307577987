import React from "react";
import { Flex, type TableColumn, Text, Tooltip } from "@adaptive/design-system";
import type { BudgetLineItems } from "@api/jobs";

import {
  CostsActualColumn,
  CostsActualFooter,
  CostsBudgetColumn,
  CostsBudgetFooter,
  CostsBudgetHeader,
  CostsBudgetRemainingHeader,
  CostsChangesColumn,
  CostsChangesFooter,
  CostsChangesHeader,
  CostsRemainingColumn,
  CostsRemainingFooter,
  CostsRevisedBudgetColumn,
  CostsRevisedBudgetFooter,
  CostsRevisedBudgetHeader,
  CostsUnpaidColumn,
  CostsUnpaidFooter,
  EmptyColumn,
  RevenuesChangesColumn,
  RevenuesChangesFooter,
  RevenuesChangesHeader,
  RevenuesDrawnColumn,
  RevenuesDrawnFooter,
  RevenuesDrawnHeader,
  RevenuesDrawnRemainingColumn,
  RevenuesDrawnRemainingFooter,
  RevenuesDrawRemainingHeader,
  RevenuesMarkupColumn,
  RevenuesOwnersBudgetColumn,
  RevenuesOwnersBudgetFooter,
  RevenuesOwnersBudgetHeader,
  RevenuesRevisedBudgetColumn,
  RevenuesRevisedBudgetFooter,
  RevenuesRevisedBudgetHeader,
  TransactionsCategoryColumn,
  TransactionsCategoryFooter,
  TransactionsLineColumn,
  TransactionsLineFooter,
} from "./lines-components";

type LinesColumn = Omit<TableColumn<BudgetLineItems>, "width">;

export const getIsDeletable = (row: BudgetLineItems) => {
  return (
    row.jobCostMethod.url !== row.jobCostMethod.parent &&
    !row.spent &&
    !row.hasInvoices &&
    !row.hasChanges
  );
};

/**
 * Columns definition
 */
type GetLineColumnsProps = {
  hasCategories: boolean;
  hasChanges: boolean;
  hasOwnersAmount: boolean;
  hasExternalRevisedBudget: boolean;
};

export const getLineColumns = ({
  hasCategories,
  hasChanges,
  hasOwnersAmount,
  hasExternalRevisedBudget,
}: GetLineColumnsProps) => {
  const transactionsName: LinesColumn["name"][] = [
    <Text weight="bold" key="transactions-item-name">
      Line items
    </Text>,
  ];
  const transactionsRender: LinesColumn["render"] = [
    (row) => <TransactionsLineColumn {...row} />,
  ];
  const transactionsFooter = [
    <TransactionsLineFooter key="transactions-item-footer" />,
  ];

  if (hasCategories) {
    transactionsName.push(<Text weight="bold">Categories</Text>);
    transactionsRender.push((row) => <TransactionsCategoryColumn {...row} />);
    transactionsFooter.push(
      <TransactionsCategoryFooter key="transactions-categories-footer" />
    );
  }

  const costsName: LinesColumn["name"][] = [
    <CostsBudgetHeader key="costs-budget-header" />,
  ];
  const costsRender: LinesColumn["render"] = [
    (row) => <CostsBudgetColumn {...row} />,
  ];
  const costsFooter = [<CostsBudgetFooter key="costs-budget-footer" />];

  if (hasChanges) {
    costsName.push(<CostsChangesHeader />);
    costsRender.push((row) => <CostsChangesColumn {...row} />);
    costsFooter.push(<CostsChangesFooter key="costs-changes-footer" />);

    costsName.push(<CostsRevisedBudgetHeader />);
    costsRender.push((row) => <CostsRevisedBudgetColumn {...row} />);
    costsFooter.push(
      <CostsRevisedBudgetFooter key="costs-revised-budget-footer" />
    );
  }

  costsName.push(
    <Flex justify="flex-end">
      <Tooltip message="Total cost of approved bills & receipts">
        <Text align="right" weight="bold">
          Actual costs to date
        </Text>
      </Tooltip>
    </Flex>
  );
  costsRender.push((row) => <CostsActualColumn {...row} />);
  costsFooter.push(<CostsActualFooter key="costs-actual-footer" />);

  if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) {
    costsName.push(
      <Flex justify="flex-end">
        <Tooltip message="Total cost of unpaid bills">
          <Text align="right" weight="bold">
            Unpaid bills
          </Text>
        </Tooltip>
      </Flex>
    );
    costsRender.push((row) => <CostsUnpaidColumn {...row} />);
    costsFooter.push(<CostsUnpaidFooter key="costs-unpaid-footer" />);
  }

  costsName.push(<CostsBudgetRemainingHeader />);
  costsRender.push((row) => <CostsRemainingColumn {...row} />);
  costsFooter.push(<CostsRemainingFooter key="costs-remaining-footer" />);

  const lineColumns: LinesColumn[] = [
    {
      id: "transactions",
      name: transactionsName,
      render: transactionsRender,
      footer: transactionsFooter,
      align: "center",
    },
    {
      id: "costs",
      name: costsName,
      render: costsRender,
      footer: costsFooter,
      align: "center",
    },
  ];

  const revenuesName: LinesColumn["name"][] = [];
  const revenuesRender: LinesColumn["render"] = [];
  const revenuesFooter = [];

  if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
    revenuesName.push("Markup");
    revenuesRender.push((row) => <RevenuesMarkupColumn {...row} />);
    revenuesFooter.push(<EmptyColumn key="revenues-empty-markup-footer" />);
  }

  if (hasOwnersAmount) {
    revenuesName.push(<RevenuesOwnersBudgetHeader />);
    revenuesRender.push((row) => <RevenuesOwnersBudgetColumn {...row} />);
    revenuesFooter.push(
      <RevenuesOwnersBudgetFooter key="revenues-owners-budget-footer" />
    );

    if (hasChanges && hasExternalRevisedBudget) {
      /* revised owners budget */
      revenuesName.push(<RevenuesChangesHeader />);
      revenuesRender.push((row) => <RevenuesChangesColumn {...row} />);
      revenuesFooter.push(
        <RevenuesChangesFooter key="revenues-owners-revised-budget-footer" />
      );

      revenuesName.push(<RevenuesRevisedBudgetHeader />);
      revenuesRender.push((row) => <RevenuesRevisedBudgetColumn {...row} />);
      revenuesFooter.push(
        <RevenuesRevisedBudgetFooter key="revenues-owners-revised-budget-footer" />
      );
    }
  }

  revenuesName.push(
    <Flex justify="flex-end">
      <Tooltip message="Total amount on draft & synced draws">
        <RevenuesDrawnHeader />
      </Tooltip>
    </Flex>
  );
  revenuesRender.push((row) => <RevenuesDrawnColumn {...row} />);
  revenuesFooter.push(<RevenuesDrawnFooter key="revenues-drawn-footer" />);

  revenuesName.push(<RevenuesDrawRemainingHeader />);
  revenuesRender.push((row) => <RevenuesDrawnRemainingColumn {...row} />);
  revenuesFooter.push(
    <RevenuesDrawnRemainingFooter key="revenues-drawn-remaining-footer" />
  );

  lineColumns.push({
    id: "revenues",
    name: revenuesName,
    render: revenuesRender,
    footer: revenuesFooter,
    align: "center",
  });

  return lineColumns;
};
