import React, { type ReactNode } from "react";
import { Flex, Text } from "@adaptive/design-system";
import { Layout } from "@components/layout";

import { STRINGS, VENDOR_REQUEST_TYPES } from "./constants";

export const RequestFormExpired = ({
  companyName,
  type,
}: {
  companyName: string;
  type?: string;
}) => {
  return window.PO_SENDING_CHANGES_ENABLED &&
    type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Flex direction="column" minHeight="150px">
      <Text weight="semi-bold">{`This request has expired. To make changes to your information, contact ${companyName}`}</Text>
    </Flex>
  ) : (
    <Layout
      title={STRINGS.EXPIRED_LINK_TITLE}
      subtitle={`To make changes to your information, contact ${companyName}`}
    />
  );
};

export const RequestError = ({ type }: { type?: string }) => {
  return window.PO_SENDING_CHANGES_ENABLED &&
    type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Flex direction="column" minHeight="150px">
      <Text weight="bold">{STRINGS.REQUEST_ERROR_TITLE}</Text>
    </Flex>
  ) : (
    <Layout title={STRINGS.REQUEST_ERROR_TITLE} />
  );
};

export const RequestFormSubmitted = ({
  message,
  type,
  children,
}: {
  message: string;
  type?: string;
  children?: ReactNode;
}) => {
  return window.PO_SENDING_CHANGES_ENABLED &&
    type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"] ? (
    <Flex direction="column" minHeight="150px">
      <Text weight="semi-bold">{message}</Text>
    </Flex>
  ) : (
    <Layout title={STRINGS.REQUEST_FORM_SUBMITTED_TITLE} subtitle={message}>
      {/* This is for "Add document" button */}
      {children}
    </Layout>
  );
};
