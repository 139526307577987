import React from "react";
import type { TableColumn } from "@adaptive/design-system";
import type { Change } from "@api/jobs/changes";

import {
  ActionColumn,
  ChangeAmountColumn,
  ChangeAmountFooter,
  ChangeRevisedAmountColumn,
  ChangeRevisedAmountFooter,
  CostCodeAccountsColumn,
  DescriptionColumn,
} from "./changes-dialog-components";

export const getChangeColumns = ({
  changeTrackingEnabled,
  externalBudgetEnabled,
}: {
  changeTrackingEnabled: boolean;
  externalBudgetEnabled: boolean;
}): TableColumn<Change>[] => [
  {
    id: "referenceNumber",
    name: "Ref #",
    render: "docNumber",
    footer: "Total",
  },
  {
    id: "costCodesAccounts",
    name: "Cost codes / Accounts",
    render: (row) => <CostCodeAccountsColumn {...row} />,
  },
  {
    id: "description",
    name: "Description",
    width: "fill",
    render: (row) => <DescriptionColumn {...row} />,
  },
  {
    id: "changeAmount",
    name: "Cost budget",
    textAlign: "right",
    render: (row) => <ChangeAmountColumn {...row} />,
    footer: () => <ChangeAmountFooter />,
  },
  ...(changeTrackingEnabled && externalBudgetEnabled
    ? ([
        {
          id: "externalBudget",
          name: "External budget",
          textAlign: "right",
          render: (row) => <ChangeRevisedAmountColumn {...row} />,
          footer: () => <ChangeRevisedAmountFooter />,
        },
      ] as TableColumn<Change>[])
    : []),
  {
    id: "actions",
    name: "Actions",
    width: 110,
    render: (row) => <ActionColumn {...row} />,
  },
];
