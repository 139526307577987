import { useCallback, useState } from "react";
import { handleErrors } from "@api/handle-errors";
import { summarizeResults } from "@utils/all-settled";
import { api } from "@utils/api";

type UseApplyObjectProps = {
  items: { id: string | number; url: string }[];
  resource: string;
};

type ExecuteHandlerOptions = {
  value: string | null;
  items?: { id: string | number; url: string }[];
  method?: "apply_object" | "apply_billable_status" | "apply_object_batch";
  fieldName?: string;
  syncInvoiceLines?: boolean;
  syncTransactionLines?: boolean;
};

export const useApplyObject = ({ items, resource }: UseApplyObjectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const execute = useCallback(
    async (options: ExecuteHandlerOptions) => {
      setIsLoading(true);

      const method = options.method ?? "apply_object";
      const enhancedItems = options.items ?? items;

      try {
        if (method === "apply_object" || method === "apply_billable_status") {
          const requests = enhancedItems.map((item) =>
            api.put(`/api/${resource}/${item.id}/${method}/`, {
              [method === "apply_object" ? "object_url" : "billable_status"]:
                options.value,
              field_name: options.fieldName,
              sync_invoice_lines: options.syncInvoiceLines ?? false,
            })
          );

          return summarizeResults(await Promise.allSettled(requests));
        } else if (method === "apply_object_batch") {
          const response = await api.put<
            { transactions_error_messages?: string[] }[]
          >(`/api/${resource}/apply_object_batch/`, {
            object_url: options.value,
            lines: enhancedItems.map(({ url }) => url),
            sync_invoice_lines: options.syncInvoiceLines ?? false,
            sync_transaction_lines: options.syncTransactionLines ?? false,
          });

          response.data.forEach((item) => {
            item.transactions_error_messages?.forEach((error) => {
              handleErrors(error);
            });
          });

          return {
            error: 0,
            success: enhancedItems.length,
            errorResponses: [],
            successResponses: response.data,
          };
        }

        throw Error("Invalid method");
      } finally {
        setIsLoading(false);
      }
    },
    [items, resource]
  );

  return { isLoading, execute };
};
