import React from "react";
import { dialog, Flex, Text } from "@adaptive/design-system";

import { renderLinkedInvoices } from "../render-linked-invoices";
import { DialogProps } from "../types";

export const confirmSyncInvoiceLines = ({
  linkedInvoices = [],
  isPlural = false,
  action,
}: DialogProps) => {
  dialog.confirmation({
    title: `${
      isPlural ? "These transactions have" : "This transaction has"
    } line items that are already added to a draw`,
    message: (
      <Flex direction="column" gap="xl">
        <Text align="center">
          {
            "Would you like to save these edits and update the corresponding linked draw "
          }
          {renderLinkedInvoices(linkedInvoices)}
          {"?"}
        </Text>
        <Text as="i" size="sm" align="center">
          Note: if you are deleting a cost or changing the job code, pressing{" "}
          <Text as="span" color="brand-2">
            Update line on draw
          </Text>{" "}
          will delete the corresponding line from the linked draw.
        </Text>
      </Flex>
    ),
    action,
  });
};
