import {
  budgetLineItemsSchema,
  jobCostMethodSchema,
} from "@shared/api/jobs/response/schema";
import { currencySchema, decimalAmountSchema, idSchema } from "@utils/schema";
import { z } from "zod";

import { markupTypeSchema } from "../request";

export const markupSimpleSchema = z.object({
  id: z.number(),
  value: decimalAmountSchema.nullish(),
  ownersValue: decimalAmountSchema.nullish(),
  markupType: markupTypeSchema.default("percentage"),
  isSeparateLine: z.boolean().nullish(),
});

export const markupSchema = markupSimpleSchema.extend({
  name: z.string().nullish(),
  budgetLine: budgetLineItemsSchema.nullish(),
  color: z.string().optional(),
  invoicedAmount: currencySchema,
  builderAmount: currencySchema,
  changeAmount: currencySchema,
  builderRevisedAmount: currencySchema,
  externalChangeAmount: currencySchema,
  ownersRevisedAmount: currencySchema,
  ownersAmount: currencySchema,
  jobCostMethod: jobCostMethodSchema,
  sourceType: z.string(),
});

export const budgetLineMarkupResponseSchema = budgetLineItemsSchema.extend({
  markup: markupSimpleSchema.nullish(),
});

export const budgetLineMarkupsResponseSchema = z.array(
  budgetLineMarkupResponseSchema
);

export const markupsSchema = z.array(markupSchema);

export const customerItemSchemaBase = z.object({
  id: z.number(),
  url: z.string().url(),
  displayName: z.string(),
  parent: z.string().url().nullish(),
  spent: z.number(),
  unpaidBills: z.number(),
  budgetLineId: idSchema.nullish(),
});

export const customerItemResponseSchema = customerItemSchemaBase.extend({
  data: z.lazy(() => customerItemSchemaBase.array().optional()),
});
