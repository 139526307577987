import React, {
  type ComponentPropsWithoutRef,
  createContext,
  type MutableRefObject,
  type ReactNode,
  useRef,
} from "react";
import { useResizeObserver } from "@adaptive/design-system/hooks";
import cn from "clsx";

import styles from "./sticky.module.css";

const StickContext = createContext<{
  enabled: boolean;
  stickRef: MutableRefObject<HTMLDivElement | null>;
  measureRef: MutableRefObject<HTMLDivElement | null>;
}>({
  enabled: true,
  stickRef: { current: null },
  measureRef: { current: null },
});

export type StickyProviderOnResizeHandler = (props: {
  sticky: DOMRect;
  measurer: DOMRect;
}) => void;

export type StickyProviderProps = {
  enabled?: boolean;
  children: ReactNode;
  onResize?: StickyProviderOnResizeHandler;
};

export const StickyProvider = ({
  enabled = true,
  onResize,
  ...props
}: StickyProviderProps) => {
  const stickRef = useRef<HTMLDivElement>(null);

  const measureRef = useRef<HTMLDivElement>(null);

  useResizeObserver(measureRef, (el) => {
    if (!stickRef.current) return;

    onResize?.({
      sticky: stickRef.current.getBoundingClientRect(),
      measurer: el.getBoundingClientRect(),
    });

    stickRef.current.style.setProperty(
      "--sticky-virtual-width",
      `${el.scrollWidth}px`
    );
  });

  useResizeObserver(stickRef, (el) => {
    if (!measureRef.current) return;

    onResize?.({
      sticky: el.getBoundingClientRect(),
      measurer: measureRef.current.getBoundingClientRect(),
    });
  });

  return (
    <StickContext.Provider
      value={{ enabled, stickRef, measureRef }}
      {...props}
    />
  );
};

export type StickyProps = ComponentPropsWithoutRef<"div">;

export const Sticky = ({ className, ...props }: StickyProps) => (
  <StickContext.Consumer>
    {({ stickRef, enabled }) => (
      <div
        ref={stickRef}
        className={cn(className, styles["sticky"], {
          [styles["-enabled"]]: enabled,
        })}
        {...props}
      />
    )}
  </StickContext.Consumer>
);

export type StickyMeasurerProps = ComponentPropsWithoutRef<"div">;

export const StickyMeasurer = (props: StickyMeasurerProps) => (
  <StickContext.Consumer>
    {({ measureRef }) => <div ref={measureRef} {...props} />}
  </StickContext.Consumer>
);
