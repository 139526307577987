import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  ComboBox,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  toast,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useForm,
  type UseMultiStepDialogReturn,
} from "@adaptive/design-system/hooks";
import type { AccountBalanceV1 } from "@api/bank-accounts";
import logo from "@assets/images/default-bank-logo.png";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { z } from "zod";

import { Step } from "../types";

type Props = {
  putObjects: (object: any) => void;
  selectHeader: string;
  dialog: UseMultiStepDialogReturn<Step>;
  unlinkedAccountBalances: AccountBalanceV1[];
};

type Fields = z.infer<typeof schema>;

const schema = z.object({
  balances: z.array(
    z.object({
      account_balance: z.string(),
      payment_account: z.string().min(1, "Payment account is required"),
    })
  ),
});

export const MultiAccountSetForm = ({
  putObjects,
  selectHeader,
  dialog,
  unlinkedAccountBalances,
}: Props) => {
  const initialValues = useDeepMemo(
    () => ({
      balances: unlinkedAccountBalances.map((account) => ({
        account_balance: account.url,
        payment_account: "",
      })),
    }),
    [unlinkedAccountBalances]
  );

  const { reset, ...form } = useForm<Fields>({
    schema,
    async onSubmit(values) {
      await putObjects(values.balances);
      dialog.hide();
      toast.success("Successfully updated!");
    },
    initialValues,
  });

  useEffect(() => {
    reset();
  }, [reset, unlinkedAccountBalances]);

  const { refetch, ...accounts } = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  useEffect(() => {
    refetch();
  }, [dialog.step, dialog.isVisible, refetch]);

  return (
    <>
      <DialogHeader>{selectHeader}</DialogHeader>
      <DialogContent>
        <Flex as="form" direction="column" gap="xl" {...form.props}>
          {unlinkedAccountBalances.map(
            (account: AccountBalanceV1, index: number) => (
              <Card key={index}>
                <Flex direction="column">
                  <Flex gap="xl" justify="space-between">
                    <Flex align="center">
                      <Flex gap="lg">
                        <Avatar src={logo} name={account.bank_name} size="sm" />
                        <Text
                          weight="bold"
                          color="neutral-800"
                        >{`${account.name}  ****${account.mask}`}</Text>
                      </Flex>
                    </Flex>
                    <Flex>
                      <ComboBox
                        label="Choose an account in QuickBooks"
                        placeholder="Select an account"
                        data={accounts.data}
                        loading={accounts.status === "loading"}
                        {...form.register(`balances.${index}.payment_account`)}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            )
          )}
        </Flex>
        <Flex direction="column">
          <Flex
            height="2xl"
            direction="row"
            justify="space-between"
            padding={["2xl", "none"]}
          >
            <Flex separator direction="column">
              <Flex height="2xl" padding={["lg", "5xl"]} />
              <Flex />
            </Flex>
            <Flex direction="column">
              <Flex height="2xl" />
              <Text color="neutral-700">or</Text>
            </Flex>
            <Flex separator direction="column">
              <Flex height="2xl" padding={["lg", "5xl"]} />
              <Flex />
            </Flex>
          </Flex>
          <Flex height="2xl" />
          <Button
            variant="ghost"
            color="primary"
            onClick={() => dialog.setStep("create-account")}
          >
            Create new
          </Button>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" variant="text" onClick={dialog.hide}>
          Cancel
        </Button>
        <Button block type="submit" form={form.id} disabled={form.isSubmitting}>
          Connect
        </Button>
      </DialogFooter>
    </>
  );
};
