import React, { type ReactNode, useState } from "react";

import {
  type Context,
  DialogContext,
  useDialogContext,
} from "./dialog-context";

type Props = Context & {
  children?: ReactNode;
};

export const DialogProvider = ({ children, ...value }: Props) => {
  const context = useDialogContext();

  const [hasTabs, setHasTabs] = useState(false);
  const [hasHeader, setHasHeader] = useState(false);
  const [hasFooter, setHasFooter] = useState(false);
  const [hasContent, setHasContent] = useState(false);

  return (
    <DialogContext.Provider
      value={{
        ...context,
        hasHeader,
        setHasHeader,
        hasFooter,
        setHasFooter,
        hasContent,
        setHasContent,
        hasTabs,
        setHasTabs,
        ...value,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
