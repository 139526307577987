import React, { type ReactNode } from "react";
import {
  Button,
  type ComboBoxProps,
  Flex,
  Icon,
  TagGroup,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import type { ActionType, SearchedJob } from "@api/jobs";
import { CustomersComboBox } from "@components/customers-combobox";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import {
  addFilter,
  addSearchedJob,
  removeFilter,
  removeSearchedJob,
  resetFilters,
  updateFilter,
} from "@store/jobs";
import { useClientInfo } from "@store/user";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

type ComboBoxOnChangeHandler = Exclude<
  ComboBoxProps<false>["onChange"],
  undefined
>;

type Props = { active: boolean; children?: ReactNode };

export const CustomersTableFilter = ({ active, children }: Props) => {
  const action: ActionType = active ? "active" : "inactive";

  const dispatch = useAppDispatch();

  const { realmId } = useClientInfo();

  const searchedJobs = useAppSelector(
    (state) => state.jobs[`${action}SearchedJobs`],
    isEqual
  );

  const removeJob = useEvent((job: SearchedJob) => {
    dispatch(removeSearchedJob({ action, response: job }));
    dispatch(removeFilter({ action, filter: { index: "id", value: job.id } }));
    dispatch(updateFilter({ action, filter: { index: "offset", value: 0 } }));
  });

  const addJob = useEvent<ComboBoxOnChangeHandler>((_, option) => {
    if (!option) return;

    const jobId = parseRefinementIdFromUrl(option.value);
    const jobName = option.label;

    if (!jobName || !jobId) return;

    if (searchedJobs.some((job) => job.id === jobId)) return;

    dispatch(
      addSearchedJob({
        action: action,
        response: { display_name: jobName, id: jobId },
      })
    );

    dispatch(
      addFilter({
        action: action,
        filter: { index: "id", value: jobId },
      })
    );

    dispatch(updateFilter({ action, filter: { index: "offset", value: 0 } }));
  });

  const onClearFiltersClick = useEvent(() => {
    if (realmId) dispatch(resetFilters(realmId));
  });

  return (
    <Flex gap="md" direction="column">
      <Flex gap="xl" align="center">
        <Flex maxWidth="375px" grow={true}>
          <CustomersComboBox
            value=""
            label=""
            suffix={false}
            active={active}
            onChange={addJob}
            addonAfter={
              <Icon name="search" aria-labelledby="search-by-job-label" />
            }
            withAction={false}
            messageVariant="hidden"
            placeholder="Filter jobs"
          />
        </Flex>
        {searchedJobs.length > 0 && (
          <Button onClick={onClearFiltersClick}>Clear filters</Button>
        )}

        {children && (
          <Flex grow justify="flex-end">
            {children}
          </Flex>
        )}
      </Flex>
      {searchedJobs.length !== 0 && (
        <TagGroup
          data={searchedJobs}
          render="display_name"
          onRemove={removeJob}
        />
      )}
    </Flex>
  );
};
