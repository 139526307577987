import React, {
  createContext,
  type ForwardedRef,
  forwardRef,
  type ReactNode,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMemo } from "react";
import { Virtuoso, type VirtuosoHandle } from "react-virtuoso";
import {
  type ColumnDef,
  createColumnHelper,
  type ExpandedState,
  type ExpandedStateList,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  type HeaderGroup,
  type OnChangeFn,
  type Row as ReactTableRow,
  type RowSelectionState,
  type SortingState,
  useReactTable,
} from "@tanstack/react-table";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import { useKeydown } from "../../hooks/use-keydown";
import { useKeyup } from "../../hooks/use-keyup";
import { usePrevious } from "../../hooks/use-previous";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { debounce } from "../../utils/debounce";
import { dotObject } from "../../utils/dot-object";
import { getScrollableParent } from "../../utils/get-scrollable-parent";
import { suffixify } from "../../utils/suffixify";
import { EmptyState } from "../empty-state";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Loader } from "../loader";
import { Pagination } from "../pagination";
import { useProvider } from "../provider/provider-context";

import { TableAddonLeft } from "./table-addon-left";
import { TableCellAction } from "./table-cell-action";
import { DEFAULT_TABLE_CONTEXT, useTableContext } from "./table-context";
import { TableSelect } from "./table-select";
import type {
  Column,
  ContextType,
  CurriedToggleSelectedHandler,
  DiffInternalValueToExternalValueProps,
  ExpandData,
  MapExternalValueToInternalValueProps,
  MultipleSelectAddon,
  OnResizeHandler,
  Props,
  Ref,
  RenderRowAddonHandler,
  Row,
  SelectedData,
  SingleSelectAddon,
  StatusState,
  UnknownData,
} from "./types";
import styles from "./table.module.css";

const SET_DATA_STATE_TIMEOUT = 100;

const SCROLL_TIMEOUT = 200;

const getColumnsWidths = (table: HTMLElement) => {
  const rows = table.querySelectorAll("[role='row']");
  const widths: number[] = [];

  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];
    const columns = row.children;

    for (let columnIndex = 0; columnIndex < columns.length; columnIndex++) {
      const column = columns[columnIndex];
      const isValidColumn = column.matches(
        "[role='cell']:not([data-empty]), [role='columnheader']:not([data-empty])"
      );

      if (!isValidColumn) continue;

      if (column.hasAttribute("data-action")) {
        widths[columnIndex] = 0;
      } else {
        column.classList.add(styles["-reset"]);
        const width = parseFloat(getComputedStyle(column).width) || 0;
        column.classList.remove(styles["-reset"]);

        if (!widths[columnIndex] || widths[columnIndex] < width) {
          widths[columnIndex] = Math.ceil(width);
        }
      }
    }
  }

  return widths;
};

const getColumnGridStyle = (start: number, end: number = Infinity) => ({
  "--table-column-grid": `${start} / ${end}`,
});

const flattenData = <Data extends UnknownData & { data?: Data["data"] }>(
  data: Data[]
) => {
  const result: Record<string, boolean> = {};

  const recursiveFlattenData = <
    Data extends UnknownData & { data?: Data["data"] },
  >(
    data: Data[],
    parentIndex = ""
  ) => {
    data.forEach((item, index) => {
      const currentIndex = parentIndex ? `${parentIndex}.${index}` : `${index}`;

      if (Array.isArray(item?.data)) {
        result[currentIndex] = true;
        recursiveFlattenData(item.data, currentIndex);
      }
    });
  };

  recursiveFlattenData(data);

  return result;
};

const diffInternalValueToExternalValue = <
  ExternalValue extends UnknownData[],
  InternalValue extends Record<string, boolean>,
>({
  next,
  prev,
  value,
  reference = "id",
}: DiffInternalValueToExternalValueProps<ExternalValue, InternalValue>) =>
  Object.keys(value).reduce((acc, path) => {
    const key = path.split(".").join(".data.");
    const oldItem = dotObject.get(prev, key);
    const newItem = dotObject.get(next, key);

    return oldItem === undefined ||
      dotObject.get(oldItem, reference) === dotObject.get(newItem, reference)
      ? { ...acc, [path]: true }
      : acc;
  }, {} as InternalValue);

const mapExternalValueToInternalValue = <
  ExternalValue extends UnknownData,
  InternalValue,
>({
  data = [],
  value = [],
  reference = "id",
}: MapExternalValueToInternalValueProps<ExternalValue>) =>
  value.reduce((acc, item) => {
    const index =
      typeof item.path === "string"
        ? item.path.split(".data.").join(".")
        : String(
            data.findIndex(
              (innerItem) =>
                dotObject.get(item, reference) ===
                dotObject.get(innerItem, reference)
            )
          );

    if (index === "-1") return acc;

    return { ...acc, [index]: true };
  }, {} as InternalValue);

const TableContext = createContext<ContextType>({
  testId: "",
  select: {},
  tableId: "",
  renderRowAddon: () => null,
});

const EMPTY_DATA: UnknownData[] = [];

const EMPTY_COLUMNS: Column<UnknownData>[] = [];

const Table = <Data extends UnknownData & { data?: Data["data"] }>(
  {
    id,
    row = {},
    size = "md",
    data = EMPTY_DATA as Data[],
    sort = {},
    style,
    header = {},
    footer = {},
    select = {},
    expand = {},
    column = {},
    columns = EMPTY_COLUMNS,
    loading,
    bordered = true,
    maxHeight = "auto",
    className,
    emptyState = { title: "No data" },
    pagination,
    "data-testid": testId,
  }: Props<Data>,
  ref: ForwardedRef<Ref>
) => {
  const { virtualScroll: providerVirtualScroll } = useProvider();

  const internalId = useId();
  const tableId = id ?? internalId;

  const bodyRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<Ref>(null);
  const internalRef = useRef<Ref>(null);
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const shiftKeyRef = useRef(false);
  const lastSelectedRowRef = useRef<ReactTableRow<Data> | null>(null);

  /**
   * These states are used when we use lazy expand on expand addon
   */
  const [status, setStatus] = useState<StatusState>([]);
  const [internalData, _setInternalData] = useState(data);

  const tableContext = useTableContext();

  /**
   * Since now we have TableProvider that handle all the configuration logic
   * we need to make sure that we are using the right context, so we need to
   * check if the id of the table is the same as the one from the context
   * if it's not the same we just ignore the context.
   */
  const { order, visibility, setColumns, setDescendantsIds } = id
    ? id === tableContext.id
      ? tableContext
      : DEFAULT_TABLE_CONTEXT
    : tableContext;

  const setInternalData = useCallback<typeof _setInternalData>((nextData) => {
    _setInternalData((previousData) =>
      typeof nextData === "function" ? nextData(previousData) : nextData
    );
  }, []);

  const debouncedSetInternalData = useMemo(
    () => debounce(setInternalData, SET_DATA_STATE_TIMEOUT),
    [setInternalData]
  );

  /**
   * Memoized selectors for row addon
   */
  const rowRender = useMemo(() => row.render, [row.render]);
  const rowIsError = useMemo(() => row.isError, [row.isError]);
  const rowOnClick = useMemo(() => row.onClick, [row.onClick]);
  const rowIsLoading = useMemo(() => row.isLoading, [row.isLoading]);
  const rowIsVisible = useMemo(() => row.isVisible, [row.isVisible]);

  /**
   * Memoized selectors for select addon
   */
  const selectValue = useMemo(
    () =>
      select.value
        ? Array.isArray(select.value)
          ? select.value
          : [select.value]
        : undefined,
    [select.value]
  );
  const selectOffset = useMemo(() => select.offset, [select.offset]);
  const selectOnChange = useMemo(() => select.onChange, [select.onChange]);
  const selectReference = useMemo(
    () => select.reference ?? "id",
    [select.reference]
  );
  const selectIsDisabled = useMemo(
    () => select.isDisabled,
    [select.isDisabled]
  );
  const selectIsSelectable = useMemo(
    () => select.isSelectable ?? (() => true),
    [select.isSelectable]
  );
  const selectPlacement = useMemo(() => select.placement, [select.placement]);
  const selectAutoSelectChild = (select as MultipleSelectAddon<Data>)
    .autoSelectChild;
  const selectMultiple = selectOnChange ? select.multiple !== false : undefined;

  /**
   * Memoized selectors for expand addon
   */
  const expandValue = useMemo(() => expand.value, [expand.value]);
  const expandOnChange = useMemo(() => expand.onChange, [expand.onChange]);
  const expandReference = useMemo(
    () => expand.reference ?? "id",
    [expand.reference]
  );
  const expandGetLazyChildren = useMemo(
    () => expand.getLazyChildren,
    [expand.getLazyChildren]
  );

  /**
   * We only use internalData when we have expand addon with lazy children
   * otherwise we use the data prop to avoid rerendering issues
   */
  const enhancedData = expandGetLazyChildren ? internalData : data;
  const previousData = usePrevious(data, EMPTY_DATA as typeof data);

  const hasExpand = useMemo(
    () => enhancedData.some((item) => item.data !== undefined),
    [enhancedData]
  );

  /**
   * Memoized selectors for column addon
   */
  const columnOnResize = useMemo(() => column.onResize, [column.onResize]);
  const enhancedColumnOnResize = useEvent<OnResizeHandler>((result) =>
    requestAnimationFrame(() => columnOnResize?.(result))
  );

  /**
   * Memoized selectors for sort addon
   */
  const sortValue = useMemo(() => {
    if (!sort.value) return;

    const desc = sort.value.startsWith("-");
    const id = desc ? sort.value.slice(1) : sort.value;

    return [{ id, desc }];
  }, [sort.value]);
  const sortOnChange = useMemo(() => sort.onChange, [sort.onChange]);

  const setTemplate = useEvent((template: string) => {
    if (style && "--table-template-columns" in style) return;

    wrapperRef.current?.style.setProperty("--table-template-columns", template);
  });

  const mapSelectValueToRowSelection = useCallback(
    () =>
      mapExternalValueToInternalValue<Data, RowSelectionState>({
        data: enhancedData,
        value: selectValue,
        reference: selectReference,
      }),
    [enhancedData, selectReference, selectValue]
  );

  const [internalRowSelection, setInternalRowSelection] =
    useState<RowSelectionState>(mapSelectValueToRowSelection());

  const rowSelection = useMemo(() => {
    if (selectValue === undefined) {
      if (!selectOnChange) return {} as RowSelectionState;

      return internalRowSelection;
    }

    return mapSelectValueToRowSelection();
  }, [
    selectValue,
    selectOnChange,
    internalRowSelection,
    mapSelectValueToRowSelection,
  ]);

  const mapExpandValueToExpanded = useCallback(
    () =>
      mapExternalValueToInternalValue<Data, ExpandedStateList>({
        data: enhancedData,
        value: expandValue,
        reference: expandReference,
      }),
    [enhancedData, expandReference, expandValue]
  );

  const [internalExpanded, setInternalExpanded] = useState<ExpandedStateList>(
    mapExpandValueToExpanded()
  );

  const expanded = useMemo(
    () =>
      expandValue === undefined ? internalExpanded : mapExpandValueToExpanded(),
    [expandValue, internalExpanded, mapExpandValueToExpanded]
  );

  const rowSelectionCount = Object.keys(rowSelection).length;

  const updateLazyChildren = useEvent(async (data: ExpandData<Data>[]) => {
    if (!expandGetLazyChildren) return;

    const rowsToGetChildren = data.filter(
      (row) =>
        !status.some(
          (item) =>
            dotObject.get(item, expandReference) ===
            dotObject.get(row, expandReference)
        )
    );

    if (!rowsToGetChildren.length) return;

    rowsToGetChildren.forEach(async (item) => {
      setStatus((previousStatus) => [
        ...previousStatus,
        {
          [expandReference]: dotObject.get(item, expandReference),
          status: "loading",
        },
      ]);

      const children = await expandGetLazyChildren(item);

      setInternalData((previousData) => {
        const path = `${item.path}.data`;
        const previousChildren = dotObject.get(previousData, path) || [];

        return dotObject.set(previousData, path, [
          ...previousChildren,
          ...children,
        ]);
      });

      setStatus((previousStatus) =>
        previousStatus.map((item) =>
          dotObject.get(item, expandReference) ===
          dotObject.get(item, expandReference)
            ? { ...item, status: "success" }
            : item
        )
      );
    });
  });

  const onSortingChange = useEvent<OnChangeFn<SortingState>>(
    (updaterOrValue) => {
      const [{ id, desc }] =
        typeof updaterOrValue === "function"
          ? updaterOrValue(sortValue!)
          : updaterOrValue;

      sortOnChange?.(desc ? `-${id}` : id);
    }
  );

  const onExpandedChange = useEvent<OnChangeFn<ExpandedState>>(
    (updaterOrValue) => {
      let nextExpanded: ExpandedStateList | undefined;

      if (updaterOrValue === true) {
        nextExpanded = flattenData(enhancedData);
      } else if (typeof updaterOrValue === "function") {
        nextExpanded = updaterOrValue(expanded) as ExpandedStateList;
      }

      if (!nextExpanded) return;

      const initialValue = {} as ExpandData<Data>;
      setInternalExpanded(nextExpanded);
      const value = Object.keys(nextExpanded).map((indexes) =>
        indexes.split(".").reduce((acc, index) => {
          const path = indexes.split(".").join(".data.");
          const item =
            acc === initialValue
              ? enhancedData
              : (acc.data as ExpandData<Data>[]);

          return { ...dotObject.get(item, index), path };
        }, initialValue)
      );
      expandOnChange?.(value);
      updateLazyChildren(value);
    }
  );

  const onRowSelectionChange = useEvent<OnChangeFn<RowSelectionState>>(
    (updaterOrValue) => {
      if (typeof updaterOrValue !== "function") return;

      const initialValue = {} as SelectedData<Data>;
      const nextRowSelection = updaterOrValue(rowSelection);
      setInternalRowSelection(nextRowSelection);
      const value = Object.keys(nextRowSelection).map((indexes) =>
        indexes.split(".").reduce((acc, index) => {
          const path = indexes.split(".").join(".data.");
          const item =
            acc === initialValue
              ? enhancedData
              : (acc.data as ExpandData<Data>[]);

          return { ...dotObject.get(item, index), path };
        }, initialValue)
      );

      if (!selectMultiple) {
        (selectOnChange as SingleSelectAddon<Data>["onChange"])?.(value[0]);
      } else {
        (selectOnChange as MultipleSelectAddon<Data>["onChange"])?.(value);
      }
    }
  );

  const enhancedIsLoadingRow = useCallback(
    (data: Data) => {
      const isLazyLoadingChildren = status.some(
        (item) =>
          dotObject.get(item, expandReference) ===
            dotObject.get(data, expandReference) && item.status === "loading"
      );

      return rowIsLoading?.(data) || isLazyLoadingChildren;
    },
    [expandReference, rowIsLoading, status]
  );

  const columnHelper = useMemo(() => createColumnHelper<Data>(), []);

  const enhancedOrder = useMemo(
    () =>
      order
        ? [...(selectMultiple !== undefined ? ["select"] : []), ...order]
        : undefined,
    [selectMultiple, order]
  );

  const renderRowAddon = useCallback<RenderRowAddonHandler<Data>>(
    ({ data, ...props }) => {
      if (enhancedIsLoadingRow(data)) {
        return (
          <Loader data-testid={suffixify(testId, "loader-row")} {...props} />
        );
      }

      if (rowIsError?.(data)) {
        return (
          <Icon
            name="exclamation-circle"
            color="error-200"
            aria-label="Error"
            data-testid={suffixify(testId, "error-row")}
            {...props}
          />
        );
      }

      return null;
    },
    [enhancedIsLoadingRow, rowIsError, testId]
  );

  const curriedToggleSelected = useCallback<CurriedToggleSelectedHandler<Data>>(
    ({ row, table }) =>
      (checked) => {
        const visibleRows = table
          .getPreGroupedRowModel()
          .flatRows.filter((row) => rowIsVisible?.(row.original) !== false);

        if (
          shiftKeyRef.current &&
          lastSelectedRowRef.current &&
          lastSelectedRowRef.current.id !== row.id
        ) {
          const correctedRowIndex = visibleRows.findIndex(
            (item) => item.index === lastSelectedRowRef.current?.index
          );
          const correctedLastSelectedRowIndex = visibleRows.findIndex(
            (item) => item.index === row.index
          );

          const startIndex = Math.min(
            correctedLastSelectedRowIndex,
            correctedRowIndex
          );
          const endIndex = Math.max(
            correctedLastSelectedRowIndex,
            correctedRowIndex
          );
          const rowsToToggle = visibleRows.slice(startIndex, endIndex + 1);

          table.setRowSelection((old) => {
            rowsToToggle.forEach((row) => {
              if (!row.getCanSelect()) return;

              if (checked) {
                old[row.index] = checked;
              } else {
                delete old[row.index];
              }
            });
            return { ...old };
          });
        } else {
          row.toggleSelected(checked);
        }

        lastSelectedRowRef.current = row;
      },
    [rowIsVisible]
  );

  const enhancedColumns = useMemo(() => {
    let cells: ColumnDef<Data, unknown>[] = [];

    if (selectMultiple !== undefined) {
      cells = [
        ...cells,
        columnHelper.display({
          id: "select",
          header: selectMultiple
            ? ({ table }) => (
                <TableContext.Consumer>
                  {({ testId, tableId }) => {
                    const rowSelectionCount =
                      table.getSelectedRowModel().flatRows.length;

                    return (
                      <TableAddonLeft>
                        <TableSelect
                          id={suffixify(tableId, "select-all")}
                          label={
                            rowSelectionCount
                              ? `${rowSelectionCount} selected`
                              : "Select all"
                          }
                          checked={table.getIsAllRowsSelected()}
                          onChange={table.toggleAllRowsSelected}
                          data-testid={suffixify(testId, "select-all")}
                          indeterminate={table.getIsSomeRowsSelected()}
                        />
                      </TableAddonLeft>
                    );
                  }}
                </TableContext.Consumer>
              )
            : undefined,
          cell: ({ table, row: item }) => (
            <TableContext.Consumer>
              {({ testId, tableId, select, renderRowAddon }) => {
                const addon = renderRowAddon({
                  data: item.original,
                  className: styles["addon"],
                });

                const isDisabled = select.isDisabled?.(item.original);
                const isSelectable = select.isSelectable?.(item.original);

                let label = `Select row ${item.id}`;

                if (isDisabled) {
                  label = typeof isDisabled === "string" ? isDisabled : "";
                }

                return (
                  <TableAddonLeft
                    offset={select.offset}
                    placement={select.placement}
                    className={cn({ [styles["-switcher"]]: addon })}
                  >
                    {addon}
                    {isSelectable && (
                      <TableSelect
                        id={suffixify(tableId, item.id)}
                        name={suffixify(tableId, "select-row")}
                        value={item.id}
                        label={label}
                        checked={item.getIsSelected()}
                        onChange={curriedToggleSelected({ row: item, table })}
                        disabled={!!isDisabled}
                        multiple={selectMultiple}
                        className={styles["checkbox"]}
                        data-testid={suffixify(testId, "select-row")}
                        indeterminate={
                          selectMultiple
                            ? item.getCanSelectSubRows()
                              ? item.getIsSomeSelected()
                              : false
                            : undefined
                        }
                      />
                    )}
                  </TableAddonLeft>
                );
              }}
            </TableContext.Consumer>
          ),
        }),
      ];
    }

    cells = [
      ...cells,
      ...columns.map((column, columnIndex) =>
        columnHelper.display({
          sortDescFirst: column.sortable !== "asc",
          enableSorting: !!column.sortable,
          id: column.id,
          cell: ({ table, row }) => (
            <TableContext.Consumer>
              {({ testId, select, renderRowAddon }) => {
                const isExpanded = row.getIsExpanded();
                const isSelected = row.getIsSelected();
                const isExpandable = row.getCanExpand();
                const isFirstColumn = columnIndex === 0;
                const style = {
                  "--table-column-depth": isFirstColumn ? row.depth : 0,
                };

                const rowData = {
                  ...row.original,
                  expanded: isExpanded,
                  selected: isSelected,
                } as Row<Data>;

                const rowAddon =
                  !select.onChange && isFirstColumn
                    ? renderRowAddon({
                        data: rowData,
                        className: styles["prefix"],
                      })
                    : null;

                let prefix = rowAddon;

                if (!prefix && isFirstColumn) {
                  if (isExpandable) {
                    prefix = (
                      <Icon
                        name={isExpanded ? "caret-down" : "caret-right"}
                        color="neutral-500"
                        variant="solid"
                        className={styles["prefix"]}
                      />
                    );
                  } else if (table.getCanSomeRowsExpand()) {
                    prefix = (
                      <Flex
                        width="20px"
                        shrink={false}
                        className={styles["prefix"]}
                      />
                    );
                  }
                }

                let content = null;

                const render =
                  Array.isArray(column.render) && column.render.length === 1
                    ? column.render[0]
                    : typeof column.render === "object" &&
                        !Array.isArray(column.render) &&
                        "render" in column
                      ? column.render.render
                      : column.render;

                if (typeof render === "function") {
                  content = render(rowData, row.index);
                } else if (typeof render === "string") {
                  content = dotObject.get(rowData, render);
                } else if (Array.isArray(render)) {
                  content = (
                    <div className={styles["inner-cell"]}>
                      {render.map((item, i) => {
                        const columnWidth = dotObject.get(column, `width.${i}`);
                        const columnStyle =
                          columnWidth !== "fill"
                            ? {
                                "--table-inner-column-width": `${columnWidth}px`,
                                "--table-inner-column-count": render.length,
                              }
                            : undefined;

                        return (
                          <div
                            key={i}
                            style={columnStyle}
                            className={cn(styles["inner-column"], {
                              [styles["-fill"]]: columnWidth === "fill",
                            })}
                            data-testid={suffixify(
                              testId,
                              "body-row",
                              row.index,
                              "column",
                              columnIndex,
                              "inner-column",
                              i
                            )}
                          >
                            {typeof item === "string"
                              ? dotObject.get(rowData, item)
                              : item(rowData, row.index)}
                          </div>
                        );
                      })}
                    </div>
                  );
                }

                return (
                  <div
                    style={style}
                    className={cn(styles["cell"], {
                      [styles["-first"]]: isFirstColumn,
                    })}
                  >
                    {prefix}
                    {content}
                  </div>
                );
              }}
            </TableContext.Consumer>
          ),
          header: ({ table, header }) => (
            <TableContext.Consumer>
              {({ testId }) => {
                const name =
                  Array.isArray(column.name) && column.name.length === 1
                    ? column.name[0]
                    : column.name;

                /**
                 * @todo replace it with header.column.getCanSort() as soon as react-table
                 * fixes the bug with sorting https://github.com/TanStack/table/issues/4136
                 */
                const canSort = column.sortable;
                const isSorted = header.column.getIsSorted();
                const isExpandable = table.getCanSomeRowsExpand();

                return Array.isArray(name) ? (
                  <div className={styles["inner-cell"]}>
                    {name.map((item, i) => {
                      const columnWidth = dotObject.get(column, `width.${i}`);
                      const columnStyle =
                        columnWidth !== "fill"
                          ? {
                              "--table-inner-column-width": `${columnWidth}px`,
                              "--table-inner-column-count": (name as []).length,
                            }
                          : undefined;

                      return (
                        <div
                          key={i}
                          style={columnStyle}
                          className={cn(styles["inner-column"], {
                            [styles["-fill"]]: columnWidth === "fill",
                          })}
                          data-testid={suffixify(
                            testId,
                            "header-row",
                            header.index - 1,
                            "column",
                            columnIndex,
                            "inner-column",
                            i
                          )}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div
                    className={cn(styles["inner-cell"], {
                      [styles["-sortable"]]: canSort,
                    })}
                  >
                    <div className={styles["cell-content"]}>
                      {isExpandable && columnIndex === 0 && (
                        <div className={styles["expand-all"]}>
                          <button
                            type="button"
                            onClick={() => table.toggleAllRowsExpanded()}
                          >
                            <Icon
                              name={
                                table.getIsAllRowsExpanded()
                                  ? "caret-down"
                                  : "caret-right"
                              }
                              color="neutral-500"
                              variant="solid"
                            />
                          </button>
                        </div>
                      )}
                      {name}
                    </div>
                    {canSort && (
                      <button
                        type="button"
                        className={cn(styles["sort-button"], {
                          [styles["-asc"]]: isSorted
                            ? isSorted === "asc"
                            : column.sortable === "asc",
                          [styles["-desc"]]: isSorted
                            ? isSorted === "desc"
                            : column.sortable !== "asc",
                        })}
                        onClick={() => header.column.toggleSorting()}
                        data-sort={isSorted ? isSorted : undefined}
                        data-testid={suffixify(testId, `sort-by-${column.id}`)}
                      >
                        <Icon
                          className={styles["sort-icon"]}
                          name="caret-up"
                          size="sm"
                          variant="solid"
                        />
                        <Icon
                          className={styles["sort-icon"]}
                          name="caret-down"
                          size="sm"
                          variant="solid"
                        />
                      </button>
                    )}
                  </div>
                );
              }}
            </TableContext.Consumer>
          ),
          ...(column.footer
            ? {
                footer: ({ table, header }) => (
                  <TableContext.Consumer>
                    {({ testId }) => {
                      const rows = table
                        .getRowModel()
                        .flatRows.map((row) => row.original);

                      const footer =
                        Array.isArray(column.footer) &&
                        column.footer.length === 1
                          ? column.footer[0]
                          : column.footer;

                      return (
                        <div className={styles["cell"]}>
                          {Array.isArray(footer) ? (
                            <div className={styles["inner-cell"]}>
                              {footer.map((item, i) => {
                                const columnWidth = dotObject.get(
                                  column,
                                  `width.${i}`
                                );
                                const columnStyle =
                                  columnWidth !== "fill"
                                    ? {
                                        "--table-inner-column-width": `${columnWidth}px`,
                                        "--table-inner-column-count": (
                                          footer as []
                                        ).length,
                                      }
                                    : undefined;

                                return (
                                  <div
                                    key={i}
                                    style={columnStyle}
                                    className={cn(styles["inner-column"], {
                                      [styles["-fill"]]: columnWidth === "fill",
                                    })}
                                    data-testid={suffixify(
                                      testId,
                                      "footer-row",
                                      header.index - 1,
                                      "column",
                                      columnIndex,
                                      "inner-column",
                                      i
                                    )}
                                  >
                                    {typeof item === "function"
                                      ? item(rows)
                                      : item}
                                  </div>
                                );
                              })}
                            </div>
                          ) : typeof footer === "function" ? (
                            footer(rows)
                          ) : footer &&
                            typeof footer === "object" &&
                            "render" in footer ? (
                            typeof footer.render === "function" ? (
                              footer.render(rows)
                            ) : (
                              footer.render
                            )
                          ) : (
                            footer
                          )}
                        </div>
                      );
                    }}
                  </TableContext.Consumer>
                ),
              }
            : {}),
        })
      ),
    ];

    return cells;
  }, [selectMultiple, columns, columnHelper, curriedToggleSelected]);

  const enableRowSelection = useCallback(
    (item: ReactTableRow<Data>) =>
      !selectIsDisabled?.(item.original) && selectIsSelectable(item.original),
    [selectIsDisabled, selectIsSelectable]
  );

  const enableSorting = useMemo(
    () => columns.some((column) => column.sortable),
    [columns]
  );

  const getSubRows = useCallback((row: Data) => row.data as Data[], []);

  const getRowCanExpand = useCallback(
    (row: ReactTableRow<Data>) => row.original.data !== undefined,
    []
  );

  /**
   * Since react table doesn't pass custom column props to their
   * columns we need another way to access our custom props
   */
  const getColumnProps = useCallback(
    (id: string) =>
      (columns.find((column) => column.id === id) || {}) as Partial<
        Column<Data>
      >,
    [columns]
  );

  const adjustColumnWidth = useCallback(() => {
    if (!internalRef.current) return;

    let widths = getColumnsWidths(internalRef.current);

    const visibleColumns = visibility
      ? columns.filter((column) => visibility[column.id] !== false)
      : columns;

    if (enhancedOrder) {
      visibleColumns.sort(
        (a, b) => enhancedOrder.indexOf(a.id) - enhancedOrder.indexOf(b.id)
      );
    }

    let columnsLength = visibleColumns.length;

    if (selectMultiple !== undefined) {
      columnsLength += 1;
    }

    if (hasExpand || rowOnClick || rowRender) {
      columnsLength += 1;
    }

    if (widths.length < columnsLength && !loading && enhancedData.length > 0) {
      widths = Array.from({ length: columnsLength }).fill(36) as number[];
    }

    const hasFillColumn = visibleColumns.some(
      (column) =>
        column?.width === "fill" ||
        (Array.isArray(column?.width) &&
          column.width.some((width) => width === "fill"))
    );
    const adjustedColumns =
      selectMultiple !== undefined
        ? [undefined, ...visibleColumns]
        : visibleColumns;
    const hasOnlyOneColumn = visibleColumns.length === 1;

    const template = widths.reduce((acc, width, index) => {
      const column = adjustedColumns[index];
      let enhancedWidth = column?.width;

      if (Array.isArray(enhancedWidth)) {
        if (enhancedWidth.some((width) => width === "fill")) {
          enhancedWidth = "fill";
        } else {
          enhancedWidth = (enhancedWidth as number[]).reduce(
            (acc, column) => acc + column,
            0
          );
        }
      }

      const isSelectColumn = selectMultiple !== undefined && index === 0;

      let max =
        hasFillColumn && !isSelectColumn && width > 0
          ? `calc(${width}px + 4%)`
          : `${width}px`;

      let min = `${width}px`;

      if (enhancedWidth !== undefined) {
        min = `${enhancedWidth === "fill" ? width : enhancedWidth}px`;
        max = enhancedWidth === "fill" ? "1fr" : `${enhancedWidth}px`;

        if (enhancedWidth === "fill" && hasOnlyOneColumn) {
          min = "min-content";
        }
      }

      if (typeof column?.minWidth === "number") {
        min = `${column?.minWidth}px`;
      } else if (column?.minWidth === "content") {
        min = "min-content";
      }

      if (!hasFillColumn && !isSelectColumn && width !== 0) {
        max = "1fr";
      }

      if (typeof column?.maxWidth === "number") {
        max = `${column?.maxWidth}px`;
      } else if (column?.maxWidth === "content") {
        max = "max-content";
      }

      return `${acc} minmax(${min}, ${max})`.trim();
    }, "");

    enhancedColumnOnResize({ template, widths });
    setTemplate(template);
  }, [
    columns,
    loading,
    rowRender,
    hasExpand,
    visibility,
    rowOnClick,
    setTemplate,
    enhancedData,
    enhancedOrder,
    selectMultiple,
    enhancedColumnOnResize,
  ]);

  const table = useReactTable({
    data: enhancedData,
    state: {
      sorting: sortValue,
      expanded,
      columnOrder: enhancedOrder,
      rowSelection,
      columnVisibility: visibility,
    },
    columns: enhancedColumns,
    getSubRows,
    enableSorting,
    manualSorting: true,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange,
    onExpandedChange,
    enableRowSelection,
    enableSortingRemoval: false,
    getExpandedRowModel: getExpandedRowModel(),
    onRowSelectionChange,
    enableSubRowSelection: selectAutoSelectChild,
    enableMultiRowSelection: selectMultiple,
  });

  const rows = table.getRowModel().rows;

  const visibleRows = useMemo(
    () => rows.filter((row) => rowIsVisible?.(row.original) !== false),
    [rows, rowIsVisible]
  );

  /**
   * This is a workaround to handle select all when we have invisible rows.
   * I tried to use native global filter that react table provides but it
   * doesn't work as expected so to make it work as expected we need to
   * rewrite the toggleAllRowsSelected function.
   */
  table.toggleAllRowsSelected = useCallback(() => {
    table.setRowSelection((old) => {
      const visibleRows = table
        .getPreGroupedRowModel()
        .flatRows.filter((row) => rowIsVisible?.(row.original) !== false);

      let { rowSelection } = table.getState();

      let isAllRowsSelected = Boolean(
        visibleRows.length && Object.keys(visibleRows).length
      );

      if (
        isAllRowsSelected &&
        visibleRows.some((row) => row.getCanSelect() && !rowSelection[row.id])
      ) {
        isAllRowsSelected = false;
      }

      rowSelection = { ...old };

      visibleRows.forEach((row) => {
        if (row.getCanSelect() && !isAllRowsSelected) {
          rowSelection[row.id] = true;
        } else {
          delete rowSelection[row.id];
        }
      });

      return rowSelection;
    });
  }, [table, rowIsVisible]);

  /**
   * This is a workaround to handle expand all when we have invisible rows.
   * I tried to use native global filter that react table provides but it
   * doesn't work as expected so to make it work as expected we need to
   * rewrite the getIsAllRowsExpanded function.
   */
  table.getIsAllRowsExpanded = useCallback(() => {
    const visibleRows = table
      .getPreGroupedRowModel()
      .flatRows.filter((row) => rowIsVisible?.(row.original) !== false);

    const { expanded } = table.getState();

    if (typeof expanded === "boolean") return expanded;

    let isAllRowsSelected = Boolean(
      visibleRows.length && Object.keys(visibleRows).length
    );

    if (
      isAllRowsSelected &&
      visibleRows.some((row) => row.getCanExpand() && !expanded[row.id])
    ) {
      isAllRowsSelected = false;
    }

    return isAllRowsSelected;
  }, [rowIsVisible, table]);

  /**
   * This is a workaround to handle expand all when we have invisible rows.
   * I tried to use native global filter that react table provides but it
   * doesn't work as expected so to make it work as expected we need to
   * rewrite the toggleAllRowsExpanded function.
   */
  table.toggleAllRowsExpanded = useCallback(() => {
    const isAllRowsExpanded = table.getIsAllRowsExpanded();
    table.setExpanded(isAllRowsExpanded ? () => ({}) : true);
  }, [table]);

  const increaseViewportBy = useMemo(
    () => (providerVirtualScroll ? 0 : Infinity),
    [providerVirtualScroll]
  );

  const renderBody = () => {
    let content: ReactNode = null;

    if (visibleRows.length > 0) {
      const scrollableParent =
        getScrollableParent(bodyRef.current) ?? undefined;

      content = (
        <Virtuoso
          ref={virtuosoRef}
          totalCount={visibleRows.length}
          itemContent={(rowIndex) => {
            const item = visibleRows[rowIndex];
            const cells = item.getVisibleCells();
            const cellsLength = cells.length;

            const canExpand =
              item.getCanExpand() &&
              !enhancedIsLoadingRow(item.original) &&
              !rowIsError?.(item.original);

            return (
              <div
                key={item.id}
                role="row"
                className={cn(styles["row"], {
                  [styles["-last"]]: rowIndex === visibleRows.length - 1,
                  [styles["-actionable"]]: rowOnClick || rowRender || canExpand,
                })}
                data-testid={suffixify(testId, "body-row", rowIndex)}
                aria-selected={item.getIsSelected()}
              >
                {cells.map((cell, cellIndex) => {
                  const isLast = cellsLength - 1 === cellIndex ? "" : undefined;
                  const columnProps = getColumnProps(cell.column.id);
                  const colSpan =
                    columnProps.render &&
                    typeof columnProps.render === "object" &&
                    "colSpan" in columnProps.render
                      ? (columnProps.render.colSpan ?? 1)
                      : 1;

                  return (
                    <div
                      key={cell.id}
                      role="cell"
                      data-last={isLast}
                      className={cn(
                        styles["column"],
                        styles[`-text-${columnProps.textAlign || "left"}`],
                        styles[`-align-${columnProps.align || "center"}`],
                        styles[`-justify-${columnProps.justify || "normal"}`],
                        {
                          [styles["-highlight"]]: columnProps.highlight,
                        }
                      )}
                      style={getColumnGridStyle(
                        cellIndex + 1,
                        colSpan + cellIndex + 1
                      )}
                      aria-hidden={colSpan === 0}
                      data-testid={suffixify(
                        testId,
                        "body-row",
                        rowIndex,
                        "column",
                        cellIndex
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </div>
                  );
                })}
                {(canExpand || rowOnClick) && (
                  <TableCellAction
                    onClick={() => {
                      rowOnClick?.(item.original);
                      canExpand && item.toggleExpanded();
                    }}
                    data-testid={testId}
                  >
                    {canExpand
                      ? item.getIsExpanded()
                        ? "Collapse"
                        : "Expand"
                      : "Open"}
                  </TableCellAction>
                )}
                {rowRender && (
                  <TableCellAction
                    render={(cellActionProps) =>
                      rowRender?.({
                        row: item.original,
                        props: cellActionProps,
                      })
                    }
                    data-testid={testId}
                  />
                )}
              </div>
            );
          }}
          useWindowScroll={!scrollableParent}
          customScrollParent={scrollableParent}
          increaseViewportBy={increaseViewportBy}
        />
      );
    } else if (loading) {
      content = (
        <div role="row" className={cn(styles["row"], styles["-last"])}>
          <div
            role="cell"
            style={getColumnGridStyle(1)}
            data-last
            className={styles["column"]}
            data-empty=""
          >
            <Flex
              width="full"
              align="center"
              justify="center"
              padding={["4xl", "none"]}
            >
              <Loader data-testid={suffixify(testId, "loader")} />
            </Flex>
          </div>
        </div>
      );
    } else if (!emptyState.hide) {
      content = (
        <div role="row" className={cn(styles["row"], styles["-last"])}>
          <div
            role="cell"
            style={getColumnGridStyle(1)}
            data-last
            className={styles["column"]}
            data-empty=""
          >
            <Flex
              width="full"
              align="center"
              justify="center"
              padding={["4xl", "none"]}
            >
              <EmptyState
                data-testid={suffixify(testId, "empty-state")}
                {...emptyState}
              />
            </Flex>
          </div>
        </div>
      );
    }

    return content ? (
      <div
        ref={bodyRef}
        role="rowgroup"
        className={styles["body"]}
        data-body=""
      >
        {content}
      </div>
    ) : (
      content
    );
  };

  const headerOffset =
    typeof header.sticky === "object" ? header.sticky.offset : 0;
  const headerStyle = {
    "--table-header-offset": `${headerOffset}px`,
  };

  const footerOffset =
    typeof footer.sticky === "object" ? footer.sticky.offset : 0;
  const footerStyle = {
    "--table-footer-offset": `${footerOffset}px`,
  };

  const enhancedStyle = { "--table-max-height": maxHeight, ...style };

  const hasColumnsHeader = useMemo(
    () => columns.every((column) => column.name),
    [columns]
  );

  const getGroupInfo = useCallback(
    (groups: HeaderGroup<Data>[], def: "header" | "footer") => {
      let visible = false;

      const content = groups.map((group, groupIndex) => (
        <div
          key={group.id}
          role="row"
          className={styles["row"]}
          data-testid={suffixify(testId, def, "row", groupIndex)}
        >
          {group.headers.map((cell, cellIndex) => {
            const columnProps = getColumnProps(cell.id);
            const groupProps = def === "footer" ? columnProps[def] : undefined;
            const colSpan =
              groupProps &&
              typeof groupProps === "object" &&
              "colSpan" in groupProps
                ? (groupProps.colSpan ?? 1)
                : 1;
            const isSorted = cell.column.getIsSorted();

            const cellContent = cell.isPlaceholder
              ? null
              : flexRender(cell.column.columnDef[def], cell.getContext());
            const isLast =
              group.headers.length - 1 === cellIndex ? "" : undefined;

            if (cellContent) {
              visible = true;
            }

            return (
              <div
                key={cell.id}
                role={def === "header" ? "columnheader" : "cell"}
                data-last={isLast}
                style={getColumnGridStyle(
                  cellIndex + 1,
                  colSpan + cellIndex + 1
                )}
                className={cn(
                  styles["column"],
                  styles[`-align-${columnProps.align || "center"}`],
                  styles[`-justify-${columnProps.justify || "normal"}`],
                  {
                    [styles["-highlight"]]: columnProps.highlight,
                    [styles[`-text-${columnProps.textAlign || "left"}`]]:
                      def !== "header",
                  }
                )}
                aria-sort={
                  def !== "header" || !isSorted
                    ? undefined
                    : isSorted === "asc"
                      ? "ascending"
                      : "descending"
                }
                aria-hidden={colSpan === 0}
                data-testid={suffixify(
                  testId,
                  def,
                  "row",
                  groupIndex,
                  "column",
                  cellIndex
                )}
              >
                {cellContent}
              </div>
            );
          })}
        </div>
      ));

      return { visible, content };
    },
    [getColumnProps, testId]
  );

  const headersGroup = getGroupInfo(table.getHeaderGroups(), "header");

  const footersGroup = getGroupInfo(table.getFooterGroups(), "footer");

  useResizeObserver(internalRef, adjustColumnWidth);

  useEffect(() => {
    queueMicrotask(adjustColumnWidth);
  }, [adjustColumnWidth]);

  useEffect(() => {
    setColumns?.(columns);
  }, [columns, setColumns]);

  useEffect(() => {
    setDescendantsIds?.((ids) => [...ids, tableId]);

    return () => {
      setDescendantsIds?.((ids) => ids.filter((id) => id !== tableId));
    };
  }, [setDescendantsIds, tableId]);

  useEffect(() => {
    /**
     * We do this check to guarantee that we don't unnecessary change the status reference
     */
    setStatus((previousStatus) =>
      previousStatus.length === 0 ? previousStatus : []
    );
    debouncedSetInternalData(data);
  }, [data, debouncedSetInternalData]);

  useEffect(() => {
    onExpandedChange((expanded) =>
      diffInternalValueToExternalValue<Data[], ExpandedStateList>({
        next: enhancedData,
        prev: previousData,
        value: expanded as ExpandedStateList,
        reference: expandReference,
      })
    );
  }, [enhancedData, previousData, onExpandedChange, expandReference]);

  useEffect(() => {
    onRowSelectionChange((selected) =>
      diffInternalValueToExternalValue<Data[], RowSelectionState>({
        next: enhancedData,
        prev: previousData,
        value: selected,
        reference: selectReference,
      })
    );
  }, [enhancedData, previousData, onRowSelectionChange, selectReference]);

  useKeydown((e) => {
    shiftKeyRef.current = e.shiftKey;
  });

  useKeyup((e) => {
    shiftKeyRef.current = e.shiftKey;
  });

  useImperativeHandle(ref, () => {
    const rootEl = wrapperRef.current!;

    rootEl.scrollToIndex = debounce((index: number) => {
      virtuosoRef.current?.scrollToIndex({
        index,
        offset:
          (header.sticky ?? true)
            ? -(headerRef.current?.getBoundingClientRect().height ?? 0)
            : 0,
        align: "start",
        behavior: "smooth",
      });
    }, SCROLL_TIMEOUT);

    return rootEl;
  }, [header.sticky]);

  return (
    <div
      ref={wrapperRef}
      style={enhancedStyle}
      className={cn(className, styles["wrapper"], {
        [styles[`-${size}`]]: size,
        [styles["-loading"]]: loading,
        [styles["-bordered"]]: bordered,
        [styles["-scrollable"]]: maxHeight !== "auto",
        [styles["-addon-left"]]: selectMultiple !== undefined,
      })}
    >
      <div
        id={id}
        ref={internalRef}
        role="table"
        className={styles["table"]}
        data-testid={testId}
      >
        <div aria-hidden="true" className={styles["row"]}>
          {enhancedColumns.map((_, i) => (
            <div key={i} />
          ))}
        </div>
        <TableContext.Provider
          value={{
            testId,
            select: {
              offset: selectOffset,
              multiple: selectMultiple,
              onChange: selectOnChange,
              placement: selectPlacement,
              isDisabled: selectIsDisabled,
              isSelectable: selectIsSelectable,
            },
            tableId,
            renderRowAddon,
          }}
        >
          {headersGroup.visible && header.hide !== true && (
            <div
              ref={headerRef}
              role="rowgroup"
              style={headerStyle}
              className={cn(styles["header"], {
                [styles["-sticky"]]: header.sticky ?? true,
              })}
              data-header=""
            >
              {table.getHeaderGroups().map((headerGroup, headerIndex) => {
                if (!hasColumnsHeader) {
                  if (visibleRows.length === 0) return null;

                  const selectId = suffixify(tableId, "select-all");

                  let content = null;

                  if (selectMultiple) {
                    content = (
                      <div
                        key="select"
                        role="columnheader"
                        style={getColumnGridStyle(1, 2)}
                        className={styles["column"]}
                        data-testid={suffixify(
                          testId,
                          "header-row",
                          headerIndex,
                          "column",
                          0
                        )}
                      >
                        <TableAddonLeft>
                          <TableSelect
                            id={selectId}
                            checked={table.getIsAllRowsSelected()}
                            onChange={table.toggleAllRowsSelected}
                            data-testid={suffixify(testId, "select-all")}
                            indeterminate={table.getIsSomeRowsSelected()}
                          />
                        </TableAddonLeft>
                      </div>
                    );
                  }

                  return (
                    <div
                      key={headerGroup.id}
                      role="row"
                      className={styles["row"]}
                      data-testid={suffixify(testId, "header-row", headerIndex)}
                    >
                      {content}
                      <div
                        key="header"
                        role="columnheader"
                        style={getColumnGridStyle(2)}
                        className={styles["column"]}
                        data-testid={suffixify(
                          testId,
                          "header-row",
                          headerIndex,
                          "column",
                          content ? 1 : 0
                        )}
                        data-last
                      >
                        <div className={styles["column-header"]}>
                          {selectMultiple ? (
                            <label
                              htmlFor={selectId}
                              className={styles["select-label"]}
                            >
                              {rowSelectionCount
                                ? `${rowSelectionCount} selected`
                                : "Select all"}
                            </label>
                          ) : (
                            <div />
                          )}
                          {header?.render && (
                            <div className={styles["header-addon"]}>
                              {header?.render()}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }

                return headersGroup.content;
              })}
            </div>
          )}
          {renderBody()}
          {footersGroup.visible && footer.hide !== true && (
            <div
              role="rowgroup"
              style={footerStyle}
              className={cn(styles["footer"], {
                [styles["-sticky"]]: footer.sticky ?? true,
              })}
              data-footer=""
            >
              {footersGroup.content}
            </div>
          )}
        </TableContext.Provider>
      </div>
      {pagination && pagination.total > 0 && (
        <Pagination
          disabled={loading}
          data-testid={suffixify(testId, "pagination")}
          {...pagination}
        />
      )}
    </div>
  );
};

const ForwardedTable = forwardRef(Table) as <
  Data extends UnknownData & { data?: Data["data"] },
>(
  props: Props<Data> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof Table>;

export { ForwardedTable as Table };
