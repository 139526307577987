import React, { type ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex } from "@adaptive/design-system";
import type { Selector } from "@reduxjs/toolkit";
import type { Attachable as AttachableType } from "@store/attachments/types";
import type { RootState } from "@store/types";

import { Attachable } from "../attachable";

type RecordPreviewProps = {
  children?: ReactNode;
  onDelete?: (id: string) => void;
  dataSelector: Selector<RootState, AttachableType>;
};

export const RecordPreview = ({
  onDelete,
  children,
  dataSelector,
}: RecordPreviewProps) => {
  const record = useSelector(dataSelector);

  const attachable = useMemo(
    () => record?.attachables?.[0],
    [record?.attachables]
  );

  return (
    <Flex
      width="full"
      align="center"
      height="full"
      padding="3xl"
      justify="center"
      background="neutral-200"
    >
      {attachable ? (
        <Attachable onDelete={onDelete} attachable={attachable as any} />
      ) : (
        children
      )}
    </Flex>
  );
};
