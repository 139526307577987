import React, { useState } from "react";
import {
  Flex,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
} from "@adaptive/design-system";

import { PERCENTAGE_FORMAT } from "../constants";

import {
  MarkupCostForm,
  type MarkupCostFormProps,
  MarkupPercentageForm,
  type MarkupPercentageFormProps,
} from ".";

export type MarkupFormProps = {
  tab?: "percentage" | "cost";
  lines: MarkupPercentageFormProps["lines"];
  formId: string;
  costInitialValues?: MarkupCostFormProps["initialValues"];
  percentageInitialValues?: MarkupPercentageFormProps["initialValues"];
  onSubmitCost: MarkupCostFormProps["onSubmit"];
  onValidityChange: (isValid: boolean) => void;
  percentageFormat?: typeof PERCENTAGE_FORMAT;
  lineReferenceKey?: MarkupPercentageFormProps["lineReferenceKey"];
  onSubmitPercentage: MarkupPercentageFormProps["onSubmit"];
  disableBudgetLines?: MarkupPercentageFormProps["disableBudgetLines"];
};

export const MarkupForm = ({
  tab,
  lines,
  formId,
  onSubmitCost,
  lineReferenceKey,
  percentageFormat,
  onValidityChange,
  costInitialValues,
  onSubmitPercentage,
  disableBudgetLines,
  percentageInitialValues,
}: MarkupFormProps) => {
  const [internalTab, setInternalTab] = useState<string>(tab ?? "percentage");

  return (
    <Tabs value={internalTab} onChange={setInternalTab}>
      {!tab && (
        <TabsList>
          <TabsTab value="percentage">Percent</TabsTab>
          <TabsTab value="cost">Amount</TabsTab>
        </TabsList>
      )}
      <TabsPanel
        as={Flex}
        height="444px"
        direction="column"
        padding={[!tab ? "2xl" : "none", "none", "none", "none"]}
      >
        {internalTab === "percentage" && (
          <MarkupPercentageForm
            lines={lines}
            formId={formId}
            onSubmit={onSubmitPercentage}
            allowedType="separate-line"
            initialValues={percentageInitialValues}
            percentageFormat={percentageFormat}
            lineReferenceKey={lineReferenceKey}
            onValidityChange={onValidityChange}
            disableBudgetLines={disableBudgetLines}
          />
        )}
        {internalTab === "cost" && (
          <MarkupCostForm
            formId={formId}
            onSubmit={onSubmitCost}
            initialValues={costInitialValues}
            onValidityChange={onValidityChange}
          />
        )}
      </TabsPanel>
    </Tabs>
  );
};
