import { useJobSettings } from "@src/jobs/hooks";
import { currencySchema } from "@src/shared/utils/schema";
import { z } from "zod";

type Currency = z.infer<typeof currencySchema>;

type Row = {
  [key: string]: any;
  builderAmount: Currency;
  builderRevisedAmount?: Currency;
  ownersAmount: Currency;
  ownersRevisedAmount?: Currency;
};

type UseBudgetAmountProps = {
  row: Row;
  type: "cost" | "revenue";
};

export const useBudgetAmount = ({
  row: {
    ownersAmount,
    ownersRevisedAmount,
    builderAmount,
    builderRevisedAmount,
  },
  type,
}: UseBudgetAmountProps): number => {
  const { ownersAmountEnabled, changeTrackingEnabled } = useJobSettings();
  if (ownersAmountEnabled && type === "revenue") {
    return (changeTrackingEnabled ? ownersRevisedAmount : ownersAmount) || 0;
  }

  return (changeTrackingEnabled ? builderRevisedAmount : builderAmount) || 0;
};
