import { useDeepMemo } from "@adaptive/design-system/hooks";
import type { BudgetLineItems } from "@api/jobs";
import { sum } from "@utils/sum";

import type { Total } from "./types";
import { useGetRemaining } from "./use-row-calculations";

export type UseLineItemTotalsProps = { budgetLines: BudgetLineItems[] };
export type UseLineItemTotalsReturnType = Total;

export const useLineItemTotals = ({ budgetLines }: UseLineItemTotalsProps) => {
  const lineItemsTotals = useDeepMemo(() => {
    return budgetLines.reduce(
      (acc, line) => ({
        builderAmount: sum(acc.builderAmount, line.builderAmount),
        changeAmount: sum(acc.changeAmount, line.changeAmount ?? 0),
        builderRevisedAmount: sum(
          acc.builderRevisedAmount,
          line.builderRevisedAmount ?? 0
        ),
        spent: sum(acc.spent, line.spent),
        builderRemainingAmount: sum(
          acc.builderRemainingAmount,
          line.builderRemainingAmount
        ),
        ownersAmount: sum(acc.ownersAmount, line.ownersAmount),
        externalChangeAmount: sum(
          acc.externalChangeAmount,
          line.externalChangeAmount ?? 0
        ),
        ownersRevisedAmount: sum(
          acc.ownersRevisedAmount,
          line.ownersRevisedAmount ?? 0
        ),
        invoicedAmount: sum(acc.invoicedAmount, line.invoicedAmount),
        unpaidBills: sum(acc.unpaidBills, line.unpaidBills),
      }),
      {
        builderAmount: 0,
        changeAmount: 0,
        builderRevisedAmount: 0,
        unpaidBills: 0,
        spent: 0,
        builderRemainingAmount: 0,
        ownersAmount: 0,
        externalChangeAmount: 0,
        ownersRevisedAmount: 0,
        invoicedAmount: 0,
      }
    );
  }, [budgetLines]);

  const { builderRemainingAmount, invoicedRemainingAmount } =
    useGetRemaining(lineItemsTotals);

  return {
    ...lineItemsTotals,
    builderRemainingAmount,
    invoicedRemainingAmount,
  };
};
