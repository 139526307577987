import { Identifiable } from "@api/expenses";
import type { QueryItem } from "@components/table-filter/formatters";
export type BaseFilters = {
  realm: string;
} & { [s: string]: string | number | boolean | Set<number> };

export const getSearchParams = (filters?: BaseFilters) => {
  const params = new URLSearchParams();

  Object.entries(filters || {})
    .filter(([, val]) => !!val)
    .forEach(([key, value]) => {
      if (value instanceof Set) {
        value.forEach((v) => params.append(key, v.toString()));
      } else {
        params.append(key, value.toString());
      }
    });

  return params;
};

export const getSearchParamsFromFilters = (filters: QueryItem[]) => {
  const params = new URLSearchParams();
  filters.forEach((filter) => {
    params.append(filter.dataIndex, filter?.value?.toString() ?? "");
  });

  return params;
};

export const getUrlBuilder = <Filters extends BaseFilters>(
  basePath: string
) => {
  const getBaseUrl = () => {
    const url = new URL(window.location.origin);
    url.pathname = basePath;
    return url;
  };

  return {
    withFilters(filters?: Filters, action?: string) {
      const url = new URL(this.forCollection());
      if (action) {
        url.pathname += action + "/";
      }
      Object.entries(filters || {})
        .filter(([, val]) => !!val)
        .forEach(([key, value]) => {
          if (value instanceof Set) {
            value.forEach((item) => {
              url.searchParams.append(key, item.toString());
            });
          } else {
            url.searchParams.set(key, value.toString());
          }
        });
      return url.toString();
    },
    forCollection() {
      const url = getBaseUrl();
      return url.toString();
    },
    forItem(item: Identifiable) {
      const url = getBaseUrl();
      url.pathname += item.id + "/";
      return url.toString();
    },
  };
};
