import React, { type ComponentPropsWithoutRef, useEffect } from "react";
import { TabList } from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { useDialogContext } from "../dialog/dialog-context";

import styles from "./tabs.module.css";

const DEFAULT_COMPONENT = "div";

type TabsListProps = ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT>;

export const TabsList = forwardRefAs<typeof DEFAULT_COMPONENT, TabsListProps>(
  ({ as: Component = DEFAULT_COMPONENT, className, ...props }, ref) => {
    const { setHasTabs } = useDialogContext();

    useEffect(() => {
      setHasTabs?.(true);

      return () => {
        setHasTabs?.(false);
      };
    }, [setHasTabs]);

    return (
      <TabList
        ref={ref}
        render={(innerProps) => <Component {...innerProps} />}
        className={cn(styles["list"], className)}
        {...props}
      />
    );
  }
);
