import React, { useReducer, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogStep,
  Flex,
  Icon,
  Loader,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useEvent,
  useMultiStepDialog,
} from "@adaptive/design-system/hooks";
import { formatPercentage, isEqual } from "@adaptive/design-system/utils";
import {
  type MarkupResponse,
  useAddBudgetLineMarkupsMutation,
  useDeleteMarkupMutation,
  useGetBudgetLinesQuery,
  useUpdateBudgetLineMarkupsMutation,
} from "@api/budgets";
import { handleErrors } from "@api/handle-errors";
import type { BudgetLineItems } from "@api/jobs";
import { useJobsCostCodeAccountSimplified } from "@hooks/use-jobs-cost-codes-accounts-simplified";
import { captureMessage } from "@sentry/react";
import {
  useLazyGetChangesQuery,
  useUpdateChangeMutation,
} from "@shared/api/jobs/changes";
import {
  MARKUP_PERCENTAGE_FORMAT,
  useJobPermissions,
  useJobSettings,
} from "@src/jobs";
import { useJobInfo } from "@store/jobs";
import { getItemUrls } from "@utils/get-cost-code-account-values";

import {
  MarkupPercentageForm,
  type MarkupPercentageFormProps,
} from "../../markup";

import type {
  EditSeparateLinePercentMarkupProps,
  ItemOption,
  MarkupDialogSteps,
} from "./types";

const FORM_ID = "budget-edit-separate-line-percent-markup";

const getSelectedLines = (
  budgetLines: BudgetLineItems[],
  markup: MarkupResponse
) => {
  const filterSelectedLines = (lines: BudgetLineItems[]) =>
    lines.filter((line) =>
      line.markups.some((lineMarkup) => markup.id === lineMarkup.id)
    );
  return filterSelectedLines(budgetLines);
};

export const EditSeparatePercentMarkupButton = ({
  markup,
}: EditSeparateLinePercentMarkupProps) => {
  const [key, reset] = useReducer(() => +new Date(), 0);

  const [resetSubmit, setResetSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [updateChange] = useUpdateChangeMutation();
  const [trigger] = useLazyGetChangesQuery();

  const { hide: hideDialog, ...dialog } = useMultiStepDialog<MarkupDialogSteps>(
    { initialStep: "markup-form" }
  );

  const { job } = useJobInfo();

  const { budgetLines } = useGetBudgetLinesQuery(
    { customerId: job.id },
    {
      selectFromResult: ({ data }) => ({
        budgetLines: (data ?? []).map((line) => ({
          amount: markup.ownersValue ? line.ownersAmount : line.builderAmount,
          ...line,
        })),
      }),
    }
  );

  const { canManage } = useJobPermissions();

  const [isValid, setIsValid] = useState(false);

  const [updateBudgetLineMarkups] = useUpdateBudgetLineMarkupsMutation();

  const [addBudgetLineMarkups] = useAddBudgetLineMarkupsMutation();

  const costCodeAccountsSimplified = useJobsCostCodeAccountSimplified();

  const { ownersAmountEnabled } = useJobSettings();

  const canRemoveMarkup = markup.id && markup.changeAmount > 0;

  const onSubmit = useEvent<MarkupPercentageFormProps["onSubmit"]>(
    async (values, e) => {
      const submitter = (e.nativeEvent as SubmitEvent)
        .submitter as HTMLButtonElement;

      const linesWithChanges = values.lines.filter(
        (line) => "hasChanges" in line && line.hasChanges
      );

      if (linesWithChanges.length && !submitter.value) {
        return dialog.setStep("change-markup-confirmation");
      }

      const costCode = costCodeAccountsSimplified.data.find(
        (costCode) => costCode.value === values.costCode
      ) as ItemOption;
      const valueKey = ownersAmountEnabled ? "ownersValue" : "value";
      try {
        setIsLoading(true);

        if (markup.id) {
          await updateBudgetLineMarkups({
            id: markup.id.toString(),
            [valueKey]: values.value,
            markupType: markup.markupType,
            budgetLineIds: values.lines.map((line) => line.id),
            ...getItemUrls(values.costCode),
            isSeparateLine: true,
            customer: job.url,
          }).unwrap();
        } else {
          await addBudgetLineMarkups({
            [valueKey]: values.value,
            ...getItemUrls(values.costCode),
            budgetLineIds: values.lines.map((line) => line.id),
            markupType: "percentage",
            isSeparateLine: true,
            customer: job.url,
          }).unwrap();
        }

        if (submitter.value === "with-changes") {
          await Promise.all(
            linesWithChanges.map(async (line) => {
              const limit = 100;
              const { data } = await trigger({
                customerId: job.id,
                budgetLineId: line.id,
                limit,
              });

              if (data?.next) {
                captureMessage(
                  `There's more than ${limit} results on job ${job.id} for budget line ${line.id}`,
                  { level: "info" }
                );
              }

              await Promise.all(
                (data?.results ?? []).map((change) => {
                  const existingMarkupIndex = change.markups.findIndex(
                    (markup) =>
                      markup.markupType === "percentage" &&
                      markup.jobCostMethod.url === values.costCode
                  );
                  const markups: Parameters<typeof updateChange>[0]["markups"] =
                    change.markups.map((markup) => ({
                      id: markup.id,
                      markupType: markup.markupType,
                      jobCostMethod: markup.jobCostMethod.url,
                      changeLineJobCostMethods: markup.changeLineJobCostMethods,
                      value: markup[valueKey],
                    }));

                  if (existingMarkupIndex > -1) {
                    // Existing markup -> update
                    markups[existingMarkupIndex] = {
                      ...markups[existingMarkupIndex],
                      value: values.value,
                    };
                  } else {
                    // New markup -> add
                    markups.push({
                      markupType: "percentage",
                      value: values.value,
                      jobCostMethod: values.costCode,
                      changeLineJobCostMethods: change.lines
                        .filter((line) =>
                          values.lines
                            .map(({ jobCostMethod }) => jobCostMethod?.id)
                            .includes(line.jobCostMethod.id)
                        )
                        .map((line) => line.jobCostMethod.url),
                    });
                  }

                  return updateChange({
                    ...change,
                    customerId: job.id,
                    changeId: change.id,
                    markups,
                    lines: change.lines.map((line) => ({
                      ...line,
                      amount: line.builderAmount,
                      jobCostMethod: line.jobCostMethod.url,
                    })),
                  });
                })
              );
            })
          );
        }

        setIsLoading(false);
        hideDialog();

        const count = values.lines.length;
        toast.success(
          `Markup ${costCode?.label} updated for ${formatPercentage(values.value, MARKUP_PERCENTAGE_FORMAT)} to ${count} cost code${count !== 1 ? "s" : ""}`
        );
      } catch (e) {
        handleErrors(e);
      }
    }
  );

  const selectedLines = useDeepMemo(
    () => getSelectedLines(budgetLines, markup),
    [markup, budgetLines]
  );

  const initialValues = useDeepMemo(
    () => ({
      lines: selectedLines,
      value: (markup.ownersValue ? markup.ownersValue : markup.value) ?? 0,
      costCode: markup.jobCostMethod.url,
      isSeparateLine: false,
    }),
    [markup.value, markup.jobCostMethod.url, selectedLines]
  );

  const [deleteMarkup] = useDeleteMarkupMutation();

  const onChange = useEvent((values) => {
    if (resetSubmit && canRemoveMarkup) {
      const isEmpty = isEqual(values, {
        value: 0,
        lines: [],
        costCode: "",
        isSeparateLine: false,
      });

      if (!isEmpty) setResetSubmit(false);
    }
  });

  const onClearMarkup = useEvent(() => {
    reset();
    setResetSubmit(true);
  });

  const onDeleteMarkup = useEvent(async () => {
    try {
      await deleteMarkup({
        markupId: markup.id,
        customer: job.id,
      }).unwrap();
    } catch (e) {
      handleErrors(e);
    }

    const costCode = costCodeAccountsSimplified.data.find(
      (costCode) => costCode.value === markup.jobCostMethod.url
    ) as ItemOption;
    toast.success(`Markup ${costCode?.label} deleted`);

    hideDialog();
  });

  return (
    <>
      {dialog.isRendered && (
        <Dialog
          show={dialog.isVisible}
          variant="multi-step-dialog"
          onClose={hideDialog}
          step={dialog.step}
          size="auto"
        >
          <DialogStep name="markup-form">
            <DialogHeader>Edit budget markup</DialogHeader>
            <DialogContent>
              <Flex minHeight="420px">
                <MarkupPercentageForm
                  key={key}
                  lines={budgetLines}
                  formId={FORM_ID}
                  onChange={onChange}
                  initialValues={key ? undefined : initialValues}
                  onSubmit={onSubmit}
                  onValidityChange={setIsValid}
                  allowedType="separate-line"
                  percentageFormat={MARKUP_PERCENTAGE_FORMAT}
                >
                  {canRemoveMarkup ? (
                    <Flex>
                      <Tooltip
                        message={`This will not remove any markup\n added directly to change orders`}
                      >
                        <Button
                          size="sm"
                          color="error"
                          onClick={onClearMarkup}
                          variant="ghost"
                        >
                          Remove markup from budget
                        </Button>
                      </Tooltip>
                    </Flex>
                  ) : null}
                </MarkupPercentageForm>
              </Flex>
            </DialogContent>
            <DialogFooter>
              <Button block color="neutral" onClick={hideDialog} variant="text">
                Cancel
              </Button>
              <Button
                block
                type={resetSubmit ? "button" : "submit"}
                form={resetSubmit ? undefined : FORM_ID}
                onClick={resetSubmit ? onDeleteMarkup : undefined}
                disabled={!isValid && !resetSubmit}
              >
                Save
              </Button>
            </DialogFooter>
          </DialogStep>
          <DialogStep name="change-markup-confirmation" onBack={dialog.back}>
            <DialogHeader>
              <Text size="xl" weight="bold" align="center">
                You&apos;ve added markup to items
                <br />
                with existing change orders
              </Text>
            </DialogHeader>
            <DialogContent>
              <Text align="center">
                Do you want to update those change orders with the new markup?
                <br />
                (Any existing change order markup will be replaced.)
              </Text>
            </DialogContent>
            <DialogFooter>
              <Flex width="full">
                <Button
                  block
                  color="neutral"
                  variant="text"
                  type="submit"
                  form={FORM_ID}
                  value="no-changes"
                  disabled={isLoading}
                >
                  No, don&apos;t apply markup
                </Button>
              </Flex>
              <Flex width="full">
                <Button
                  block
                  type="submit"
                  form={FORM_ID}
                  value="with-changes"
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Yes, update changes"}
                </Button>
              </Flex>
            </DialogFooter>
          </DialogStep>
        </Dialog>
      )}
      <Tooltip
        message={!canManage ? "You don't have permission to do this" : ""}
      >
        <Button
          size="sm"
          variant="ghost"
          color="neutral"
          aria-label="Edit inline markups"
          disabled={!canManage}
          onClick={dialog.show}
        >
          <Icon name="pen" />
        </Button>
      </Tooltip>
    </>
  );
};
