import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  ButtonGroup,
  CurrencyField,
  Dialog,
  dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent, useForm } from "@adaptive/design-system/hooks";
import { updateApproval } from "@api/approvals";
import { handleErrors } from "@api/handle-errors";
import { Comment } from "@components/comments/comment";
import { validateMessage } from "@components/comments/utils";
import { ErrorAlert } from "@components/common/error-alert";
import { ExpiredDocumentsAlert } from "@components/common/expired-documents-alert";
import { TooManyLinesAlert } from "@components/common/too-many-lines-alert";
import { DuplicateAlert } from "@components/duplicate-alert";
import { Form } from "@components/form";
import { RequestedVendorDocumentAlert } from "@components/request-vendor-document";
import {
  useBillFormActions,
  useBillFormPermissions,
} from "@src/bills/bill-form-context";
import { BILL_STATUS, getTransactionType, STRINGS } from "@src/bills/constants";
import {
  approvalsBillSelector,
  camelCaseBillSelector,
  duplicatesBillSelector,
  errorsBillSelector,
  isDirtyBillSelector,
  relatedErrorsBillSelector,
  statusBillSelector,
  vendorBillSelector,
  workflowsBillSelector,
} from "@src/bills/utils";
import { setBillStatus } from "@store/billSlice";
import { useAppSelector } from "@store/hooks";
import { BasePermissions, BaseRoles, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";
import { isNull } from "@utils/usefulFunctions";
import { z } from "zod";

import {
  loadBill,
  recordBillUpdate,
  unarchiveCurrentBill,
} from "../../../shared/store/billSlice";
import { ApprovalsSection } from "../approvals-section/";
import { Comments } from "../comments";
import { useCycle } from "../cycle-provider";
import { DynamicActions } from "../dynamic-actions";
import { Info } from "../info";
import { Items } from "../items";
import { PurchaseOrders, PurchaseOrdersAlert } from "../purchase-orders";

const schema = z.object({
  comment: z.string().max(1000),
});

const INITIAL_VALUES = {
  comment: "",
};

export const ApproveBillStep = () => {
  const { save, close: onClose } = useBillFormActions();

  const { role, user, hasPermission } = useUserInfo();

  const rejectCommentRef = useRef();

  const {
    id,
    docNumber,
    canApprove,
    isApproved,
    isArchivedByUser,
    linesCount,
    amountToPay,
    tooManyLines,
    isVendorCredit,
    alreadyEvaluated,
    initialReviewStatus,
    shouldShowPurchaseOrders,
    totalAmount,
  } = useAppSelector(camelCaseBillSelector);

  const billStatus = useAppSelector(statusBillSelector);

  const duplicates = useAppSelector(duplicatesBillSelector);

  const isDirty = useAppSelector(isDirtyBillSelector);

  const approvals = useAppSelector(approvalsBillSelector);

  const workflows = useAppSelector(workflowsBillSelector);

  const canBypass = hasPermission(BasePermissions.BYPASS_APPROVAL_WORKFLOWS);

  const isApprover = workflows.length
    ? canApprove
    : hasPermission(BasePermissions.APPROVE_BILLS);

  const vendor = useAppSelector(vendorBillSelector);

  const errors = useAppSelector(errorsBillSelector);

  const relatedErrors = useAppSelector(relatedErrorsBillSelector);

  const cycle = useCycle();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const transactionType = useMemo(
    () => getTransactionType(isVendorCredit),
    [isVendorCredit]
  );

  const refetchBill = useCallback(() => dispatch(loadBill(id)), [dispatch, id]);

  const permissions = useBillFormPermissions();

  const dialogReject = useDialog();

  const onSave = useEvent(() => save({ status: BILL_STATUS.APPROVAL }));

  const form = useForm({
    schema,
    async onSubmit(values) {
      const handler = async () => {
        dispatch(setBillStatus("loading"));

        try {
          await updateApproval(id, {
            status: "REJECTED",
            comment: values.comment,
            amount: amountToPay,
            bypass: false,
          });

          dialogReject.hide();

          toast.success(`${transactionType} #${docNumber} rejected`);

          if (!cycle.status || cycle.status === "all") {
            await refetchBill();
          } else {
            const hasNext = cycle.hasNavigation && (await cycle.next());

            if (hasNext) return;

            navigate("/bills?status=approval");

            toast.success(
              `You approved all ${transactionType.toLowerCase()}s that require your approval`
            );
          }
        } catch (e) {
          handleErrors(e);
          throw e;
        } finally {
          dispatch(setBillStatus("loaded"));
        }
      };

      const { promptWarning, hasExternalMentions } = validateMessage(
        values.comment
      );

      if (hasExternalMentions) {
        return promptWarning({
          onConfirm: handler,
          onCancel: () => rejectCommentRef.current?.focus(),
        });
      }

      await handler();
    },
    initialValues: INITIAL_VALUES,
  });

  const isBoss = useMemo(() => role.name === BaseRoles.BOSS, [role]);

  const canEvaluate =
    !isDirty &&
    (initialReviewStatus !== BILL_STATUS.APPROVAL ||
      (isApprover && !alreadyEvaluated && !isApproved) ||
      (canBypass && !isApproved));

  const isUserInWorkflow = useMemo(() => {
    const approvers = workflows.flatMap((workflow) =>
      workflow.steps.flatMap((step) => step.approvers)
    );

    return approvers.some(
      (approver) =>
        (approver.type === "role" && approver.name === role.name) ||
        (approver.type === "user" && approver.id === user.id)
    );
  }, [workflows, role.name, user.id]);

  const isLastApprover = useMemo(
    () =>
      workflows.some((workflow) => {
        const [missingStep, ...othersMissingSteps] = workflow.steps.filter(
          (step) =>
            !(
              (step.requirementType === "ONE_OF" &&
                step.approvers.some(
                  (approver) => approver.status === "APPROVED"
                )) ||
              (step.requirementType === "ALL_OF" &&
                step.approvers.every(
                  (approver) => approver.status === "APPROVED"
                ))
            )
        );

        if (!missingStep || othersMissingSteps.length > 0) return false;

        const userApprover = missingStep.approvers.find(
          (approver) =>
            (approver.type === "role" && role.id === approver.id) ||
            (approver.type === "user" && approver.id === user.id)
        );

        if (missingStep.requirementType === "ONE_OF" && !!userApprover) {
          return true;
        }

        const isAlreadyApprovedByOthers = missingStep.approvers
          .filter((approver) => userApprover?.id !== approver.id)
          .every((approver) => approver.status === "APPROVED");

        return (
          missingStep.requirementType === "ALL_OF" &&
          !!userApprover &&
          isAlreadyApprovedByOthers
        );
      }),
    [workflows, role.id, user.id]
  );

  const bypassActive = useMemo(
    () =>
      (canBypass && isUserInWorkflow && alreadyEvaluated && workflows.length) ||
      (canBypass && !isUserInWorkflow && workflows.length),
    [canBypass, isUserInWorkflow, alreadyEvaluated, workflows.length]
  );

  const evaluateTooltipMessage = useMemo(() => {
    if (canEvaluate) return;

    if (isDirty) {
      return "You have unsaved changes. Save changes first to proceed with this action";
    }

    if (initialReviewStatus !== BILL_STATUS.APPROVAL) {
      return "Click save to enable this action";
    }

    if (alreadyEvaluated) {
      return `You have already approved this ${transactionType.toLowerCase()}`;
    }

    return STRINGS.ACTION_NOT_ALLOWED;
  }, [
    isDirty,
    alreadyEvaluated,
    canEvaluate,
    initialReviewStatus,
    transactionType,
  ]);

  const submitApproval = useCallback(
    async (bypass) => {
      dispatch(setBillStatus("loading"));

      try {
        const result = await updateApproval(id, {
          status: "APPROVED",
          bypass,
          ...(!isVendorCredit ? { amount: amountToPay } : {}),
        });

        toast.success(`${transactionType} #${docNumber} approved`);

        if (!result) return;

        dispatch(
          recordBillUpdate({
            is_approved: result.data.is_approved,
            can_approve: result.data.can_approve,
            is_approval_evaluated: result.data.is_approval_evaluated,
            approvals: result.data.approvals,
            approval_workflows: result.data.approval_workflows,
          })
        );
      } catch (e) {
        handleErrors(e);
        throw e;
      } finally {
        dispatch(setBillStatus("loaded"));
      }
    },
    [amountToPay, docNumber, id, dispatch, transactionType, isVendorCredit]
  );

  const showBypassDialog = useCallback((onConfirm) => {
    dialog.confirmation({
      title: "Bypass others approvers",
      message:
        "Other people are supposed to review the transaction before it can be approved.",
      action: {
        primary: {
          onClick: onConfirm,
          children: "Bypass other approvals",
        },
      },
    });
  }, []);

  const onReject = useEvent(() => {
    form.reset();
    dialogReject.show();
  });

  const onApprove = useEvent(async () => {
    const handler = async (bypass) => {
      if (!isLastApprover) {
        toast.warning(
          `Other people on the approval chain still need to review this ${transactionType.toLowerCase()}`
        );
      }

      await submitApproval(bypass);
      analytics.track("billApprove", { billId: id, bypass });

      cycle.disable();

      refetchBill();
    };

    if (bypassActive) {
      showBypassDialog(() => {
        handler(true);
      });
    } else {
      handler(false);
    }
  });

  const onUnarchive = useEvent(() => {
    cycle.disable();
    dispatch(unarchiveCurrentBill());
  });

  const onApproveAndNext = useEvent(async () => {
    const handler = async (bypass) => {
      await submitApproval(bypass);

      const hasNext = cycle.hasNavigation && (await cycle.next());

      if (hasNext) return;

      navigate("/bills?status=approval");
      toast.success(
        `You approved all ${transactionType.toLowerCase()}s that require your approval`
      );
    };

    if (bypassActive) {
      showBypassDialog(() => {
        handler(true);
      });
    } else {
      handler(false);
    }
  });

  const isRejectLoading =
    form.isSubmitting || !form.isValid || billStatus === "loading";

  const onChangePaymentAmount = useEvent(
    (value) =>
      !isVendorCredit && dispatch(recordBillUpdate({ amount_to_pay: value }))
  );

  useEffect(() => {
    if (isNull(amountToPay) && !isVendorCredit) {
      dispatch(recordBillUpdate({ amount_to_pay: "0.00" }));
    }
  }, [amountToPay, dispatch, isVendorCredit]);

  return (
    <>
      <div className="steps-section-content">
        <Flex gap="5xl" direction="column" padding={["3xl", "5xl"]}>
          <Flex gap="xl" direction="column">
            {isArchivedByUser && permissions.canEditBill && (
              <Alert variant="info">
                <AlertTitle>
                  This {transactionType.toLowerCase()} is not editable
                </AlertTitle>
                <AlertContent>
                  <Link as="button" type="button" onClick={onUnarchive}>
                    Restore this {transactionType.toLowerCase()}
                  </Link>{" "}
                  in order to make any modifications to it
                </AlertContent>
              </Alert>
            )}

            {duplicates.length > 0 && <DuplicateAlert data={duplicates} />}

            <PurchaseOrdersAlert />

            <TooManyLinesAlert transactionType="Bill" linesCount={linesCount} />

            {(errors.length > 0 || relatedErrors.length > 0) && (
              <ErrorAlert
                data={errors}
                onChange={refetchBill}
                objectType="Bill"
                relatedData={relatedErrors}
              />
            )}

            <ExpiredDocumentsAlert vendor={vendor} />

            <RequestedVendorDocumentAlert vendor={vendor} />

            <Form onEnterSubmit={onSave}>
              <Info />
            </Form>
          </Flex>
          {!isVendorCredit && (
            <Flex
              gap="xl"
              direction="column"
              margin={["none", "auto", "none", "none"]}
            >
              <Text size="xl" weight="bold" id="amount-to-pay-label">
                Amount to pay
              </Text>
              <CurrencyField
                aria-labelledby="amount-to-pay-label"
                value={amountToPay}
                disabled={isArchivedByUser || !permissions.canEditBill}
                onChange={onChangePaymentAmount}
                autoFocus
                errorMessage={
                  amountToPay > totalAmount && STRINGS.PAYMENT_AMOUNT_T0_HIGH
                }
                triggerChangeOnFocusedUnmount={false}
              />
            </Flex>
          )}

          {shouldShowPurchaseOrders && <PurchaseOrders />}

          <Form onEnterSubmit={onSave}>
            <Items />
          </Form>

          {/**
           * We need it as backward compatibility since old bills could not have
           * workflows attached, new bills will always have at least one workflow
           */}
          {workflows.length > 0 && (
            <ApprovalsSection
              objectId={id}
              objectType="Bill"
              editable={
                initialReviewStatus === BILL_STATUS.APPROVAL &&
                !isArchivedByUser
              }
              approvals={approvals}
              workflows={workflows}
              helperMessage={(mode) =>
                mode !== "EMPTY" && initialReviewStatus !== BILL_STATUS.APPROVAL
                  ? "Click save to enable editing of approvals"
                  : ""
              }
            />
          )}
          <Comments />
        </Flex>
      </div>

      <footer className="steps-section-footer">
        <Flex gap="xl">
          <Button size="lg" variant="text" color="neutral" onClick={onClose}>
            Cancel
          </Button>
          <DynamicActions />
        </Flex>
        <Flex gap="xl">
          {isArchivedByUser ? (
            <Button size="lg" onClick={onUnarchive}>
              Restore
            </Button>
          ) : (
            <>
              <Tooltip
                message={
                  permissions.canEditBill
                    ? tooManyLines
                      ? STRINGS.BUTTON_TOO_MANY_LINES
                      : undefined
                    : STRINGS.ACTION_NOT_ALLOWED
                }
                placement="left"
              >
                <Button
                  disabled={
                    isArchivedByUser || !permissions.canEditBill || tooManyLines
                  }
                  variant="ghost"
                  size="lg"
                  onClick={onSave}
                >
                  Save
                </Button>
              </Tooltip>

              <Tooltip message={evaluateTooltipMessage} placement="left">
                <Button
                  size="lg"
                  color="error"
                  variant="ghost"
                  onClick={onReject}
                  disabled={!canEvaluate}
                >
                  Reject
                </Button>
              </Tooltip>

              <Tooltip message={evaluateTooltipMessage} placement="left">
                <ButtonGroup
                  size="lg"
                  disabled={!canEvaluate || cycle.isLoading}
                >
                  {cycle.status && cycle.status !== "all" ? (
                    <>
                      <Button onClick={onApproveAndNext}>
                        {cycle.isLoading ? (
                          <Loader />
                        ) : bypassActive && !isApproved ? (
                          "Bypass approvals"
                        ) : (
                          "Approve"
                        )}
                      </Button>
                      {(isBoss || (permissions.canPayBill && isLastApprover)) &&
                        !cycle.isLoading && (
                          <Dropdown placement="bottom-end">
                            <DropdownTrigger
                              as={Button}
                              aria-label="Actions"
                              data-testid="bill-approve-actions-trigger"
                            >
                              <Icon name="chevron-down" />
                            </DropdownTrigger>
                            <DropdownList>
                              <DropdownItem onClick={onApprove}>
                                Advance to payment
                              </DropdownItem>
                            </DropdownList>
                          </Dropdown>
                        )}
                    </>
                  ) : (
                    <Button onClick={onApprove}>Approve</Button>
                  )}
                </ButtonGroup>
              </Tooltip>
            </>
          )}
        </Flex>
      </footer>

      <Dialog
        size="sm"
        show={dialogReject.isVisible}
        onClose={dialogReject.hide}
      >
        <DialogHeader>Reject {transactionType}</DialogHeader>
        <DialogContent>
          <Flex as="form" {...form.props} direction="column">
            <Comment
              ref={rejectCommentRef}
              label={`Reason for rejecting the ${transactionType.toLowerCase()}`}
              vendor={vendor}
              disabled={isRejectLoading}
              minHeight={96}
              messageVariant="absolute"
              {...form.register({ name: "comment" })}
            />
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Button
            block
            variant="ghost"
            color="neutral"
            onClick={dialogReject.hide}
            disabled={isRejectLoading}
          >
            Cancel
          </Button>
          <Button
            block
            color="error"
            form={form.id}
            type="submit"
            disabled={isRejectLoading}
            data-testid="bill-reject-submit"
          >
            {isRejectLoading ? <Loader /> : "Reject"}
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
