import React, { type ComponentPropsWithoutRef } from "react";
import { normalizeProps, useActor } from "@zag-js/react";
import * as zagToast from "@zag-js/toast";

import { Alert } from "../alert";
import { AlertContent } from "../alert/alert-content";

import type { InternalToastConfig } from "./toast-create";
import styles from "./toast.module.css";

type Props = {
  actor: zagToast.Service;
};

type Context = {
  render?: (api: zagToast.Api) => InternalToastConfig;
};

const TYPE: Record<
  zagToast.Type,
  ComponentPropsWithoutRef<typeof Alert>["variant"]
> = {
  info: "info",
  error: "error",
  custom: "warning",
  loading: "info",
  success: "success",
};

export const Toast = ({ actor }: Props) => {
  const [state, send] = useActor(actor);

  const api = zagToast.connect(state, send, normalizeProps);

  const { maxWidth, message, truncate } =
    (state?.context as Context)?.render?.(api) ?? {};

  const progressbarProps = {
    style: {
      opacity: api.visible ? 1 : 0,
      animationName: api.type === "loading" ? "none" : undefined,
      transformOrigin: "left",
      animationDuration: `${state.context.duration}ms`,
      animationPlayState: api.paused ? "paused" : "running",
    },
    "data-part": "progressbar",
    "data-type": state.context.type,
    "data-scope": "toast",
  };

  const style = {
    ...api.rootProps.style,
    ...(maxWidth
      ? { maxWidth: typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth }
      : {}),
  };

  return (
    <Alert
      show
      variant={TYPE[api.type]}
      onClose={api.dismiss}
      className={styles["toast"]}
      {...api.rootProps}
      style={style}
    >
      <span {...api.ghostBeforeProps} />
      <AlertContent {...api.titleProps} truncate={truncate}>
        {message}
      </AlertContent>
      <div className={styles["progress-bar"]} {...progressbarProps} />
      <span {...api.ghostAfterProps} />
    </Alert>
  );
};
