import React, { useEffect, useState } from "react";
import { Link as ReactRouterLink, Outlet } from "react-router-dom";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Flex,
  Link,
} from "@adaptive/design-system";
import { query } from "@api/expenses/outdated-expenses";
import { Main, MainContent } from "@components/main";
import { useBankAccountsV2 } from "@hooks/use-bank-accounts";
import { useClientInfo } from "@src/shared/store/user";
import { useExpenseAction } from "@store/expenses";

import { Heading } from "./heading";
import { ExpensesTab } from "./tabs";

const ExpensesErrorAlert = () => {
  const { client, realmId } = useClientInfo();

  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => {
    if (!realmId) return;

    query({
      filters: {
        realm: String(realmId),
        review_status: "errors",
        include_transaction_generated_drafts:
          !client?.settings.card_feed_enabled,
      },
    }).then((response) => setHasErrors(response?.count > 0));
  }, [client?.settings.card_feed_enabled, realmId]);

  return (
    hasErrors && (
      <Alert variant="warning">
        <AlertTitle>
          Some of your receipts did not sync with QuickBooks
        </AlertTitle>
        <AlertContent>
          <Link as={ReactRouterLink} to="/expenses?status=all" state="errors">
            View receipts with sync errors
          </Link>
        </AlertContent>
      </Alert>
    )
  );
};

const ExpensesPlaidAlert = () => {
  const { isPlaidLoginRequired } = useBankAccountsV2({ accountType: "credit" });

  return (
    isPlaidLoginRequired && (
      <Alert variant="error">
        <AlertTitle>
          Your credentials have expired for one or more cards
        </AlertTitle>
        <AlertContent>
          Please{" "}
          <Link as={ReactRouterLink} to="/settings/company/cards">
            re-authenticate your bank account
          </Link>{" "}
          to continue pulling transactions
        </AlertContent>
      </Alert>
    )
  );
};

export const ExpensesTablePage = () => {
  const { loadTableData } = useExpenseAction();

  useEffect(() => loadTableData(), [loadTableData]);

  return (
    <>
      <Heading />
      <MainContent>
        <Flex
          direction="column"
          style={{
            /* We need to this calc to compensate margin from MainContent */
            minHeight: "calc(var(--spacing-full) - var(--spacing-4xl))",
          }}
          gap="xl"
          shrink={false}
        >
          <ExpensesErrorAlert />
          <ExpensesPlaidAlert />
          <ExpensesTab />
        </Flex>
      </MainContent>
    </>
  );
};

export const Expenses = () => (
  <Main>
    <Outlet />
  </Main>
);
