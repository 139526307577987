import {
  ComponentPropsWithoutRef,
  ComponentRef,
  EventHandler,
  FormEvent,
  KeyboardEventHandler,
  useRef,
} from "react";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import { mergeRefs } from "@adaptive/design-system/utils";

type DefaultComponent = "form";

type Ref = ComponentRef<DefaultComponent>;

type Props = ComponentPropsWithoutRef<DefaultComponent> & {
  onEnterSubmit?: () => void;
};

type FormHandler = EventHandler<FormEvent<HTMLFormElement>>;

export const Form = forwardRef<Ref, Props>(
  ({ id, onInvalid, onSubmit, onEnterSubmit, ...props }, ref) => {
    const internalRef = useRef<HTMLFormElement>(null);
    const [invalid, setInvalid] = useState<HTMLInputElement | null>(null);
    useEffect(() => {
      if (!invalid) return;

      invalid.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });

      invalid.focus({ preventScroll: true });
      setInvalid(null);
    }, [invalid]);

    const onKeyDown = useEvent<KeyboardEventHandler>((e) => {
      if (
        e.code === "Enter" &&
        document.activeElement &&
        internalRef.current?.contains(document.activeElement)
      ) {
        const isButton =
          document.activeElement.tagName === "BUTTON" ||
          document.activeElement.getAttribute("role") === "button";

        const isComboBox =
          document.activeElement.getAttribute("role") === "combobox";
        const isComboboxExpanded =
          document.activeElement.getAttribute("aria-expanded") === "true";

        if (!isButton && (!isComboBox || (isComboBox && !isComboboxExpanded))) {
          e.preventDefault();
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
          requestAnimationFrame(() => onEnterSubmit?.());
        }
      }
    });

    const enhancedOnInvalid = useCallback<FormHandler>(
      (e) => {
        const input = e.target as HTMLInputElement;
        if (!invalid && input) setInvalid(input);
        onInvalid?.(e);
      },
      [invalid, onInvalid]
    );

    /**
     * Copied from `useForm` hook enhanced onSubmit logic
     */
    const enhancedOnSubmit = useCallback<FormHandler>(
      async (e) => {
        e.preventDefault();

        const targetId = (e.target as HTMLElement).id;
        const isCorrectForm = id === targetId || !targetId;

        if (!isCorrectForm) return;

        const innerSubmitButton =
          e.currentTarget?.querySelector<HTMLElement>("[type='submit']");

        const outsideSubmitButton = document.querySelector<HTMLElement>(
          `[form="${id}"]`
        );

        (innerSubmitButton || outsideSubmitButton)?.focus({
          preventScroll: true,
        });

        requestAnimationFrame(() => {
          onSubmit?.(e);
        });
      },
      [id, onSubmit]
    );

    return (
      <form
        id={id}
        ref={mergeRefs(ref, internalRef)}
        onSubmit={enhancedOnSubmit}
        onInvalid={enhancedOnInvalid}
        onKeyDown={onKeyDown}
        {...props}
      />
    );
  }
);

Form.displayName = "Form";
