import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Flex,
  LabelValueGroup,
  Loader,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import type { BillPayment } from "@api/bill-payments";
import { CardTransactionField } from "@card-feed/components";
import type { CardTransactionFieldOnChangeHandler } from "@card-feed/types";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { useBankAccountsV1 } from "@hooks/use-bank-accounts";
import {
  HUMAN_READABLE_PAYMENT_STATUS,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PAYMENT_STATUS_VARIANT,
} from "@src/bills/constants";
import { OBJECT_ORIGIN } from "@src/shared/constants";
import { updateBillPayment } from "@src/shared/store/billSlice";
import { useClientSettings } from "@store/user";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";
import { humanReadablePaymentMethod } from "@utils/usefulFunctions";

import type { PaymentStatusKey } from "../../types";

import { getVendorACHLabel, selectVendorPaymentData } from "./utils";

type DataProps = {
  label: string;
  value: any;
}[];

type BillPaymentInfoProps = {
  loading: boolean;
  disabled?: boolean;
  billPayment: BillPayment;
  onViewPayment: (value: string) => void;
  onCancelPayment?: (value: string) => void;
};

export const BillPaymentInfo = ({
  loading,
  disabled,
  billPayment,
  onViewPayment,
  onCancelPayment,
}: BillPaymentInfoProps) => {
  const settings = useClientSettings();

  const dispatch = useDispatch();

  const accountBalances = useBankAccountsV1();

  const camelCasedBillPayment = useMemo(
    () => transformKeysToCamelCase(billPayment),
    [billPayment]
  );

  const paymentAccounts = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  const { addressOptions: vendorAddresses } = useSelector(
    selectVendorPaymentData
  );

  const accountBalance = accountBalances.data.find(
    (item) => item.value === billPayment.account_balance?.url
  );

  const vendorAddress = vendorAddresses.find(
    (item) => item.value === billPayment.vendor_address
  )?.label;

  const account = paymentAccounts.data.find(
    (item) => item.value === billPayment.account
  )?.label;

  const {
    total_amount: totalAmount,
    original_total_amount: originalTotalAmount,
    internal_method: internalMethod,
    status,
    vendor_email: vendorEmail,
    vendor_banking_ach: vendorBankingAch,
    vendor_phone_number: vendorPhoneNumber,
    transaction_date: transactionDate,
    checks,
    id: billPaymentId,
    amount_for_bill: amountForBill,
    vendor: billPaymentVendor,
    created_in: createdIn,
  } = billPayment;

  const onChangeCardTransaction = useEvent<CardTransactionFieldOnChangeHandler>(
    (_, option) => {
      dispatch(
        updateBillPayment({
          id: billPaymentId,
          card_transaction: option ? transformKeysToSnakeCase(option) : null,
        })
      );
    }
  );

  const paymentAmount = useMemo(() => {
    if (
      [PAYMENT_STATUS.CANCELLED, PAYMENT_STATUS.FAILED].includes(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        status as PaymentStatusKey
      )
    ) {
      const formattedOriginalTotalAmount = formatCurrency(originalTotalAmount, {
        currencySign: true,
        allowNegative: true,
      });
      return formattedOriginalTotalAmount;
    }
    const formattedTotalAmount = formatCurrency(totalAmount, {
      currencySign: true,
      allowNegative: true,
    });

    const formattedAmountForBill = formatCurrency(amountForBill, {
      currencySign: true,
      allowNegative: true,
    });

    // if values differ, it means the payment includes multiple bills
    // and we only want to show the amount paid for this particular bill
    return formattedAmountForBill !== formattedTotalAmount
      ? `${formattedAmountForBill} (of ${formattedTotalAmount} payment)`
      : formattedTotalAmount;
  }, [originalTotalAmount, totalAmount, amountForBill, status]);

  const data = useMemo(() => {
    const items: DataProps = [
      { label: "Made in", value: createdIn },
      { label: "Payment amount", value: paymentAmount },
    ];

    if (billPaymentVendor?.display_name) {
      items.push({ label: "Recipient", value: billPaymentVendor.display_name });
    }

    if (internalMethod) {
      items.push({
        label: "Payment method",
        value: humanReadablePaymentMethod(internalMethod),
      });
    }

    if (createdIn === OBJECT_ORIGIN.ADAPTIVE) {
      if (internalMethod === PAYMENT_METHOD.MARK_AS_PAID) {
        if (account) items.push({ label: "Payment account", value: account });
      } else {
        if (accountBalance) {
          items.push({ label: "From account", value: accountBalance.label });
        }

        const check = checks?.[0];

        if (check) {
          items.push({ label: "Check number", value: check.check_number });
        }
      }
    }

    if (internalMethod === PAYMENT_METHOD.ACH) {
      if (vendorEmail) {
        items.push({ label: "Recipient email", value: vendorEmail });
      }

      if (
        status !== PAYMENT_STATUS.CANCELLED &&
        status !== PAYMENT_STATUS.ACH_REQUESTED
      ) {
        const toAccount =
          vendorBankingAch &&
          getVendorACHLabel(transformKeysToCamelCase(vendorBankingAch));

        if (toAccount) {
          items.push({ label: "To account", value: toAccount });
        }
      }
    } else if (internalMethod === PAYMENT_METHOD.VIRTUAL_EMAIL_CHECK) {
      if (vendorEmail) {
        items.push({ label: "Recipient email", value: vendorEmail });
      }
    } else if (internalMethod === PAYMENT_METHOD.VIRTUAL_SMS_CHECK) {
      if (vendorPhoneNumber) {
        items.push({
          label: "Recipient phone number",
          value: vendorPhoneNumber,
        });
      }

      if (vendorAddress) {
        items.push({ label: "Recipient address", value: vendorAddress });
      }
    }

    return items;
  }, [
    account,
    vendorBankingAch,
    vendorEmail,
    status,
    vendorAddress,
    vendorPhoneNumber,
    accountBalance,
    internalMethod,
    checks,
    paymentAmount,
    billPaymentVendor?.display_name,
    createdIn,
  ]);

  return (
    <Flex gap="xl" direction="column">
      <Alert
        variant={
          status in PAYMENT_STATUS_VARIANT
            ? PAYMENT_STATUS_VARIANT[status as PaymentStatusKey]
            : "info"
        }
      >
        <AlertTitle>
          <Flex gap="xl" justify="space-between">
            Payment {HUMAN_READABLE_PAYMENT_STATUS[status as PaymentStatusKey]}
            {transactionDate && (
              <Text as="span" weight="regular">
                Paid on{" "}
                <Text as="strong" weight="bold">
                  {formatDate(parseDate(transactionDate, "yyyy-MM-dd"), "P")}
                </Text>
              </Text>
            )}
          </Flex>
        </AlertTitle>

        <AlertContent as={Flex} gap="xl" justify="space-between">
          <LabelValueGroup data={data} compact />
          {internalMethod === PAYMENT_METHOD.PRINT_CHECK &&
            checks.length > 0 && (
              <Flex>
                <Button onClick={() => onViewPayment(billPaymentId)}>
                  View check
                </Button>
              </Flex>
            )}
          {internalMethod === PAYMENT_METHOD.ACH &&
            status === PAYMENT_STATUS.ACH_REQUESTED &&
            onCancelPayment && (
              <Flex>
                <Button
                  onClick={() => onCancelPayment(billPaymentId)}
                  size="lg"
                >
                  {loading ? <Loader /> : "Cancel payment"}
                </Button>
              </Flex>
            )}
        </AlertContent>
      </Alert>
      {settings.cardFeedEnabled && (
        <CardTransactionField
          cost={camelCasedBillPayment}
          label="Card transaction"
          onChange={onChangeCardTransaction}
          disabled={disabled}
          hintMessage="The transaction from your card history that matches this receipt"
          valueVariant="long"
          messageVariant="absolute"
        />
      )}
    </Flex>
  );
};
