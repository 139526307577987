import React from "react";
import { dialog, Flex, Tag, Text } from "@adaptive/design-system";
import { parseMention } from "@adaptive/design-system/utils";

type PromptWarningHandler = (params: {
  message?: string;
  onCancel?: () => void;
  onConfirm: (hasExternalMentions: boolean) => void;
}) => void;

export const validateMessage = (value: string) => {
  const mentions = new Set<string>();

  parseMention({
    value,
    render: ({ url, mention }) => {
      if (url?.includes("/vendors/")) mentions.add(mention);
    },
  });

  const externalMentions = [...mentions];

  const hasExternalMentions = !!externalMentions.length;

  const promptWarning: PromptWarningHandler = ({
    message = "You @ mentioned someone external to your company:",
    onCancel,
    onConfirm,
  }) => {
    const dialogId = dialog.confirmation({
      title: "Is it OK to send this?",
      align: "center",
      message: (
        <Flex direction="column" gap="xl" align="center">
          <Text align="center">{message}</Text>
          <Flex gap="sm" wrap justify="center">
            {externalMentions.map((mention) => (
              <Tag color="info" key={mention}>
                {mention}
              </Tag>
            ))}
          </Flex>
          <Text align="center">
            They will receive a notification and have access to all replies
            within this comment thread.
          </Text>
        </Flex>
      ),
      action: {
        primary: {
          children: "Send",
          onClick: () => {
            onConfirm?.(hasExternalMentions);
          },
        },
        secondary: {
          children: "Cancel",
          autoHide: false,
          onClick: () => {
            dialog.hide(dialogId).then(() => onCancel?.());
          },
        },
      },
    });
  };

  return { promptWarning, externalMentions, hasExternalMentions };
};
