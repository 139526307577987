import React from "react";
import { Avatar, Flex, Text } from "@adaptive/design-system";
import {
  formatDate,
  parseDate,
  parseStringCopy,
} from "@adaptive/design-system/utils";

import { LIEN_WAIVER_STATUS, STRINGS } from "../constants/constants";
import type { BillLienWaiver } from "../types";

type BillLienWaiverFieldSignedProps = {
  billLienWaiver: BillLienWaiver;
};

export const BillLienWaiverFieldSigned = ({
  billLienWaiver,
}: BillLienWaiverFieldSignedProps) => {
  const revisionEventDate = parseDate(
    billLienWaiver.revisionEvent.createdAt,
    "iso"
  );

  const isMarkedAsSigned =
    billLienWaiver.status === LIEN_WAIVER_STATUS.MARKED_AS_SIGNED;

  return (
    <Flex width="full" gap="md" align="flex-start">
      <Avatar name="signature" color="success" size="lg" />
      <Flex align="flex-start" direction="column" gap="xs">
        <Text weight="bold">
          {parseStringCopy(
            isMarkedAsSigned
              ? STRINGS.LIEN_WAIVER_SIGNED_UPLOAD_TITLE
              : STRINGS.LIEN_WAIVER_SIGNED_TITLE,
            {
              // TODO: Replace with vendor name from data
              vendor: "John Caroll",
              user: billLienWaiver.revisionEvent.author.fullName,
            }
          )}
        </Text>

        <Text color="neutral-700" size="sm">
          {parseStringCopy(STRINGS.LIEN_WAIVER_ACTIVITY_TIME, {
            date: formatDate(revisionEventDate, "MM/dd/yy"),
            time: formatDate(revisionEventDate, "hh:mma"),
          })}
        </Text>
      </Flex>
    </Flex>
  );
};
