import React, { memo, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ComboBox,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Link,
  Loader,
  PhoneField,
  Switch,
  Text,
  TextField,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, useForm } from "@adaptive/design-system/hooks";
import {
  useGetBudgetLinesQuery,
  useGetMarkupsForCustomerQuery,
} from "@api/budgets";
import { postCustomer, putCustomer } from "@api/customers";
import {
  displayError,
  getNonFieldErrors,
  handleErrors,
  isNonFieldErrors,
} from "@api/handle-errors";
import { useBankAccountsV1 } from "@hooks/use-bank-accounts";
import { useLocationSimplified } from "@hooks/useLocationSimplified";
import { useQBClassSimplified } from "@shared/hooks/useQBClassSimplified";
import { useUsersSimplified } from "@shared/hooks/useUsersSimplified";
import { useGetChangesQuery } from "@src/shared/api/jobs/changes";
import { STATE_OPTIONS } from "@src/vendors/vendor/tabs/constants";
import { api } from "@store/api-simplified";
import { useAppDispatch } from "@store/hooks";
import { fetchJob, setCategoriesEnabled, useJobInfo } from "@store/jobs";
import { useDrawerVisibility } from "@store/ui";
import { useClientInfo } from "@store/user";
import { z } from "zod";

const ERRORS_MAP = {
  display_name_with_parent: "A job with this name already exists",
  display_name_with_null_parent: "A job with this name already exists",
};

const schema = z.object({
  zip: z.string(),
  name: z.string().min(1),
  state: z.string(),
  phone: z.string(),
  active: z.boolean(),
  address: z.string(),
  city: z.string(),
  qb_class: z.string(),
  location: z.string(),
  company_name: z.string().optional(),
  bank_account: z.string(),
  people_on_job: z.array(z.string()),
  categories_enabled: z.boolean(),
  change_tracking_enabled: z.boolean(),
  owners_amount_enabled: z.boolean(),
});

type Fields = z.infer<typeof schema>;

const INITIAL_VALUES: Fields = {
  zip: "",
  name: "",
  state: "",
  phone: "",
  address: "",
  city: "",
  active: true,
  qb_class: "",
  location: "",
  bank_account: "",
  company_name: "",
  people_on_job: [],
  categories_enabled: false,
  change_tracking_enabled: false,
  owners_amount_enabled: false,
};

export const JobFormInfo = memo(() => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { status, job } = useJobInfo();

  const { realm, client } = useClientInfo();

  const { setStep, visible, setVisible, setShouldShowHideConfirmation } =
    useDrawerVisibility("job");

  const peopleOnJob = useUsersSimplified({
    filters: { is_staff: false },
    enabled: visible,
  });

  const { data: changes } = useGetChangesQuery(
    { customerId: job.id },
    { refetchOnMountOrArgChange: true, skip: !job.id }
  );

  const disableTurnOffChanges = useMemo(
    () => (changes?.results || []).length > 0,
    [changes]
  );

  const initialValues = useMemo(() => {
    if (job.id && visible && status === "loaded") {
      const address = job.addresses.find(
        (address) => address.type === "Billing"
      );

      return {
        name: job.display_name_without_company || "",
        company_name: job.company_name || "",
        bank_account: job.bank_account?.url || "",
        qb_class: job.qb_class?.url || "",
        location: job.location?.url || "",
        people_on_job: job.restricted_to_users.map((user) => user.url),
        phone: job.phone_number || "",
        address: address?.line1 || "",
        city: address?.city || "",
        state: address?.state || "",
        zip: address?.postal_code || "",
        active: job.active,
        categories_enabled: job.categories_enabled ?? false,
        change_tracking_enabled: job.change_tracking_enabled ?? false,
        owners_amount_enabled: job.owners_amount_enabled ?? false,
      };
    }

    return INITIAL_VALUES;
  }, [
    job.id,
    status,
    visible,
    job.active,
    job.addresses,
    job.company_name,
    job.phone_number,
    job.location?.url,
    job.qb_class?.url,
    job.bank_account?.url,
    job.categories_enabled,
    job.restricted_to_users,
    job.owners_amount_enabled,
    job.change_tracking_enabled,
    job.display_name_without_company,
  ]);

  const bankAccounts = useBankAccountsV1({ enabled: visible });

  const [categoriesIsLoading, setCategoriesIsLoading] = useState(false);

  const canSetQBClass = client?.settings.can_set_qb_class ?? false;

  const qbClasses = useQBClassSimplified({ enabled: canSetQBClass && visible });

  const canSetLocation = client?.settings.can_set_location ?? false;

  const locations = useLocationSimplified({
    enabled: canSetLocation && visible,
  });

  const clientCategoriesEnabled = client?.settings.categories_enabled ?? false;

  const clientChangeTrackingEnabled =
    client?.settings.change_tracking_enabled ?? false;

  const clientExternalBudgetEnabled =
    client?.settings.external_budget_enabled ?? false;

  const { data: budgetLines = [] } = useGetBudgetLinesQuery({
    customerId: job.id,
  });

  const { reset, ...form } = useForm<Fields>({
    schema,
    async onSubmit(values) {
      const enhancedValues = {
        active: values.active,
        address: {
          line1: values.address,
          state: values.state,
          postal_code: values.zip,
          city: values.city,
        },
        qb_class: values.qb_class,
        location: values.location,
        bank_account: values.bank_account,
        phone_number: values.phone,
        display_name: values.name,
        company_name: values.company_name,
        categories_enabled: values.categories_enabled,
        restricted_to_users: values.people_on_job,
        change_tracking_enabled: values.change_tracking_enabled,
        owners_amount_enabled: values.owners_amount_enabled,
      };
      const shouldOfferAutoExternalBudget =
        job.owners_amount_enabled === false &&
        values.owners_amount_enabled &&
        budgetLines.length > 0;
      try {
        if (job.id) {
          await putCustomer(String(job.id), enhancedValues);
        } else {
          const job = await postCustomer({
            realm: realm?.url,
            ...enhancedValues,
          });
          navigate(`/jobs/${job.id}`);
          setVisible(false, true);
        }
        /**
         * @todo move async logic to live on redux RTK to
         * avoid this kind of workaround to invalidate cache
         */
        dispatch(
          api.util.invalidateTags([
            "CustomersSimplified",
            "BudgetLines",
            "CustomerMarkup",
          ])
        );
      } catch (e: unknown) {
        if (isNonFieldErrors(e)) {
          for (const error of getNonFieldErrors(e)) {
            let errorDisplayed = false;
            for (const [key, value] of Object.entries(ERRORS_MAP)) {
              if (error.indexOf(key) !== -1) {
                displayError(value);
                errorDisplayed = true;
                break;
              }
            }
            if (!errorDisplayed) displayError(error);
          }
        } else handleErrors(e);
        return;
      }
      if (job.id) {
        await dispatch(fetchJob(String(job.id)));
        toast.success("Job updated!");
        if (shouldOfferAutoExternalBudget)
          toast.success(
            <Flex as="span" direction="column">
              <Text as="strong" weight="bold">
                External budget enabled{" "}
              </Text>
              <Link
                href="https://help.adaptive.build/en/articles/8944533-internal-budget-vs-external-budget"
                target="_blank"
              >
                Learn more about managing multiple budgets
              </Link>
            </Flex>,
            {
              duration: 9000,
            }
          );
      } else {
        toast.success("Job created!");
      }
    },
    initialValues,
  });

  const onChangeCategoriesEnabled = useEvent(async (value: boolean) => {
    if (!job.id) return;

    setCategoriesIsLoading(true);
    dispatch(setCategoriesEnabled(value));
    form.setValue("categories_enabled", value);

    try {
      await putCustomer(String(job.id), { categories_enabled: value });
    } catch (e) {
      dispatch(setCategoriesEnabled(!value));
      form.setValue("categories_enabled", !value);
      handleErrors(e);
    } finally {
      setCategoriesIsLoading(false);
    }
  });

  const { data: markups = [] } = useGetMarkupsForCustomerQuery({
    customer: job.id,
    distinct: true,
    isSeparateLine: true,
  });

  const disableExternalBudget = useMemo(() => {
    return markups.some((markup) => !!markup.ownersValue);
  }, [markups]);

  useEffect(() => {
    if (visible) reset();
  }, [visible, reset]);

  useEffect(() => {
    setShouldShowHideConfirmation(form.isDirty);
  }, [form.isDirty, setShouldShowHideConfirmation]);

  return (
    <>
      <DialogHeader>
        <Flex justify="space-between" align="center" width="full">
          {job.id ? "Edit job" : "New job"}
          {!!job.id && (
            <Switch
              label="Active"
              placement="right"
              disabled={form.isSubmitting}
              {...form.register({ name: "active", type: "boolean" })}
            />
          )}
        </Flex>
      </DialogHeader>
      <DialogContent>
        <Flex as="form" {...form.props} direction="column">
          <Flex gap="xl" direction="row" width="full">
            <TextField
              label="Company name"
              disabled={form.isSubmitting}
              {...form.register("company_name")}
            />
            <TextField
              label="Job name"
              required
              disabled={form.isSubmitting}
              {...form.register("name")}
            />
          </Flex>
          <Flex gap="xl" direction="row" width="full">
            {canSetQBClass && (
              <ComboBox
                data={qbClasses.data}
                loading={qbClasses.status === "loading"}
                label="Class"
                disabled={form.isSubmitting}
                hintMessage="This class will be applied to all bills and expenses linked to this job"
                {...form.register("qb_class")}
              />
            )}
            {canSetLocation && (
              <ComboBox
                data={locations.data}
                loading={locations.status === "loading"}
                label="Location"
                disabled={form.isSubmitting}
                {...form.register("location")}
              />
            )}
          </Flex>
          <ComboBox
            data={bankAccounts.data}
            loading={bankAccounts.status === "loading"}
            label="Default bank account"
            hintMessage="This account will be prefilled during payment"
            disabled={form.isSubmitting}
            {...form.register("bank_account")}
          />
          <ComboBox
            data={peopleOnJob.data}
            loading={peopleOnJob.status === "loading"}
            multiple
            label="People on job"
            hintMessage="Everyone is able to see all the job details unless only specific people are assigned"
            placeholder="Everyone"
            disabled={form.isSubmitting}
            {...form.register({
              name: "people_on_job",
              type: "multiple-select",
            })}
          />
          <Flex gap="xl" direction="row" width="full">
            <Flex direction="column" width="full">
              <TextField
                label="Address"
                disabled={form.isSubmitting}
                {...form.register("address")}
              />
              <Flex direction="row" gap="xl">
                <ComboBox
                  data={STATE_OPTIONS}
                  label="State"
                  disabled={form.isSubmitting}
                  {...form.register("state")}
                />
                <TextField
                  label="Zip"
                  disabled={form.isSubmitting}
                  {...form.register("zip")}
                />
              </Flex>
            </Flex>
            <Flex direction="column" width="full">
              <TextField
                label="City"
                disabled={form.isSubmitting}
                {...form.register("city")}
              />
              <PhoneField
                label="Phone"
                disabled={form.isSubmitting}
                {...form.register("phone")}
              />
            </Flex>
          </Flex>
          <Flex gap="2xl" direction="column">
            {clientCategoriesEnabled && (
              <Flex gap="xl" justify="space-between" align="flex-end">
                <Flex gap="md" direction="column">
                  <Text size="sm">Draw schedule categorization</Text>
                  <Switch
                    label="Categories"
                    placement="right"
                    disabled={form.isSubmitting}
                    {...form.register({
                      name: "categories_enabled",
                      type: "boolean",
                      onChange: onChangeCategoriesEnabled,
                    })}
                  />
                </Flex>
                {form.values.categories_enabled && !!job.id && (
                  <Tooltip
                    message={
                      categoriesIsLoading
                        ? "We are enabling categories for this job"
                        : ""
                    }
                  >
                    <Button
                      size="sm"
                      variant="ghost"
                      onClick={() => setStep("categories")}
                      disabled={form.isSubmitting || categoriesIsLoading}
                    >
                      Manage categories
                    </Button>
                  </Tooltip>
                )}
              </Flex>
            )}
            {clientChangeTrackingEnabled && (
              <Flex gap="md" direction="column">
                <Text size="sm">Change tracking</Text>
                <Tooltip
                  as={Flex}
                  width="fit-content"
                  message={
                    disableTurnOffChanges &&
                    "Change tracking cannot be disabled once\nyou have added changes to your budget"
                  }
                >
                  <Switch
                    disabled={form.isSubmitting || disableTurnOffChanges}
                    placement="right"
                    label="Changes + revised budget"
                    {...form.register({
                      name: "change_tracking_enabled",
                      type: "boolean",
                    })}
                  />
                </Tooltip>
              </Flex>
            )}
            {clientExternalBudgetEnabled && (
              <Flex gap="md" direction="column">
                <Text size="sm">Multiple budgets</Text>
                <Flex align="flex-start" gap="md">
                  <Switch
                    label="Cost budget"
                    checked
                    disabled={form.isSubmitting || true}
                    placement="right"
                  />
                </Flex>
                <Flex />
                <Tooltip
                  as={Flex}
                  width="fit-content"
                  message={
                    disableExternalBudget &&
                    "External budget cannot be disabled once\nyou have added amounts or markup to your external budget"
                  }
                >
                  <Switch
                    placement="right"
                    label="External budget"
                    disabled={disableExternalBudget || form.isSubmitting}
                    {...form.register({
                      name: "owners_amount_enabled",
                      type: "boolean",
                    })}
                  />
                </Tooltip>
              </Flex>
            )}
          </Flex>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button
          variant="text"
          color="neutral"
          size="lg"
          type="button"
          onClick={() => setVisible(false)}
          data-testid="cancel-job"
        >
          Cancel
        </Button>
        <Button
          variant="solid"
          type="submit"
          size="lg"
          disabled={form.isSubmitting || !form.isValid}
          form={form.id}
          data-testid="save-job"
        >
          {form.isSubmitting ? <Loader /> : "Save"}
        </Button>
      </DialogFooter>
    </>
  );
});

JobFormInfo.displayName = "JobFormInfo";
