import React, { type ReactNode, useMemo, useState } from "react";
import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";

import { useEvent } from "../../hooks/use-event";

import { TableContext, type Value } from "./table-context";
import type { Column } from "./types";

type TableProviderProps = { id: string; children: ReactNode };

export const TableProvider = ({ id, children }: TableProviderProps) => {
  const [order, setOrder] = useState<
    Record<string, ColumnOrderState | undefined>
  >({});

  const [, setDescendantsIds] = useState<string[]>([]);

  const [columns, setColumns] = useState<Record<string, Column<any>[]>>({});

  const [visibility, setVisibility] = useState<
    Record<string, VisibilityState | undefined>
  >({});

  const enhancedSetOrder = useEvent((nextOrder: ColumnOrderState) => {
    setOrder((previousOrder) => ({ ...previousOrder, [id]: nextOrder }));
  });

  const enhancedSetColumns = useEvent((nextColumns: Column<any>[]) =>
    setColumns((previousColumns) => ({
      ...previousColumns,
      [id]: nextColumns,
    }))
  );

  const enhancedSetVisibility = useEvent((nextVisibility: VisibilityState) =>
    setVisibility((previousVisibility) => ({
      ...previousVisibility,
      [id]: nextVisibility,
    }))
  );

  const enhancedSetDescendantsIds = useEvent(
    (cb: (descendantsIds: string[]) => string[]) =>
      setDescendantsIds((previousDescendantsIds) => {
        const nextDescendantsIds = cb(previousDescendantsIds);

        if (nextDescendantsIds.length > 1) {
          // eslint-disable-next-line no-console
          console.warn(
            `TableProvider: ${id} has more than one descendant table. This could cause some unexpected side effects.`
          );
        }

        return nextDescendantsIds;
      })
  );

  const value = useMemo<Value>(
    () => ({
      id,
      order: order[id],
      columns: columns[id],
      setOrder: enhancedSetOrder,
      visibility: visibility[id],
      setColumns: enhancedSetColumns,
      setVisibility: enhancedSetVisibility,
      setDescendantsIds: enhancedSetDescendantsIds,
    }),
    [
      id,
      order,
      columns,
      visibility,
      enhancedSetOrder,
      enhancedSetColumns,
      enhancedSetVisibility,
      enhancedSetDescendantsIds,
    ]
  );

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};
