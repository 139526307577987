import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  TextField,
  toast,
} from "@adaptive/design-system";
import { useTwoFactorAuth } from "@shared/store/ui";
import { useUserAction } from "@shared/store/user";
import { userSelector } from "@store/user/slice";

import type { User } from "./types";

const CODE_LENGTH = 6;
const RESEND_TIMEOUT = 60;
export const TwoFactorAuthDialog = () => {
  const user = useSelector(userSelector) as unknown as User;
  const [verificationAvailable, setVerificationAvailable] = useState(false);
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const { sendSms2FA, verifySms2FA } = useUserAction();
  const { visible, setVisible, callback } = useTwoFactorAuth();
  const verifySms = useCallback(async () => {
    try {
      await verifySms2FA({
        code: code,
        verification_sid: verificationId,
      });
      toast.success("Phone number verified");
      if (callback) callback();
    } catch (error: unknown) {
      const { message } = error as Error;
      if (message) {
        setErrorMessage(message);
        toast.error(message);
      }
    }
  }, [callback, code, verificationId, verifySms2FA]);

  const onSendCode = useCallback(async () => {
    try {
      const sms2FA = await sendSms2FA();
      setVerificationId(sms2FA.data.sid);
      setVerificationAvailable(true);
      setResendTimer(RESEND_TIMEOUT);
    } catch (e) {
      setVisible(false);
      toast.error(
        "Two factor authentication failed. Please check your phone number"
      );
    }
  }, [setVisible, sendSms2FA]);

  useEffect(() => {
    const timeoutId =
      resendTimer > 0
        ? setTimeout(() => setResendTimer(resendTimer - 1), 1000)
        : undefined;

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [resendTimer]);

  useEffect(() => {
    if (visible) {
      onSendCode();
    }
  }, [onSendCode, visible]);

  useEffect(() => {
    if (code.length === CODE_LENGTH && verificationId) {
      setCode("");
      verifySms();
    } else {
      setErrorMessage("");
    }
  }, [code, verificationId, verifySms]);

  return verificationAvailable ? (
    <Dialog
      show={visible}
      variant="dialog"
      onClose={() => setVisible(false)}
      size="sm"
    >
      <DialogHeader>SMS verification required</DialogHeader>
      <DialogContent>
        <Flex direction="column" gap="xl">
          <Text size="md" weight="semi-bold">
            We sent a 6 digit verification code to {user.phone_number}
          </Text>
          <TextField
            errorMessage={errorMessage}
            maxLength={6}
            value={code}
            onChange={setCode}
          />
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block variant="ghost" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button block onClick={onSendCode} disabled={resendTimer > 0}>
          {resendTimer > 0
            ? `Resend in ${resendTimer} seconds`
            : "Resend verification code"}
        </Button>
      </DialogFooter>
    </Dialog>
  ) : null;
};
