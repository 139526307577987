import React, { memo, useEffect, useMemo, useRef } from "react";
import {
  ComboBox,
  DateField,
  Flex,
  Icon,
  TagGroup,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  formatDate,
  isEqual,
  omit,
  parseDate,
  pick,
  suffixify,
} from "@adaptive/design-system/utils";

import { useTableFilterOptions } from "../../hooks/useTableFilterOptions";

import { pickOptionValues } from "./formatters";
import { useFilters } from "./table-filter-hooks";
import type {
  TableFilterControlsProps,
  TableFilterOnChangeHandler,
  TableFilterOnRemoveHandler,
  ValuesFilters,
} from "./types";

const DATE_KEYS = ["date_after", "date_before"];

export const TableFilterControls = memo(
  ({
    size = "md",
    filters,
    children,
    dateProps = {},
    extraData = [],
    fixedTags = [],
    withFilterTags = true,
    withDateFilter = false,
    includeFilters = ["vendors", "customers", "accounts", "costCodes"],
    onFiltersChange,
    extraDataLoading = false,
    filterProps = {},
    renderAfter,
    renderBefore,
  }: TableFilterControlsProps) => {
    const {
      filters: currentFilters,
      addFilter,
      removeFilter,
    } = useFilters(filters);

    const options = useTableFilterOptions({
      extraData,
      includeFilters,
      extraDataLoading,
    });

    /**
     * We need it to guarantee that we only dispatch change event
     * if user interact with it and not on first render
     */
    const hasInteractedRef = useRef(false);
    const previousFiltersRef = useRef<ValuesFilters | undefined>();

    useEffect(() => {
      if (
        hasInteractedRef.current &&
        !isEqual(previousFiltersRef.current, currentFilters)
      ) {
        onFiltersChange(currentFilters);
        previousFiltersRef.current = currentFilters;
      }
    }, [currentFilters, onFiltersChange]);

    const data = useMemo(
      () =>
        Object.values(pickOptionValues(currentFilters)).map(
          (item) =>
            options.data.find((option) => option.value === item.value) ?? item
        ),
      [currentFilters, options.data]
    );

    const tags = useMemo(
      () => [
        ...fixedTags.map((tag) => ({
          ...tag,
          value: tag.label,
          disabled: true,
        })),
        ...data,
      ],
      [data, fixedTags]
    );

    const hasTags = withFilterTags && tags.length > 0;

    const date = useMemo(() => {
      if (!currentFilters.date_after || !currentFilters.date_before) {
        return { from: undefined, to: undefined };
      }

      return {
        to: parseDate(currentFilters.date_before, "yyyy-MM-dd") as Date,
        from: parseDate(currentFilters.date_after, "yyyy-MM-dd") as Date,
      };
    }, [currentFilters.date_after, currentFilters.date_before]);

    const onChange = useEvent<TableFilterOnChangeHandler>((_, option) => {
      hasInteractedRef.current = true;
      addFilter(option);
    });

    const onRemove = useEvent<TableFilterOnRemoveHandler>((option) => {
      hasInteractedRef.current = true;
      removeFilter(option);
    });

    const onDateChange = useEvent((value) => {
      hasInteractedRef.current = true;

      const filters = {
        date_after: formatDate(value.from, "yyyy-MM-dd"),
        date_before: formatDate(value.to, "yyyy-MM-dd"),
      };

      const currentDateFilters = {
        date_after: "",
        date_before: "",
        ...pick(currentFilters, DATE_KEYS),
      };

      const hasChanged = !isEqual(currentDateFilters, filters);

      if (!hasChanged) return;

      addFilter([
        { key: "date_after", value: filters.date_after },
        { key: "date_before", value: filters.date_before },
      ]);
    });

    return (
      <Flex align="left" direction="column" grow gap="md">
        <Flex
          align="center"
          justify="space-between"
          direction="row"
          gap={size === "md" ? "xl" : "md"}
          height="fit-content"
        >
          {renderBefore?.()}

          <Flex
            width="full"
            grow={filterProps?.grow}
            maxWidth={filterProps?.maxWidth ?? "270px"}
            minWidth={filterProps?.minWidth ?? "120px"}
          >
            <ComboBox
              size={size}
              placeholder="Filter"
              value=""
              data={options.data}
              loading={options.status === "loading"}
              onChange={onChange}
              suffix={false}
              messageVariant="hidden"
              addonAfter={
                <Icon
                  size={size}
                  name="search"
                  aria-labelledby={suffixify("search-&-filter", "label")}
                />
              }
              data-testid="filter"
              {...omit(filterProps, ["maxWidth", "grow"])}
            />
          </Flex>

          {withDateFilter && (
            <Flex
              width="full"
              grow={dateProps.grow}
              maxWidth={dateProps.maxWidth ?? "275px"}
            >
              <DateField
                size={size}
                mode="range"
                value={date}
                onChange={onDateChange}
                placeholder="Filter by date"
                messageVariant="hidden"
                {...omit(dateProps, ["maxWidth", "grow"])}
              />
            </Flex>
          )}

          {children && (
            <Flex
              align="center"
              justify="space-between"
              height="full"
              grow
              gap={size === "md" ? "xl" : "md"}
              shrink={false}
            >
              {children}
            </Flex>
          )}

          {renderAfter?.()}
        </Flex>
        {hasTags && (
          <TagGroup
            size={size}
            data={tags}
            render={(item) => `${item.groupLabel}: ${item.label}`}
            onRemove={onRemove}
          />
        )}
      </Flex>
    );
  }
);

TableFilterControls.displayName = "TableFilterControls";
