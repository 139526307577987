import type { BudgetLineItems } from "@api/jobs";

export const createIdListMap = (
  lineItems: BudgetLineItems[],
  path: string | undefined
) => {
  const obj: Record<string, string> = { path: "" };
  lineItems.forEach((elem: BudgetLineItems, i: number) => {
    if (elem.jobCostMethod) {
      const id = elem.id;
      obj[id] = path ? `${path}.${i}` : `${i}`;
    }
  });
  return obj;
};
