import React, { type ReactNode } from "react";
import cn from "clsx";

import styles from "./main.module.css";

type Props = { className?: string; children?: ReactNode };

export const MainSubtitle = ({ className, ...props }: Props) => (
  <span className={cn(styles["main-subtitle"], className)} {...props} />
);
