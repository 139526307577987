import {
  transformInvoiceGetLinesToInvoicePayload,
  transformInvoiceGetMarkupsToInvoicePayload,
} from "@src/jobs/invoice/utils";
import { api } from "@store/api-simplified";
import { api as fetchApi, handleRequest, handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";
import type { AxiosError } from "axios";

import {
  billableExpensesRequestSchema,
  initiateInvoiceDownloadSchema,
  invoiceEvaluatePayloadSchema,
  invoiceStorePayloadSchema,
  invoiceUpdateInfoPayloadSchema,
  invoiceUpdateLinesInternalPayloadSchema,
  invoiceUpdateMarkupsInternalPayloadSchema,
  invoiceUpdatePartialPayloadSchema,
} from "./request";
import {
  billableExpensesResponseSchema,
  invoiceBillableExpensesResponseSchema,
  invoiceGetResponseSchema,
  invoiceSettingsResponseSchema,
  invoicesResponseSchema,
  invoiceSyncTransactionsResponseSchema,
  invoiceUpdateLinesResponseSchema,
} from "./response";
import type {
  BillableExpensesPayload,
  BillableExpensesResponse,
  DownloadInvoiceFilesPayload,
  InitiateDownloadInvoiceFilesPayload,
  InvoiceBillableExpensesPayload,
  InvoiceBillableExpensesResponse,
  InvoiceDeletePayload,
  InvoiceEvaluatePayload,
  InvoiceEvaluateResponse,
  InvoiceGetLinesPayload,
  InvoiceGetLinesResponse,
  InvoiceGetMarkupsPayload,
  InvoiceGetMarkupsResponse,
  InvoiceGetPayload,
  InvoiceGetResponse,
  InvoiceSettingsGetPayload,
  InvoiceSettingsGetResponse,
  InvoicesListPayload,
  InvoicesResponse,
  InvoicesSyncTransactionsResponse,
  InvoiceStorePayload,
  InvoiceStoreResponse,
  InvoiceSyncTransactionsPayload,
  InvoiceUpdateInfoPayload,
  InvoiceUpdateLinesPayload,
  InvoiceUpdateLinesResponse,
  InvoiceUpdateMarkupsPayload,
  InvoiceUpdateMarkupsResponse,
  InvoiceUpdatePartialPayload,
  InvoiceUpdateResponse,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: [
    "Invoices",
    "InvoiceLines",
    "InvoiceMarkups",
    "InvoiceSettings",
  ],
});

class AbortError extends Error {
  constructor(message = "") {
    super(message);
    this.name = "AbortError";
  }
}

export const isAbortError = (e: unknown) => {
  let error = undefined;

  if (e && typeof e === "object") {
    if ("data" in e) {
      error = e.data;
    } else if (
      "error" in e &&
      e.error &&
      typeof e.error === "object" &&
      "data" in e.error
    ) {
      error = e.error.data;
    }
  }

  if (!error) return false;

  return (
    error instanceof AbortError ||
    (typeof error === "object" &&
      "name" in error &&
      error.name === "AbortError")
  );
};

/*
These endpoints correspond to the invoices for jobs details page.
*/

const isNormalLine = (
  line: Record<string, unknown>
): line is InvoiceGetResponse["lines"][number] => Object.keys(line).length >= 1;

const invoicesApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    storeInvoice: builder.mutation<InvoiceStoreResponse, InvoiceStorePayload>({
      query: (body) => ({
        url: "invoices/",
        method: "POST",
        body: transformKeysToSnakeCase(
          handleRequest(body, invoiceStorePayloadSchema)
        ),
      }),
      invalidatesTags: [{ type: "Invoices", id: "LIST" }],
    }),
    evaluateInvoice: builder.mutation<
      InvoiceEvaluateResponse,
      InvoiceEvaluatePayload
    >({
      query: (body) => ({
        url: `invoices/${body.invoiceId}/approve/`,
        body: transformKeysToSnakeCase(
          handleRequest(body, invoiceEvaluatePayloadSchema)
        ),
        method: "PUT",
      }),
      invalidatesTags: (_, error, { invoiceId }) =>
        !error ? [{ type: "Invoices", invoiceId }] : [],
    }),
    updateInvoice: builder.mutation<
      InvoiceUpdateResponse,
      InvoiceUpdatePartialPayload
    >({
      query: (body) => ({
        url: `invoices/${body.id}/`,
        body: transformKeysToSnakeCase(
          handleRequest(body, invoiceUpdatePartialPayloadSchema)
        ),
        method: "PUT",
      }),
      invalidatesTags: (_, error, { id }) =>
        !error
          ? [
              "BudgetLines",
              "CustomerMarkup",
              { type: "Invoices", id },
              { type: "InvoiceLines", id },
              { type: "InvoiceMarkups", id },
            ]
          : [],
    }),
    updateInvoiceInfo: builder.mutation<
      InvoiceUpdateResponse,
      InvoiceUpdateInfoPayload
    >({
      query: (body) => ({
        url: `invoices/${body.id}/`,
        body: transformKeysToSnakeCase(
          handleRequest(body, invoiceUpdateInfoPayloadSchema)
        ),
        method: "PUT",
      }),
      async onQueryStarted(
        {
          id,
          date,
          title,
          toName,
          dueDate,
          docNumber,
          description,
          reviewStatus,
        },
        { dispatch, queryFulfilled }
      ) {
        const { undo } = dispatch(
          invoicesApi.util.updateQueryData(
            "getInvoice",
            { invoiceId: id },
            (draft) => {
              draft.date = date;
              draft.title = title;
              draft.toName = toName;
              draft.dueDate = dueDate;
              draft.docNumber = docNumber;
              draft.description = description;
              draft.reviewStatus = reviewStatus;
            }
          )
        );
        queryFulfilled.catch(undo);
      },
      invalidatesTags: [{ type: "Invoices", id: "LIST" }],
    }),
    deleteInvoice: builder.mutation<void, InvoiceDeletePayload>({
      query: (body) => ({
        url: `invoices/${body.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, { id }) =>
        !error
          ? [
              "BudgetLines",
              { type: "Invoices", id },
              { type: "InvoiceLines", id },
              { type: "InvoiceMarkups", id },
            ]
          : [],
    }),
    getBillableExpenses: builder.query<
      BillableExpensesResponse,
      BillableExpensesPayload
    >({
      query: (args) => {
        const { customerId, params } = handleRequest(
          args,
          billableExpensesRequestSchema
        );
        return {
          url: `customers/${customerId}/billableexpenses/?${params}`,
          responseHandler: async (response) => {
            const data = await response.json();
            return handleResponse(
              data.map(transformKeysToCamelCase),
              billableExpensesResponseSchema
            );
          },
        };
      },
    }),
    getInvoiceBillableExpenses: builder.query<
      InvoiceBillableExpensesResponse,
      InvoiceBillableExpensesPayload
    >({
      query: ({ customerId, invoiceId }) => ({
        url: `customers/${customerId}/invoices/${invoiceId}/billableexpenses/`,
        responseHandler: async (response) => {
          const data = await response.json();

          return handleResponse(
            data.map(transformKeysToCamelCase),
            invoiceBillableExpensesResponseSchema
          );
        },
      }),
    }),
    getInvoice: builder.query<InvoiceGetResponse, InvoiceGetPayload>({
      query: (payload) => ({
        url: `invoices/${payload.invoiceId}`,
        responseHandler: async (response) => {
          const data = await response.json();

          if (response.status >= 400) return data;

          return handleResponse(
            transformKeysToCamelCase(data),
            invoiceGetResponseSchema
          );
        },
      }),
      providesTags: (_, __, { invoiceId }) => [
        { type: "Invoices", id: invoiceId },
      ],
    }),
    getInvoices: builder.query<InvoicesResponse, InvoicesListPayload>({
      query: ({ customerId, ...params }) => ({
        url: `customers/${customerId}/invoices/`,
        params,
        responseHandler: async (response) => {
          const data = await response.json();

          return handleResponse(
            transformKeysToCamelCase(data),
            invoicesResponseSchema
          );
        },
      }),
      providesTags: (result) => [
        ...(result?.results || []).map(
          ({ id }) => ({ type: "Invoices", id }) as const
        ),
        { type: "Invoices", id: "LIST" },
      ],
    }),
    downloadInvoiceFiles: builder.query<string, DownloadInvoiceFilesPayload>({
      query: ({ id, params }) => ({
        url: `invoices/${id}/lines/export/?${params}`,
        responseHandler: async (response) => {
          const data = await response.json();
          return data?.id ? (data.id as string) : undefined;
        },
      }),
    }),
    initiateDownloadInvoiceFiles: builder.mutation<
      string,
      InitiateDownloadInvoiceFilesPayload
    >({
      query: ({ id, body }) => ({
        url: `invoices/${id}/lines/export/`,
        method: "POST",
        body: handleRequest(body, initiateInvoiceDownloadSchema),
        responseHandler: async (response) => {
          const data = await response.json();
          return data?.id ? (data.id as string) : data;
        },
      }),
    }),
    updateInvoiceLines: builder.mutation<
      InvoiceUpdateLinesResponse,
      InvoiceUpdateLinesPayload
    >({
      queryFn: ({ id, lines, queue, signal }) => {
        const emptyLines = lines.filter((line) => !isNormalLine(line));

        const normalLines = lines.filter(isNormalLine);

        const enhancedLines =
          transformInvoiceGetLinesToInvoicePayload(normalLines);

        const payload = [...enhancedLines, ...emptyLines];

        const timer =
          payload.length <= 1
            ? 0
            : (window.FRONTEND_INVOICE_LINES_UPDATE_DEBOUNCE_TIMEOUT ?? 2000);

        let aborted = false;
        let executing = false;

        return new Promise((resolve) => {
          const timeout = setTimeout(async () => {
            executing = true;

            try {
              if (queue) await Promise.allSettled(queue);

              if (aborted) {
                return resolve({
                  error: { status: 400, data: new AbortError() },
                });
              }

              const { data } = await fetchApi.put(
                `/api/invoices/${id}/`,
                transformKeysToSnakeCase(
                  handleRequest(
                    { lines: payload },
                    invoiceUpdateLinesInternalPayloadSchema
                  )
                )
              );

              resolve({ data });
            } catch (e) {
              const err = e as AxiosError;
              const data = err.response?.data || err.message;
              const status = err.response?.status || 400;

              resolve({ error: { status, data } });
            }
          }, timer);

          signal?.addEventListener("abort", () => {
            aborted = true;

            if (!executing) {
              clearTimeout(timeout);
              resolve({ error: { status: 400, data: new AbortError() } });
            }
          });
        });
      },
      async onQueryStarted({ id, lines }, { dispatch, queryFulfilled }) {
        const emptyLines = lines
          .filter((line) => !isNormalLine(line))
          .map((line) => ({
            ...line,
            id: "",
            url: `${window.location.origin}/api/invoicelines/unknown`,
            amount: 0,
            totalAmount: 0,
            description: "",
          }));

        const normalLines = lines.filter(isNormalLine);

        dispatch(
          enhancedInvoicesApi.util.updateQueryData(
            "getInvoiceLines",
            { invoiceId: id },
            () => [...normalLines, ...emptyLines]
          )
        );

        dispatch(
          enhancedInvoicesApi.util.updateQueryData(
            "getInvoice",
            { invoiceId: id },
            (draft) => {
              normalLines.forEach((line) => {
                const draftLineIndex = draft.summary?.lines.findIndex(
                  (draftLine) =>
                    draftLine.jobCostMethod?.id === line.jobCostMethod?.id
                );

                if (
                  draftLineIndex !== undefined &&
                  draftLineIndex !== -1 &&
                  draft.summary
                ) {
                  draft.summary.lines[draftLineIndex].thisDraw = line.amount;

                  if (line.invoicedAmount) {
                    draft.summary.lines[draftLineIndex].invoicedPercent =
                      line.invoicedAmount;
                  }
                }
              });

              return draft;
            }
          )
        );

        try {
          const { lines } = handleResponse(
            transformKeysToCamelCase((await queryFulfilled).data),
            invoiceUpdateLinesResponseSchema
          ) as InvoiceUpdateLinesResponse;

          const hasMultipleRequests =
            (await Promise.all(dispatch(api.util.getRunningMutationsThunk())))
              .length > 1;

          if (hasMultipleRequests) return;

          const newLines = lines.filter(
            (line) =>
              !normalLines.some((normalLine) => normalLine.id === line.id)
          );

          if (!newLines.length) return;

          dispatch(
            enhancedInvoicesApi.util.updateQueryData(
              "getInvoiceLines",
              { invoiceId: id },
              (previousLines) => [
                ...previousLines.filter((line) => !!line.id),
                ...newLines,
              ]
            )
          );
        } catch (e) {
          if (isAbortError(e)) return;

          dispatch(
            invoicesApi.util.invalidateTags([
              "Invoices",
              "BudgetLines",
              { type: "InvoiceMarkups", id },
            ])
          );
        }
      },
      invalidatesTags: (_, error, { id }) =>
        !error
          ? ["Invoices", "BudgetLines", { type: "InvoiceMarkups", id }]
          : [],
    }),
    updateInvoiceMarkups: builder.mutation<
      InvoiceUpdateMarkupsResponse,
      InvoiceUpdateMarkupsPayload
    >({
      query: ({ id, markups }) => {
        const enhancedMarkups =
          transformInvoiceGetMarkupsToInvoicePayload(markups);

        return {
          url: `invoices/${id}/`,
          body: {
            ...transformKeysToSnakeCase(
              handleRequest(
                { markups: enhancedMarkups },
                invoiceUpdateMarkupsInternalPayloadSchema
              )
            ),
          },
          method: "PUT",
        };
      },
      async onQueryStarted({ id, markups }, { dispatch, queryFulfilled }) {
        dispatch(
          enhancedInvoicesApi.util.updateQueryData(
            "getInvoiceMarkups",
            { invoiceId: id },
            () => markups
          )
        );

        try {
          await queryFulfilled;
        } catch (e) {
          invoicesApi.util.invalidateTags([
            { type: "Invoices", id },
            { type: "InvoiceMarkups", id },
          ]);
        }
      },
      invalidatesTags: (_, error, { id }) =>
        !error
          ? ["CustomerMarkup", { type: "Invoices", id }, "BudgetLines"]
          : [],
    }),
    createInvoiceLinesFromTransactions: builder.mutation({
      query: ({ invoiceId, invoiceLines }) => {
        return {
          url: `invoices/${invoiceId}/lines/`,
          method: "POST",
          body: invoiceLines.map(transformKeysToSnakeCase),
        };
      },
      invalidatesTags: (_, error, { invoiceId }) => {
        return !error
          ? [
              { type: "InvoiceLines", id: invoiceId },
              { type: "InvoiceMarkups", id: invoiceId },
              { type: "Invoices", id: invoiceId },
              "BudgetLines",
            ]
          : [];
      },
    }),
    getInvoiceSettings: builder.query<
      InvoiceSettingsGetResponse,
      InvoiceSettingsGetPayload
    >({
      query: ({ customerId }) => ({
        url: `customers/${customerId}/invoice-settings/`,
        responseHandler: async (response) => {
          const data = await response.json();
          const res = handleResponse(
            transformKeysToCamelCase(data),
            invoiceSettingsResponseSchema
          );
          return res;
        },
      }),
      providesTags: (_, __, { customerId }) => [
        { type: "InvoiceSettings", customerId },
      ],
    }),
    updateInvoiceSettings: builder.mutation({
      query: ({ customerId, invoiceSettingId, body }) => ({
        url: `customers/${customerId}/invoice-settings/${invoiceSettingId}/`,
        method: "PATCH",
        body: transformKeysToSnakeCase(body),
      }),
      invalidatesTags: (_, error, { customerId }) =>
        !error ? [{ type: "InvoiceSettings", customerId }] : [],
    }),
    syncTransactions: builder.mutation<
      InvoicesSyncTransactionsResponse,
      InvoiceSyncTransactionsPayload
    >({
      query: (body) => {
        return {
          url: `invoices/${body.id}/sync_transactions/`,
          method: "PUT",
          body: { sync: body.sync },
          responseHandler: async (response) => {
            const data = await response.json();
            if (response.status >= 400) return data;
            return handleResponse(
              data.map(transformKeysToCamelCase),
              invoiceSyncTransactionsResponseSchema
            );
          },
        };
      },
      invalidatesTags: (_, error, { id }) =>
        !error ? [{ type: "Invoices", id }] : [],
    }),
  }),
});

export const enhancedInvoicesApi = invoicesApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceMarkups: builder.query<
      InvoiceGetMarkupsResponse,
      InvoiceGetMarkupsPayload
    >({
      query: (payload) => ({
        url: `invoices/${payload.invoiceId}`,
        responseHandler: async (response) => {
          const data = await response.json();

          if (response.status >= 400) return data;

          const { markups } = handleResponse(
            transformKeysToCamelCase(data),
            invoiceGetResponseSchema
          ) as InvoiceGetResponse;

          return markups;
        },
      }),
      providesTags: (_, __, { invoiceId }) => [
        { type: "InvoiceMarkups", id: invoiceId },
      ],
    }),
    getInvoiceLines: builder.query<
      InvoiceGetLinesResponse,
      InvoiceGetLinesPayload
    >({
      query: (payload) => ({
        url: `invoices/${payload.invoiceId}`,
        responseHandler: async (response) => {
          const data = await response.json();

          if (response.status >= 400) return data;

          const { lines } = handleResponse(
            transformKeysToCamelCase(data),
            invoiceGetResponseSchema
          ) as InvoiceGetResponse;

          return lines;
        },
      }),
      providesTags: (_, __, { invoiceId }) => [
        { type: "InvoiceLines", id: invoiceId },
      ],
    }),
  }),
});
