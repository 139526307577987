import React, { memo, useMemo, useState } from "react";
import {
  ComboBox,
  type ComboBoxActionAddon,
  type ComboBoxProps,
} from "@adaptive/design-system";
import { handleErrors } from "@api/handle-errors";
import { type StoreCustomerCategoryResponse } from "@api/jobs";
import { useStoreCustomerCategoryMutation } from "@api/jobs/jobs";
import type { Option } from "@shared/types";
import { useJobCategories, useJobCategoriesIsLoading } from "@src/jobs";
import { useJobInfo } from "@store/jobs";

type BudgetsCategoriesComboboxProps = Omit<
  ComboBoxProps<false>,
  "data" | "action" | "disabled" | "loading"
> & {
  extra?: Option[];
  onAddCategory?: (category: StoreCustomerCategoryResponse) => void;
};

export const BudgetsCategoriesCombobox = memo(
  ({ onAddCategory, extra = [], ...props }: BudgetsCategoriesComboboxProps) => {
    const data = useJobCategories();

    const { job } = useJobInfo();

    const isLoading = useJobCategoriesIsLoading();

    const enhancedData = useMemo(() => [...data, ...extra], [data, extra]);

    const [storeCategory] = useStoreCustomerCategoryMutation();

    const [isUpdating, setIsUpdating] = useState(false);

    const action = useMemo<ComboBoxActionAddon>(
      () => ({
        icon: "plus",
        mode: "no-match",
        children: "Add a category",
        onClick: async (query) => {
          try {
            setIsUpdating(true);

            const category = await storeCategory({
              customerId: job.id,
              displayName: query,
            }).unwrap();

            onAddCategory?.(category);
          } catch (e) {
            handleErrors(e);
          } finally {
            setIsUpdating(false);
          }
        },
      }),
      [job.id, onAddCategory, storeCategory]
    );

    return (
      <ComboBox
        size="sm"
        data={enhancedData}
        action={action}
        loading={isUpdating || isLoading}
        disabled={isUpdating}
        listSize={5}
        placeholder="Select a category"
        emptyMessage="No options available. Enter text to create a new category"
        messageVariant="hidden"
        {...props}
      />
    );
  }
);

BudgetsCategoriesCombobox.displayName = "BudgetsCategoriesCombobox";
