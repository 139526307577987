import React, {
  type ComponentPropsWithoutRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  dialog,
  Flex,
  Icon,
  Table,
  type TableColumn,
  Tag,
  Text,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import { ApproversCombobox } from "@components/approvers-combobox";
import { WorkflowApprovers } from "@components/workflow-approvers";
import type { Workflow } from "@shared/api/workflows";
import { groupApprovers } from "@src/settings/components/approval-workflows/utils";
import { noop } from "@src/shared/utils/noop";
import { useDrawerVisibility } from "@store/ui";
import { BasePermissions, useUserInfo } from "@store/user";
import { useWorkflowAction } from "@store/workflow";

type Status = "APPROVED" | "PENDING" | "REJECTED";

type Props = {
  objectId: number;
  editable?: boolean;
  objectType: "Bill" | "Expense" | "Invoice";
  workflows: Workflow[];
  helperMessage?: (
    mode: "EMPTY" | "ONE_OF" | "ALL_OF" | "LIST" | "NO_STEPS"
  ) => string | false;
};

const TAG_STATUS: Record<
  Status,
  ComponentPropsWithoutRef<typeof Tag>["color"]
> = {
  PENDING: "info",
  APPROVED: "success",
  REJECTED: "error",
};

const getSingleWorkflowRequirementType = (workflow: Workflow) =>
  workflow.steps.length === 1 ? workflow.steps[0].requirementType : undefined;

const getApproverStatus = (approverUrl: string, workflow: Workflow): Status => {
  const approver = workflow.steps[0]?.approvers.find(
    (item) => item.url === approverUrl
  );

  if (!approver) return "PENDING";

  return approver.status || "PENDING";
};

const ApprovalWorkflowsSection = ({
  editable = true,
  objectId,
  workflows = [],
  objectType,
  helperMessage,
}: Props) => {
  const { hasPermission } = useUserInfo();

  const { setVisible } = useDrawerVisibility("workflowApprovals");

  const [isLoading, setIsLoading] = useState(false);

  const [approvers, setApprovers] = useState<string[]>([]);

  const {
    deleteApprovalsWorkflow,
    persistWorkflowApprovals,
    setCurrentWorkflowApprovals,
    setPartialCurrentWorkflowApprovals,
  } = useWorkflowAction();

  const canManageApprovalWorkflows = useMemo(
    () => hasPermission(BasePermissions.MANAGE_APPROVAL_WORKFLOWS),
    [hasPermission]
  );
  const workflowsLength = workflows?.length ?? 0;

  const workflowViewMode = useMemo(() => {
    if (workflowsLength === 0) return "EMPTY";

    if (!canManageApprovalWorkflows) {
      return workflows[0].steps.length ? "LIST" : "NO_STEPS";
    }

    if (workflowsLength === 1 && workflows[0].steps.length <= 1 && editable) {
      const requirementType = getSingleWorkflowRequirementType(workflows[0]);

      return requirementType ?? "ALL_OF";
    }

    return workflows.some(
      (workflow) =>
        workflow.steps.length > 0 && workflow.steps[0].approvers.length > 0
    )
      ? "LIST"
      : "EMPTY";
  }, [canManageApprovalWorkflows, editable, workflows, workflowsLength]);

  /**
   * @todo remove it as soon as we confirm if we really need it or not
   */
  const isAddWorkflowEnabled = false;

  const canAddWorkflow = useMemo(() => {
    if (!isAddWorkflowEnabled) return false;

    if (!editable || workflowViewMode === "EMPTY") return false;

    return (
      workflowViewMode === "LIST" || workflows[0].steps[0]?.approvers.length > 0
    );
  }, [editable, isAddWorkflowEnabled, workflowViewMode, workflows]);

  const curriedOnDeleteWorkflow = useCallback(
    (workflow: Workflow) => () => {
      const handler = async () => {
        await deleteApprovalsWorkflow({
          id: workflow.id,
          objectId,
          objectType,
        });

        if (workflowsLength === 1) {
          await persistWorkflowApprovals({
            payload: {
              steps: [
                { requirementType: "ALL_OF", order: 1, roles: [], users: [] },
              ],
            },
            objectId,
            objectType,
          });
        }
      };

      dialog.confirmation({
        title: "Delete workflow?",
        action: {
          primary: {
            color: "error",
            onClick: handler,
            children: "Delete workflow",
          },
        },
      });
    },
    [
      deleteApprovalsWorkflow,
      objectId,
      objectType,
      workflowsLength,
      persistWorkflowApprovals,
    ]
  );

  const curriedOnEditWorkflow = useCallback(
    (workflow: Workflow) => () => {
      setCurrentWorkflowApprovals({ ...workflow, objectId, objectType });
      setVisible(true);
    },
    [objectId, objectType, setCurrentWorkflowApprovals, setVisible]
  );

  const onAdd = useEvent(async () => {
    setPartialCurrentWorkflowApprovals({ objectId, objectType });
    setVisible(true);
  });

  const updateApprovers = useEvent(async () => {
    setIsLoading(true);

    const previousApprovers = groupApprovers(
      (workflows[0].steps[0]?.approvers ?? []).map((approver) => approver.url)
    );

    const nextApprovers = groupApprovers(approvers);

    if (isEqual(nextApprovers, previousApprovers)) return setIsLoading(false);

    await persistWorkflowApprovals({
      payload: {
        id: workflows[0].id,
        steps: [
          {
            order: 1,
            requirementType:
              getSingleWorkflowRequirementType(workflows[0]) || "ALL_OF",
            ...nextApprovers,
          },
        ],
      },
      objectId,
      objectType,
    });

    setIsLoading(false);
  });

  /**
   * @todo implement workflow reset
   */
  const isResetEnabled = editable && false;

  const onReset = useEvent(noop);

  const columns: TableColumn<Workflow>[] = useMemo(
    () => [
      {
        id: "workflow",
        width: "fill",
        render: (row) => (
          <Flex gap="md" direction="column">
            <WorkflowApprovers steps={row.steps} showStatus />
          </Flex>
        ),
      },
      {
        id: "action",
        render: (row) =>
          canManageApprovalWorkflows && editable ? (
            <Flex gap="md" justify="flex-end">
              <Button
                color="neutral"
                variant="text"
                onClick={curriedOnEditWorkflow(row)}
                aria-label="Edit workflow"
              >
                <Icon name="pen" />
              </Button>
              <Button
                color="neutral"
                variant="text"
                onClick={curriedOnDeleteWorkflow(row)}
                aria-label="Delete workflow"
                data-testid={`delete-workflow-${row.id}`}
              >
                <Icon name="trash" />
              </Button>
            </Flex>
          ) : null,
      },
    ],
    [
      canManageApprovalWorkflows,
      editable,
      curriedOnEditWorkflow,
      curriedOnDeleteWorkflow,
    ]
  );

  const helperMessageContent = useMemo(() => {
    let message = helperMessage?.(workflowViewMode);

    if (!message && message !== false && workflowViewMode === "EMPTY") {
      message =
        "Click save to see which approvers will be notified or to add a custom approval workflow";
    }

    return message;
  }, [helperMessage, workflowViewMode]);

  useEffect(() => {
    if (["ALL_OF", "ONE_OF"].includes(workflowViewMode)) {
      const approvers = (workflows[0].steps[0]?.approvers ?? []).map(
        (approver) => approver.url
      );

      setApprovers(approvers);
    }
  }, [workflows, workflowViewMode]);

  if (
    workflowViewMode === "NO_STEPS" ||
    (workflowViewMode === "EMPTY" && !helperMessageContent)
  ) {
    return null;
  }

  return (
    <Flex gap="md" direction="column">
      <Flex gap="xl" direction="column">
        <Flex gap="xl" direction="row" justify="space-between" align="center">
          <Text id="to-be-approved-title" size="xl" weight="bold">
            {editable ? "To be approved by" : "Approved by"}
          </Text>
          {workflowViewMode !== "EMPTY" && (
            <Flex gap="md">
              {canAddWorkflow && (
                <Button size="sm" variant="ghost" onClick={onAdd}>
                  Add workflow
                </Button>
              )}
              {isResetEnabled && (
                <Button size="sm" variant="ghost" onClick={onReset}>
                  Reset default
                </Button>
              )}
            </Flex>
          )}
        </Flex>

        {["ALL_OF", "ONE_OF"].includes(workflowViewMode) && (
          <Flex gap="xl">
            <ApproversCombobox
              value={approvers}
              onBlur={updateApprovers}
              loading={isLoading ? "Updating..." : false}
              onChange={setApprovers}
              helperMessage={
                workflowViewMode === "ALL_OF"
                  ? "Require all - to change, press edit"
                  : "Require either - to change, press edit"
              }
              data-testid="approvers"
              aria-labelledby="to-be-approved-title"
              renderMultipleValue={(option) => ({
                children: option.label,
                color:
                  TAG_STATUS[getApproverStatus(option.value, workflows[0])],
              })}
            />

            <Button
              color="neutral"
              onClick={curriedOnEditWorkflow(workflows[0])}
              variant="ghost"
              disabled={isLoading}
              aria-label="Edit workflow"
            >
              <Icon name="pen" />
            </Button>
          </Flex>
        )}

        {workflowViewMode === "LIST" && (
          <Flex gap="xl" as="ul" direction="column">
            <Table
              id="approvals-section-table"
              data={workflows}
              size="sm"
              header={{ hide: true }}
              columns={columns}
              bordered={false}
            />
          </Flex>
        )}
      </Flex>

      {helperMessageContent && <Text size="md">{helperMessageContent}</Text>}
    </Flex>
  );
};

export const ApprovalsSection = ({
  editable = true,
  objectId,
  workflows = [],
  objectType,
  helperMessage,
}: Props) => {
  return (
    <ApprovalWorkflowsSection
      editable={editable}
      objectId={objectId}
      workflows={workflows}
      objectType={objectType}
      helperMessage={helperMessage}
    />
  );
};
