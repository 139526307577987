import React, { ComponentPropsWithoutRef, ReactNode, useMemo } from "react";
import { Card, Flex, Text } from "@adaptive/design-system";
import cn from "clsx";

import contentStyles from "./section.module.css";

type SectionProps = ComponentPropsWithoutRef<"div"> & {
  margin?: boolean;
  heading?: string | ReactNode;
  hideBorder?: boolean;
  tags?: ReactNode[];
  hidePadding?: boolean;
};

export const Section = ({
  margin = false,
  heading,
  children,
  tags,
  hideBorder = false,
  hidePadding = false,
}: SectionProps) => {
  const sectionHeading = useMemo(() => {
    if (["string", "number"].includes(typeof heading)) {
      return (
        <Text size="xl" weight="bold">
          {heading}
        </Text>
      );
    }

    return heading;
  }, [heading]);

  return (
    <Flex
      as="section"
      gap="xl"
      shrink={false}
      direction="column"
      className={cn(contentStyles["section"], {
        [contentStyles["-margin"]]: margin,
        [contentStyles["-hide-border"]]: hideBorder,
        [contentStyles["-hide-padding"]]: hidePadding,
      })}
    >
      <Flex gap="xl" align="flex-start">
        <Flex
          gap="md"
          padding={["xs", "none", "none"]}
          justify="flex-start"
          align="center"
        >
          {sectionHeading}
        </Flex>
        {tags && (
          <Flex gap="md" wrap grow basis={0} justify="flex-end">
            {tags}
          </Flex>
        )}
      </Flex>
      <Card>{children}</Card>
    </Flex>
  );
};
