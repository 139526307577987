import React, { type ComponentPropsWithoutRef } from "react";
import { TabPanel, useTabContext } from "@ariakit/react";
import forwardRefAs from "forward-ref-as";

const DEFAULT_COMPONENT = "div";

type TabsPanelProps = Omit<
  ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT>,
  "value"
> & { value?: string };

export const TabsPanel = forwardRefAs<typeof DEFAULT_COMPONENT, TabsPanelProps>(
  ({ as: Component = DEFAULT_COMPONENT, value, ...props }, ref) => {
    const tabContext = useTabContext();

    const activeId = tabContext?.useState((state) => state.activeId);

    const tabId = value ?? activeId;

    return (
      <TabPanel
        ref={ref}
        tabId={tabId}
        render={(innerProps) => <Component {...innerProps} />}
        focusable={false}
        {...props}
      />
    );
  }
);
