import { asStageContent, asStageFooter } from "../../shared/sequential/factory";
import {
  StageContentMap,
  StageDatumMap,
  StageFooterMap,
} from "../../shared/sequential/types";

import { Actions as EditActions, Edit } from "./edit";
import { Actions as ReviewActions, Review } from "./review";

export const Stages = [
  "DRAFT",
  "FOR_REVIEW",
  // don't wrap prettier
] as const;

export const TabData: StageDatumMap<typeof Stages> = {
  DRAFT: {
    title: "EDIT",
    description: "Edit the details of the receipt.",
  },
  FOR_REVIEW: {
    title: "REVIEW",
    description: "Review the details of the receipt.",
  },
};

export const StageContent: StageContentMap<typeof Stages> = {
  DRAFT: Edit,
  FOR_REVIEW: Review,
} as const;

export const ExpenseContent = asStageContent(StageContent);

export const Footers: StageFooterMap<typeof Stages> = {
  DRAFT: EditActions,
  FOR_REVIEW: ReviewActions,
} as const;

export const ExpenseFooter = asStageFooter(Footers);
