import { api } from "@store/api-simplified";
import { handleRequest, handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import {
  convertToAdaptiveResponseSchema,
  getPurchaseOrderResponseSchema,
  getPurchaseOrdersPayloadSchema,
  getPurchaseOrdersResponseSchema,
} from "./schemas";
import type {
  BatchConvertToAdaptivePayload,
  BatchConvertToAdaptiveResponse,
  ConvertToAdaptivePayload,
  ConvertToAdaptiveResponse,
  GetPurchaseOrderPayload,
  GetPurchaseOrderResponse,
  GetPurchaseOrdersPayload,
  GetPurchaseOrdersResponse,
} from "./types";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: ["PurchaseOrders"],
});

const purchaseOrdersApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseOrder: builder.query<
      GetPurchaseOrderResponse,
      GetPurchaseOrderPayload
    >({
      query: (args) => ({
        url: `purchaseorders/${args.id}`,
        responseHandler: async (response) =>
          handleResponse(
            transformKeysToCamelCase(await response.json()),
            getPurchaseOrderResponseSchema
          ),
      }),
    }),
    convertToAdaptive: builder.mutation<
      ConvertToAdaptiveResponse,
      ConvertToAdaptivePayload
    >({
      query: (id) => ({
        url: `purchaseorders/${id}/convert/`,
        method: "PUT",
        responseHandler: async (response) =>
          handleResponse(
            transformKeysToCamelCase(await response.json()),
            convertToAdaptiveResponseSchema
          ),
      }),
    }),
    batchConvertToAdaptive: builder.mutation<
      BatchConvertToAdaptiveResponse,
      BatchConvertToAdaptivePayload
    >({
      queryFn: async (ids, _, __, baseQuery) => {
        await Promise.all(
          ids.map((id) =>
            baseQuery({
              url: `purchaseorders/${id}/convert/`,
              method: "PUT",
            })
          )
        );

        return { data: undefined };
      },
    }),
    getPurchaseOrders: builder.query<
      GetPurchaseOrdersResponse,
      GetPurchaseOrdersPayload
    >({
      query: (args) => {
        const params = transformKeysToSnakeCase(
          handleRequest(args, getPurchaseOrdersPayloadSchema)
        );

        const query = new URLSearchParams();

        Object.entries(params).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) => query.append(key, item));
          } else if (value !== undefined) {
            query.append(key, value);
          }
        });

        return {
          url: "purchaseorders/",
          params: query,
          responseHandler: async (response) =>
            handleResponse(
              transformKeysToCamelCase(await response.json()),
              getPurchaseOrdersResponseSchema
            ),
        };
      },
    }),
  }),
});

export const {
  useGetPurchaseOrdersQuery,
  useLazyGetPurchaseOrderQuery,
  useConvertToAdaptiveMutation,
  useBatchConvertToAdaptiveMutation,
} = purchaseOrdersApi;
