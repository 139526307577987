import React, { type FC, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Loader, Text } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import {
  dotObject,
  formatCurrency,
  formatDate,
} from "@adaptive/design-system/utils";
import {
  Comments,
  type CommentsSelector,
  type OnAddThreadCommentHandler,
} from "@components/comments";
import {
  ExternalLayout,
  type ExternalLayoutProps,
} from "@components/external-layout";
import { Logout } from "@components/logout";
import { MainContent } from "@components/main";
import { useCurrentClientFromRealm } from "@hooks/useCurrentClientFromRealm";
import { useUserInfo } from "@store/user";
import type { AnyAction } from "redux";

import {
  ACHRequestForm,
  DocRequestForm,
  POSignatureRequestForm,
  VendorRequest,
} from "../../public/components/vendor-request";
import { VENDOR_REQUEST_TYPES } from "../../public/components/vendor-request/constants";
import { conversationsApi, useGetTransactionQuery } from "../api/api";

import type { Params } from "./types";

const FORM: Record<keyof typeof VENDOR_REQUEST_TYPES, FC<any>> = {
  [VENDOR_REQUEST_TYPES.PO_SIGNATURE]: POSignatureRequestForm,
  [VENDOR_REQUEST_TYPES.ACH]: ACHRequestForm,
  [VENDOR_REQUEST_TYPES.DOC]: DocRequestForm,
  [undefined as any]: null,
};

export const Page = memo(() => {
  const params = useParams<Params>();

  const dispatch = useDispatch();

  const { user } = useUserInfo();

  const type =
    params.type === "purchase-orders" ? "purchaseorders" : params.type!;

  const transaction = useGetTransactionQuery({
    id: params.id!,
    type,
  });

  const vendorRequestParams = transaction.data?.latestRequestParams;

  const onAddThreadComment = useEvent<OnAddThreadCommentHandler>(
    ({ text, parentCommentUrl, files }) => {
      const action = conversationsApi.util.updateQueryData(
        "getTransaction",
        { id: params.id!, type },
        (transaction) => {
          transaction.comments = transaction.comments.map((comment) =>
            comment.url === parentCommentUrl
              ? {
                  ...comment,
                  replies: [
                    ...(comment.replies || []),
                    {
                      id: "",
                      text,
                      files,
                      author: user as any,
                      createdAt: new Date(),
                      attachable: "",
                      timelineEventType: "COMMENT",
                    },
                  ],
                }
              : comment
          );
        }
      );

      dispatch(action as unknown as AnyAction);
    }
  );

  const attachable = useMemo(() => {
    const attachable = transaction.data?.attachables[0];

    if (
      !attachable ||
      (!dotObject.get(attachable, "file") && !dotObject.get(attachable, "pdf"))
    ) {
      return undefined;
    }

    return attachable as ExternalLayoutProps["attachable"];
  }, [transaction.data?.attachables]);

  const selector = useMemo<CommentsSelector>(
    () => ({
      url: transaction.data?.url ?? "",
      comments: transaction.data?.comments ?? [],
    }),
    [transaction.data?.comments, transaction.data?.url]
  );

  useCurrentClientFromRealm(transaction.data?.realm?.url, {
    suppressToast: true,
  });

  if (transaction.isLoading) {
    return <Loader position="fixed" />;
  }

  if (!transaction.data) {
    return <Logout />;
  }

  return (
    <ExternalLayout attachable={attachable}>
      <MainContent as="div">
        <Flex gap="2xl" direction="column" shrink={false}>
          <Flex direction="column">
            <Text as="h1" size="xl" weight="bold">
              {transaction.data.humanReadableType} for{" "}
              {transaction.data.vendor.displayName}
            </Text>
            <Text>
              {formatCurrency(transaction.data.totalAmount, {
                currencySign: true,
                allowNegative: true,
              })}
              {transaction.data.date
                ? ` (${formatDate(transaction.data.date)})`
                : ""}
            </Text>
          </Flex>
          <Flex separator gap="5xl" direction="column">
            {vendorRequestParams?.type && FORM && (
              <VendorRequest
                vendorRequestParams={vendorRequestParams}
                type={vendorRequestParams?.type}
                form={FORM[vendorRequestParams.type]}
              />
            )}
            <Comments
              selector={selector}
              placeholder={`Reply to ${transaction.data.client.name}`}
              showExternalFlag={false}
              onAddThreadComment={onAddThreadComment}
              showExternalWarning={false}
            />
          </Flex>
        </Flex>
      </MainContent>
    </ExternalLayout>
  );
});

Page.displayName = "ConversationPage";
