import React, { type ReactNode, useCallback, useMemo, useState } from "react";
import {
  Button,
  ComboBox,
  dialog,
  Dropdown,
  DropdownItem,
  DropdownList,
  DropdownTrigger,
  Flex,
  Icon,
  Link,
  Loader,
  Text,
  toast,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { suffixify } from "@adaptive/design-system/utils";
import {
  api as expenseApi,
  useLazyGetExpenseLinkedInvoicesQuery,
} from "@api/expenses";
import type { Expense } from "@api/expenses/types";
import {
  handleErrors,
  parseCustomErrors,
  transformErrorToCustomError,
} from "@api/handle-errors";
import { useUpdateQuickBooksErrorsMutation } from "@api/quickbooks";
import {
  BatchApplyObject,
  type BatchApplyObjectOnChangeHandler,
} from "@components/batch-apply-object";
import { ConfirmationDialog } from "@components/confirmation-dialog";
import {
  DownloadButton,
  type OnDownloadHandler,
} from "@components/download-button";
import { useApplyObject } from "@hooks/use-apply-object";
import { useUsersSimplified } from "@hooks/useUsersSimplified";
import type { QueryFilters } from "@store/expenses/types";
import { useClientInfo, useClientSettings } from "@store/user";
import { summarizeResults } from "@utils/all-settled";
import * as analytics from "@utils/analytics";
import { noop } from "@utils/noop";
import {
  confirmArchiveWithLinkedInvoices,
  confirmBackToEdit,
  confirmSyncInvoiceLines,
  InvoiceData,
  UNLINK_INVOICE_LINES_OPTION,
  type UnlinkInvoiceLinesOption,
} from "@utils/transaction-confirm-messages";

import { isError, isPending } from "../table/columns";
import type { StatusMapKey } from "../types";
import {
  REVIEW_STATUS,
  STRINGS,
  UNSYNCED_QUICKBOOKS_STATUS,
} from "../utils/constants";

type Props = {
  selection?: Expense[];
  filters?: Partial<QueryFilters<"ALL" | "DRAFT" | "FOR_REVIEW">>;
  status: StatusMapKey;
  onActionPerformed: (clearSelections?: boolean) => void;
};

type Action = {
  eventName: string;
  label: ReactNode;
  color?: "error" | "neutral";
  testId: string;
  action: (
    id: Expense["id"],
    syncOption?: UnlinkInvoiceLinesOption
  ) => Promise<unknown>;
  tooltip?: string;
  disabled?: boolean;
  successMessageTitle: ReactNode;
  successMessageContent?: (result: {
    succeeded: Expense[];
    failed: Expense[];
  }) => ReactNode;
  confirmationTitle?: ReactNode;
  confirmationMessage?: string;
  confirmationButtonText?: string;
  syncConfirmation?: (
    linkedInvoices: InvoiceData[],
    callback: (syncOption: UnlinkInvoiceLinesOption) => Promise<void>
  ) => void;
};

type PerformActionHandler = (props: {
  action: Action["action"];
  eventName: string;
  syncOption?: UnlinkInvoiceLinesOption;
  successMessageTitle: Action["successMessageTitle"];
  successMessageContent?: Action["successMessageContent"];
}) => Promise<void>;

type PerformActionWithConfirmationHandler = (props: {
  eventName: string;
  action: Action["action"];
  successMessageTitle: Action["successMessageTitle"];
  successMessageContent?: Action["successMessageContent"];
  confirmationTitle: Action["confirmationTitle"];
  confirmationMessage: Action["confirmationMessage"];
  confirmationButtonText: string;
  syncConfirmation?: (
    linkedInvoices: InvoiceData[],
    callback: (syncOption: UnlinkInvoiceLinesOption) => Promise<void>
  ) => void;
}) => void;

type ReAssignButtonProps = { data: Expense[]; onSubmit?: () => void };

const ReAssignButton = ({ data, onSubmit }: ReAssignButtonProps) => {
  const [user, setUser] = useState("");

  const applyObject = useApplyObject({
    items: data,
    resource: "expenses",
  });

  const users = useUsersSimplified({ filters: { is_staff: false } });

  const enhancedOnSubmit = useEvent(async () => {
    try {
      const value = user || null;

      const { success, errorResponses } = await applyObject.execute({
        value,
        method: "apply_object",
        fieldName: "assignee",
      });

      if (errorResponses.length) {
        errorResponses.forEach((error) => handleErrors(error));
      }

      if (success) {
        toast.success(`${success} Receipt${success > 1 ? "s" : ""} updated!`);
      }

      analytics.track("expenseBatchActions", {
        value,
        action: "assignee",
        location: "send_it_back_dialog",
        expenseIds: data.map(({ id }) => id),
      });
    } catch (e) {
      toast.error(`Error updating receipts`);
    }

    onSubmit?.();
  });

  const onClick = useEvent(() => {
    dialog.confirmation({
      title: "Change assignee",
      align: "center",
      variant: "dialog",
      message: (
        <Flex width="full">
          <ComboBox
            data={users.data}
            label="Select assignee"
            loading={users.isLoading}
            onChange={(value) => setUser(value)}
            placeholder="Unassigned"
            messageVariant="hidden"
          />
        </Flex>
      ),
      action: {
        primary: {
          onClick: enhancedOnSubmit,
          children: "Save",
        },
      },
    });
  });

  return (
    <Flex as="span">
      <Link as="button" type="button" onClick={onClick}>
        Change assignee
      </Link>
    </Flex>
  );
};

export const BatchActions = ({
  status,
  filters,
  selection = [],
  onActionPerformed,
}: Props) => {
  const settings = useClientSettings();

  const { realmId, realm } = useClientInfo();

  const applyObject = useApplyObject({
    items: selection,
    resource: "expenses",
  });

  const confirmationDialog = useDialog();

  const [isLoading, setIsLoading] = useState(false);

  const [triggerGetLinkedInvoices] = useLazyGetExpenseLinkedInvoicesQuery();

  const [confirmationState, setConfirmationState] = useState({
    title: "" as ReactNode,
    message: "",
    onConfirm: noop,
    confirmationButtonText: "Confirm",
  });

  const [updateQuickBooksErrorsMutation] = useUpdateQuickBooksErrorsMutation();

  const onBatchApply = useEvent<BatchApplyObjectOnChangeHandler>(
    async ({ value, fieldName, skipSync }) => {
      if (!value && fieldName !== "user") return;

      const handler = async (syncInvoiceLines = false) => {
        try {
          const { success, errorResponses } = await applyObject.execute({
            value: value || null,
            method:
              fieldName === "billable_status"
                ? "apply_billable_status"
                : "apply_object",
            fieldName: fieldName === "user" ? "assignee" : fieldName,
            syncInvoiceLines,
          });

          if (errorResponses.length) {
            errorResponses.forEach((error) => handleErrors(error));
          }

          if (success) {
            toast.success(
              `${success} Receipt${success > 1 ? "s" : ""} updated!`
            );
            onActionPerformed(false);
          }

          analytics.track("expenseBatchActions", {
            value,
            action: fieldName,
            location: `${status}_tab`,
            expenseIds: selection.map(({ id }) => id),
          });
        } catch (e) {
          toast.error(`Error updating receipts`);
        }
      };

      const { data } = await triggerGetLinkedInvoices({
        expenseIds: selection.map(({ id }) => id),
      });

      const linkedInvoices: InvoiceData[] = data?.invoices ?? [];
      if (linkedInvoices.length && !skipSync) {
        confirmSyncInvoiceLines({
          linkedInvoices,
          isPlural: selection.length > 1,
          action: {
            primary: {
              color: "primary",
              onClick: () => handler(true),
              children: "Update line on draw",
            },
            secondary: {
              onClick: handler,
              children: "Don't update line on draw",
            },
          },
        });
      } else {
        handler();
      }
    }
  );

  const performAction = useCallback<PerformActionHandler>(
    async ({
      eventName,
      action,
      successMessageTitle,
      successMessageContent,
      syncOption,
    }) => {
      setIsLoading(true);

      const failed: Expense[] = [];
      const succeeded: Expense[] = [];

      const requests = selection.map(async (item) => {
        try {
          await action(item.id, syncOption);
          succeeded.push(item);
        } catch (error) {
          failed.push(item);
          throw transformErrorToCustomError({
            error,
            extra: { docNumber: item.docNumber, id: item.id },
            render: (message) => `${message} on the following receipts:`,
          });
        }
      });

      const { success, errorResponses } = summarizeResults(
        await Promise.allSettled(requests)
      );

      const enhancedErrors = parseCustomErrors<{
        docNumber?: string;
        id: string;
      }>({
        errors: errorResponses,
        render: ({ isFirst, message, docNumber, id }) =>
          `${message}${isFirst ? "" : ","} [#${docNumber ?? "UNKNOWN"}](/expenses/${id}/)`,
      });

      if (enhancedErrors.length) {
        enhancedErrors.forEach((error) =>
          handleErrors(error, { maxWidth: 800, truncate: 4 })
        );
      }

      if (success) {
        toast.success(
          <Flex as="span" direction="column">
            <Text as="strong" weight="bold">
              {success} Receipt{success > 1 ? "s" : ""} {successMessageTitle}!
            </Text>
            {successMessageContent?.({ failed, succeeded })}
          </Flex>,
          { duration: successMessageContent ? 10000 : undefined }
        );
      }

      analytics.track("expenseBatchActions", {
        action: eventName,
        location: `${status}_tab`,
        expenseIds: selection.map(({ id }) => id),
      });

      setIsLoading(false);
      onActionPerformed();
    },
    [status, selection, onActionPerformed]
  );

  const performActionWithConfirmation =
    useCallback<PerformActionWithConfirmationHandler>(
      ({
        eventName,
        action,
        successMessageTitle,
        successMessageContent,
        confirmationTitle,
        confirmationMessage,
        confirmationButtonText,
        syncConfirmation,
      }) => {
        triggerGetLinkedInvoices({
          expenseIds: selection.map(({ id }) => id),
        }).then(({ data }) => {
          const linkedInvoices: InvoiceData[] = data?.invoices ?? [];
          if (linkedInvoices.length && syncConfirmation) {
            syncConfirmation(
              linkedInvoices,
              (syncOption: UnlinkInvoiceLinesOption) =>
                performAction({
                  eventName,
                  action,
                  successMessageTitle,
                  successMessageContent,
                  syncOption,
                })
            );
          } else if (confirmationMessage) {
            setConfirmationState({
              title: confirmationTitle ?? "Are you sure?",
              message: confirmationMessage,
              onConfirm: () =>
                performAction({
                  eventName,
                  action,
                  successMessageTitle,
                  successMessageContent,
                  syncOption: UNLINK_INVOICE_LINES_OPTION.SKIP,
                }).then(() => {
                  confirmationDialog.hide();
                }),
              confirmationButtonText,
            });
            confirmationDialog.show();
          } else {
            performAction({
              eventName,
              action,
              successMessageTitle,
              successMessageContent,
              syncOption: UNLINK_INVOICE_LINES_OPTION.SKIP,
            });
          }
        });
      },
      [confirmationDialog, performAction, selection, triggerGetLinkedInvoices]
    );

  const onDownload = useEvent<OnDownloadHandler>(async ({ mode, params }) => {
    params.append("ordering", "-updated_at");

    if (realmId) {
      params.append("realm", String(realmId));
    }

    if (status !== "ALL") {
      params.append("is_archived", "false");
      params.append("review_status", status);
    }

    params.append(
      "include_transaction_generated_drafts",
      !settings.cardFeedEnabled ? "true" : "false"
    );

    Object.entries(filters || {})
      .filter(([, val]) => val === false || !!val)
      .forEach(([key, value]) => {
        if ((value as unknown) instanceof Set) {
          (value as unknown as Set<string | number | boolean>).forEach((item) =>
            params.append(key, item.toString())
          );
        } else {
          params.set(key, value.toString());
        }
      });

    if (mode === "selection") {
      (selection || []).map(({ id }) => params.append("id", String(id)));
    }

    const fileId = await expenseApi.exportExpenses(params);
    onActionPerformed(false);
    return fileId;
  });

  const updateExpense = useCallback(
    async (id: Expense["id"], expense: Partial<Omit<Expense, "id">>) => {
      if (!realm?.url) return;

      await expenseApi.put({ ...expense, id, realm: realm.url });
    },
    [realm]
  );

  const ignoreSyncErrorsAction = useMemo<Action>(() => {
    const isInvalid = selection.some((item) => !isError(item));

    return {
      label: "Ignore sync errors",
      testId: "ignore-sync-errors",
      action: (id) => {
        const item = selection.find((item) => item.id === id)!;

        return updateQuickBooksErrorsMutation({
          ids: item.errors.map((error) => error.id),
          isIgnored: true,
        }).unwrap();
      },
      tooltip: isInvalid
        ? "You can only ignore sync errors on receipts that have sync errors"
        : "",
      disabled: isInvalid,
      eventName: "ignore-sync-errors",
      successMessageTitle: "with sync errors ignored",
    };
  }, [selection, updateQuickBooksErrorsMutation]);

  const sendItBackAction = useMemo<Action>(() => {
    const isInvalid = selection.some(
      (item) => item.reviewStatus === "DRAFT" || isPending(item)
    );

    return {
      label: (
        <Flex gap="sm" align="center">
          <Text>Send back to card holder</Text>
          <Tooltip
            as={Icon}
            name="info-circle"
            size="sm"
            message={STRINGS.sendBackTooltip}
          />
        </Flex>
      ),
      tooltip: isInvalid
        ? `You can't send back receipts that are already in the ${STRINGS.draftTitle} status`
        : "",
      eventName: "send_it_back",
      testId: "send-it-back",
      disabled: isInvalid,
      action: (id, syncOption) =>
        updateExpense(id, {
          reviewStatus: REVIEW_STATUS.DRAFT,
          unlinkInvoiceLinesOption: syncOption,
        }),
      successMessageTitle: `moved back to ${STRINGS.draftTitle} status`,
      successMessageContent: ({ succeeded }) => (
        <ReAssignButton data={succeeded} onSubmit={onActionPerformed} />
      ),
      syncConfirmation: (linkedInvoices, callback) =>
        confirmBackToEdit({
          linkedInvoices,
          isPlural: selection.length > 1,
          action: {
            primary: {
              color: "primary",
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.DELETE),
              children: "Delete line on draw",
            },
            secondary: {
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.UNLINK),
              children: "Keep line on draw",
            },
          },
        }),
    };
  }, [selection, updateExpense, onActionPerformed]);

  const archiveAction = useMemo<Action>(() => {
    const isInvalid = selection.some(
      (item) => item.isArchived || isPending(item)
    );

    return {
      label: "Archive",
      eventName: "archive",
      testId: "archive",
      action: (id, syncOption) =>
        updateExpense(id, {
          isArchived: true,
          reviewStatus: REVIEW_STATUS.DRAFT,
          unlinkInvoiceLinesOption: syncOption,
        }),
      successMessageTitle: "archived",
      tooltip: isInvalid
        ? "You can't archive receipts that have already been Archived"
        : "",
      disabled: isInvalid,
      syncConfirmation: (linkedInvoices, callback) =>
        confirmArchiveWithLinkedInvoices({
          linkedInvoices,
          isPlural: selection.length > 1,
          note: "Note: Archiving will also delete the transaction from QuickBooks and reopen any linked purchase orders.",
          action: {
            primary: {
              color: "primary",
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.DELETE),
              children: "Update line on draw",
            },
            secondary: {
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.UNLINK),
              children: "Don't update line on draw",
            },
          },
        }),
      ...(!UNSYNCED_QUICKBOOKS_STATUS.includes(
        status as (typeof REVIEW_STATUS)[keyof typeof REVIEW_STATUS]
      )
        ? {
            confirmationTitle: (
              <>
                Are you sure you want to <br />
                archive this transaction?
              </>
            ),
            confirmationMessage:
              "Archiving will also delete the receipt from QuickBooks.",
            confirmationButtonText: "Archive",
          }
        : {}),
    };
  }, [selection, status, updateExpense]);

  const submitAction = useMemo<Action>(() => {
    const isInvalid = selection.some(
      (item) =>
        item.reviewStatus !== REVIEW_STATUS.DRAFT ||
        item.isArchived ||
        isPending(item)
    );

    return {
      label: "Submit",
      eventName: "for_review",
      testId: "ready-for-review",
      action: (id) =>
        updateExpense(id, { reviewStatus: REVIEW_STATUS.FOR_REVIEW }),
      successMessageTitle: "ready for review",
      tooltip: isInvalid ? "You can only submit Drafts" : "",
      disabled: isInvalid,
    };
  }, [selection, updateExpense]);

  const publishAction = useMemo<Action>(() => {
    const isInvalid = selection.some(
      (item) =>
        item.reviewStatus !== REVIEW_STATUS.FOR_REVIEW ||
        item.isArchived ||
        isPending(item)
    );

    return {
      label: "Sync to QuickBooks",
      eventName: "publish",
      testId: "publish",
      action: (id) =>
        updateExpense(id, { reviewStatus: REVIEW_STATUS.REVIEWED }),
      successMessageTitle: "synced to QuickBooks",
      tooltip: isInvalid
        ? "You can only sync to QuickBooks already submitted receipts"
        : "",
      disabled: isInvalid,
    };
  }, [selection, updateExpense]);

  const deleteAction = useMemo<Action>(() => {
    const isInvalid = selection.some(
      (item) =>
        (item.reviewStatus !== REVIEW_STATUS.DRAFT && !item.isArchived) ||
        isPending(item)
    );

    return {
      label: "Delete",
      eventName: "delete",
      testId: "delete",
      action: (id) => expenseApi.delete({ id }),
      successMessageTitle: "deleted",
      tooltip: isInvalid
        ? "For receipts that were archived from the Approval or Pay pages, you can only delete Archived bills"
        : "",
      color: "error",
      disabled: isInvalid,
      confirmationTitle: (
        <>
          Are you sure you want to <br />
          delete this transaction?
        </>
      ),
      confirmationMessage: "You can't undo this action.",
      confirmationButtonText: "Delete",
    };
  }, [selection]);

  const unpublishAction = useMemo<Action>(() => {
    const isInvalid = selection.some(
      (item) =>
        item.reviewStatus !== REVIEW_STATUS.REVIEWED ||
        item.isArchived ||
        isPending(item)
    );

    return {
      label: "Remove from QuickBooks",
      eventName: "unpublish",
      testId: "unpublish",
      action: (id, syncOption) =>
        updateExpense(id, {
          reviewStatus: REVIEW_STATUS.DRAFT,
          unlinkInvoiceLinesOption: syncOption,
        }),
      successMessageTitle: "removed from QuickBooks",
      tooltip: isInvalid
        ? "You can only reject receipts Ready for approval"
        : "",
      disabled: isInvalid,
      syncConfirmation: (linkedInvoices, callback) =>
        confirmBackToEdit({
          linkedInvoices,
          isPlural: selection.length > 1,
          action: {
            primary: {
              color: "primary",
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.DELETE),
              children: "Delete line on draw",
            },
            secondary: {
              onClick: () => callback(UNLINK_INVOICE_LINES_OPTION.UNLINK),
              children: "Keep line on draw",
            },
          },
        }),
    };
  }, [selection, updateExpense]);

  const restoreAction = useMemo<Action>(() => {
    const isInvalid = selection.some(
      (item) => !item.isArchived || isPending(item)
    );

    return {
      label: "Restore",
      eventName: "restore",
      testId: "restore",
      action: (id) =>
        updateExpense(id, {
          reviewStatus: REVIEW_STATUS.DRAFT,
          isArchived: false,
        }),
      successMessageTitle: "restored",
      tooltip: isInvalid ? "You can only restore Archived receipts" : "",
      disabled: isInvalid,
    };
  }, [updateExpense, selection]);

  const convertAction = useMemo<Action>(() => {
    const isInvalid = selection.some(
      (item) => item.isTransactionGeneratedDraft || isPending(item)
    );

    return {
      label: "Re-categorize as a bill",
      eventName: "re_categorize",
      testId: "convert",
      action: (id: Expense["id"]) => expenseApi.convert({ id }),
      successMessageTitle: "re-categorized as a bill",
      tooltip: isInvalid
        ? "Credit card placeholder transactions can't be converted to bills"
        : "",
      disabled: isInvalid,
    };
  }, [selection]);

  const statusToActions = useMemo<Record<StatusMapKey, Action[]>>(
    () => ({
      DRAFT: [
        submitAction,
        archiveAction,
        ignoreSyncErrorsAction,
        deleteAction,
        convertAction,
      ],
      FOR_REVIEW: [
        publishAction,
        archiveAction,
        sendItBackAction,
        ignoreSyncErrorsAction,
        convertAction,
      ],
      ALL: [
        submitAction,
        publishAction,
        unpublishAction,
        archiveAction,
        sendItBackAction,
        ignoreSyncErrorsAction,
        restoreAction,
        deleteAction,
      ],
    }),
    [
      deleteAction,
      submitAction,
      archiveAction,
      publishAction,
      restoreAction,
      convertAction,
      unpublishAction,
      sendItBackAction,
      ignoreSyncErrorsAction,
    ]
  );

  const statusActions = useMemo(
    () => statusToActions[status] || [],
    [status, statusToActions]
  );

  return (
    <>
      <Flex justify="space-between" gap="md" align="center">
        {(applyObject.isLoading || isLoading) && <Loader position="fixed" />}
        <DownloadButton
          mode={{
            all: { enabled: true },
            selection: {
              enabled: !!(selection?.length && selection.length > 0),
            },
          }}
          size="md"
          data-testid={status}
          onDownload={onDownload}
        />
        {selection.length > 0 && statusActions.length > 0 && (
          <Dropdown placement="bottom-end" flip={false} listSize={6}>
            <DropdownTrigger
              as={Button}
              color="primary"
              data-testid={`${status}-actions-trigger`}
            >
              Actions
              <Icon name="ellipsis-vertical" variant="solid" />
            </DropdownTrigger>
            <DropdownList>
              {statusActions.map((action) => (
                <Tooltip
                  key={suffixify(status, action.testId)}
                  message={action.tooltip}
                  placement="left"
                >
                  <DropdownItem
                    color={action.color}
                    onClick={() =>
                      performActionWithConfirmation({
                        eventName: action.eventName,
                        action: action.action,
                        successMessageTitle: action.successMessageTitle,
                        successMessageContent: action.successMessageContent,
                        confirmationTitle: action.confirmationTitle,
                        syncConfirmation: action.syncConfirmation,
                        confirmationMessage: action.confirmationMessage,
                        confirmationButtonText:
                          action.confirmationButtonText ?? "Confirm",
                      })
                    }
                    disabled={action.disabled}
                    data-testid={`${status}-${action.testId}`}
                  >
                    {action.label}
                  </DropdownItem>
                </Tooltip>
              ))}
              <DropdownList label="Edit properties">
                <DropdownItem>
                  <BatchApplyObject
                    onChange={onBatchApply}
                    includeUsers={{ name: "assignee", clearable: true }}
                    includePaymentAccounts
                    includeBillableStatus
                    accountFilters={{ only_line_item_accounts: true }}
                  />
                </DropdownItem>
              </DropdownList>
            </DropdownList>
          </Dropdown>
        )}
      </Flex>
      <ConfirmationDialog
        title={confirmationState.title}
        dialog={confirmationDialog}
        message={confirmationState.message}
        onConfirm={confirmationState.onConfirm}
        confirmButtonColor="error"
        confirmButtonText={confirmationState.confirmationButtonText}
      />
    </>
  );
};
