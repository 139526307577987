import { useCallback, useEffect, useState } from "react";
import { useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import type { Option } from "@shared/types/objects";
import { useClientHasChanged, useClientInfo } from "@store/user";

import type { Formatter, QueryItem, QuerySets } from "./formatters";
import type {
  AddFilterHandler,
  StrictValuesFilters,
  ValuesFilters,
} from "./types";

export const useFilters = (filters?: ValuesFilters) => {
  const [options, setOptions] = useState<ValuesFilters>(filters || {});

  useEffect(() => {
    if (filters) {
      setOptions((prevFilters) =>
        isEqual(prevFilters, filters) ? prevFilters : filters
      );
    }
  }, [filters]);

  const addFilter = useEvent<AddFilterHandler>((option) => {
    if (!option) return;

    const nextValues = (Array.isArray(option) ? option : [option]).reduce(
      (acc, item) => {
        if ("groupLabel" in item) {
          return { ...acc, [item.value]: item } as ValuesFilters;
        } else if ("key" in item) {
          return { ...acc, [item.key]: item.value } as ValuesFilters;
        }

        return acc;
      },
      {} as ValuesFilters
    );

    setOptions({ ...options, ...nextValues });
  });

  const removeFilter = useEvent((option: Option) => {
    const updated = { ...options };
    if (updated[option.value]) {
      delete updated[option.value];
    }
    setOptions(updated);
  });

  return {
    filters: options,
    addFilter,
    removeFilter,
  } as const;
};

type UseTableFilterControlsProps<T> = T extends QuerySets | QueryItem[]
  ? {
      storageKey?: string;
      formatter: Formatter<T>;
      initialFilters?: StrictValuesFilters;
    }
  : never;

export const useTableFilters = <T>({
  formatter,
  storageKey,
  initialFilters,
}: UseTableFilterControlsProps<T>) => {
  const { realmId } = useClientInfo();

  const [tableKey, setTableKey] = useState(
    storageKey ? `filters-r${realmId}-${storageKey}` : undefined
  );

  const [filters, setFilters] = useState(() => {
    if (initialFilters) return initialFilters;

    if (!tableKey) return {};

    const storedFilters = sessionStorage.getItem(tableKey);
    return storedFilters ? JSON.parse(storedFilters) : {};
  });

  const [formattedFilters, setFormattedFilters] = useState<T | undefined>(() =>
    formatter(realmId, filters)
  );

  useClientHasChanged({
    onClientChanged(client) {
      if (tableKey) {
        sessionStorage.removeItem(tableKey);
        setTableKey(`filters-r${client?.realm?.id}-${storageKey}`);
      }

      setFilters({});
    },
  });

  useEffect(() => {
    setFormattedFilters(formatter(realmId, filters));
  }, [filters, realmId, formatter]);

  const enhancedSetFilters = useCallback(
    (filters: StrictValuesFilters) => {
      if (tableKey) sessionStorage.setItem(tableKey, JSON.stringify(filters));

      setFilters(filters);

      const formatted = formatter(realmId, filters);
      setFormattedFilters(formatted);

      return { filters: formatted, rawFilters: filters };
    },
    [formatter, realmId, tableKey]
  );

  return {
    filters: formattedFilters,
    setFilters: enhancedSetFilters,
    rawFilters: filters,
  };
};
