import React from "react";
import { dialog, Flex, Text } from "@adaptive/design-system";

import { renderLinkedInvoices } from "../render-linked-invoices";
import { DialogProps } from "../types";

type ConfirmArchiveProps = DialogProps & { note: string };
export const confirmArchiveWithLinkedInvoices = ({
  note,
  isPlural = false,
  linkedInvoices = [],
  action,
}: ConfirmArchiveProps) => {
  dialog.confirmation({
    title: `${
      isPlural ? "These transactions have" : "This transaction has"
    } line items that are already added to a draw`,
    message: (
      <Flex direction="column" gap="xl">
        <Text align="center">
          Archived transactions cannot be linked to draws.
        </Text>
        <Text align="center">
          {"Would you like to delete the line on the associated draw "}
          {renderLinkedInvoices(linkedInvoices)}
          {" or keep it and unlink this transaction?"}
        </Text>
        <Text as="i" size="sm" align="center">
          {note}
        </Text>
      </Flex>
    ),
    action,
  });
};

export const confirmArchiveExpense = ({ action }: DialogProps) => {
  dialog.confirmation({
    title: (
      <>
        Are you sure you want to <br />
        archive this transaction?
      </>
    ),
    message: "Archiving will also delete the receipt from QuickBooks.",
    action,
  });
};
