import React, { useEffect, useMemo } from "react";
import {
  Button,
  ComboBox,
  type ComboBoxActionAddon,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Flex,
  Text,
  toast,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useForm,
  type UseMultiStepDialogReturn,
} from "@adaptive/design-system/hooks";
import type { Account } from "@api/accounts";
import { useAccountsSimplified } from "@hooks/use-accounts-simplified";
import { z } from "zod";

import { Step } from "../types";

type Props = {
  putObject: (object: any) => void;
  selectHeader: string;
  dialog: UseMultiStepDialogReturn<Step>;
  selectedAccount?: Account;
  onAccountQueryChange: (query: string) => void;
};

type Fields = z.infer<typeof schema>;

const schema = z.object({
  payment_account: z.string().min(1, "Payment account is required"),
});

export const AccountSetForm = ({
  putObject,
  selectHeader,
  dialog: { setStep, ...dialog },
  selectedAccount,
  onAccountQueryChange,
}: Props) => {
  const initialValues = useDeepMemo(
    () => ({ payment_account: selectedAccount?.url || "" }),
    [selectedAccount]
  );

  const { reset, ...form } = useForm<Fields>({
    schema,
    async onSubmit(values) {
      await putObject(values);
      dialog.hide();
      toast.success("Successfully updated!");
    },
    initialValues,
  });

  useEffect(() => {
    reset();
  }, [reset, selectedAccount]);

  const { refetch, ...accounts } = useAccountsSimplified({
    filters: {
      only_payment_accounts: true,
      can_accounts_link_to_lines_desktop: true,
    },
  });

  const action = useMemo<ComboBoxActionAddon>(
    () => ({
      icon: "plus",
      children: "Add new",
      onClick: (query) => {
        onAccountQueryChange(query);
        setStep("create-account");
      },
    }),
    [setStep, onAccountQueryChange]
  );

  useEffect(() => {
    refetch();
  }, [dialog.step, dialog.isVisible, refetch]);

  return (
    <>
      <DialogHeader>{selectHeader}</DialogHeader>
      <DialogContent>
        <Flex as="form" direction="row" gap="sm" {...form.props}>
          <ComboBox
            label="Choose an account"
            placeholder="Select an account"
            data={accounts.data}
            action={action}
            loading={accounts.status === "loading"}
            {...form.register("payment_account")}
          />
          <Flex direction="column">
            <Flex height="2xl" />
            <Flex padding={["lg", "xl", "lg", "xl"]}>
              <Text color="neutral-700">or</Text>
            </Flex>
          </Flex>
          <Flex direction="column">
            <Flex height="2xl" />
            <Button color="primary" onClick={() => setStep("create-account")}>
              Create new
            </Button>
          </Flex>
        </Flex>
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" variant="text" onClick={dialog.hide}>
          Close
        </Button>
        <Button
          block
          type="submit"
          form={form.id}
          disabled={form.isSubmitting}
          data-testid="account-set-form-save-button"
        >
          Save
        </Button>
      </DialogFooter>
    </>
  );
};
