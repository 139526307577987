import { sum } from "@src/shared/utils/sum";

import { useBudgetAmount } from "./use-budget-amount";

export const useGetRemaining = (row: {
  [key: string]: any;
  builderAmount: number;
  builderRevisedAmount?: number;
  ownersAmount: number;
  spent: number;
  invoicedAmount: number;
}) => {
  const preferredRevenueBudget = useBudgetAmount({ row, type: "revenue" });
  const preferredCostBudget = useBudgetAmount({ row, type: "cost" });
  const builderRemainingAmount = sum(preferredCostBudget, -row.spent);
  const invoicedRemainingAmount = sum(
    preferredRevenueBudget,
    -row.invoicedAmount
  );

  return {
    builderRemainingAmount,
    invoicedRemainingAmount,
  };
};
