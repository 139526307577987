import React, {
  type FocusEventHandler,
  Fragment,
  memo,
  useEffect,
  useState,
} from "react";
import {
  Button,
  CurrencyField,
  Flex,
  Icon,
  Link,
  type TableRow,
  Tag,
  Text,
  Tooltip,
  Wrapper,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { formatCurrency } from "@adaptive/design-system/utils";
import type { BudgetLineItems, StoreCustomerCategoryResponse } from "@api/jobs";
import { useJobPermissions, useJobSettings } from "@src/jobs";
import {
  CURRENCY_FIELD_FORMAT_PROPS,
  CURRENCY_FORMAT,
} from "@src/jobs/constants";
import { AddBudgetLineDialogButton } from "@src/jobs/detail-view/add-budget-line-dialog";
import { useJobInfo } from "@store/jobs";
import { sum } from "@utils/sum";

import { BudgetsCategoriesCombobox } from "../budgets-categories-combobox";

import { useGetRemaining } from "./hooks/use-row-calculations";
import {
  useBudgetsTableActions,
  useBudgetsTableBuilderAmount,
  useBudgetsTableBuilderRevisedAmount,
  useBudgetsTableChangeAmount,
  useBudgetsTableExternalChangeAmount,
  useBudgetsTableInvoicedAmount,
  useBudgetsTableIsBuilderBudgetLocked,
  useBudgetsTableOwnersAmount,
  useBudgetsTableOwnersRevisedAmount,
  useBudgetsTableShouldShowSourceType,
  useBudgetsTableSpent,
  useBudgetsTableUnpaidBills,
} from "./budgets-table-context";
import { getIsDeletable } from "./lines";
import { getPreferredCostBudgetAmount } from "./utils";

type LinesRow = TableRow<BudgetLineItems>;

const getBudgetRemaining = (row: LinesRow) =>
  (row.data?.length ? row.data : [row]).reduce(
    (acc, line) =>
      sum(acc, sum(getPreferredCostBudgetAmount(line), -line.spent)),
    0
  );

export const EmptyColumn = memo(() => null);

EmptyColumn.displayName = "EmptyColumn";

/**
 * Transactions components
 */
export const TransactionsDeleteButton = memo<LinesRow>((row) => {
  const { canManage } = useJobPermissions();
  const isDeletable = getIsDeletable(row);
  const { job } = useJobInfo();

  const { onDeleteBudgetLine } = useBudgetsTableActions();
  const columnsPreventingDeletion = job.change_tracking_enabled
    ? "spent, drawn, or change amounts"
    : "spent or drawn amounts";
  return (
    <Tooltip
      message={
        !canManage
          ? "You don't have permission to do this"
          : isDeletable
            ? ""
            : `Budget lines that have ${columnsPreventingDeletion} cannot be deleted`
      }
    >
      <Button
        size="sm"
        color="neutral"
        variant="ghost"
        onClick={() => onDeleteBudgetLine(row)}
        disabled={!canManage || !isDeletable}
        aria-label={`Remove ${row.jobCostMethod.displayName}`}
        data-testid="budget-remove-line-button"
      >
        <Icon name="trash" />
      </Button>
    </Tooltip>
  );
});

TransactionsDeleteButton.displayName = "TransactionsDeleteButton";

export const TransactionsLineColumn = memo<LinesRow>((row) => {
  const { categoriesEnabled } = useJobSettings();

  const shouldShowSourceType = useBudgetsTableShouldShowSourceType();

  const {
    jobCostMethod: { displayName, parentLabel },
  } = row;
  return (
    <Flex
      gap="md"
      width="full"
      align="center"
      justify="space-between"
      minWidth="200px"
    >
      <Flex direction="column">
        <Text>{displayName}</Text>
        {shouldShowSourceType && <Text size="xs">{row.sourceType}</Text>}
        {parentLabel && <Text size="xs">Parent: {parentLabel}</Text>}
      </Flex>

      {!categoriesEnabled && <TransactionsDeleteButton {...row} />}
    </Flex>
  );
});

TransactionsLineColumn.displayName = "TransactionsLineColumn";

export const TransactionsLineFooter = memo(() => {
  const { categoriesEnabled } = useJobSettings();

  return (
    <Flex align="center" justify="space-between">
      <AddBudgetLineDialogButton />

      {!categoriesEnabled && <Text weight="bold">Item total</Text>}
    </Flex>
  );
});

TransactionsLineFooter.displayName = "TransactionsLineFooter";

export const TransactionsCategoryColumn = memo(
  ({
    includeDelete = true,
    ...row
  }: LinesRow & { includeDelete?: boolean }) => {
    const { updateCategory } = useBudgetsTableActions();

    const [internalValue, setInternalValue] = useState<
      string | { label: string; value: string }
    >("");

    const onChange = useEvent((categoryId?: string) => {
      if (!categoryId || categoryId === row.category?.id) return;

      updateCategory({ categoryId, budgetLineId: row.id });
      setInternalValue(categoryId);
    });

    const onBlur = useEvent<FocusEventHandler<HTMLInputElement>>((e) => {
      if (e.currentTarget.value !== "" || row.category === null) return;

      updateCategory({ categoryId: "", budgetLineId: row.id });
      setInternalValue("");
    });

    const onAddCategory = useEvent(
      (category: StoreCustomerCategoryResponse) => {
        updateCategory({ categoryId: category.id, budgetLineId: row.id });
        setInternalValue({ value: category.id, label: category.displayName });
      }
    );

    useEffect(() => {
      setInternalValue(
        row.category?.id
          ? { label: row.category.displayName, value: row.category.id }
          : ""
      );
    }, [row.category]);

    return (
      <Flex gap="md">
        <BudgetsCategoriesCombobox
          value={internalValue}
          onBlur={onBlur}
          onChange={onChange}
          onAddCategory={onAddCategory}
        />
        {includeDelete && <TransactionsDeleteButton {...row} />}
      </Flex>
    );
  }
);

TransactionsCategoryColumn.displayName = "TransactionsCategoryColumn";

export const TransactionsCategoryFooter = memo(() => {
  return (
    <Flex align="center" justify="flex-end">
      <Text weight="bold">Item total</Text>
    </Flex>
  );
});

TransactionsCategoryFooter.displayName = "TransactionsCategoryFooter";

/**
 * Costs budget components
 */
export const CostsBudgetHeader = memo(() => {
  const { canManage } = useJobPermissions();

  const { ownersAmountEnabled } = useJobSettings();

  const isBuilderBudgetLocked = useBudgetsTableIsBuilderBudgetLocked();

  const { onToggleBudgetLockedStatus } = useBudgetsTableActions();

  const { curriedOnOpenOneSchema } = useBudgetsTableActions();

  return (
    <Wrapper
      when={window.BUDGET_LOCKABLE_ENABLED ?? false}
      render={(children) => (
        <Flex align="center" width="full" gap="md" justify="right">
          {children}
          <Tooltip
            message={
              canManage
                ? isBuilderBudgetLocked
                  ? "Unlock the initial budget to make edits. These edits will not be reflected in the Revised budget column"
                  : isBuilderBudgetLocked === null
                    ? "Lock in this job’s initial budget to enable revision tracking"
                    : "Lock the Budget column to push changes over to the Revised budget column"
                : "You don't have permission to do this"
            }
            aria-label={
              isBuilderBudgetLocked
                ? "Lock builder’s budget"
                : "Unlock builder’s budget"
            }
          >
            <Button
              size="sm"
              color="neutral"
              variant="ghost"
              onClick={onToggleBudgetLockedStatus}
              disabled={!canManage}
              aria-label={
                isBuilderBudgetLocked
                  ? "Lock builder’s budget"
                  : "Unlock builder’s budget"
              }
            >
              <Icon name={isBuilderBudgetLocked ? "lock" : "unlock"} />
            </Button>
          </Tooltip>
        </Flex>
      )}
    >
      <Flex align="center" width="full" gap="md" justify="right">
        <Text align="right" weight="bold">
          {ownersAmountEnabled ? "Cost budget" : "Budget"}
        </Text>
        <Tooltip
          message={
            canManage
              ? "Import your cost budget from a spreadsheet"
              : "You don't have permission to do this"
          }
        >
          <Button
            size="sm"
            color="neutral"
            variant="text"
            onClick={curriedOnOpenOneSchema("builderAmount")}
            disabled={!canManage}
            aria-label="Import cost budget"
          >
            <Icon name="arrow-up-from-bracket" />
          </Button>
        </Tooltip>
      </Flex>
    </Wrapper>
  );
});

CostsBudgetHeader.displayName = "CostsBudgetHeader";

export const CostsBudgetColumn = memo<LinesRow>((row) => {
  const { canEditInitialBudget } = useJobPermissions();

  const { curriedOnEditBuildersBudgetChange } = useBudgetsTableActions();

  const isStatic = !canEditInitialBudget;

  return isStatic ? (
    <Text align="right">
      {formatCurrency(row.builderAmount, CURRENCY_FORMAT)}
    </Text>
  ) : (
    <CurrencyField
      {...CURRENCY_FIELD_FORMAT_PROPS}
      value={row.builderAmount}
      onChange={curriedOnEditBuildersBudgetChange(row)}
    />
  );
});

CostsBudgetColumn.displayName = "CostsBudgetColumn";

export const CostsBudgetFooter = memo(() => {
  const builderAmount = useBudgetsTableBuilderAmount();

  return (
    <Text align="right" weight="bold" data-testid="budget-total">
      {formatCurrency(builderAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsBudgetFooter.displayName = "CostsBudgetFooter";

/**
 * Costs changes components
 */
export const CostsChangesHeader = () => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();
  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeChanges()}
        data-testid="changes"
      >
        <Text as="span" size="sm" weight="bold" align="right">
          Changes
        </Text>
      </Link>
    </Flex>
  );
};

CostsChangesHeader.displayName = "CostsChangesHeader";

export const CostsChangesColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();
  if (!row.hasChanges)
    return (
      <Flex justify="flex-end">
        <Text align="right" size="sm">
          {formatCurrency(row.changeAmount ?? 0, CURRENCY_FORMAT)}
        </Text>
      </Flex>
    );

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeChanges(row)}
        variant="success"
        data-testid="changes"
      >
        <Text as="span" size="sm" align="right">
          {formatCurrency(row.changeAmount ?? 0, CURRENCY_FORMAT)}
        </Text>
      </Link>
    </Flex>
  );
});

CostsChangesColumn.displayName = "CostsChangesColumn";

export const CostsChangesFooter = memo(() => {
  const builderChangeAmount = useBudgetsTableChangeAmount();
  return (
    <Text weight="bold" align="right">
      {formatCurrency(builderChangeAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsChangesFooter.displayName = "CostsChangesFooter";

/**
 * Costs revised budget components
 */
export const CostsRevisedBudgetHeader = () => (
  <Flex justify="flex-end">
    <Tooltip message="[Budget] + [Changes]">
      <Text weight="bold" align="right">
        Revised budget
      </Text>
    </Tooltip>
  </Flex>
);

CostsRevisedBudgetHeader.displayName = "CostsRevisedBudgetHeader";

export const CostsRevisedBudgetColumn = memo<LinesRow>((row) => (
  <Text align="right">
    {formatCurrency(row.builderRevisedAmount ?? 0, CURRENCY_FORMAT)}
  </Text>
));

CostsRevisedBudgetColumn.displayName = "CostsRevisedBudgetColumn";

export const CostsRevisedBudgetFooter = memo(() => {
  const builderRevisedAmount = useBudgetsTableBuilderRevisedAmount();
  return (
    <Text weight="bold" align="right">
      {formatCurrency(builderRevisedAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsRevisedBudgetFooter.displayName = "CostsRevisedBudgetFooter";

/**
 * Costs actual components
 */
export const CostsActualColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeTransactions } = useBudgetsTableActions();
  const amountSpent = (
    <Text as="span" size="sm" data-testid="spent">
      {formatCurrency(row.spent, CURRENCY_FORMAT)}
    </Text>
  );
  return (
    <Flex justify="flex-end">
      {row.spent ? (
        <Link
          as="button"
          type="button"
          onClick={curriedOnSeeTransactions(row)}
          variant="success"
        >
          <Text as="span" size="sm" align="right">
            {amountSpent}
          </Text>
        </Link>
      ) : (
        amountSpent
      )}
    </Flex>
  );
});

CostsActualColumn.displayName = "CostsActualColumn";

export const CostsActualFooter = memo(() => {
  const spent = useBudgetsTableSpent();

  return (
    <Text weight="bold" align="right">
      {formatCurrency(spent, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsActualFooter.displayName = "CostsActualFooter";

/**
 * Costs unpaid components
 */
export const CostsUnpaidColumn = memo<LinesRow>((row) => {
  const unpaidBills = (
    <Text as="span" size="sm">
      {formatCurrency(row.unpaidBills, CURRENCY_FORMAT)}
    </Text>
  );
  const { curriedOnSeeTransactions } = useBudgetsTableActions();

  return (
    <Flex justify="flex-end">
      {row.unpaidBills !== 0 ? (
        <Link
          as="button"
          type="button"
          onClick={curriedOnSeeTransactions(row)}
          variant="success"
        >
          <Text as="span" size="sm" align="right">
            {unpaidBills}
          </Text>
        </Link>
      ) : (
        unpaidBills
      )}
    </Flex>
  );
});

CostsUnpaidColumn.displayName = "CostsUnpaidColumn";

export const CostsUnpaidFooter = memo(() => {
  const unpaidBills = useBudgetsTableUnpaidBills();

  return (
    <Text weight="bold" align="right">
      {formatCurrency(unpaidBills, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsUnpaidFooter.displayName = "CostsUnpaidFooter";

/**
 * Costs remaining components
 */
export const CostsBudgetRemainingHeader = memo(() => {
  const { changeTrackingEnabled } = useJobSettings();
  const message = !changeTrackingEnabled
    ? "[Budget] - [Actual costs to date]"
    : "[Revised budget] - [Actual costs to date]";
  return (
    <Flex justify="flex-end">
      <Tooltip message={message}>
        <Text weight="bold" align="right">
          Budget remaining
        </Text>
      </Tooltip>
    </Flex>
  );
});

CostsBudgetRemainingHeader.displayName = "CostsBudgetRemainingHeader";

export const CostsRemainingColumn = memo<LinesRow>((row) => {
  const budgetRemaining = getBudgetRemaining(row);

  return (
    <Text
      align="right"
      color={budgetRemaining >= 0 ? "neutral-800" : "warning-200"}
    >
      {formatCurrency(budgetRemaining, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsRemainingColumn.displayName = "CostsRemainingColumn";

export const CostsRemainingFooter = memo(() => {
  const spent = useBudgetsTableSpent();

  const builderAmount = useBudgetsTableBuilderAmount();

  const builderRevisedAmount = useBudgetsTableBuilderRevisedAmount();

  const { changeTrackingEnabled } = useJobSettings();
  const preferredBudget = changeTrackingEnabled
    ? builderRevisedAmount
    : builderAmount;
  return (
    <Text weight="bold" align="right" data-testid="budget-remaining-total">
      {formatCurrency(sum(preferredBudget, -spent), CURRENCY_FORMAT)}
    </Text>
  );
});

CostsRemainingFooter.displayName = "CostsRemainingFooter";

/**
 * Revenues markups components
 */
export const RevenuesMarkupColumn = memo<LinesRow>((row) => {
  const inlineMarkups = row.markups.filter(
    ({ isSeparateLine }) => !isSeparateLine
  );

  const { curriedOnEditWorkflow } = useBudgetsTableActions();

  if (row.expanded) return null;

  return (
    <Flex align="center" gap="sm" justify="space-between">
      <Flex gap="sm" align="center">
        {inlineMarkups.length > 0 && (
          <>
            <Button
              size="sm"
              color="neutral"
              variant="ghost"
              aria-label="Edit inline percent markup"
              onClick={curriedOnEditWorkflow(row)}
            >
              <Icon name="pen" />
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
});

RevenuesMarkupColumn.displayName = "RevenuesMarkupColumn";

/**
 * Revenues owners budget components
 */
export const RevenuesOwnersBudgetHeader = memo(() => {
  const { ownersAmountEnabled } = useJobSettings();
  const { canManage } = useJobPermissions();
  const { curriedOnOpenOneSchema } = useBudgetsTableActions();

  return (
    <Flex align="center" gap="md" justify="right">
      <Flex align="center" width="full" gap="md" justify="right">
        <Text weight="bolder" size="sm">
          {ownersAmountEnabled ? "External budget" : "Budget"}
        </Text>
        <Tooltip
          message={
            canManage
              ? "Import your external budget from a spreadsheet"
              : "You don't have permission to do this"
          }
        >
          <Button
            size="sm"
            color="neutral"
            variant="text"
            onClick={curriedOnOpenOneSchema("ownersAmount")}
            disabled={!canManage}
            aria-label="Import external budget"
          >
            <Icon name="arrow-up-from-bracket" />
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
});

RevenuesOwnersBudgetHeader.displayName = "RevenuesOwnersBudgetHeader";

export const RevenuesOwnersBudgetColumn = memo<LinesRow>((row) => {
  const { canManage } = useJobPermissions();

  const { curriedOnEditOwnersBudgetChange } = useBudgetsTableActions();
  const amount = !canManage ? (
    <Text align="right">
      {formatCurrency(row.ownersAmount, CURRENCY_FORMAT)}
    </Text>
  ) : (
    <CurrencyField
      {...CURRENCY_FIELD_FORMAT_PROPS}
      value={row.ownersAmount}
      onChange={curriedOnEditOwnersBudgetChange(row)}
    />
  );

  const builderRevisedAmount = row.builderRevisedAmount ?? 0;
  const builderAmount = row.builderAmount ?? 0;

  const { changeTrackingEnabled } = useJobSettings();
  const preferredBudget = changeTrackingEnabled
    ? builderRevisedAmount
    : builderAmount;

  const difference = Number(
    ((row.ownersAmount * 100) / preferredBudget - 100).toFixed(2)
  );
  const isValidResult =
    !Number.isNaN(difference) && Number.isFinite(difference);

  return (
    <Flex
      gap="sm"
      width="full"
      align="center"
      padding={["none", "none", "none", "30px"]}
    >
      <Flex width="145px" justify="flex-end" shrink={false}>
        {amount}
      </Flex>
      <Flex width="57px">
        {isValidResult && difference !== 0 && row.ownersAmount !== 0 && (
          <Tooltip
            message={`${parseFloat(Math.abs(difference).toFixed(1))}% ${
              difference > 0 ? "increase" : "decrease"
            } from cost budget`}
          >
            <Flex width="57px">
              <Tag size="sm" truncate={{ tooltip: false }}>
                {parseFloat(difference.toFixed(1))}%
              </Tag>
            </Flex>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
});

RevenuesOwnersBudgetColumn.displayName = "RevenuesOwnersBudgetColumn";

export const RevenuesOwnersBudgetFooter = memo(() => {
  const ownersAmount = useBudgetsTableOwnersAmount();

  return (
    <Text align="right" weight="bold">
      {formatCurrency(ownersAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesOwnersBudgetFooter.displayName = "RevenuesOwnersBudgetFooter";

/**
 * Revenue changes components
 */
export const RevenuesChangesHeader = () => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();
  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeChanges()}
        data-testid="changes"
      >
        <Text as="span" size="sm" weight="bold" align="right">
          Changes
        </Text>
      </Link>
    </Flex>
  );
};

RevenuesChangesHeader.displayName = "RevenuesChangesHeader";

export const RevenuesChangesColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();
  if (!row.hasChanges)
    return (
      <Flex justify="flex-end">
        <Text align="right" size="sm">
          {formatCurrency(row.externalChangeAmount ?? 0, CURRENCY_FORMAT)}
        </Text>
      </Flex>
    );

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeChanges(row)}
        variant="success"
        data-testid="changes"
      >
        <Text as="span" size="sm" align="right">
          {formatCurrency(row.externalChangeAmount ?? 0, CURRENCY_FORMAT)}
        </Text>
      </Link>
    </Flex>
  );
});

RevenuesChangesColumn.displayName = "RevenuesChangesColumn";

export const RevenuesChangesFooter = memo(() => {
  const externalChangeAmount = useBudgetsTableExternalChangeAmount();
  return (
    <Text weight="bold" align="right">
      {formatCurrency(externalChangeAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesChangesFooter.displayName = "RevenuesChangesFooter";

/**
 * Revenue revised budget components
 */
export const RevenuesRevisedBudgetHeader = () => (
  <Flex justify="flex-end">
    <Tooltip message="[Budget] + [Changes]">
      <Text weight="bold" align="right">
        Revised budget
      </Text>
    </Tooltip>
  </Flex>
);

RevenuesRevisedBudgetHeader.displayName = "RevenuesRevisedBudgetHeader";

export const RevenuesRevisedBudgetColumn = memo<LinesRow>((row) => (
  <Text align="right">
    {formatCurrency(row.ownersRevisedAmount ?? 0, CURRENCY_FORMAT)}
  </Text>
));

RevenuesRevisedBudgetColumn.displayName = "RevenuesRevisedBudgetColumn";

export const RevenuesRevisedBudgetFooter = memo(() => {
  const totalOwnersRevisedAmount = useBudgetsTableOwnersRevisedAmount();
  return (
    <Text weight="bold" align="right">
      {formatCurrency(totalOwnersRevisedAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesRevisedBudgetFooter.displayName = "RevenuesRevisedBudgetFooter";

/**
 * Revenues drawn components
 */

export const RevenuesDrawnHeader = memo(() => {
  const { curriedOnSeeDrawnToDate } = useBudgetsTableActions();
  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeDrawnToDate()}
        data-testid="drawn-to-date"
      >
        <Text as="span" size="sm" weight="bold" align="right">
          Drawn to date
        </Text>
      </Link>
    </Flex>
  );
});

RevenuesDrawnHeader.displayName = "RevenuesDrawnHeader";

export const RevenuesDrawnColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeDrawnToDate } = useBudgetsTableActions();
  const invoicedAmount = (
    <Text as="span">{formatCurrency(row.invoicedAmount, CURRENCY_FORMAT)}</Text>
  );

  return (
    <Flex justify="flex-end">
      {row.hasInvoices ? (
        <Link
          as="button"
          type="button"
          onClick={curriedOnSeeDrawnToDate(row)}
          variant="success"
        >
          <Text as="span" size="sm" align="right">
            {invoicedAmount}
          </Text>
        </Link>
      ) : (
        invoicedAmount
      )}
    </Flex>
  );
});

RevenuesDrawnColumn.displayName = "RevenuesDrawnColumn";

export const RevenuesDrawnFooter = memo(() => {
  const invoicedAmount = useBudgetsTableInvoicedAmount();

  return (
    <Text align="right" weight="bold" data-testid="invoiced">
      {formatCurrency(invoicedAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesDrawnFooter.displayName = "RevenuesDrawnFooter";

/**
 * Revenues drawn remaining components
 */
export const RevenuesDrawRemainingHeader = memo(() => {
  const { changeTrackingEnabled, ownersAmountEnabled } = useJobSettings();
  const message = ownersAmountEnabled
    ? "[Owner’s budget] - [Drawn to date]"
    : !changeTrackingEnabled
      ? "[Budget] - [Drawn to date]"
      : "[Revised budget] - [Drawn to date]";
  return (
    <Flex justify="flex-end">
      <Tooltip message={message}>
        <Text weight="bold" align="right">
          Draw remaining
        </Text>
      </Tooltip>
    </Flex>
  );
});

RevenuesDrawRemainingHeader.displayName = "RevenuesDrawRemainingHeader";

export const RevenuesDrawnRemainingColumn = memo<LinesRow>((row) => {
  const { invoicedRemainingAmount } = useGetRemaining({
    ...row,
    builderRevisedAmount: row.builderRevisedAmount ?? row.builderAmount,
  });
  return (
    <Text
      align="right"
      color={invoicedRemainingAmount >= 0 ? "neutral-800" : "warning-200"}
    >
      {formatCurrency(invoicedRemainingAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesDrawnRemainingColumn.displayName = "RevenuesDrawnRemainingColumn";

export const RevenuesDrawnRemainingFooter = memo(() => {
  const builderAmount = useBudgetsTableBuilderAmount();
  const builderRevisedAmount = useBudgetsTableBuilderRevisedAmount();
  const ownersRevisedAmount = useBudgetsTableOwnersRevisedAmount();
  const spent = useBudgetsTableSpent();
  const ownersAmount = useBudgetsTableOwnersAmount();
  const invoicedAmount = useBudgetsTableInvoicedAmount();
  const { invoicedRemainingAmount } = useGetRemaining({
    builderAmount,
    builderRevisedAmount,
    ownersAmount,
    ownersRevisedAmount,
    invoicedAmount,
    spent,
  });
  return (
    <Text weight="bold" align="right">
      {formatCurrency(invoicedRemainingAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesDrawnRemainingFooter.displayName = "RevenuesDrawnRemainingFooter";
