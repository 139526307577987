import React, {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ComponentRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "clsx";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { suffixify } from "../../utils";
import { dotObject } from "../../utils/dot-object";
import { omit } from "../../utils/omit";
import { Tag } from "../tag";
import { Tooltip } from "../tooltip";

import styles from "./tag-group.module.css";

type DefaultComponent = "div";
type Ref = ComponentRef<DefaultComponent>;

type TagProps = ComponentProps<typeof Tag>;
type Size = TagProps["size"];
type Color = TagProps["color"];
type Variant = TagProps["variant"];

type PrimitiveData = number | string;
type ObjectData = { disabled?: boolean | string; [key: string]: unknown };

type OwnProps = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "children" | "onClick" | "color"
> & {
  size?: Size;
  limit?: number | "auto";
  "data-testid"?: string;
};

type RenderObject = {
  item: PrimitiveData | ObjectData;
  hidden: PrimitiveData;
  visible: PrimitiveData;
};

type ObjectProps<Data> = OwnProps & {
  data?: Data[];
  render:
    | string
    | ((
        item: Data
      ) => PrimitiveData | { visible: PrimitiveData; hidden: PrimitiveData });
  color?: (item: Data) => Color;
  variant?: Variant;
  onClick?: (value: Data) => void;
  onRemove?: (value: Data) => void;
};

type PrimitiveProps = OwnProps & {
  data?: PrimitiveData[];
  onClick?: (value: PrimitiveData) => void;
  color?: Color;
  variant?: Variant;
  onRemove?: (value: PrimitiveData) => void;
};

type Props<Data> = ObjectProps<Data> | PrimitiveProps;

const GAP_SIZE = 4;

const MAX_COUNTER = 99;

const getTagProps = <Data extends ObjectData>({
  item,
  size,
  color,
  variant,
  onClick,
  onRemove,
}: Omit<Props<Data>, "onClick" | "onRemove"> & {
  item: any;
  onClick?: (item: any) => void;
  onRemove?: (item: any) => void;
}) => {
  const enhancedOnClick =
    (item as Data)?.disabled !== true && onClick
      ? () => onClick(item)
      : undefined;

  return {
    as: enhancedOnClick ? "button" : undefined,
    type: enhancedOnClick ? "button" : undefined,
    size,
    color: typeof color === "function" ? color(item as Data) : color,
    variant,
    onClick: enhancedOnClick,
    onRemove:
      (item as Data)?.disabled !== true && onRemove
        ? () => onRemove(item)
        : undefined,
  } as TagProps;
};

export const TagGroup = <Data extends ObjectData>({
  data = [],
  size = "md",
  limit,
  color = "neutral",
  variant = "square",
  onClick,
  onRemove,
  className,
  "data-testid": testId,
  ...props
}: Props<Data>) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const internalRef = useRef<Ref>(null);
  const hiddenWrapperRef = useRef<HTMLDivElement>(null);

  const [internalLimit, setInternalLimit] = useState<number | undefined>(1);

  const enhancedLimit = limit !== "auto" ? limit : internalLimit;

  const render = useMemo(
    () => (props as ObjectProps<Data>).render,
    [(props as ObjectProps<Data>).render] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const adjustVisibleItems = useEvent(() => {
    if (limit !== "auto" || !internalRef.current || !hiddenWrapperRef.current) {
      return;
    }

    hiddenWrapperRef.current.removeAttribute("hidden");

    const items = hiddenWrapperRef.current.querySelectorAll("span");
    const wrapperWidth = internalRef.current.getBoundingClientRect().width;

    let contentWidth =
      (tooltipRef.current?.getBoundingClientRect().width || 0) + GAP_SIZE * 2;
    let visibleLength = 0;

    for (const item of items) {
      const itemWidth = item.getBoundingClientRect().width;
      const nextContentWidth = contentWidth + GAP_SIZE + itemWidth;

      if (nextContentWidth > wrapperWidth) break;

      contentWidth = nextContentWidth;
      visibleLength++;
    }

    hiddenWrapperRef.current.setAttribute("hidden", "");

    setInternalLimit(visibleLength);
  });

  const enhanceItem = useCallback(
    (item: PrimitiveData | Data): RenderObject => {
      if (typeof item !== "object") {
        return { visible: item, hidden: item, item };
      }

      const rendered =
        typeof render === "function"
          ? render(item)
          : dotObject.get(item, render);

      if (rendered === null || typeof rendered !== "object") {
        return { visible: rendered, hidden: rendered, item };
      }

      return { ...rendered, item };
    },
    [render]
  );

  const [enhancedData, hiddenData, visibleData] = useDeepMemo(() => {
    const enhancedData = (data as (Data | PrimitiveData)[]).reduce(
      (acc, item) => {
        const enhancedItem = enhanceItem(item);
        return !enhancedItem.visible ? acc : [...acc, enhancedItem];
      },
      [] as RenderObject[]
    );

    return [
      enhancedData,
      enhancedData.slice(
        enhancedLimit ?? enhancedData.length,
        enhancedData.length
      ),
      enhancedData.slice(0, enhancedLimit ?? undefined),
    ];
  }, [data, enhanceItem, enhancedLimit]);

  const hiddenCounter =
    hiddenData.length > MAX_COUNTER ? MAX_COUNTER : hiddenData.length;

  useResizeObserver(internalRef, () => queueMicrotask(adjustVisibleItems), {
    observe: "width",
  });

  useLayoutEffect(() => {
    queueMicrotask(adjustVisibleItems);
  }, [adjustVisibleItems]);

  return (
    <div
      ref={internalRef}
      data-testid={testId}
      className={cn(styles["tag-group"], className, {
        [styles["-limit"]]: limit !== undefined,
      })}
      {...omit(props as Omit<ObjectProps<unknown>, "color">, ["render"])}
    >
      {limit === "auto" && (
        <div
          ref={hiddenWrapperRef}
          className={cn(styles["wrapper"], styles["-hidden"])}
          hidden
        >
          {enhancedData.map(({ visible, item }, index) => (
            <Tag
              key={index}
              {...getTagProps({
                item,
                size,
                color,
                variant,
                onClick,
                onRemove,
              })}
            >
              {visible}
            </Tag>
          ))}
        </div>
      )}
      <div className={styles["wrapper"]}>
        {visibleData.map(({ visible, item }, index) => (
          <Tag
            key={index}
            data-testid={suffixify(testId, "tag")}
            {...getTagProps({ item, size, color, variant, onClick, onRemove })}
          >
            {visible}
          </Tag>
        ))}
        {hiddenCounter > 0 && (
          <Tooltip
            as={Tag}
            ref={tooltipRef}
            size={size}
            align="left"
            color={typeof color === "string" ? color : undefined}
            variant={variant}
            message={hiddenData.map(({ hidden }) => hidden).join("\n")}
            data-testid={suffixify(testId, "tag")}
            className={cn(styles["remaining"], {
              [styles["-one-char"]]: hiddenCounter < 10,
              [styles["-two-chars"]]: hiddenCounter >= 10,
            })}
          >
            + {hiddenCounter}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
