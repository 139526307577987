import React, {
  type ComponentPropsWithoutRef,
  type FocusEventHandler,
} from "react";
import { useSelector } from "react-redux";
import { RefNumberField } from "@components/ref-number-field";
import type { Selector } from "@reduxjs/toolkit";

import { RootState } from "../../shared/store/types";

type Props = {
  selector: Selector<RootState, string | undefined | null>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: ComponentPropsWithoutRef<typeof RefNumberField>["onChange"];
  label: string;
  disabled?: boolean;
  helperMessage?: string;
};

export const DocumentNumber = ({
  selector,
  onBlur,
  onChange,
  disabled,
  label,
  helperMessage,
}: Props) => {
  const value = useSelector(selector);

  return (
    <RefNumberField
      name="doc_number"
      label={label}
      value={value || ""}
      disabled={disabled || false}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={{
        QBO: window.QBO_REF_NUMBER_MAX_LENGTH,
        QBDT: window.QBDT_REF_NUMBER_MAX_LENGTH,
      }}
      helperMessage={helperMessage}
    />
  );
};
