import { dotObject, isEqual, omit, pick } from "@adaptive/design-system/utils";
import { createSelector } from "@reduxjs/toolkit";
import type { RootState } from "@store/types";

export const selectPurchaseOrderState = (state: RootState) =>
  state.purchaseOrder;

export const selectPurchaseOrder = createSelector(
  selectPurchaseOrderState,
  (state) => state.purchaseOrder
);

export const selectPurchaseOrderType = createSelector(
  selectPurchaseOrder,
  (state) => state.type
);

export const selectPurchaseOrderIsLoading = createSelector(
  selectPurchaseOrderState,
  (state) => state.purchaseOrderLoading
);

export const selectStaticPurchaseOrder = createSelector(
  selectPurchaseOrderState,
  (state) => state.staticPurchaseOrder
);

/**
 * Adjust amount field since it could be either 0 or 0.00
 */
const normalizePurchaseOrder = (purchaseOrder: any) => {
  const normalizedPurchaseOrder = { ...purchaseOrder };

  normalizedPurchaseOrder.lines = normalizedPurchaseOrder.lines.map(
    (line: any) => {
      const amount = Number(Number(String(line.amount)).toFixed(2));

      return omit({ ...line, amount: amount || 0 }, ["balance"]);
    }
  );

  delete normalizedPurchaseOrder.realm;

  normalizedPurchaseOrder.private_note =
    normalizedPurchaseOrder.private_note || "";

  if (normalizedPurchaseOrder.vendor) {
    normalizedPurchaseOrder.vendor = {
      ...normalizedPurchaseOrder.vendor,
      id: String(normalizedPurchaseOrder.vendor.id),
    };
  }

  normalizedPurchaseOrder.shipping_address =
    normalizedPurchaseOrder.shipping_address || "";

  return omit(normalizedPurchaseOrder, ["open_balance", "total_amount"]);
};

export const createSelectorPurchaseOrderIsDirty = (
  field?: string | string[],
  deepField?: string | string[]
) =>
  createSelector(
    selectPurchaseOrder,
    selectStaticPurchaseOrder,
    (purchaseOrder, staticPurchaseOrder) => {
      const normalizedPurchaseOrder = normalizePurchaseOrder(purchaseOrder);
      const normalizedStaticPurchaseOrder =
        normalizePurchaseOrder(staticPurchaseOrder);

      if (!field) {
        return !isEqual(normalizedPurchaseOrder, normalizedStaticPurchaseOrder);
      }

      const purchaseOrderValues = {};
      const staticPurchaseOrderValues = {};

      (Array.isArray(field) ? field : [field]).forEach((f) => {
        let value = dotObject.get(normalizedPurchaseOrder, f);
        let staticValue = dotObject.get(normalizedStaticPurchaseOrder, f);

        if (deepField) {
          const fieldsToPick = Array.isArray(deepField)
            ? deepField
            : [deepField];

          value = pick(value, fieldsToPick);
          staticValue = pick(staticValue, fieldsToPick);
        }

        dotObject.set(purchaseOrderValues, f, value);
        dotObject.set(staticPurchaseOrderValues, f, staticValue);
      });

      return !isEqual(purchaseOrderValues, staticPurchaseOrderValues);
    }
  );

export const selectPurchaseOrderIsDirty = createSelectorPurchaseOrderIsDirty();

const LINE_FIELDS_TO_DIRTY_CHECK = [
  "item",
  "status",
  "amount",
  "account",
  "customer",
  "description",
];

export const selectPurchaseOrderDirtyLines = createSelector(
  selectPurchaseOrder,
  selectStaticPurchaseOrder,
  (purchaseOrder, staticPurchaseOrder) => {
    const normalizedPurchaseOrderLines =
      normalizePurchaseOrder(purchaseOrder)?.lines;
    const normalizedStaticPurchaseOrderLines =
      normalizePurchaseOrder(staticPurchaseOrder)?.lines;

    return (normalizedPurchaseOrderLines || []).filter(
      (line: any, index: number) => {
        const lineFields = pick(line, LINE_FIELDS_TO_DIRTY_CHECK);
        const staticLineFields = pick(
          normalizedStaticPurchaseOrderLines?.[index],
          LINE_FIELDS_TO_DIRTY_CHECK
        );

        return !isEqual(lineFields, staticLineFields);
      }
    );
  }
);
