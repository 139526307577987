import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type FocusEventHandler,
  type ForwardedRef,
  forwardRef,
  type KeyboardEventHandler,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  type DateRange as ReactDayPickerRangeValue,
  DayPicker,
  type DayPickerProps,
  type MonthChangeEventHandler,
  type SelectRangeEventHandler,
  type SelectSingleEventHandler,
} from "react-day-picker";
import {
  Popover,
  type PopoverStoreState,
  usePopoverStore,
} from "@ariakit/react";
import cn from "clsx";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import { useRifm } from "rifm";

import {
  FORMATTED_DATE_LENGTH,
  FORMATTED_RANGE_DATE_LENGTH,
} from "../../constants";
import { useEvent } from "../../hooks/use-event";
import { useOnInteractOutside } from "../../hooks/use-on-interact-outside";
import { formatDate } from "../../utils/format-date";
import { mergeRefs } from "../../utils/merge-refs";
import { parseDate } from "../../utils/parse-date";
import { setSelectionRange } from "../../utils/set-selection-range";
import { suffixify } from "../../utils/suffixify";
import { Icon } from "../icon";
import { TextField } from "../text-field";

import styles from "./date-field.module.css";

type Ref = ComponentRef<typeof TextField>;

type Modes = "single" | "range";

type SingleValue = Date | null;

type RangeValue = { from?: Date; to?: Date };

type ValueProp<Mode extends Modes> = Mode extends "single"
  ? SingleValue
  : RangeValue;

type Props<Mode extends Modes> = Omit<
  ComponentPropsWithoutRef<typeof TextField>,
  | "value"
  | "prefix"
  | "suffix"
  | "onBlur"
  | "onChange"
  | "addonAfter"
  | "addonBefore"
  | "changeMode"
  | "triggerChangeOnFocusedUnmount"
> & {
  mode?: Mode;
  flip?: boolean;
  value?: ValueProp<Mode>;
  portal?: boolean;
  onBlur?: () => void;
  onChange?: (value: ValueProp<Mode>) => void;
  placement?: PopoverStoreState["placement"];
};

const SLASH_INDEXES = [3, 6, 16, 19];

const ONLY_ONE_SLASH_REGEX = /\/{2,}/g;

const IconLeft = () => <Icon name="arrow-left" />;

const IconRight = () => <Icon name="arrow-right" />;

const adjustDate = (str: string) => {
  const slices = str.split("");

  if (slices[0] && slices[1] === "/") {
    return `0${slices[0]}`;
  } else if (slices[3] && slices[4] === "/") {
    return `${str.slice(0, -2)}0${slices[3]}`;
  } else if (slices[13] && slices[14] === "/") {
    return `${str.slice(0, -2)}0${slices[13]}`;
  } else if (slices[16] && slices[17] === "/") {
    return `${str.slice(0, -2)}0${slices[16]}`;
  }

  return str;
};

const DateField = <Mode extends Modes = "single">(
  {
    id,
    mode,
    size,
    flip = false,
    value,
    portal = false,
    onBlur,
    onFocus,
    onChange,
    placement = "bottom-start",
    onKeyDown,
    errorMessage,
    helperMessage,
    ...props
  }: Props<Mode>,
  ref: ForwardedRef<Ref>
) => {
  const internalId = useId();
  const enhancedId = id ?? internalId;

  const pickerRef = useRef<HTMLDivElement>(null);
  const internalRef = useRef<Ref>(null);

  const [month, setMonth] = useState<Date>();
  const [isFocused, setIsFocused] = useState(false);
  const [maskedValue, setMaskedValue] = useState("");
  const [selectedDate, setSelectedDate] = useState<
    Date | RangeValue | undefined
  >(
    value ?? (mode === "range" ? { from: undefined, to: undefined } : undefined)
  );
  const [possibleSelectedToDate, setPossibleSelectedToDate] = useState<
    Date | undefined
  >(undefined);
  const previousSelectedDateRef = useRef(selectedDate);

  const popoverStore = usePopoverStore({ open: isFocused, placement });

  const enhancedSelectedDate = useMemo(() => {
    if (value === undefined) {
      return selectedDate as Date | ReactDayPickerRangeValue | undefined;
    }

    if (mode === "range") {
      const hasPreviousRangeSelected =
        !(previousSelectedDateRef as RangeValue).from &&
        !(previousSelectedDateRef as RangeValue).to;

      const isSelecting =
        (selectedDate &&
          ((!(selectedDate as RangeValue).from &&
            (selectedDate as RangeValue).to) ||
            ((selectedDate as RangeValue).from &&
              !(selectedDate as RangeValue).to))) ||
        (!(selectedDate as RangeValue).from &&
          !(selectedDate as RangeValue).to &&
          hasPreviousRangeSelected);

      const nextValue = (
        isSelecting ? selectedDate : value
      ) as ReactDayPickerRangeValue;
      previousSelectedDateRef.current = nextValue;

      return nextValue;
    }

    return (value as Date) ?? undefined;
  }, [mode, selectedDate, value]);

  const enhancedOnChange = useEvent((value: string) => {
    const enhancedValue = formatDate(value);
    const parsedDate = parseDate(enhancedValue);

    setMaskedValue(enhancedValue);

    if (!parsedDate) return;

    if (
      mode === "range" &&
      (parsedDate as RangeValue).from &&
      (parsedDate as RangeValue).to &&
      enhancedValue.length === FORMATTED_RANGE_DATE_LENGTH
    ) {
      setSelectedDate(parsedDate as RangeValue);
      setMonth((parsedDate as RangeValue).from);
      (onChange as Props<"range">["onChange"])?.(parsedDate as RangeValue);
    } else if (
      [undefined, "single"].includes(mode) &&
      enhancedValue.length === FORMATTED_DATE_LENGTH
    ) {
      setSelectedDate(parsedDate);
      setMonth(parsedDate as Date);
      (onChange as Props<"single">["onChange"])?.(parsedDate as Date);
    }
  });

  const onSingleSelect = useEvent<SelectSingleEventHandler>((value) => {
    setMonth(value);
    setIsFocused(false);
    setSelectedDate(value);
    (onChange as Props<"single">["onChange"])?.(value || null);
  });

  const onRangeSelect = useEvent<SelectRangeEventHandler>((value) => {
    if (enhancedSelectedDate instanceof Date) return;

    const enhancedValue = value || { from: undefined, to: undefined };
    const hasRangeSelected =
      enhancedSelectedDate?.from && enhancedSelectedDate.to;

    if (hasRangeSelected) {
      if (enhancedValue.from !== enhancedSelectedDate?.from) {
        enhancedValue.to = undefined;
      } else if (enhancedValue.to !== enhancedSelectedDate?.to) {
        enhancedValue.from = enhancedValue.to;
        enhancedValue.to = undefined;
      }
    }

    setSelectedDate(enhancedValue);

    if (enhancedValue.from && enhancedValue.to) {
      setMonth(enhancedValue.from);
      setIsFocused(false);
      (onChange as Props<"range">["onChange"])?.(enhancedValue);
    }
  });

  const onMonthChange = useEvent<MonthChangeEventHandler>((value) => {
    setMonth(value);
  });

  const enhancedOnFocus = useEvent<FocusEventHandler<Ref>>((e) => {
    onFocus?.(e);
    setIsFocused(true);
  });

  const enhancedOnKeyDown = useEvent<KeyboardEventHandler<Ref>>((e) => {
    onKeyDown?.(e);

    if (e.code === "Tab") setIsFocused(false);
  });

  const rifm = useRifm({
    value: maskedValue,
    format: (str) => {
      const enhancedStr = adjustDate(str);

      return SLASH_INDEXES.includes(enhancedStr.length) &&
        enhancedStr.endsWith("/")
        ? enhancedStr.replace(ONLY_ONE_SLASH_REGEX, "")
        : formatDate(enhancedStr);
    },
    accept: /[\d.]/g,
    replace: (str) => {
      setSelectionRange(internalRef.current, str.length + 1, str.length + 1);

      return str;
    },
    onChange: enhancedOnChange,
  });

  const isSelected =
    ((mode === "single" || !mode) && enhancedSelectedDate) ||
    (mode === "range" &&
      ((enhancedSelectedDate as RangeValue).from ||
        (enhancedSelectedDate as RangeValue).to));

  const dayPickerProps = useMemo<DayPickerProps>(() => {
    let commonProps: DayPickerProps = {
      month,
      className: styles["rdp"],
      fixedWeeks: true,
      classNames: styles,
      components: { IconLeft, IconRight },
      onMonthChange,
      showOutsideDays: true,
    };

    if (mode === "range") {
      const to = (selectedDate as RangeValue).to;
      const from = (selectedDate as RangeValue).from;
      const isAfterRange = isAfter(possibleSelectedToDate || 0, from || 0);
      const isBeforeRange = isBefore(possibleSelectedToDate || 0, from || 0);

      commonProps = {
        ...commonProps,
        mode: "range",
        month: commonProps.month || from,
        selected: enhancedSelectedDate as ReactDayPickerRangeValue,
        onSelect: onRangeSelect,
        className: cn(commonProps.className, {
          [styles["-after"]]: isAfterRange,
          [styles["-before"]]: isBeforeRange,
        }),
        modifiers:
          from && !to && possibleSelectedToDate
            ? {
                range: { from, to: possibleSelectedToDate },
              }
            : undefined,
        onDayFocus: setPossibleSelectedToDate,
        numberOfMonths: 2,
        onDayMouseEnter: setPossibleSelectedToDate,
        modifiersClassNames: { range: styles["-range"] },
      };
    }

    if (!mode || mode === "single") {
      commonProps = {
        ...commonProps,
        mode: "single",
        month: commonProps.month || (enhancedSelectedDate as Date | undefined),
        selected: enhancedSelectedDate as Date | undefined,
        onSelect: onSingleSelect,
        required: true,
        numberOfMonths: 1,
      };
    }

    return commonProps;
  }, [
    mode,
    month,
    selectedDate,
    onMonthChange,
    onRangeSelect,
    onSingleSelect,
    enhancedSelectedDate,
    possibleSelectedToDate,
  ]);

  useOnInteractOutside([internalRef, pickerRef], () => {
    if (!isFocused) return;

    const hasCleared = maskedValue.length === 0;
    const hasPreviousValue = !!enhancedSelectedDate;

    if (hasCleared) {
      setMonth(new Date());

      if (mode === "range") {
        (onChange as Props<"range">["onChange"])?.({
          from: undefined,
          to: undefined,
        });
        setSelectedDate({
          from: undefined,
          to: undefined,
        });
      } else {
        (onChange as Props<"single">["onChange"])?.(null);
        setSelectedDate(undefined);
      }
    } else if (hasPreviousValue) {
      setMonth(
        mode === "range"
          ? ((enhancedSelectedDate as RangeValue).from as Date)
          : (enhancedSelectedDate as Date)
      );
      setMaskedValue(formatDate(enhancedSelectedDate));
    } else {
      setMaskedValue("");
    }

    setIsFocused(false);

    requestAnimationFrame(() => onBlur?.());
  });

  useEffect(() => {
    setMaskedValue(
      !enhancedSelectedDate ? "" : formatDate(enhancedSelectedDate)
    );
  }, [enhancedSelectedDate]);

  return (
    <>
      <TextField
        id={enhancedId}
        ref={mergeRefs(ref, internalRef)}
        type="tel"
        size={size}
        value={rifm.value}
        onFocus={enhancedOnFocus}
        onKeyDown={enhancedOnKeyDown}
        containerRef={popoverStore.setAnchorElement}
        errorMessage={isFocused ? "" : errorMessage}
        helperMessage={isFocused ? "" : helperMessage}
        onInput={rifm.onChange}
        maxLength={
          mode === "range" ? FORMATTED_RANGE_DATE_LENGTH : FORMATTED_DATE_LENGTH
        }
        addonAfter={
          <Icon
            size={size}
            name="calendar"
            aria-labelledby={suffixify(enhancedId, "label")}
          />
        }
        triggerChangeOnFocusedUnmount={false}
        {...props}
      />
      <Popover
        id={suffixify(enhancedId, "picker")}
        ref={pickerRef}
        modal={false}
        flip={flip}
        store={popoverStore}
        gutter={8}
        portal={portal}
        className={styles["picker"]}
        autoFocusOnShow={false}
        autoFocusOnHide={false}
      >
        <DayPicker
          {...dayPickerProps}
          className={cn(dayPickerProps.className, {
            [styles["-selected"]]: isSelected,
          })}
        />
      </Popover>
    </>
  );
};

const ForwardedDateField = forwardRef(DateField) as <
  Mode extends Modes = "single",
>(
  props: Props<Mode> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof DateField>;

export { ForwardedDateField as DateField };
