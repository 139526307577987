import type {
  ACHStatus,
  DocumentStatus,
  DocumentType,
} from "@shared/api/vendors/types";
import type { Option } from "@shared/types/objects";

export const DOCUMENT_TYPE_OPTIONS: Option<DocumentType>[] = [
  { label: "Combined COI", value: "INSURANCE" },
  { label: "Workers compensation COI", value: "WORKERS_COMP" },
  { label: "General liability COI", value: "GENERAL_LIABILITY" },
  { label: "W-9", value: "W9" },
  { label: "Contract", value: "CONTRACT" },
  { label: "Other", value: "OTHER" },
];

export const DOCUMENT_STATUS_OPTIONS: Option<DocumentStatus>[] = [
  { label: "Active", value: "ACTIVE" },
  { label: "Expired", value: "EXPIRED" },
  { label: "Expiring soon", value: "EXPIRING" },
  { label: "Missing", value: "MISSING" },
  { label: "Request sent", value: "REQUEST_SENT" },
];

export const ACH_STATUS_OPTIONS: Option<ACHStatus>[] = [
  { label: "Active", value: "ACTIVE" },
  { label: "Missing", value: "MISSING" },
  { label: "Request sent", value: "REQUEST_SENT" },
];
