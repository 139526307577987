import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type ReactNode,
} from "react";
import cn from "clsx";

import { Button } from "../button";
import { Flex } from "../flex";
import { Image } from "../image";

import styles from "./empty-state.module.css";

type DefaultComponent = "div";

type ActionProps = {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
};

type Props = Omit<ComponentPropsWithoutRef<DefaultComponent>, "children"> & {
  title: ReactNode;
  action?: ActionProps | ReactNode;
  subtitle?: ReactNode;
  maxWidth?: number;
};

const inboxSrc = new URL("../../assets/images/inbox.svg", import.meta.url).href;

const isActionProps = (action: unknown): action is ActionProps =>
  typeof action === "object" &&
  action !== null &&
  "onClick" in action &&
  "children" in action;

export const EmptyState = forwardRef<ComponentRef<DefaultComponent>, Props>(
  (
    { title, action, subtitle, className, style, maxWidth = 345, ...props },
    ref
  ) => (
    <div
      ref={ref}
      style={{ ...style, ["--empty-state-max-width"]: `${maxWidth}px` }}
      className={cn(className, styles["empty-state"])}
      {...props}
    >
      <Image src={inboxSrc} width={80} height={51} />
      <Flex gap="sm" direction="column">
        <h3 className={styles["title"]}>{title}</h3>
        {subtitle && <p className={styles["subtitle"]}>{subtitle}</p>}
      </Flex>
      {isActionProps(action) ? <Button {...action} /> : action}
    </div>
  )
);

EmptyState.displayName = "EmptyState";
