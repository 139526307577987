import React, { FormEventHandler, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Button, Flex, Icon, ProgressBar, Text } from "@adaptive/design-system";
import { Loader } from "@components/common/Loader";
import { BrandingLogo } from "@shared/components/branding-logo";

type Props = {
  children?: ReactNode;
  title: string;
  progress?: number;
  formWidth?: string;
  headerWidth?: string;
  backUrl?: string;
  loading?: boolean;
  subtitle?: ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  onSkip?: () => void;
};

export const Layout: React.FC<Props> = ({
  children,
  title,
  subtitle,
  progress,
  backUrl,
  formWidth = "480px",
  headerWidth = "480px",
  loading = false,
  onSubmit,
  onSkip,
}: Props) => {
  return loading ? (
    <Loader />
  ) : (
    <Flex padding="5xl" gap="xl" height="screen" direction="column">
      <Flex
        align="center"
        shrink={false}
        height="50px"
        justify={backUrl || onSkip ? "space-between" : "center"}
      >
        {backUrl ? (
          <Button as={Link} variant="ghost" to={backUrl} aria-label="Back">
            <Icon name="arrow-left" />
          </Button>
        ) : null}
        {progress ? (
          <Flex width="90px">
            <ProgressBar value={progress} />
          </Flex>
        ) : null}
        {backUrl || onSkip ? (
          <Flex width="65px">
            {onSkip ? (
              <Button variant="ghost" onClick={onSkip}>
                Skip
              </Button>
            ) : null}
          </Flex>
        ) : null}
      </Flex>

      <Flex
        direction="column"
        grow
        shrink={false}
        align="center"
        justify="center"
      >
        <Flex
          direction="column"
          align="center"
          gap="96px"
          width="full"
          padding={["none", "none", "5xl"]}
        >
          <Flex gap="2xl" direction="column" width={headerWidth}>
            <Flex align="center" direction="column">
              <BrandingLogo />
              <Text
                size="3xl"
                as="h2"
                family="brand"
                weight="regular"
                align="center"
              >
                {title}
              </Text>
            </Flex>
            {subtitle ? (
              <Text size="md" align="center">
                {subtitle}
              </Text>
            ) : null}
          </Flex>
          <Flex
            as="form"
            onSubmit={onSubmit}
            justify="center"
            direction="column"
            width="full"
            maxWidth={formWidth}
            noValidate
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
