import { useCallback } from "react";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { useAppDispatch } from "@store/hooks";

import { RootState } from "../types";

import {
  type Role,
  roleSelector,
  updateRole as updateRoleAction,
} from "./slice";
import {
  createRole,
  deleteRole as deleteRoleAction,
  loadPermissions as loadPermissionsAction,
  loadRole as loadRoleAction,
  loadRoles as loadRolesAction,
  updateRole,
} from "./thunks";

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useRoleAction = () => {
  const dispatch = useAppDispatch();
  const { roles } = useAppSelector(roleSelector);
  const { permissionCategories } = useAppSelector(roleSelector);
  const loadRoles = useCallback(
    (clientId: string) => {
      dispatch(loadRolesAction(clientId));
    },
    [dispatch]
  );

  const loadPermissions = useCallback(() => {
    dispatch(loadPermissionsAction());
  }, [dispatch]);

  const loadRole = useCallback(
    (roleId: string) => dispatch(loadRoleAction(roleId)).unwrap(),
    [dispatch]
  );

  const updateRoleSlice = useCallback(
    (role: Role) => {
      dispatch(updateRoleAction(role));
    },
    [dispatch]
  );

  const saveRole = useCallback(
    (role: Role) => {
      if (role.id) {
        dispatch(updateRole(role));
      } else {
        dispatch(createRole(role));
      }
    },
    [dispatch]
  );

  const deleteRole = useCallback(
    (role: Role, newRole?: Role) => {
      return dispatch(deleteRoleAction({ role, newRole })).unwrap();
    },
    [dispatch]
  );

  return {
    loadRoles,
    roles,
    permissionCategories,
    loadRole,
    updateRoleSlice,
    loadPermissions,
    deleteRole,
    saveRole,
  } as const;
};
