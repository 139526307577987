import { useCallback, useEffect, useRef } from "react";
import { dialog } from "@adaptive/design-system";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import type { GetPurchaseOrderResponse } from "@api/purchase-orders";
import { getPurchaseOrderResponseSchema } from "@api/purchase-orders/schemas";
import { useUserAction } from "@shared/store/user";
import {
  selectPurchaseOrder,
  selectPurchaseOrderIsDirty,
} from "@src/purchase-orders/purchase-orders-selectors";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import * as analytics from "@utils/analytics";
import { handleResponse } from "@utils/api";
import { transformKeysToCamelCase } from "@utils/schema/converters";

import { RootState } from "../types";

import {
  drawerState,
  drawerStep,
  drawerVisibility,
  modalCallback,
  modalVisibility,
  resetDrawerState,
  resetDrawerStep,
  resetModalState,
  resetModalStep,
  setDrawerState,
  setDrawerStep,
  setDrawerVisibility,
  setModalCallback,
  setModalVisible,
  UIState,
} from "./slice";

export const useModalVisibility = <T extends keyof UIState["modals"]>(
  name: T
) => {
  const visible = useAppSelector(modalVisibility[name]);
  const dispatch = useAppDispatch();

  const setVisible = useCallback(
    (flag: boolean) => {
      dispatch(setModalVisible({ key: name, value: flag }));
      dispatch(resetModalStep(name));
      if (!flag) dispatch(resetModalState(name));
    },
    [dispatch, name]
  );

  return { visible, setVisible } as const;
};

export const useDrawerVisibility = <T extends keyof UIState["drawers"]>(
  name: T
) => {
  if (!drawerVisibility[name]) {
    throw new Error(
      `Unregistered Drawer: '${name}'.  Did you register it in the UI Slice?`
    );
  }

  const step = useAppSelector(drawerStep[name]);

  const state = useAppSelector(drawerState[name]);

  const shouldShowHideConfirmation = !!state.shouldShowHideConfirmation;

  const visible = useAppSelector(drawerVisibility[name]);

  const othersVisibleSelector = useCallback(
    (state: RootState) =>
      Object.entries(state.ui.drawers).some(
        ([key, { visible }]) => key !== name && visible === true
      ),
    [name]
  );

  const othersVisible = useAppSelector(othersVisibleSelector);

  const dispatch = useAppDispatch();

  const setVisible = useEvent(
    (flag: boolean, ignoreShouldShowHideConfirmation = false) => {
      const handler = () => {
        dispatch(setDrawerVisibility({ key: name, value: flag }));
        dispatch(resetDrawerStep(name));

        if (!flag) dispatch(resetDrawerState(name));
      };

      if (
        flag ||
        ignoreShouldShowHideConfirmation ||
        !shouldShowHideConfirmation
      ) {
        return handler();
      }

      dialog.confirmation({
        title: "You have unsaved changes",
        message: "Are you sure you want to leave this page without saving?",
        action: {
          close: () => {
            analytics.track("closeDrawer", { drawer: name, action: "stay" });
          },
          primary: {
            onClick: () => {
              analytics.track("closeDrawer", { drawer: name, action: "stay" });
            },
            children: "Stay on page",
          },
          secondary: {
            onClick: () => {
              handler();
              analytics.track("closeDrawer", { drawer: name, action: "leave" });
            },
            children: "Leave without saving",
          },
        },
      });
    }
  );

  const setState = useCallback(
    (state: Record<string, unknown>) => {
      dispatch(setDrawerState({ key: name, value: state }));
    },
    [dispatch, name]
  );

  const setShouldShowHideConfirmation = useEvent((flag: boolean) => {
    if (shouldShowHideConfirmation !== flag) {
      setState({ ...state, shouldShowHideConfirmation: flag });
    }
  });

  const setStep = useCallback(
    (step: string) => dispatch(setDrawerStep({ key: name, value: step })),
    [dispatch, name]
  );

  return {
    step,
    state,
    setStep,
    visible,
    setState,
    setVisible,
    othersVisible,
    setShouldShowHideConfirmation,
  } as const;
};

/**
 * @todo We should find an optimal way to save the callback reference
 * Now, the callback it's being stored on the redux state which is not a good practice
 */
export const useTwoFactorAuth = () => {
  const modalName = "twoFactoAuth";
  const visible = useAppSelector(modalVisibility[modalName]);
  const callback = useAppSelector(modalCallback[modalName]);
  const dispatch = useAppDispatch();
  const { update: updateUser } = useUserAction();

  const setVisible = useCallback(
    (flag: boolean) =>
      dispatch(setModalVisible({ key: modalName, value: flag })),
    [dispatch]
  );
  const setCallback = useCallback(
    (callback: () => void) =>
      dispatch(setModalCallback({ key: modalName, value: callback })),
    [dispatch]
  );

  const askTwoFactorAuth = useCallback(
    (callback: () => void) => {
      setCallback(callback);
      setVisible(true);
    },
    [setCallback, setVisible]
  );

  const checkTwoFactorAuth = useCallback(
    async (callback?: () => void) => {
      // Load the user here to make sure we have the latest data
      // The two_factor_verified flag might have expired
      const user = await updateUser();

      if (
        (!window.TWO_FACTOR_AUTHENTICATION_ENABLED ||
          user.two_factor_verified) &&
        callback
      ) {
        callback();
      } else if (!user.two_factor_verified && callback) {
        askTwoFactorAuth(() => {
          setVisible(false);
          callback();
        });
      } else {
        askTwoFactorAuth(() => setVisible(false));
      }
    },
    [askTwoFactorAuth, setVisible, updateUser]
  );

  return {
    visible,
    setVisible,
    callback,
    checkTwoFactorAuth,
  } as const;
};

type ShowPurchaseOrderState = {
  id?: string | number;
  caller?: string;
  vendorId?: string | number;
  /**
   * When we are interacting with purchase order thru bill form, we want to force the
   * purchase order to have be linked since the form was not saved yet
   */
  linkedLines?: (string | number)[];
  /**
   * Sometimes we need to run something before we convert the purchase order,
   * we use it as example on bill form, on bill form we need to save before
   * we convert to keep the purchase order in sync with the bill
   */
  beforeConversion?: () => Promise<unknown>;
};

type ShowPurchaseOrderHandler = (state?: ShowPurchaseOrderState) => void;

export type OnClosePurchaseOrderHandler = (
  purchaseOrder?: GetPurchaseOrderResponse
) => void;

type UsePurchaseOrderDrawerProps = {
  onClose?: OnClosePurchaseOrderHandler;
};

export const usePurchaseOrderDrawer = ({
  onClose,
}: UsePurchaseOrderDrawerProps = {}) => {
  const isDirty = useAppSelector(selectPurchaseOrderIsDirty);

  const isVisibleRef = useRef(false);

  const latestPurchaseOrderRef = useRef<any>();

  const {
    state,
    visible,
    setState,
    setVisible,
    setShouldShowHideConfirmation,
  } = useDrawerVisibility("purchaseOrder");

  const purchaseOrder = useAppSelector(selectPurchaseOrder);

  latestPurchaseOrderRef.current = useDeepMemo(() => {
    if (!purchaseOrder?.id) return latestPurchaseOrderRef.current;

    return handleResponse(
      transformKeysToCamelCase(purchaseOrder),
      getPurchaseOrderResponseSchema
    );
  }, [purchaseOrder]);

  const show = useEvent<ShowPurchaseOrderHandler>(
    ({ id = "new", ...options } = {}) => {
      setState({ id, ...options });
      setVisible(true);
    }
  );

  const hide = useEvent((ignoreShouldShowHideConfirmation?: boolean) => {
    setVisible(false, ignoreShouldShowHideConfirmation);
  });

  useEffect(() => {
    if (isVisibleRef.current && !visible) {
      onClose?.(latestPurchaseOrderRef.current);
      latestPurchaseOrderRef.current = undefined;
    }

    isVisibleRef.current = visible;
  }, [onClose, visible]);

  useEffect(() => {
    setShouldShowHideConfirmation(isDirty);
  }, [isDirty, setShouldShowHideConfirmation]);

  return {
    show,
    hide,
    state: state as ShowPurchaseOrderState,
    isVisible: visible,
  } as const;
};
