import React, { type ComponentProps } from "react";
import cn from "clsx";

import styles from "./main.module.css";

type Props = ComponentProps<"footer">;

export const MainFooter = ({ className, ...props }: Props) => (
  <footer className={cn(className, styles["main-footer"])} {...props} />
);
