import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { Button, ComboBox, Flex, Loader, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@shared/api/handle-errors";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";

import {
  useGetLienWaiverTemplatesQuery,
  useGetLienWaiverTypesListQuery,
  useRequestLienWaiverMutation,
  useUpdateLienWaiverRequestMutation,
} from "../api/api";
import {
  LIEN_WAIVER_STATUS,
  STRINGS,
  TEMPLATE_NOT_REQUESTED_ID,
} from "../constants/constants";
import { getMessageToVendor } from "../utils/get-message-to-vendor";

import { BillLienWaiverFieldProps } from "./bill-lien-waiver-field";

type BillLienWaiverFieldComboboxProps = {
  openRequestDialog: () => void;
} & Pick<
  BillLienWaiverFieldProps,
  | "value"
  | "onChange"
  | "paymentAmount"
  | "onRequestUpdate"
  | "billLienWaiver"
  | "billId"
  | "vendor"
>;

export const BillLienWaiverFieldCombobox = ({
  value,
  onChange,
  paymentAmount,
  openRequestDialog,
  onRequestUpdate,
  billLienWaiver,
  billId,
  vendor,
}: BillLienWaiverFieldComboboxProps) => {
  const navigate = useNavigate();

  const { data: lienWaiverTypesData, isLoading: lienWaiverTypesLoading } =
    useGetLienWaiverTypesListQuery();
  const {
    data: lienWaiverTemplatesData,
    isLoading: lienWaiverTemplatesLoading,
  } = useGetLienWaiverTemplatesQuery({ withStatus: true });
  const [updateLienWaiverRequest, { isLoading: requestUpdateIsLoading }] =
    useUpdateLienWaiverRequestMutation();
  const [requestLienWaiver, { isLoading: requestLienWaiverIsLoading }] =
    useRequestLienWaiverMutation();

  const isLoading =
    lienWaiverTypesLoading ||
    lienWaiverTemplatesLoading ||
    requestUpdateIsLoading ||
    requestLienWaiverIsLoading;

  const templatesData = useMemo(
    () =>
      lienWaiverTemplatesData?.map((template) => ({
        label:
          lienWaiverTypesData?.results?.find(
            (type) => type.url === template.type
          )?.name || template.type,
        value: template.url || TEMPLATE_NOT_REQUESTED_ID,
      })) || [],
    [lienWaiverTemplatesData, lienWaiverTypesData?.results]
  );

  const isEditAction = templatesData.length > 1;

  const isValidValue = !!value && value !== TEMPLATE_NOT_REQUESTED_ID;

  const handleMarkAsRequested = useEvent(async () => {
    try {
      if (billLienWaiver?.id) {
        await updateLienWaiverRequest({
          id: billLienWaiver.id,
          status: LIEN_WAIVER_STATUS.MARKED_AS_REQUESTED,
          paymentAmount,
          lienWaiverTemplateId: value,
        }).unwrap();
      } else {
        await requestLienWaiver({
          billId: billId!,
          vendorId: parseRefinementIdFromUrl(vendor?.url) ?? "",
          vendorEmail: vendor?.email,
          status: LIEN_WAIVER_STATUS.MARKED_AS_REQUESTED,
          lienWaiverTemplateId: parseRefinementIdFromUrl(value)!,
          paymentAmount: paymentAmount,
          message: getMessageToVendor(vendor),
        }).unwrap();
      }
      toast.success(STRINGS.MARKED_AS_REQUESTED);
      onRequestUpdate();
    } catch (error) {
      handleErrors(error);
    }
  });

  if (lienWaiverTypesLoading || lienWaiverTemplatesLoading) {
    return (
      <Flex width="full" maxWidth="420px" align="center" justify="center">
        <Loader size="xl" />
      </Flex>
    );
  }

  return (
    <Flex width="full" maxWidth="420px" direction="column" gap="md">
      <ComboBox
        value={value}
        onChange={onChange}
        data={templatesData}
        messageVariant="hidden"
        action={{
          icon: isEditAction ? "pen" : "plus",
          children: isEditAction
            ? STRINGS.SUPPORT_EDIT_TEMPLATES
            : STRINGS.SUPPORT_ADD_TEMPLATES,
          onClick: () => navigate("/settings/company/general"),
        }}
        disabled={isLoading}
      />
      {isValidValue && (
        <Flex width="full" gap="md">
          <Button
            size="sm"
            onClick={openRequestDialog}
            disabled={!paymentAmount || isLoading}
          >
            {STRINGS.REQUEST_LIEN_WAIVER}
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={handleMarkAsRequested}
            disabled={!paymentAmount || isLoading}
          >
            {STRINGS.MARK_AS_REQUESTED}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
