import React, { memo } from "react";
import { Flex } from "@adaptive/design-system";

import { BudgetsTable } from "./budgets-table";

export const Budget = memo(() => (
  <Flex direction="column" gap="2xl" shrink={false}>
    <BudgetsTable />
  </Flex>
));

Budget.displayName = "Budget";
