import React from "react";
import {
  Flex,
  Icon,
  ProgressBar,
  Text,
  Tooltip,
} from "@adaptive/design-system";
import { formatCurrency } from "@adaptive/design-system/utils";
import { CURRENCY_FORMAT, INVOICE_STRINGS } from "@src/jobs";
import { useJobInfo } from "@store/jobs";

type ProgressSubBarProps = {
  partialValue: number;
  totalValue: number;
  partialValueTitle: string;
  totalValueTitle: string;
  partialValueTooltip: string;
  totalValueTooltip: string;
};

export const JobProgressBar = () => {
  const { status, job } = useJobInfo();

  if (status !== "loaded") return null;

  return (
    <Flex direction="row" gap="4xl" justify="space-between">
      {window.BUDGET_PROGRESS_BAR_ENABLED ? (
        <ProgressSubBar
          partialValueTitle="Spent"
          partialValue={job.spent}
          partialValueTooltip="Money spent so far in this job"
          totalValue={job.builder_revised_amount || 0}
          totalValueTitle="Budget"
          totalValueTooltip="Builder's total budget"
        />
      ) : null}
      {window.INVOICE_PROGRESS_BAR_ENABLED ? (
        <ProgressSubBar
          partialValueTitle={INVOICE_STRINGS.INVOICED}
          partialValue={job.invoiced}
          partialValueTooltip={`Total amount of ${INVOICE_STRINGS.LOWERCASE_INVOICES} so far in this job`}
          totalValueTitle="Owner's budget"
          totalValue={job.owners_revised_amount || 0}
          totalValueTooltip="Owner's total budget"
        />
      ) : null}
    </Flex>
  );
};

const ProgressSubBar = ({
  partialValueTitle,
  partialValue,
  partialValueTooltip,
  totalValueTitle,
  totalValue,
  totalValueTooltip,
}: ProgressSubBarProps) => (
  <Flex width="full" direction="column" gap="lg">
    <Flex gap="xs" direction="column">
      <Flex justify="space-between">
        <Flex direction="column">
          <Flex gap="sm" align="center">
            <Text size="sm">{partialValueTitle}</Text>
            <Tooltip
              as={Icon}
              name="info-circle"
              size="sm"
              message={partialValueTooltip}
            />
          </Flex>
          <Text size="sm" weight="bold">
            {formatCurrency(partialValue, CURRENCY_FORMAT)}
          </Text>
        </Flex>
        <Flex direction="column">
          <Flex gap="sm" align="center">
            <Text size="sm">{totalValueTitle}</Text>
            <Tooltip
              as={Icon}
              name="info-circle"
              size="sm"
              message={totalValueTooltip}
            />
          </Flex>
          <Text size="sm" weight="bold">
            {formatCurrency(totalValue, CURRENCY_FORMAT)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
    <Flex width="full">
      <ProgressBar value={(partialValue * 100) / totalValue} />
    </Flex>
  </Flex>
);
