import React, { type ReactNode } from "react";

type Props = {
  when: boolean;
  render: (children: ReactNode) => JSX.Element;
  children?: ReactNode;
};

export const Wrapper = ({ when, render, children }: Props) =>
  when ? render(children) : <>{children}</>;
