/**
 * @todo Enable eslint/tslint and fix all issues
 */
/* eslint-disable */
// @ts-nocheck
import { dotObject } from "@adaptive/design-system/utils";
import { createSelector, type Selector } from "@reduxjs/toolkit";

import type {
  Expense,
  LineItem as LineItemData,
} from "../../api/expenses/types";
import type { RootState } from "../types";

import type { LineCollection } from "./line-types";
import type { Entry, ExpenseState } from "./types";
import { initialState } from "./utils";
import { asOption } from "@utils/converters/utils";
import type { User } from "../user";

type ExpenseSelector<T extends keyof Entry<Expense>> = (
  s: RootState
) => Entry<Expense>[T];
const EXPENSE_LINES_OBJ_FIELDS_TO_DIRTY_CHECK = ["attribution", "customer"];

const EXPENSE_INVOICED_LINES_FIELDS_TO_DIRTY_CHECK = ["amount", "description"];

const getUrl = (obj: unknown): obj is string =>
  typeof obj === "object" ? dotObject.get(obj as object, "url") : obj;

export const expenseSelector: (s: RootState) => ExpenseState["entry"] = ({
  expenses,
}) => expenses.entry;

export const expenseFetchStatusSelector: (
  s: RootState
) => ExpenseState["fetchStatus"] = ({ expenses }) => expenses.entry.fetchStatus;

export const createIsOwnExpenseSelector = (user: User) =>
  createSelector(
    [staticEntrySelector],
    (expense) => expense.isCreator || expense.assignee?.url === user.url
  );

export const entrySelector: (s: RootState) => Expense = ({ expenses }) =>
  expenses.entry;

export const staticEntrySelector: (s: RootState) => Expense = ({ expenses }) =>
  expenses.staticEntry;

export const isDirtyLinkedInvoiceLinesSelector = createSelector(
  entrySelector,
  staticEntrySelector,
  (staticExpense, dynamicExpense) => {
    if (!staticExpense?.id) return false;

    const staticLines = Object.values(staticExpense.lines || {}).filter(
      (line: LineItemData) => !!line.linkedToDraftInvoice
    );
    const dynamicLines = Object.values(dynamicExpense.lines || {}).filter(
      (line: LineItemData) => !!line.linkedToDraftInvoice
    );
    const vendorChanged =
      staticExpense.vendor?.url !== dynamicExpense.vendor?.url &&
      staticLines.length > 0;

    return (
      vendorChanged ||
      staticLines.some((line, index) => {
        const dynamicLine = dynamicLines[index];
        const newBillableStatus = dotObject.get(line, "billableStatus");
        const isBillableStatusDirty =
          newBillableStatus != dotObject.get(dynamicLine, "billableStatus") &&
          newBillableStatus === "NotBillable";
        if (isBillableStatusDirty) return true;

        const isInfoDirty = EXPENSE_INVOICED_LINES_FIELDS_TO_DIRTY_CHECK.some(
          (field) => {
            const previousValue = dotObject.get(line, field);
            const nextValue = dotObject.get(dynamicLine, field);
            const hasChanged = nextValue != previousValue;

            if (
              hasChanged &&
              field === "billableStatus" &&
              nextValue === "NotBillable"
            ) {
              return true;
            }

            return hasChanged;
          }
        );

        if (isInfoDirty) return true;

        return EXPENSE_LINES_OBJ_FIELDS_TO_DIRTY_CHECK.some(
          (field) =>
            getUrl(dotObject.get(dynamicLine, field)) !=
            getUrl(dotObject.get(line, field))
        );
      })
    );
  }
);

export const staticReviewStatusSelector = createSelector(
  staticEntrySelector,
  (staticExpense) => staticExpense.reviewStatus
);

export const staticIsArchivedSelector = createSelector(
  staticEntrySelector,
  (staticExpense) => staticExpense.isArchived
);

export const staticLinesSelector = createSelector(
  staticEntrySelector,
  (staticExpense) => staticExpense.lines
);

export const getExpenseFieldSelector: <T extends keyof Entry<Expense>>(
  s: T
) => ExpenseSelector<T> = (field) => (s) => expenseSelector(s)[field];
type SelectorMap = {
  [Field in keyof Entry<Expense>]: ExpenseSelector<Field>;
};

export const expenseDuplicatesSelector = createSelector(
  entrySelector,
  (expense) => expense.duplicate.filter((item) => item.id != expense.id)
);

export const staticIsTransactionGeneratedDraftSelector = createSelector(
  staticEntrySelector,
  (staticExpense) => staticExpense.isTransactionGeneratedDraft
);

export const expenseAssigneeOptionSelector = createSelector(
  entrySelector,
  (expense) => {
    const assignee = expense.assignee || {};

    return asOption({
      displayName: assignee.fullName,
      ...assignee,
    });
  }
);

export const expenseSelectors = Object.keys(initialState.entry).reduce(
  (selectors, key) => {
    return {
      ...selectors,
      [key]: getExpenseFieldSelector(key as keyof Entry<Expense>),
    };
  },
  {}
) as SelectorMap;

export const selectLineById: <Key extends keyof LineCollection>(
  id: Key
) => Selector<RootState, LineCollection[Key] | undefined> = (id) => (s) =>
  expenseSelectors.lines(s)[id];

export const selectorByLineId: <
  Field extends keyof LineItemData,
  Key extends keyof LineCollection,
>(
  id: Key,
  key: Field
) => Selector<RootState, LineCollection[Key][Field] | undefined> =
  (id, key) => (s) => {
    const line = selectLineById(id)(s);
    if (!line) {
      return;
    }

    return line[key];
  };

export const expenseQuerySelector: Selector<
  RootState,
  ExpenseState["query"]
> = (s) => s.expenses.query;

export const expenseNextAction: Selector<
  RootState,
  "unArchive" | "unPublish" | "publish" | "pending"
> = (s) => {
  const expense = expenseSelector(s);

  if (expense.isArchived) return "unArchive";

  if (expense.reviewStatus === "REVIEWED") {
    if (expense.publishedToQuickbooks) return "unPublish";

    return "pending";
  }

  return "publish";
};
