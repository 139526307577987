import type { Vendor } from "@api/vendors/types";
import { getRouteConfigurations } from "@src/vendors/vendor/tabs";

export const SubmitErrorCodes = {
  NONE: 0,
  MISSING_REALM: 1,
  INVALID_PAYLOAD: 2,
  UNKNOWN: 3,
} as const;

export type SavedDocument = NonNullable<Vendor["documents"]>[number] & {
  isEditing?: boolean;
};

export type EditDocument = Omit<SavedDocument, "url" | "parent" | "document">;

export type VirtualDocument = EditDocument & {
  document: File;
};

export type Document = SavedDocument | VirtualDocument;

export type ChangeSet<AddedType, RemovedType, EditedType> = {
  added?: AddedType[];
  removed?: RemovedType[];
  edited?: EditedType[];
};

export type Stage = ReturnType<typeof getRouteConfigurations>[number]["path"];
