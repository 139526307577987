import { useDialog } from "@adaptive/design-system/hooks";
import type { InvoiceGetResponse } from "@api/invoices";
import type { Option } from "@shared/types";
import { z } from "zod";

export type Markup = InvoiceGetResponse["markups"][number] & { color?: string };

export type Line = InvoiceGetResponse["lines"][number] & { markups: Markup[] };

export type Comment = Exclude<InvoiceGetResponse["comments"][number], "id"> & {
  id: string;
};

export type ManageMarkupDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  markup?: Markup;
};

type ManageMarkupFormProps = {
  onFormChange?: (isValid: boolean) => void;
  onClose?: () => void;
  markup?: Markup;
  formId?: string;
};

export const amountFormSchema = z
  .object({
    value: z.number(),
    costCode: z.string().url("Item is required"),
  })
  .required();

export type AmountFields = z.infer<typeof amountFormSchema>;

export type AmountFormProps = ManageMarkupFormProps & {
  initialValues: AmountFields;
};

export type PercentFormProps = ManageMarkupFormProps;

export type ItemOption = Option & { id: number };

export type TransactionLine = Partial<Line["transactionLine"]> & {
  data?: Line[];
  amount: number;
  totalAmount: number;
};

export type Transaction = TransactionLine | Line;

export type CurriedOnChangeLineHandler = (props: {
  line: Line;
  field:
    | "costCodeAccount"
    | "description"
    | "amount"
    | "totalAmount"
    | "vendor";
}) => (value: string | number, option?: Option) => void;

export type CurriedOnChangeMarkupHandler = ({
  field,
  markup,
}: {
  field: "description" | "amount" | "costCodeAccount" | "vendor";
  markup: Markup;
}) => (value: string | number, option?: Option) => void;

export type Mode = "edit" | "view";

export type CurriedOnDeleteMarkupHandler = (markup: Markup) => () => void;
export type CurriedOnEditMarkupHandler = (markup: Markup) => () => void;

export type CurriedOnDeleteTransactionHandler = (
  transaction: Transaction
) => () => void;

export type InvoiceCostTableContextType = {
  hasMarkups: boolean;

  curriedOnChangeLine: CurriedOnChangeLineHandler;
  curriedOnChangeMarkup: CurriedOnChangeMarkupHandler;
  curriedOnEditMarkup: CurriedOnEditMarkupHandler;
  curriedOnDeleteMarkup: CurriedOnDeleteMarkupHandler;
  curriedOnDeleteTransaction: CurriedOnDeleteTransactionHandler;
};
