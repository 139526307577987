import React, { useMemo, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  toast,
} from "@adaptive/design-system";
import { useDialog } from "@adaptive/design-system/hooks";
import { useEvent } from "@adaptive/design-system/hooks";
import { useUpdateBudgetLineMutation } from "@api/budgets";
import { budgetLineUpdateRequestPayloadSchema } from "@api/budgets/request";
import { handleErrors } from "@api/handle-errors";
import type { BudgetLineItems } from "@api/jobs";
import { MARKUP_PERCENTAGE_FORMAT, useJobSettings } from "@src/jobs";
import {
  MarkupEditInlineForm,
  type MarkupEditInlineFormProps,
} from "@src/jobs/markup";
import { useJobInfo } from "@store/jobs";

type EditInlineMarkupDialogProps = {
  dialog: ReturnType<typeof useDialog>;
  budgetLineItem: BudgetLineItems;
};

const FORM_ID = "edit-inline-markup-form";

export const EditInlineMarkupDialog = ({
  dialog,
  budgetLineItem,
}: EditInlineMarkupDialogProps) => {
  const { jobCostMethod } = budgetLineItem;
  const [updateBudgetLine] = useUpdateBudgetLineMutation();

  const { job } = useJobInfo();
  const onSaveEditMarkup = useEvent<MarkupEditInlineFormProps["onSubmit"]>(
    async ({ markups }) => {
      try {
        const body = markups.map(({ id, value, isSeparateLine, costCode }) => ({
          value,
          markupType: "percentage",
          isSeparateLine,
          item: costCode,
          customer: job.id,
          id,
        }));
        await updateBudgetLine(
          budgetLineUpdateRequestPayloadSchema.parse({
            budgetLineId: budgetLineItem.id,
            customerId: job.id,
            markups: body,
          })
        );

        toast.success(`Markup ${jobCostMethod.displayName} updated`);
        dialog.hide();
      } catch (e) {
        handleErrors(e);
      }
    }
  );

  const [isValid, setIsValid] = useState(false);
  const { ownersAmountEnabled } = useJobSettings();
  const initialValues = useMemo(
    () => ({
      markups: budgetLineItem.markups
        .filter(({ isSeparateLine }) => !isSeparateLine)
        .map((markup) => ({
          id: markup.id,
          value: (ownersAmountEnabled ? markup.ownersValue : markup.value) ?? 0,
          isSeparateLine: false,
          costCode: markup.jobCostMethod.url,
        })),
    }),
    [budgetLineItem.markups, ownersAmountEnabled]
  );

  return (
    <Dialog show={dialog.isVisible} variant="dialog" onClose={dialog.hide}>
      <DialogHeader>
        Edit markup: {jobCostMethod?.displayName ?? "Unknown cost code"}{" "}
      </DialogHeader>
      <DialogContent>
        {dialog.isVisible && (
          <MarkupEditInlineForm
            formId={FORM_ID}
            initialValues={initialValues}
            onSubmit={onSaveEditMarkup}
            onValidityChange={setIsValid}
            percentageFormat={MARKUP_PERCENTAGE_FORMAT}
          />
        )}
      </DialogContent>
      <DialogFooter>
        <Button block color="neutral" variant="text" onClick={dialog.hide}>
          Cancel
        </Button>
        <Button type="submit" form={FORM_ID} block disabled={!isValid}>
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
