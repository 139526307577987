import { reviewStatus } from "@shared/api/expenses/response/schema";
import type { CollectionQueries } from "@shared/store/expenses/types";

const DRAFT_TITLE = "Missing info";

export const STRINGS = {
  title: "Receipts",
  subtitle:
    "New receipt?  Drag and drop your receipts in this window to upload them",
  vendorHeader: "VENDOR NAME",
  documentHeader: "Ref #",
  amount: "AMOUNT",
  draftTitle: DRAFT_TITLE,
  sendBackTooltip: `Send this back to ${DRAFT_TITLE}.\n Reassign the user to populate a coworker's to-do list`,
  viewTutorialAction: "View tutorial",
};

export const TABLE_MESSAGES = {
  EMPTY_STATE_TITLE: "No results found",
  EMPTY_STATE_ACTION: "Create new receipt",
  EMPTY_STATE_SUBTITLE:
    "Create a new receipt manually or drag and drop multiple receipts here.",
  EMPTY_STATE_SUBTITLE_CONFLICT: `You can't filter by User when the My receipts toggle is enabled.`,
};

export const REVIEW_STATUS = reviewStatus.enum;

export const DROPZONE_MESSAGES = {
  IDLE: "Upload or drag and drop a file",
  PENDING: "We are processing your file",
  DRAGGING: "Drop multiple files here to bulk upload them!",
};

export const StatusMap: { [Status in CollectionQueries]: string } = {
  DRAFT: "drafts",
  FOR_REVIEW: "review",
  ALL: "all",
} as const;

export const STATUS_FILTER = [
  { label: "Draft", value: "DRAFT", groupLabel: "Status" },
  { label: "Archived", value: "archived", groupLabel: "Status" },
  { label: "For review", value: "FOR_REVIEW", groupLabel: "Status" },
  { label: "Sync errors", value: "errors", groupLabel: "Status" },
  {
    label: "Ignored sync errors",
    value: "ignored_errors",
    groupLabel: "Status",
  },
  {
    label: "Synced to QuickBooks",
    value: "published_to_quickbooks",
    groupLabel: "Status",
  },
  {
    label: "Pending sync to QuickBooks",
    value: "not_published_to_quickbooks",
    groupLabel: "Status",
  },
];

export const CARD_FILTER = [
  {
    label: "Has matched transaction",
    value: "card_link_true",
    groupLabel: "Matched transaction",
  },
  {
    label: "No matched transaction",
    value: "card_link_false",
    groupLabel: "Matched transaction",
  },
];

export const RECEIPT_FILTER = [
  {
    key: "receipt",
    label: "Has image",
    value: "receipt_true",
    groupLabel: "Receipt image",
  },
  {
    key: "receipt",
    label: "Missing image",
    value: "receipt_false",
    groupLabel: "Receipt image",
  },
];

export const DRAW_STATUS_FILTER = [
  {
    label: "Linked to a paid draw",
    value: "LINKED_AND_PAID",
    groupLabel: "Draw status",
  },
  {
    label: "Linked to an unpaid draw",
    value: "LINKED_AND_UNPAID",
    groupLabel: "Draw status",
  },
  {
    label: "Not linked to a draw",
    value: "UNLINKED",
    groupLabel: "Draw status",
  },
];

export const BILLABLE_STATUS_FILTER = [
  {
    label: "Has been billed",
    value: "HasBeenBilled",
    groupLabel: "Billable status",
  },
  { label: "Billable", value: "Billable", groupLabel: "Billable status" },
  {
    label: "Not billable",
    value: "NotBillable",
    groupLabel: "Billable status",
  },
];

export const HUMAN_READABLE_EXPENSE_REVIEW_STATUS = {
  DRAFT: "Draft",
  REVIEWED: "Published",
  FOR_REVIEW: "For review",
};

export const HUMAN_READABLE_EXPENSE_TYPE = {
  EXPENSE: "Expense",
  LABOR: "Labor",
};

export const UNSYNCED_QUICKBOOKS_STATUS: (typeof REVIEW_STATUS)[keyof typeof REVIEW_STATUS][] =
  [REVIEW_STATUS.DRAFT, REVIEW_STATUS.FOR_REVIEW];
