import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEvent } from "@adaptive/design-system/hooks";
import { Comments as AbstractComments } from "@components/comments";
import {
  billSelector,
  commentsBillSelector,
  vendorBillSelector,
} from "@src/bills/utils";

import { recordBillUpdate } from "../../../shared/store/billSlice";
import { BasePermissions, useUserInfo } from "../../../shared/store/user";

const commentsSelector = (state) => {
  const { url, comments } = billSelector(state);

  return {
    url,
    comments: comments.map((comment) => ({
      ...comment,
      createdAt:
        typeof comment.created_at === "string"
          ? new Date(comment.created_at)
          : comment.created_at,
      timelineEventType: comment.timeline_event_type,
      author: {
        ...comment.author,
        fullName: comment.author.full_name,
      },
      hasExternalMention: comment.has_external_mention,
      attachable: comment.attachable,
      replies:
        comment.replies?.map((reply) => ({
          ...reply,
          createdAt:
            typeof reply.created_at === "string"
              ? new Date(reply.created_at)
              : reply.created_at,
          timelineEventType: reply.timeline_event_type,
          hasExternalMention: reply.has_external_mention,
          author: {
            ...reply.author,
            fullName: reply.author.full_name,
          },
        })) || [],
      ...(comment.diffs
        ? {
            diffs: comment.diffs.map((diff) => ({
              ...diff,
              oldValue: diff.old_value,
              newValue: diff.new_value,
            })),
          }
        : {}),
    })),
  };
};

export const Comments = () => {
  const dispatch = useDispatch();

  const { hasPermission } = useUserInfo();

  const vendor = useSelector(vendorBillSelector);

  const comments = useSelector(commentsBillSelector);

  const canComment = hasPermission(BasePermissions.COMMENT_BILL);

  const onAddComment = useEvent(
    ({ user, text, id, url, parentCommentUrl, hasExternalMention, files }) => {
      const newComment = {
        id,
        text,
        url,
        files,
        author: { ...user, full_name: user.fullName },
        created_at: new Date(),
        timeline_event_type: "COMMENT",
        has_external_mention: hasExternalMention,
      };
      dispatch(
        recordBillUpdate({
          comments: parentCommentUrl
            ? comments.map((comment) => {
                if (comment.url === parentCommentUrl) {
                  return {
                    ...comment,
                    replies: [...(comment.replies || []), newComment],
                  };
                }
                return comment;
              })
            : [...comments, newComment],
        })
      );
    }
  );

  return (
    <AbstractComments
      vendor={vendor.url ? vendor : undefined}
      selector={commentsSelector}
      onAddComment={canComment ? onAddComment : undefined}
    />
  );
};
