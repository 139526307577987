import { formatDate, roundNumber } from "@adaptive/design-system/utils";
import { idSchema } from "@shared/utils/schema";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";
import { z } from "zod";

import { transformKeysToSnakeCase } from "../../../utils/schema/converters";
import {
  Expense,
  LineCollection,
  LineItemAttribution,
  ReferenceNode,
} from "../types";

const reviewStatus = z
  .literal("DRAFT")
  .or(z.literal("FOR_REVIEW"))
  .or(z.literal("REVIEWED"));

const foreignKeyUrl = z.string().url().nullish();

const transformReferenceNodeToUrl = (
  node: unknown
): z.infer<typeof foreignKeyUrl> => {
  if (node === null) return node;
  return (node as ReferenceNode).url;
};

const foreignKeyUrlFromReferenceNode = z.preprocess(
  transformReferenceNodeToUrl,
  foreignKeyUrl
);

const lineSchema = z
  .object({
    id: z.number().transform((val) => (val < 1 ? null : val)),
    description: z.optional(z.string().nullish()),
    amount: z.optional(
      z.preprocess((val) => (val as number).toString(), z.string())
    ),
    customer: z.optional(foreignKeyUrlFromReferenceNode),
    vendor: z.optional(foreignKeyUrlFromReferenceNode),
    account: z.optional(foreignKeyUrl),
    billableStatus: z.optional(
      z.enum(["Billable", "HasBeenBilled", "NotBillable"])
    ),
    isAVariance: z.optional(z.boolean()),
    item: z.optional(foreignKeyUrl),
    order: z.number().nullish(),
  })
  .transform((line) => {
    const isOtherName = line.vendor?.includes("othernames");
    return {
      ...line,
      vendor: !isOtherName ? line.vendor : null,
      otherName: isOtherName ? line.vendor : null,
    };
  });

const lineAttributionMap: {
  [Key in LineItemAttribution["groupLabel"]]: (
    attribution: LineItemAttribution
  ) => Partial<z.infer<typeof lineSchema>>;
} = {
  "Cost code": (attr) => ({
    account: null,
    item: transformReferenceNodeToUrl(attr),
  }),
  Account: (attr) => ({
    account: transformReferenceNodeToUrl(attr),
    item: null,
  }),
};

const transformFromAttributionToField = (raw: unknown) => {
  const lines = raw as LineCollection;
  return Object.values(lines).map((line) => {
    // remove attribution, parse back to BE expected field
    // also use snake_case version of field
    let attr: any = {
      item: null,
      account: null,
    };

    if (line.attribution?.groupLabel) {
      const data = line.attribution;
      attr = lineAttributionMap[data.groupLabel](data);
    }

    return {
      ...line,
      ...attr,
    };
  });
};

export const linesPayloadSchema = z.preprocess(
  transformFromAttributionToField,
  lineSchema.array()
);

export const transformMapTaxToSalesTax = (
  raw: Expense
): Expense & { salesTax?: number } => {
  if (!(raw as Expense)?.tax) {
    return raw;
  }

  return {
    ...raw,
    salesTax: raw.tax?.isSet ? raw.tax.value : 0,
  };
};

export const calculateTotalAmount = (raw: Expense & { salesTax?: number }) => {
  if (!raw.lines) {
    return raw;
  }
  raw.totalAmount = sum(
    sumBy(Object.values(raw.lines), "amount"),
    raw.salesTax || 0
  );
  return raw;
};

const expensePutPreprocessor = (val: unknown) => {
  let value = { ...(val as Expense) };
  [
    // order is important
    transformMapTaxToSalesTax,
    calculateTotalAmount,
  ].forEach((tx) => {
    value = tx(value);
  });

  return value;
};

export const expenseItemPutPayload = z.preprocess(
  expensePutPreprocessor,
  z
    .object({
      // TODO: attachables: z.optional(attachable),
      date: z.optional(
        z
          .date()
          .nullish()
          .transform((val) => (val ? formatDate(val, "yyyy-MM-dd") : val))
      ),
      docNumber: z.optional(z.string().nullish()),
      isArchived: z.optional(z.boolean()),
      lines: z.optional(linesPayloadSchema),
      assignee: z.optional(foreignKeyUrlFromReferenceNode),
      privateNote: z.optional(z.string().nullish()),
      paymentAccount: z.optional(
        z.preprocess(transformReferenceNodeToUrl, z.optional(foreignKeyUrl))
      ),
      reviewStatus: reviewStatus,
      realm: z.string().url({ message: "Realm is Required" }),
      salesTax: z.optional(z.number().nullish()),
      totalAmount: z.optional(z.number().transform((s) => roundNumber(s, 2))),
      vendor: z.optional(foreignKeyUrlFromReferenceNode),
      cardTransaction: z.optional(
        z.preprocess(transformReferenceNodeToUrl, z.optional(foreignKeyUrl))
      ),
      isTransactionGeneratedDraft: z.optional(z.boolean()),
      syncInvoiceLines: z.optional(z.boolean()),
      unlinkInvoiceLinesOption: z
        .literal("Skip")
        .or(z.literal("Delete"))
        .or(z.literal("Unlink"))
        .optional(),
    })
    .transform((expense) => {
      const isOtherName = expense.vendor?.includes("othernames");
      return transformKeysToSnakeCase({
        ...expense,
        vendor: !isOtherName ? expense.vendor : null,
        otherName: isOtherName ? expense.vendor : null,
      });
    })
);

export const checkExpenseDuplicationRequestSchema = z.object({
  vendorId: idSchema,
  docNumber: z.string(),
});

export const getLinkedInvoicesRequestSchema = z.object({
  expenseIds: z.array(idSchema),
});
