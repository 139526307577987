// TODO this is a "common" or reusable schema so it should be moved
// somewhere to reflect that
import { isValidEmail } from "@utils/is-valid-email";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { arraySchema, dateSchema, idSchema } from "@utils/schema";
import {
  transformExtractFirstItem,
  transformKeysToCamelCase,
} from "@utils/schema/converters";
import { z } from "zod";

import { errorSchema } from "../../errors";

const responseTransforms = (val: unknown) =>
  [
    // order is important
    transformKeysToCamelCase,
  ].reduce((prev, transform) => transform(prev as any), val);

const pagination = z.string().url();
export const collectionBaseResponseSchema = z.object({
  count: z.number(),
  next: pagination.nullish(),
  previous: pagination.nullish(),
});

const id = z.union([z.string(), z.number(), z.string().uuid()]);
const displayName = z.string();
const email = z.string().email();
const taxId = z.string();
const url = z.string().url();
const hasOverdueBills = z.boolean();
const hasExpiredDocuments = z.boolean();
const hasDocRequests = z.boolean();
// const defaultAttribution = referenceNodeSchema;
export const paymentTerm = z.enum(["ON_RECEIPT", "NET_30", "NET_60", "NET_90"]);
export const documentType = z.enum([
  "INSURANCE",
  "W9",
  "CONTRACT",
  "OTHER",
  "WORKERS_COMP",
  "GENERAL_LIABILITY",
  "COMBINED_COI",
]);

export const documentStatus = z.enum([
  "ACTIVE",
  "EXPIRED",
  "EXPIRING",
  "MISSING",
  "REQUEST_SENT",
]);

export const achStatus = z.enum(["ACTIVE", "MISSING", "REQUEST_SENT"]);

import { VENDOR_REQUEST_STATUS } from "@components/request-vendor-ach/constants";
export const vendorRequestStatus = z.enum([
  VENDOR_REQUEST_STATUS.PENDING,
  VENDOR_REQUEST_STATUS.EXPIRED,
]);

export const achSchema = z.object({
  id,
  archived: z.boolean().optional(),
  routingNumber: z.string(),
  accountNumber: z.string(),
  url: z.string().url(),
});

export const documentSchema = z.object({
  id,
  url,
  name: z.string().nullish(),
  document: url.nullish(),
  parent: url,
  type: documentType,
  expirationDate: dateSchema,
  isExpired: z.boolean().nullish(),
});

export const latestAchRequestSchema = z.object({
  id,
  createdAt: dateSchema,
  vendorEmail: z.string().email(),
  status: vendorRequestStatus,
});

export const latestPendingPoRequestSchema = z.object({
  id,
  createdAt: dateSchema,
  vendorEmail: z.string().email(),
  status: vendorRequestStatus,
  poCanBeRequested: z.boolean(),
});

export const pendingDocRequestSchema = z.object({
  id,
  createdAt: dateSchema,
  documentTypes: z.array(documentType),
});

export const bankingAch = achSchema.partial();

export const address = z.object({
  line1: z.string().nullish(),
  line2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  postalCode: z.string().optional().nullish(),
  url: z.string().url(),
});

export const vendorSchema = z.object({
  address: address.nullish(),
  bankingAchs: bankingAch.array().transform(transformExtractFirstItem),
  displayName,
  documents: documentSchema.array().nullish(),
  paymentTerm: paymentTerm.nullish(),
  email: email.catch((error) => (error.input ? error.input : "")),
  hasExpiredDocuments,
  hasDocRequests,
  expiredDocumentsWithoutReplacements: documentSchema.array().nullish(),
  hasOverdueBills,
  id,
  realm: z.string(),
  phoneNumber: z.string().nullish(),
  taxId,
  url,
  errors: errorSchema.array(),
  latestAchRequest: latestAchRequestSchema.nullish(),
  latestPendingPoRequest: latestPendingPoRequestSchema.nullish(),
  pendingDocRequests: pendingDocRequestSchema.array(),
  achCanBeRequested: z.boolean(),
  defaultAccount: z
    .object({
      url: z.string().url(),
      displayName: z.string(),
    })
    .nullish(),
  defaultItem: z
    .object({
      url: z.string().url(),
      displayName: z.string(),
    })
    .nullish(),
  defaultPaymentDays: z.number().nullish(),
  commonVendor: z.string().url().nullish(),
  types: z.array(z.string()),
  isStoredEmailValid: z.boolean().default(true),
  averageTransaction: z.number().nullish(),
  totalPayables: z.number().nullish(),
  hasBankingAch: z.boolean().nullish(),
  publishedToQuickbooks: z.boolean().nullish(),
});

export const vendorPartialSchema = vendorSchema
  .deepPartial()
  .augment({
    taxId: taxId.nullish(),
    email: email.catch((error) => (error.input ? error.input : "")),
    phoneNumber: z.string().nullish(),
  })
  .transform((vendor) => ({
    ...vendor,
    isStoredEmailValid: !vendor.email || isValidEmail(vendor.email),
  }));

export const vendorQueryResponseSchema = collectionBaseResponseSchema.augment({
  results: vendorSchema.array(),
});

export const documentResponseSchema = z.preprocess(
  responseTransforms,
  documentSchema
    .partial({
      id: true,
    })
    .transform((document) => ({
      id: parseRefinementIdFromUrl(document.url) || undefined,
      ...document,
    }))
);

export const documentQueryResponseSchema = collectionBaseResponseSchema.augment(
  {
    results: documentSchema.array(),
  }
);

export const achQueryResponseSchema = collectionBaseResponseSchema.augment({
  results: achSchema.array(),
});

export const vendorSimplifiedResponseSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: displayName,
});

export const vendorsGetResponseSchema = z.object({
  next: z.string().url().nullish(),
  count: z.number(),
  results: arraySchema(
    vendorSchema.pick({
      displayName: true,
      url: true,
      id: true,
      phoneNumber: true,
      email: true,
      hasOverdueBills: true,
      hasExpiredDocuments: true,
      hasDocRequests: true,
      latestAchRequest: true,
      errors: true,
      types: true,
      defaultItem: true,
      defaultAccount: true,
      defaultPaymentDays: true,
      realm: true,
      averageTransaction: true,
      totalPayables: true,
      hasBankingAch: true,
    })
  ),
  previous: z.string().url().nullish(),
});
