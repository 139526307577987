import { createSelector } from "@reduxjs/toolkit";
import { ROUTE_LABEL } from "@src/vendors/vendor/tabs/routes";
import type { RootState } from "@store/types";
import {
  getUserFriendlyError,
  UserFriendlyErrorKey,
} from "@store/vendors/errors";
import type { Form } from "@store/vendors/slice";
import { camelToSpaceCase } from "@utils/schema/converters";

const formatSimplifiedErrors = <
  T extends Omit<Form["errors"], "nonFieldErrors">,
>(
  error: T
) => {
  const format = (str: string) =>
    [
      camelToSpaceCase,
      (s: string) =>
        s
          .split(" ")
          .map((s) => s[0].toUpperCase() + s.slice(1))
          .join(" "),
    ].reduce((s, fn) => fn(s), str);

  return Object.entries(error).map(([key, value]) => ({
    id: key,
    message: `${format(key)}:\t${value}`,
    details: value,
  }));
};

export const selectErrors = (state: RootState) => state.vendors.form.errors;

export const selectSubmissionErrors = createSelector(selectErrors, (errors) => {
  if (!errors || !errors?.nonFieldErrors?.length) {
    return [];
  }

  const { nonFieldErrors } = errors;

  return nonFieldErrors.map((message, id) => ({
    id: `${id}`,
    message: getUserFriendlyError(message as UserFriendlyErrorKey),
    detail: null,
  }));
});

export const selectErrorsGroups = createSelector(
  [selectErrors, selectSubmissionErrors],
  (errors, submissionErrors) => {
    if (!errors) {
      return errors;
    }

    // these are "form"/"submission" level errors
    const duplicateNameError = submissionErrors.find(
      ({ message }) => message === "A vendor with this name already exists"
    );

    const { info, banking, documents } = errors;

    //  taxId is visible on the accounts tab,
    //  but the field is stored on the info object
    //  because it's part of the '/vendors' payload
    //  so, we'll break it out here
    const { taxId, ...infoErrors } = info || {};
    if (duplicateNameError) {
      infoErrors.displayName = duplicateNameError.message;
    }

    const vendorInfo = Object.keys(infoErrors).length > 0 && infoErrors;
    const accounts =
      (banking || taxId) && Object.assign({}, banking, taxId && { taxId });

    // TODO type/derive these keys from the registered tabs
    return {
      ...(vendorInfo ? { [ROUTE_LABEL.INFO]: vendorInfo } : {}),
      ...(accounts ? { [ROUTE_LABEL.ACCOUNTING]: accounts } : {}),
      ...(documents ? { [ROUTE_LABEL.DOCUMENTS]: documents } : {}),
    };
  }
);

export const makeErrorSelectorForTab = (...tabs: string[]) =>
  createSelector([selectErrorsGroups], (errors) => {
    if (!errors) {
      return {};
    }

    return tabs.reduce((acc, tab) => {
      const errKey = tab as unknown as keyof typeof errors;
      const formatted = formatSimplifiedErrors(errors[errKey] || {});
      return Object.assign(acc, formatted?.length && { [errKey]: formatted });
    }, {});
  });
