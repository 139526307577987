import React from "react";
import {
  type TableColumn,
  type TableEmptyState,
} from "@adaptive/design-system";
import type { MarkupResponse } from "@api/budgets";

import {
  CostsActualColumn,
  CostsBudgetColumn,
  CostsChangeColumn,
  CostsRemainingColumn,
  CostsRevisedBudgetColumn,
  CostsUnpaidColumn,
  EmptyColumn,
  RevenuesChangeColumn,
  RevenuesInvoicedAmountColumn,
  RevenuesInvoiceRemainingAmountColumn,
  RevenuesOwnersAmountColumn,
  RevenuesRevisedBudgetColumn,
  TransactionsColumn,
  TransactionsColumnControl,
} from "./markups-components";

type MarkupsColumn = Omit<TableColumn<MarkupResponse>, "width">;

/**
 * Columns definition
 */
type GetMarkupColumnsProps = {
  hasChanges: boolean;
  hasExternalBudget: boolean;
  hasExternalRevisedBudget: boolean;
};

export const getMarkupColumns = ({
  hasChanges,
  hasExternalBudget,
  hasExternalRevisedBudget,
}: GetMarkupColumnsProps) => {
  const costsRender: MarkupsColumn["render"] = [
    (row) => <CostsBudgetColumn {...row} />,
  ];

  if (hasChanges) {
    /* add for Changes column */
    costsRender.push((row) => <CostsChangeColumn {...row} />);

    /* add for Revised Budget column */
    costsRender.push((row) => <CostsRevisedBudgetColumn {...row} />);
  }

  costsRender.push(
    (row) => row.budgetLine && <CostsActualColumn {...row.budgetLine} />
  );

  if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) {
    costsRender.push(
      (row) => row.budgetLine && <CostsUnpaidColumn {...row.budgetLine} />
    );
  }

  costsRender.push(
    (row) => row.budgetLine && <CostsRemainingColumn {...row.budgetLine} />
  );

  const markupColumns: MarkupsColumn[] = [
    {
      id: "transactions",
      render: [
        (row) => <TransactionsColumn {...row} />,
        (row) => <TransactionsColumnControl {...row} />,
      ],
    },
    { id: "costs", render: costsRender },
  ];

  const revenuesRender: MarkupsColumn["render"] = [];

  if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
    revenuesRender.push(() => <EmptyColumn />);
  }

  if (hasExternalBudget) {
    revenuesRender.push((row) => <RevenuesOwnersAmountColumn {...row} />);

    if (hasChanges && hasExternalRevisedBudget) {
      revenuesRender.push((row) => <RevenuesChangeColumn {...row} />);
      revenuesRender.push((row) => <RevenuesRevisedBudgetColumn {...row} />);
    }
  }

  revenuesRender.push((row) => <RevenuesInvoicedAmountColumn {...row} />);
  revenuesRender.push((row) => (
    <RevenuesInvoiceRemainingAmountColumn {...row} />
  ));

  markupColumns.push({ id: "revenues", render: revenuesRender });

  return markupColumns;
};

export const MARKUP_EMPTY_STATE: TableEmptyState = { hide: true };
