import React, { type ReactNode, useMemo } from "react";
import {
  Alert,
  AlertContent,
  AlertTitle,
  Button,
  Flex,
  Tooltip,
} from "@adaptive/design-system";
import { useDialog, useEvent } from "@adaptive/design-system/hooks";
import { formatDate } from "@adaptive/design-system/utils";
import { useAppSelector } from "@store/hooks";
import { useVendorAction, useVendorInfo } from "@store/vendors";
import { vendorSelector } from "@store/vendors/selectors";

import { AchRequestDialog } from "./ach-request-dialog";
import { achAlertVariant, STRINGS, VENDOR_REQUEST_STATUS } from "./constants";
import { type AchRequestDialogProps } from ".";

type RequestVendorAchAlertProps = {
  customAchRequestAlertContent?: ReactNode;
};

export const RequestVendorAchAlert = ({
  customAchRequestAlertContent,
}: RequestVendorAchAlertProps) => {
  const { info: vendor } = useAppSelector(vendorSelector);

  const { latestAchRequest: request } = vendor;

  const createdAt = formatDate(request?.createdAt || "", "PPPp");

  const vendorEmail = request?.vendorEmail;

  const alertTitle = useMemo(() => {
    if (request?.status === VENDOR_REQUEST_STATUS.EXPIRED) {
      return `The ACH details request you sent to ${vendorEmail} on ${createdAt} has expired.`;
    } else if (request?.status === VENDOR_REQUEST_STATUS.PENDING) {
      return `You sent the vendor a request to ${vendorEmail} to provide their ACH details on
  ${createdAt}.`;
    }

    return "";
  }, [request, createdAt, vendorEmail]);

  const alertContent = useMemo(() => {
    if (request?.status === VENDOR_REQUEST_STATUS.EXPIRED) {
      return STRINGS.DEFAULT_EXPIRED_ACH_REQUEST_ALERT_CONTENT;
    } else if (request?.status === VENDOR_REQUEST_STATUS.PENDING) {
      if (customAchRequestAlertContent) return customAchRequestAlertContent;

      return STRINGS.DEFAULT_PENDING_ACH_REQUEST_ALERT_CONTENT;
    }

    return "";
  }, [request, customAchRequestAlertContent]);

  return (
    request && (
      <Flex direction="column" key={request.id}>
        <Alert variant={achAlertVariant(request.status)}>
          <AlertTitle>{alertTitle}</AlertTitle>
          <AlertContent>{alertContent}</AlertContent>
        </Alert>
      </Flex>
    )
  );
};

type RequestVendorAchButtonProps = {
  size?: "sm" | "md";
  billId?: number | null;
  variant?: "ghost" | "solid";
  disabled?: boolean;
};

export const RequestVendorAchButton = ({
  billId,
  disabled,
  ...props
}: RequestVendorAchButtonProps) => {
  const { fetchById } = useVendorAction();

  const requestAchDialog = useDialog({ lazy: true });

  const { info: vendor } = useAppSelector(vendorSelector);

  const { canManagePaymentInfo } = useVendorInfo();

  const { latestAchRequest: request } = vendor;

  const initialValues = useMemo<AchRequestDialogProps["initialValues"]>(
    () => ({
      vendors: [
        {
          id: vendor.id,
          displayName: vendor.displayName,
          email: vendor.email,
          billId,
        },
      ],
    }),
    [vendor.id, vendor.displayName, vendor.email, billId]
  );

  const onSubmitRequestAch = useEvent(() => fetchById(vendor.id));

  return (
    <>
      {vendor.achCanBeRequested && (
        <Tooltip
          message={
            !canManagePaymentInfo ? "You don't have permission to do this" : ""
          }
          placement="left"
        >
          <Button
            onClick={requestAchDialog.show}
            disabled={!canManagePaymentInfo || !vendor.id || disabled}
            {...props}
          >
            {request ? STRINGS.RESEND_BUTTON_LABEL : STRINGS.SEND_BUTTON_LABEL}
          </Button>
        </Tooltip>
      )}

      {requestAchDialog.isRendered && (
        <AchRequestDialog
          dialog={requestAchDialog}
          multiple={false}
          onSubmit={onSubmitRequestAch}
          initialValues={initialValues}
        />
      )}
    </>
  );
};

type RequestVendorAchProps = Pick<
  RequestVendorAchButtonProps,
  "billId" | "disabled"
> &
  Pick<RequestVendorAchAlertProps, "customAchRequestAlertContent">;

export const RequestVendorAch = ({
  billId,
  disabled,
  customAchRequestAlertContent,
}: RequestVendorAchProps) => (
  <Flex direction="column" gap="xl">
    <RequestVendorAchAlert
      customAchRequestAlertContent={customAchRequestAlertContent}
    />
    <RequestVendorAchButton billId={billId} disabled={disabled} />
  </Flex>
);
