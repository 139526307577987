import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@adaptive/design-system";
import { Layout } from "@components/layout";

type Props = {
  to?: string;
  name?: string;
};

export const NotFound = ({ to = "/", name = "the main page" }: Props) => (
  <Layout
    title="404 Page not found"
    subtitle="We're sorry, the page you requested could not be found. Please go back to the homepage or contact us."
  >
    <Button as={Link} to={to} replace>
      Go to {name}
    </Button>
  </Layout>
);
