import { useDeepMemo } from "@adaptive/design-system/hooks";
import type { MarkupResponse } from "@api/budgets";
import { sum } from "@utils/sum";

import { useBudgetAmount } from "./use-budget-amount";
import type { UseLineItemTotalsReturnType } from "./use-line-item-totals";

export type UseMarkupLineTotalsProps = { markupLines: MarkupResponse[] };

export const useMarkupLineTotals = ({
  markupLines,
}: UseMarkupLineTotalsProps): UseLineItemTotalsReturnType => {
  const markupLineTotals = useDeepMemo(() => {
    return markupLines.reduce(
      (acc, markup) => ({
        builderAmount: sum(acc.builderAmount, markup.builderAmount),
        changeAmount: sum(acc.changeAmount, markup.changeAmount),
        builderRevisedAmount: sum(
          acc.builderRevisedAmount,
          markup.builderRevisedAmount
        ),
        spent: sum(acc.spent, markup.budgetLine?.spent || 0),
        unpaidBills: sum(acc.unpaidBills, markup.budgetLine?.unpaidBills || 0),
        builderRemainingAmount: sum(
          acc.builderRemainingAmount,
          markup.budgetLine?.builderRemainingAmount || 0
        ),
        ownersAmount: sum(acc.ownersAmount, markup.ownersAmount || 0),
        externalChangeAmount: sum(
          acc.externalChangeAmount,
          markup.externalChangeAmount || 0
        ),
        ownersRevisedAmount: sum(
          acc.ownersRevisedAmount,
          markup.ownersRevisedAmount || 0
        ),
        invoicedAmount: sum(
          acc.invoicedAmount,
          markup.budgetLine?.invoicedAmount || 0
        ),
      }),
      {
        builderAmount: 0,
        changeAmount: 0,
        builderRevisedAmount: 0,
        unpaidBills: 0,
        spent: 0,
        builderRemainingAmount: 0,
        ownersAmount: 0,
        externalChangeAmount: 0,
        ownersRevisedAmount: 0,
        invoicedAmount: 0,
      }
    );
  }, [markupLines]);

  const preferredRevenueBudget = useBudgetAmount({
    row: markupLineTotals,
    type: "revenue",
  });

  const { invoicedAmount } = markupLineTotals;

  return {
    ...markupLineTotals,
    invoicedRemainingAmount: sum(preferredRevenueBudget, -invoicedAmount),
  };
};
