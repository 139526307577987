import React, { type ComponentProps, type ReactNode } from "react";
import mitt from "mitt";

import { Button } from "../button";

import { Dialog, type DialogOnCloseHandler } from "./dialog";
import { DialogContainer } from "./dialog-container";

type Action = {
  onClick?: () => unknown | Promise<unknown>;
  children?: ReactNode;
  autoHide?: boolean;
  "data-testid"?: string;
} & Pick<ComponentProps<typeof Button>, "color" | "variant">;

export type Options = {
  size?: ComponentProps<typeof Dialog>["size"];
  title: ReactNode;
  align?: ComponentProps<typeof Dialog>["align"];
  action?: {
    close?: DialogOnCloseHandler;
    primary?: Action;
    secondary?: Action;
  };
  message?: ReactNode;
  variant?: Extract<
    ComponentProps<typeof Dialog>["variant"],
    "confirmation" | "dialog"
  >;
};

export type InternalOptions = Options & {
  id: string;
  name: string;
  show: boolean;
};

type Events = {
  [key: `${string}-add`]: InternalOptions;
  [key: `${string}-remove-started`]: string;
  [key: `${string}-remove-finished`]: string;
};

let id = 0;

const generateId = () => String(id++);

export const emitter = mitt<Events>();

export const createDialog = (name: string) => ({
  dialog: {
    confirmation: (options: Options) => {
      const id = generateId();
      emitter.emit(`${name}-add`, {
        size: "sm",
        variant: "confirmation",
        ...options,
        id,
        name,
        show: true,
        action: {
          ...(options.action ?? {}),
          primary: {
            children: "Confirm",
            ...(options.action?.primary ?? {}),
          },
        },
      });
      return id;
    },
    hide: (id: string) =>
      new Promise<void>((resolve) => {
        emitter.on(`${name}-remove-finished`, (finishedId) => {
          if (id === finishedId) resolve();

          emitter.off(`${name}-remove-finished`);
        });
        emitter.emit(`${name}-remove-started`, id);
      }),
  },
  DialogContainer: () => <DialogContainer name={name} />,
});
