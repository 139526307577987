import { useMemo, useState } from "react";
import { useEvent, usePagination } from "@adaptive/design-system/hooks";
import type { StrictValuesFilters } from "@components/table-filter";
import type { QueryItem } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { useClientInfo, useClientSettings } from "@store/user";

import { useGetExpensesQuery } from "../api/api";
import type { Expense, GetExpensesPayload } from "../api/types";
import { filterFormatter } from "../utils/filter-formatter";

export type UseExpensesParams = {
  order?: { order: string; setOrder: (order: string) => void };
  query?: GetExpensesPayload;
  filters?: ReturnType<typeof useTableFilters<QueryItem[]>>;
  enabled?: boolean;
  pagination?: ReturnType<typeof usePagination>;
  initialFilters?: GetExpensesPayload;
};

const EMPTY_DATA: Expense[] = [];

export const useExpenses = ({
  order: externalOrder,
  query = [],
  enabled = true,
  filters: externalFilters,
  initialFilters = [],
  pagination: externalPagination,
}: UseExpensesParams = {}) => {
  const clientSettings = useClientSettings();

  const { realmId } = useClientInfo();

  const internalPagination = usePagination();
  const pagination = externalPagination ?? internalPagination;

  const [internalOrder, setInternalOrder] = useState("-date");
  const order = externalOrder?.order ?? internalOrder;
  const setOrder = externalOrder?.setOrder ?? setInternalOrder;

  const internalFilters = useTableFilters({ formatter: filterFormatter });
  const {
    filters = initialFilters,
    rawFilters,
    setFilters,
  } = externalFilters && !Array.isArray(externalFilters)
    ? externalFilters
    : internalFilters;

  const enhancedSetFilters = useEvent((filters) => {
    setFilters(filters as StrictValuesFilters);
  });

  const clearFilters = useEvent(() => enhancedSetFilters({}));

  const enhancedFilters = useMemo(() => {
    const params = [...filters, ...query];

    params.push({ dataIndex: "limit", value: pagination.perPage });
    params.push({ dataIndex: "offset", value: pagination.offset });
    params.push({ dataIndex: "ordering", value: order });

    const hasIncludeTransactionGeneratedDrafts = params.some(
      (param) => param.dataIndex === "include_transaction_generated_drafts"
    );

    if (!hasIncludeTransactionGeneratedDrafts) {
      params.push({
        dataIndex: "include_transaction_generated_drafts",
        value: !clientSettings.cardFeedEnabled,
      });
    }

    return params;
  }, [
    filters,
    query,
    pagination.perPage,
    pagination.offset,
    order,
    clientSettings.cardFeedEnabled,
  ]);

  const transactions = useGetExpensesQuery(enhancedFilters, {
    selectFromResult: ({ isFetching, data }) => ({
      data: data?.results || EMPTY_DATA,
      count: data?.count || 0,
      isLoading: isFetching,
    }),
    skip: !realmId || !enabled,
    refetchOnMountOrArgChange: true,
  });

  return {
    ...pagination,
    ...transactions,
    order,
    filters: enhancedFilters,
    setOrder,
    rawFilters,
    setFilters: enhancedSetFilters,
    clearFilters,
  };
};
