import { ComponentProps } from "react";
import { CurrencyField } from "@adaptive/design-system";
const INVOICE_STRING = "Draw";
const INVOICES_STRING = INVOICE_STRING + "s";

export const INVOICE_STRINGS = {
  INVOICE: INVOICE_STRING,
  LOWERCASE_INVOICE: INVOICE_STRING.toLowerCase(),
  INVOICES: INVOICE_STRING + "s",
  LOWERCASE_INVOICES: INVOICES_STRING.toLowerCase(),
  INVOICED: INVOICES_STRING,
  INVOICED_REMAINING: "Draws remaining",
  NEW_INVOICE: "New draw",
};

export const PERMISSION_STRINGS = {
  NO_PERMISSION: "Sorry, you don't have permission to perform this action",
};

export const CURRENCY_FORMAT = {
  currencySign: true,
  allowNegative: true,
  showZeroDecimals: false,
  maximumDecimalDigits: 15,
};

export const CURRENCY_FIELD_FORMAT_PROPS: Pick<
  ComponentProps<typeof CurrencyField>,
  "size" | "align" | "placeholder" | "messageVariant" | "allowNegative"
> = {
  size: "sm",
  align: "right",
  placeholder: "0",
  messageVariant: "hidden",
  allowNegative: true,
};

export const CURRENCY_FIELD_FORM_FORMAT_PROPS: Pick<
  ComponentProps<typeof CurrencyField>,
  "size" | "align" | "placeholder" | "messageVariant" | "allowNegative"
> = { ...CURRENCY_FIELD_FORMAT_PROPS, size: "md" };

export const PERCENTAGE_FORMAT = {
  min: -99999999,
  max: 99999999,
  percentSign: true,
  allowNegative: true,
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
};

export const MARKUP_PERCENTAGE_FORMAT = {
  ...PERCENTAGE_FORMAT,
  maximumFractionDigits: 4,
};

export const DEFAULT_VENDOR_INVOICE_OPTION_VALUE = "empty";
