import { dotObject } from "../dot-object";
import { is } from "../is";

type SortBy = <T extends Record<string, unknown>>(
  collection: T[],
  key: string,
  direction?: "asc" | "desc"
) => T[];

type SortableValue = string | number | boolean;

export const sortBy: SortBy = (collection, key, direction = "asc") =>
  collection.sort((a, b) => {
    const valueA = dotObject.get(a, key) as SortableValue;
    const valueB = dotObject.get(b, key) as SortableValue;
    const isAscending = direction === "asc";

    if (is.number(valueA) && is.number(valueB)) {
      return isAscending ? valueA - valueB : valueB - valueA;
    }

    if (is.string(valueA) && is.string(valueB)) {
      return isAscending
        ? valueA.localeCompare(valueB, "en", { numeric: true })
        : valueB.localeCompare(valueA, "en", { numeric: true });
    }

    if (isAscending) {
      return valueA > valueB ? 1 : -1;
    }

    return valueA < valueB ? 1 : -1;
  });
