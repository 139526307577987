import { useCallback } from "react";
import { dotObject } from "@adaptive/design-system/utils";
import type {
  BudgetLineItems,
  JobDetailState,
  UpdateBuilderBudgetLockedStatusPayload,
} from "@api/jobs";
import { budgetLinesApi } from "@src/shared/api/budgets";
import { useAppDispatch, useAppSelector } from "@store/hooks";

import { setBudgetLines as setBudgetLinesAction } from "./slice";
import { updateBudgedLockedStatus as updateBudgedLockedStatusAction } from "./thunks";

export const useBudgetActions = () => {
  const dispatch = useAppDispatch();
  const { job } = useJobInfo();

  const updateBudgedLockedStatus = useCallback(
    async (payload: UpdateBuilderBudgetLockedStatusPayload) => {
      await dispatch(updateBudgedLockedStatusAction(payload)).unwrap();
    },
    [dispatch]
  );

  const setBudgetLines = useCallback(
    (payload: BudgetLineItems[]) => {
      dispatch(setBudgetLinesAction(payload));
    },
    [dispatch]
  );

  const copyBuilderBudgetToRevisedBudget = useCallback(
    (idListMap: Record<string, string>) => {
      dispatch(
        budgetLinesApi.util.updateQueryData(
          "getBudgetLines",
          { customerId: job.id },
          (draft) => {
            for (const path of Object.values(idListMap)) {
              const [index, ...keys] = path.split(".");
              const budgetLine = draft[Number(index)];
              if (keys.length > 0) {
                const builderAmount = dotObject.get(
                  budgetLine,
                  [...keys, "builderAmount"].join(".")
                );
                const builderRevisedAmount = dotObject.set(
                  budgetLine,
                  [...keys, "builderRevisedAmount"].join("."),
                  builderAmount
                );
                draft[Number(index)] = builderRevisedAmount;
              } else {
                budgetLine.builderRevisedAmount = budgetLine.builderAmount;
              }
            }
          }
        )
      );
    },
    [dispatch, job.id]
  );

  return {
    copyBuilderBudgetToRevisedBudget,
    setBudgetLines,
    updateBudgedLockedStatus,
  };
};

export const useJobInfo = () => {
  const { status, job } = useAppSelector(
    (state) => state.jobs.job as JobDetailState
  );

  return {
    status,
    job,
  };
};
