import React, { type ComponentPropsWithoutRef } from "react";
import { Tab } from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import styles from "./tabs.module.css";

const DEFAULT_COMPONENT = "button";

type TabsTabProps = Omit<
  ComponentPropsWithoutRef<typeof DEFAULT_COMPONENT>,
  "value"
> & { value: string };

export const TabsTab = forwardRefAs<typeof DEFAULT_COMPONENT, TabsTabProps>(
  ({ as: Component = DEFAULT_COMPONENT, className, value, ...props }, ref) => {
    return (
      <Tab
        id={value}
        ref={ref}
        render={(innerProps) => <Component {...innerProps} />}
        className={cn(styles["tab"], className)}
        accessibleWhenDisabled={false}
        {...props}
      />
    );
  }
);
