import { fuzzySearch } from "../../utils/fuzzy-search";

import type {
  EnhancedOption,
  GetInitialStateHandler,
  GetOptionIndexHandler,
  Option,
} from "./types";

export const getInitialState: GetInitialStateHandler = ({ data, multiple }) => {
  const hasGroup = data.some((option) => option.groupLabel);
  const mode = hasGroup ? "group-interacting" : "option-interacting";

  return {
    mode,
    open: false,
    data,
    query: "",
    group: "",
    index: getNextOptionIndex({ mode, data, currentIndex: -1 }),
    option: multiple ? [] : undefined,
    queryMode: "idle",
  };
};

export const getNextOptionIndex: GetOptionIndexHandler = ({
  data,
  mode,
  strict = false,
  currentIndex = -1,
}) => {
  let nextIndex = currentIndex + 1;
  let option = data[nextIndex];

  while (option && option.disabled && mode !== "group-interacting") {
    nextIndex++;
    option = data[nextIndex];
  }

  if (strict && !option) {
    nextIndex = getPreviousOptionIndex({ data, mode, currentIndex: nextIndex });
  }

  return nextIndex;
};

export const getPreviousOptionIndex: GetOptionIndexHandler = ({
  mode,
  data,
  currentIndex = -1,
}) => {
  let previousIndex = currentIndex - 1;
  let option = data[previousIndex];

  while (option && option.disabled && mode !== "group-interacting") {
    previousIndex--;
    option = data[previousIndex];
  }

  return previousIndex;
};

export const search = (
  haystack: Option[],
  needle: string,
  threshold: number
) => {
  const fuzzyInstance = fuzzySearch(haystack, { keys: ["label"], threshold });

  const matches = fuzzyInstance.search(needle) as EnhancedOption[];

  const optionMap = new Map<string, EnhancedOption>();

  const childrenMap = new Map<string, EnhancedOption[]>();

  fuzzyInstance.all.forEach((option) => {
    optionMap.set(option.value, option as EnhancedOption);
    if (option.parent) {
      if (!childrenMap.has(option.parent)) {
        childrenMap.set(option.parent, []);
      }
      childrenMap.get(option.parent)?.push(option as EnhancedOption);
    }
  });

  const results: EnhancedOption[] = [];

  matches.forEach((option) => {
    const stack: EnhancedOption[] = [option];

    const visited = new Set<string>();

    while (stack.length > 0) {
      const current = stack.pop()!;
      if (!visited.has(current.value)) {
        visited.add(current.value);
        results.push(current);
        const children = childrenMap.get(current.value) || [];
        children.forEach((child) => {
          if (!matches.some((match) => match.value === child.value)) {
            results.push(child);
            stack.push(child);
          }
        });
      }
    }
  });

  results.sort((a, b) =>
    a.parent === b.parent
      ? a.label.localeCompare(b.label, "en", { numeric: true })
      : 0
  );

  return [...new Set(results)];
};

export const getMatch = (option: EnhancedOption) =>
  option.matches?.find((match) => match.value === option.label);

export const getDepth = (
  option: Option,
  options: Option[],
  depth = 0
): number => {
  const parent = options.find(
    (item) => item.value && item.value === option.parent
  );

  if (!parent) return depth;

  return getDepth(parent, options, depth + 1);
};

const isValidOption = (value: unknown) =>
  typeof value === "object" &&
  value !== null &&
  (value as Option).value !== undefined &&
  !!(value as Option).label;

export const isSingleOption = (value: unknown): value is Option =>
  isValidOption(value);

export const isMultipleOption = (value: unknown): value is Option[] =>
  Array.isArray(value) && value.some(isValidOption);
