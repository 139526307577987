import React, { type MouseEventHandler } from "react";
import { Button, Flex, Icon, Loader, Text } from "@adaptive/design-system";

import { DropZoneProps } from "./draggable";

export const DropZoneState = {
  IDLE: "IDLE",
  DRAGGING: "DRAGGING",
  PENDING: "PENDING",
  WARNING: "WARNING",
  DISABLED: "DISABLED",
} as const;

export type Keys = keyof typeof DropZoneState;

type GenericMessageProps = {
  flip: DropZoneProps["flip"];
  message?: string;
  onClick?: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
};

export type DragActionProps = {
  state: (typeof DropZoneState)[Keys];
  disabled?: boolean;
} & GenericMessageProps;

const PlainMessage = ({ message }: Pick<GenericMessageProps, "message">) => {
  if (!message) return null;

  return <Text weight="bolder">{message}</Text>;
};

const CallToDragAction = ({ flip, message, onClick }: GenericMessageProps) => (
  <>
    <Flex
      gap="xl"
      align="center"
      width={flip === "vertical" ? "auto" : "full"}
      direction={flip === "vertical" ? "column" : "row"}
    >
      <Icon name="image" size="2xl" />
      <Flex
        gap="sm"
        align={flip === "vertical" ? "center" : "flex-start"}
        direction="column"
      >
        <PlainMessage message={message} />
        <Text size="sm">
          PNG, JPG, PDF up to {window.MAX_ATTACHABLE_FILE_SIZE_MB}MB
        </Text>
      </Flex>
    </Flex>
    <Button data-skip-focusable="" variant="solid" onClick={onClick}>
      Upload file
    </Button>
  </>
);

const Warning = ({ flip, message }: Omit<GenericMessageProps, "onClick">) => {
  if (!message) return null;

  return (
    <Flex
      gap="xl"
      align="center"
      width={flip === "vertical" ? "auto" : "full"}
      direction={flip === "vertical" ? "column" : "row"}
    >
      <Icon name="exclamation-triangle" size="2xl" />
      <Flex
        gap="sm"
        align={flip === "vertical" ? "center" : "flex-start"}
        direction="column"
      >
        <PlainMessage message={message} />
      </Flex>
    </Flex>
  );
};

const InProgress = ({
  flip,
  message,
}: Omit<GenericMessageProps, "onClick">) => (
  <>
    <Flex
      gap="xl"
      align="center"
      width={flip === "vertical" ? "auto" : "full"}
      direction={flip === "vertical" ? "column" : "row"}
    >
      <Loader size="2xl" />
      <Flex
        gap="sm"
        align={flip === "vertical" ? "center" : "flex-start"}
        direction="column"
      >
        <PlainMessage message={message} />
        <Text size="sm">A few seconds left</Text>
      </Flex>
    </Flex>
    <Button variant="solid" disabled>
      Processing
    </Button>
  </>
);

const DraggingAction = ({
  flip,
  message,
}: Omit<GenericMessageProps, "onClick">) => (
  <Flex
    gap="xl"
    align="center"
    width={flip === "vertical" ? "auto" : "full"}
    direction={flip === "vertical" ? "column" : "row"}
  >
    <Icon name="image" size="2xl" />
    <PlainMessage message={message} />
  </Flex>
);

const Disabled = () => (
  <PlainMessage message="You have no permissions to upload a file" />
);

const DragActions: {
  [State in Keys]: (p: GenericMessageProps) => JSX.Element | null;
} = {
  IDLE: CallToDragAction,
  DRAGGING: DraggingAction,
  PENDING: InProgress,
  WARNING: Warning,
  DISABLED: Disabled,
};

export const CurrentDragAction = ({
  flip,
  state,
  message,
  onClick,
  disabled,
}: DragActionProps) => {
  const Action = DragActions[disabled ? "DISABLED" : state];

  if (!Action) {
    return <PlainMessage message={`Something went wrong: ${state}`} />;
  }

  return <Action flip={flip} message={message} onClick={onClick} />;
};
