import { createSelector } from "@reduxjs/toolkit";
import { CollectionQueries } from "@store/expenses/types";
import { RootState } from "@store/types";
import { parseRefinementIdFromUrl } from "@utils/parse-refinement-id-from-url";
import { sum } from "@utils/sum";
import { sumBy } from "@utils/sumBy";

import { expenseSelector } from "./selectors";

export const selectExpenseQuery = createSelector(
  (state: RootState) => state.expenses,
  (expenses) => expenses.query
);

export const selectExpenseQueryResults = createSelector(
  [
    (state: RootState) => selectExpenseQuery(state),
    (state: RootState, status: CollectionQueries) => status,
  ],
  (query, status) => query[status]
);

export const selectCommonFilters = createSelector(
  selectExpenseQuery,
  (query) => query.filters
);

export const selectExpenseQueryFilters = createSelector(
  [
    selectExpenseQuery,
    selectCommonFilters,
    (state: RootState, status: CollectionQueries) => status,
  ],
  (query, commonFilters, status) => ({
    ...commonFilters,
    ...query[status].filters,
  })
);

export const selectExpenseQueryController = createSelector(
  [selectExpenseQuery, (state: RootState, status: CollectionQueries) => status],
  (query, status) => query[status].controller
);

export const selectExpenseQueryStatus = createSelector(
  [selectExpenseQuery, (state: RootState, status: CollectionQueries) => status],
  (query, status) => query[status].response
);

export const selectExpenseQueryStateInitialized = createSelector(
  [selectExpenseQuery, selectExpenseQueryStatus],
  (query, status) => status !== null
);

export const selectExpenseTableInitialized = createSelector(
  [
    (state) => selectExpenseQueryStateInitialized(state, "ALL"),
    (state) => selectExpenseQueryStateInitialized(state, "DRAFT"),
    (state) => selectExpenseQueryStateInitialized(state, "FOR_REVIEW"),
  ],
  (all, draft, forReview) => all || draft || forReview
);

export const selectFilterByStatus = createSelector(
  [
    (state) => selectExpenseQueryFilters(state, "DRAFT"),
    (state) => selectExpenseQueryFilters(state, "FOR_REVIEW"),
    (state) => selectExpenseQueryFilters(state, "ALL"),
  ],
  (draft, forReview, all) => [draft, forReview, all]
);

export const selectExpenseVendorId = createSelector(
  expenseSelector,
  (expense) => parseRefinementIdFromUrl(expense.vendor?.url)
);

export const selectExpenseDocNumber = createSelector(
  expenseSelector,
  (expense) => expense.docNumber
);

export const selectExpenseSubTotal = createSelector(
  expenseSelector,
  (expense) => sumBy(Object.values(expense.lines || {}), "amount")
);

export const selectExpenseSalesTax = createSelector(
  expenseSelector,
  (expense) => expense.tax?.value || 0
);

export const selectExpenseTotalAmount = createSelector(
  [selectExpenseSubTotal, selectExpenseSalesTax],
  (subTotal, salesTax) => sum(salesTax, subTotal)
);

export const selectExpenseCalculations = createSelector(
  [selectExpenseSubTotal, selectExpenseSalesTax, selectExpenseTotalAmount],
  (subTotal, salesTax, total) => ({ subTotal, salesTax, total })
);
