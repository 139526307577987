import React, { useEffect, useState } from "react";
import { Dialog, DialogStep } from "@adaptive/design-system";
import {
  useEvent,
  type UseMultiStepDialogReturn,
} from "@adaptive/design-system/hooks";

import { Account } from "../../../../shared/api/accounts";
import { AccountCreateForm } from "../account-create-form";
import { Step } from "../types";

import { AccountSetForm } from "./account-set-form";

type Props = {
  dialog: UseMultiStepDialogReturn<Step>;
  putObject: (object: any) => void;
  selectHeader: string;
  isCreditCard?: boolean;
  isBankAccount?: boolean;
  selectedAccount?: Account;
};

export const AccountSelectDialog = ({
  putObject,
  selectHeader,
  dialog,
  isCreditCard,
  isBankAccount,
  selectedAccount,
}: Props) => {
  const [accountQuery, setAccountQuery] = useState<string>("");
  const [createdAccount, setCreatedAccount] = useState<Account | null>(null);

  const onAccountQueryChange = useEvent((query: string) => {
    setAccountQuery(query);
  });

  const onCreatedAccountChange = useEvent((account: Account) => {
    setCreatedAccount(account);
  });

  useEffect(() => {
    setCreatedAccount(null);
    setAccountQuery(selectedAccount?.display_name || "");
  }, [selectedAccount]);

  return (
    <Dialog
      step={dialog.step}
      show={dialog.isVisible}
      variant="multi-step-dialog"
      onClose={dialog.hide}
    >
      <DialogStep name="set-account">
        <AccountSetForm
          dialog={dialog}
          putObject={putObject}
          selectHeader={selectHeader}
          selectedAccount={createdAccount || selectedAccount}
          onAccountQueryChange={onAccountQueryChange}
        />
      </DialogStep>
      <DialogStep name="create-account" onBack={dialog.back}>
        <AccountCreateForm
          dialog={dialog}
          isCreditCard={isCreditCard}
          isBankAccount={isBankAccount}
          accountQuery={accountQuery}
          onCreatedAccountChange={onCreatedAccountChange}
        />
      </DialogStep>
    </Dialog>
  );
};
