import { currencySchema } from "@src/shared/utils/schema";
import { z } from "zod";

type Currency = z.infer<typeof currencySchema> | null | undefined;

type Row = {
  [key: string]: any;
  builderAmount?: Currency;
  changeAmount?: Currency;
  builderRevisedAmount?: Currency;
  ownersRevisedAmount?: Currency;
};

export const isNumber = (value: unknown): value is number =>
  typeof value === "number" && !isNaN(value);

export const getIsNullOrUndefined = (value: any): boolean =>
  value === null || value === undefined;

export const getPreferredCostBudgetAmount = (row: Row): number => {
  if (!row) return 0;

  const { builderAmount, builderRevisedAmount } = row;

  if (!getIsNullOrUndefined(builderRevisedAmount)) {
    return builderRevisedAmount as number;
  }

  if (!getIsNullOrUndefined(builderAmount)) {
    return builderAmount as number;
  }

  return 0;
};

export const getPreferredRevenueBudgetAmount = (row: Row): number => {
  if (!row) return 0;

  const {
    builderAmount,
    builderRevisedAmount,
    ownersAmount,
    ownersRevisedAmount,
  } = row;

  if (
    !getIsNullOrUndefined(ownersRevisedAmount) &&
    isNumber(ownersRevisedAmount)
  ) {
    return ownersRevisedAmount;
  }

  if (!getIsNullOrUndefined(ownersAmount) && isNumber(ownersAmount)) {
    return ownersAmount;
  }

  if (
    !getIsNullOrUndefined(builderRevisedAmount) &&
    isNumber(builderRevisedAmount)
  ) {
    return builderRevisedAmount;
  }

  if (!getIsNullOrUndefined(builderAmount) && isNumber(builderAmount)) {
    return builderAmount;
  }

  return 0;
};
