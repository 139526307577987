import { api } from "@store/api-simplified";
import { handleResponse } from "@utils/api";
import {
  transformKeysToCamelCase,
  transformKeysToSnakeCase,
} from "@utils/schema/converters";

import {
  getDynamicVariablesListResponseSchema,
  getLienWaiverTemplateResponseSchema,
  getLienWaiverTemplatesResponseSchema,
  getLienWaiverTypesResponseSchema,
  getSignatureFieldsResponseSchema,
  renderLienWaiverPDFResponseSchema,
  saveLienWaiverTemplateResponseSchema,
} from "./schemas";
import type {
  DeleteLienWaiverTemplatePayload,
  DeleteLienWaiverTemplateResponse,
  GetDynamicVariablesListResponse,
  GetLienWaiverTemplatePayload,
  GetLienWaiverTemplateResponse,
  GetLienWaiverTemplatesPayload,
  GetLienWaiverTemplatesResponse,
  GetLienWaiverTypesListResponse,
  GetSignatureFieldsResponse,
  RenderBillLienWaiverTemplatePDFPayload,
  RenderLienWaiverTemplatePDFPayload,
  RenderLienWaiverTemplatePDFResponse,
  RequestLienWaiverPayloadSchema,
  SaveLienWaiverTemplatePayload,
  SaveLienWaiverTemplateResponse,
  UpdateLienWaiverRequestPayloadSchema,
} from "./types";

const TAG = "LienWaiverTemplates";

const enhancedApi = api.enhanceEndpoints({
  addTagTypes: [TAG],
});

export const lienWaiverTemplatesApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getDynamicVariables: builder.query<GetDynamicVariablesListResponse, void>({
      query: () => {
        return {
          url: "dynamicvariables/",
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getDynamicVariablesListResponseSchema
            );
          },
        };
      },
    }),
    getLienWaiverTypesList: builder.query<GetLienWaiverTypesListResponse, void>(
      {
        query: () => {
          return {
            url: "lienwaivertypes/",
            responseHandler: async (response) => {
              const data = await response.json();

              return handleResponse(
                transformKeysToCamelCase(data),
                getLienWaiverTypesResponseSchema
              );
            },
          };
        },
      }
    ),
    getSignatureFields: builder.query<GetSignatureFieldsResponse, void>({
      query: () => {
        return {
          url: "signaturefields/",
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              getSignatureFieldsResponseSchema
            );
          },
        };
      },
    }),
    getLienWaiverTemplates: builder.query<
      GetLienWaiverTemplatesResponse,
      GetLienWaiverTemplatesPayload
    >({
      query: (args) => {
        const { withStatus } = args || {};

        return {
          url: `lienwaivertemplates/${withStatus ? "?with_status=true" : ""}`,
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              data.map(transformKeysToCamelCase),
              getLienWaiverTemplatesResponseSchema
            );
          },
        };
      },
      providesTags: (response) =>
        response?.length
          ? [
              ...response.map(
                ({ id }) =>
                  ({
                    type: TAG,
                    id,
                  }) as const
              ),
            ]
          : [{ type: TAG, id: "LIST" }],
    }),
    getLienWaiverTemplate: builder.query<
      GetLienWaiverTemplateResponse,
      GetLienWaiverTemplatePayload
    >({
      query: ({ id }) => {
        return {
          url: `lienwaivertemplates/${id}/`,
          responseHandler: async (response) => {
            const data = await response.json();

            if (response.status === 404) return response;

            return handleResponse(
              transformKeysToCamelCase(data),
              getLienWaiverTemplateResponseSchema
            );
          },
        };
      },
      providesTags: (response) =>
        response && "id" in response && response?.id
          ? [{ type: TAG, id: response.id }]
          : [{ type: TAG, id: "DETAIL" }],
    }),
    saveLienWaiverTemplate: builder.mutation<
      SaveLienWaiverTemplateResponse,
      SaveLienWaiverTemplatePayload
    >({
      query: (payload) => {
        const isNewTemplate = !("id" in payload) || !payload.id;
        if (isNewTemplate) {
          delete payload.id;
        }

        return {
          url: `lienwaivertemplates/${isNewTemplate ? "" : `${payload.id}/`}`,
          method: isNewTemplate ? "POST" : "PATCH",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              saveLienWaiverTemplateResponseSchema
            );
          },
        };
      },
      invalidatesTags: (_, error) => (error ? [] : [TAG]),
    }),
    deleteLienWaiverTemplate: builder.mutation<
      DeleteLienWaiverTemplateResponse,
      DeleteLienWaiverTemplatePayload
    >({
      query: (payload) => {
        return {
          url: `lienwaivertemplates/${payload.id}/`,
          method: "DELETE",
          body: transformKeysToSnakeCase(payload),
        };
      },
      invalidatesTags: (_, error) => (error ? [] : [TAG]),
    }),
    renderLienWaiverPdf: builder.mutation<
      RenderLienWaiverTemplatePDFResponse,
      RenderLienWaiverTemplatePDFPayload
    >({
      query: (payload) => {
        return {
          url: "vendor-comms/lien-waiver-to-pdf/",
          method: "POST",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              renderLienWaiverPDFResponseSchema
            );
          },
        };
      },
    }),
    renderBillLienWaiverPdf: builder.mutation<
      RenderLienWaiverTemplatePDFResponse,
      RenderBillLienWaiverTemplatePDFPayload
    >({
      query: (payload) => {
        return {
          url: "vendor-comms/lien-waiver-bill-to-pdf/",
          method: "POST",
          body: transformKeysToSnakeCase(payload),
          responseHandler: async (response) => {
            const data = await response.json();

            return handleResponse(
              transformKeysToCamelCase(data),
              renderLienWaiverPDFResponseSchema
            );
          },
        };
      },
    }),
    requestLienWaiver: builder.mutation<void, RequestLienWaiverPayloadSchema>({
      query: (payload) => {
        const { files, ...restPayload } = payload;
        const body = new FormData();
        Object.entries(transformKeysToSnakeCase(restPayload)).forEach(
          ([key, value]) => {
            body.append(key, String(value));
          }
        );
        files?.forEach((file) => {
          body.append("files", file);
        });

        return {
          url: "lienwaiverrequest/",
          method: "POST",
          headers: { "Content-Type": undefined },
          body,
        };
      },
    }),
    updateLienWaiverRequest: builder.mutation<
      void,
      UpdateLienWaiverRequestPayloadSchema
    >({
      query: (payload) => {
        const { id, pdf, ...rest } = payload;

        const body = new FormData();
        Object.entries(transformKeysToSnakeCase(rest)).forEach(
          ([key, value]) => {
            body.append(key, String(value));
          }
        );
        if (pdf) {
          body.append("pdf", pdf);
        }

        return {
          url: `lienwaiverrequest/${id}/`,
          headers: { "Content-Type": undefined },
          method: "PUT",
          body,
        };
      },
    }),
    deleteLienWaiverRequest: builder.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `lienwaiverrequest/${id}/`,
          method: "DELETE",
        };
      },
    }),
    sendLienWaiverReminder: builder.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `lienwaiverrequest/${id}/send-reminder/`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useDeleteLienWaiverRequestMutation,
  useDeleteLienWaiverTemplateMutation,
  useGetDynamicVariablesQuery,
  useGetLienWaiverTemplateQuery,
  useGetLienWaiverTemplatesQuery,
  useGetLienWaiverTypesListQuery,
  useGetSignatureFieldsQuery,
  useRenderBillLienWaiverPdfMutation,
  useRenderLienWaiverPdfMutation,
  useRequestLienWaiverMutation,
  useSaveLienWaiverTemplateMutation,
  useSendLienWaiverReminderMutation,
  useUpdateLienWaiverRequestMutation,
} = lienWaiverTemplatesApi;
