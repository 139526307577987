import React, { useMemo } from "react";
import {
  Alert,
  AlertContent,
  Button,
  Flex,
  Tooltip,
} from "@adaptive/design-system";
import { useEvent, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { formatDate } from "@adaptive/design-system/utils";
import type { VendorDocRequestType } from "@api/vendors/types";
import { isListEmpty } from "@shared/utils/usefulFunctions";
import { useAppSelector } from "@store/hooks";
import { useVendorAction, useVendorInfo } from "@store/vendors";
import { vendorSelector } from "@store/vendors/selectors";

import {
  DocumentSelectDialog,
  type DocumentSelectDialogProps,
  type DocumentSelectDialogStep,
  formatDocumentTypes,
  STRINGS,
} from ".";

export const RequestVendorDocumentButton = () => {
  const { fetchById } = useVendorAction();

  const { info: vendor } = useAppSelector(vendorSelector);

  const { canManageNonPaymentInfo } = useVendorInfo();

  const requestDocumentsDialog = useMultiStepDialog<DocumentSelectDialogStep>({
    lazy: true,
    initialStep: "select-documents",
  });

  const documentSelectInitialValues = useMemo<
    DocumentSelectDialogProps["initialValues"]
  >(
    () => ({
      vendors: [
        { id: vendor.id, displayName: vendor.displayName, email: vendor.email },
      ],
    }),
    [vendor.id, vendor.displayName, vendor.email]
  );

  const onSubmitRequestDocuments = useEvent(() => fetchById(vendor.id));

  return (
    <>
      <Tooltip
        message={
          !canManageNonPaymentInfo ? "You don't have permission to do this" : ""
        }
        placement="left"
      >
        <Button
          size="sm"
          onClick={requestDocumentsDialog.show}
          disabled={!canManageNonPaymentInfo || !vendor.id}
        >
          {STRINGS.REQUEST_BUTTON_LABEL}
        </Button>
      </Tooltip>

      {requestDocumentsDialog.isRendered && (
        <DocumentSelectDialog
          dialog={requestDocumentsDialog}
          multiple={false}
          onSubmit={onSubmitRequestDocuments}
          initialValues={documentSelectInitialValues}
        />
      )}
    </>
  );
};

export const RequestVendorDocumentAlert = () => {
  const { info: vendor } = useAppSelector(vendorSelector);

  if (isListEmpty(vendor.pendingDocRequests)) return null;

  return (
    <Flex direction="column" gap="xl">
      {vendor.pendingDocRequests.map(
        (request: VendorDocRequestType) =>
          !isListEmpty(request.documentTypes) && (
            <Flex direction="column" key={request.id}>
              <Alert variant="info">
                <AlertContent>
                  You sent the vendor a request to provide their{" "}
                  {formatDocumentTypes(request.documentTypes)} on{" "}
                  {formatDate(request.createdAt, "PPPp")}
                </AlertContent>
              </Alert>
            </Flex>
          )
      )}
    </Flex>
  );
};
