import type { ComponentPropsWithoutRef, ReactNode } from "react";
import type { Options, Placement, Type } from "@zag-js/toast";
import mitt from "mitt";

import { Text } from "../text";

export type ToastConfig = {
  onClose?: () => void;
  maxWidth?: string | number;
  duration?: number;
  truncate?: ComponentPropsWithoutRef<typeof Text>["truncate"];
};

export type InternalToastConfig = Omit<ToastConfig, "duration"> & {
  message: ReactNode;
};

export type ToastEmitterConfig = Options<string> & {
  render: () => InternalToastConfig;
  duration: number;
  placement: Placement;
  removeDelay: number;
};

export const emitter = mitt<{ create: ToastEmitterConfig; dismiss: string }>();

const DURATION = 5000;

const generateId = () => String(Date.now() + Math.floor(Math.random() * 100));

const createToast =
  (type: Type) =>
  (
    message: ReactNode,
    { duration = DURATION, onClose, ...config }: ToastConfig = {}
  ) => {
    const id = generateId();

    emitter.emit("create", {
      id,
      type,
      render: () => ({ ...config, message }),
      onStatusChange({ status }) {
        if (status === "dismissing") {
          onClose?.();
        }
      },
      duration,
      placement: "top-end",
      removeDelay: 1000,
    });

    return () => emitter.emit("dismiss", id);
  };

export const toast = {
  info: createToast("info"),
  error: createToast("error"),
  warning: createToast("custom"),
  success: createToast("success"),
};
