import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "@adaptive/design-system";
import {
  getVendorPublicRequest,
  type VendorPublicRequestBaseParams,
  type VendorPublicRequestParams,
} from "@api/vendor-requests";

import { RequestError, RequestFormExpired } from "./common";
import { VENDOR_REQUEST_TYPES } from "./constants";
import { type VendorRequestDataProps, type VendorRequestProps } from "./types";

export const VendorRequest = ({
  type,
  form: Form,
  vendorRequestParams,
}: VendorRequestProps) => {
  const params = useParams() as VendorPublicRequestBaseParams;

  const queryParams = useMemo<VendorPublicRequestParams>(() => {
    if (vendorRequestParams && window.PO_SENDING_CHANGES_ENABLED) {
      return vendorRequestParams;
    }

    return { ...params, type };
  }, [params, type, vendorRequestParams]);

  const [vendorRequestData, setVendorRequestData] =
    useState<VendorRequestDataProps>();

  const [hasErrors, setHasErrors] = useState(false);

  const {
    company_name: companyName,
    vendor,
    vendor_request: vendorRequest,
  } = vendorRequestData || {};

  const { is_editable: isEditable } = vendorRequest || {};

  const getVendorRequestData = useCallback(() => {
    return getVendorPublicRequest(queryParams)
      .then((data) => setVendorRequestData(data))
      .catch(() => setHasErrors(true));
  }, [queryParams]);

  useEffect(() => {
    if (!vendor && queryParams.vendorUid) {
      getVendorRequestData();
    }
  }, [vendor, queryParams.vendorUid, getVendorRequestData]);

  return vendor && vendorRequest && companyName ? (
    isEditable ||
    // the new submitted/expired form messages are used only for PO_SIGNATURE type
    (window.PO_SENDING_CHANGES_ENABLED &&
      type === VENDOR_REQUEST_TYPES["PO_SIGNATURE"]) ? (
      <Form
        vendor={vendor}
        companyName={companyName}
        queryParams={queryParams}
        request={vendorRequest}
      />
    ) : (
      <RequestFormExpired companyName={companyName} type={type} />
    )
  ) : hasErrors ? (
    <RequestError type={type} />
  ) : (
    <Loader position="fixed" />
  );
};
