import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useEvent } from "@adaptive/design-system/hooks";

import { HumanReadable } from "../../api/expenses";
import { useAppDispatch } from "../hooks";
import { RootState } from "../types";

import {
  AttachableLifeCycleHooks,
  AttachableOptions,
  submitAttachable,
} from "./slice";

interface BaseAttachableOptions {
  id?: string | number | undefined;
  url?: string | undefined;
  humanReadableType: HumanReadable | "document";
}

export const getOptionsFor = <T extends BaseAttachableOptions>({
  url,
  humanReadableType,
}: T): AttachableOptions => {
  const document = humanReadableType.toLowerCase() as Lowercase<HumanReadable>;

  if (!url) {
    return {
      document,
      save: true,
    };
  }

  return {
    parent: url,
    save: false,
  };
};

export const useAttachmentActions = () => {
  const dispatch = useAppDispatch();

  const [requestIds, setRequestIds] = useState<string[]>([]);

  const onRequestId = useEvent((requestId: string) => {
    if (!requestIds.includes(requestId)) {
      setRequestIds([...requestIds, requestId]);
    }
  });

  const [requests, setRequests] = useState<Record<string, any>>({});
  const statuses = useSelector<RootState, Record<string, any>>(
    (state) => state.attachable.statuses
  );

  useEffect(() => {
    const requestsById = requestIds.reduce((filteredStatuses, id) => {
      const status = statuses[id];
      const update = status ? { [id]: status } : {};

      return {
        ...statuses,
        ...update,
      };
    }, {});

    setRequests(requestsById);
  }, [statuses, requestIds]);

  const submitAttachables = useCallback(
    async (
      files: File[],
      attachableOptions: AttachableOptions,
      onComplete: () => void,
      onItemComplete: AttachableLifeCycleHooks["onItemComplete"],
      onItemStarted: AttachableLifeCycleHooks["onItemStarted"],
      onItemUpload: AttachableLifeCycleHooks["onItemUpload"]
    ) => {
      await Promise.all(
        files.map(
          async (file) =>
            await dispatch(
              submitAttachable({
                file,
                attachableOptions,
                onItemComplete,
                onItemStarted,
                onRequestId,
                onItemUpload,
              })
            )
        )
      ).then(() => onComplete());
    },
    [dispatch, onRequestId]
  );

  return {
    requests,
    submitAttachables,
  };
};
