import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  forwardRef,
  type ReactNode,
} from "react";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { omit } from "../../utils/omit";
import { suffixify } from "../../utils/suffixify";
import { Flex } from "../flex";
import { Icon } from "../icon";
import { Text, type TextProps } from "../text";
import { Tooltip } from "../tooltip";

import styles from "./label-value.module.css";

type LabelProps = {
  size?: TextProps["size"];
  color?: TextProps["color"];
  weight?: TextProps["weight"];
  children: ReactNode;
};

type ValueProps = {
  size?: TextProps["size"];
  color?: TextProps["color"];
  weight?: TextProps["weight"];
  children?: ReactNode;
};

type Props = Omit<ComponentPropsWithoutRef<"p">, "color"> & {
  label: ReactNode | LabelProps;
  value?: ReactNode | ValueProps;
  compact?: boolean;
  hintMessage?: ReactNode;
  "data-testid"?: string;
};

export const LabelValue = forwardRef<ComponentRef<typeof Flex>, Props>(
  (
    { label, value, compact, hintMessage, "data-testid": testId, ...props },
    ref
  ) => {
    const labelProps = useDeepMemo(() => {
      let props: LabelProps = {
        size: "sm",
        color: "neutral-800",
        weight: "regular",
        children: null,
      };

      if (label) {
        props =
          typeof label === "object"
            ? { ...props, ...label }
            : { ...props, children: label };
      }

      return props;
    }, [label]);

    const valueProps = useDeepMemo(() => {
      let props: ValueProps = {
        size: compact ? "sm" : "md",
        color: "neutral-800",
        weight: compact ? "bold" : "bolder",
        children: "-",
      };

      if (value) {
        props =
          typeof value === "object"
            ? { ...props, ...value }
            : { ...props, children: value };
      }

      return props;
    }, [compact, value]);

    return (
      <Flex
        ref={ref}
        gap={compact ? "sm" : "none"}
        direction={compact ? "row" : "column"}
        data-testid={testId}
        {...props}
      >
        <Text
          as="span"
          role="term"
          truncate
          data-testid={suffixify(testId, "label")}
          {...omit(labelProps, ["children"])}
        >
          {labelProps.children}
          {hintMessage && (
            <Tooltip
              as={Icon}
              size="sm"
              name="info-circle"
              message={hintMessage}
              className={styles["hint-message"]}
              data-testid={suffixify(testId, "hint")}
            />
          )}
        </Text>
        <Text
          as="span"
          role="definition"
          truncate
          data-testid={suffixify(testId, "value")}
          {...valueProps}
        />
      </Flex>
    );
  }
);

LabelValue.displayName = "LabelValue";
