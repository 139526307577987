import { createSlice, Selector } from "@reduxjs/toolkit";

import { RootState } from "../types";

export type Role = {
  id?: string;
  name: string;
  description: string;
  url?: string;
  client?: string;
  base?: boolean;
  permissions?: number[];
};
export type Permission = {
  id: number;
  name: string;
};

export type PermissionCategory = {
  id: number;
  name: string;
  permissions: Permission[];
};
export type RoleState = {
  roles: Role[];
  permissionCategories: PermissionCategory[];
};

const initialState: RoleState = {
  roles: [],
  permissionCategories: [],
};

export const roleSelector: Selector<RootState, RoleState> = (state) =>
  state.role;

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    addRole: (state, action) => {
      state.roles = [...state.roles, action.payload];
    },
    updateRoles: (state, action) => {
      state.roles = action.payload;
    },
    deleteRole: (state, action) => {
      state.roles = state.roles.filter((role) => role.id !== action.payload);
    },
    updatePermissionsCategories: (state, action) => {
      state.permissionCategories = action.payload;
    },
    updateRole: (state, action) => {
      state.roles = state.roles.map((role) => {
        if (role.id === action.payload.id) {
          role.name = action.payload.name;
          role.description = action.payload.description;
          role.permissions = action.payload.permissions;
        }
        return role;
      });
    },
  },
});

export const {
  updateRoles,
  updateRole,
  updatePermissionsCategories,
  deleteRole,
  addRole,
} = roleSlice.actions;

export const { reducer } = roleSlice;

export { loadRoles } from "./thunks";
