import React, { useMemo, useState } from "react";
import {
  Button,
  Flex,
  Loader,
  Text,
  TextField,
  Timeline,
} from "@adaptive/design-system";
import { useForm } from "@adaptive/design-system/hooks";
import {
  formatCurrency,
  formatDate,
  parseDate,
} from "@adaptive/design-system/utils";
import { handleErrors } from "@api/handle-errors";
import { postVendorPublicPOSignatureRequest } from "@api/vendor-requests";
import { ExternalLayout } from "@components/external-layout";
import { MainContent } from "@components/main";
import { z } from "zod";

import { RequestFormExpired, RequestFormSubmitted } from "./common";
import { STRINGS, VENDOR_REQUEST_TYPES } from "./constants";
import type { FormProps, VendorPoSignatureRequestType } from "./types";
import { VendorRequest } from "./vendor-request";

const schema = z.object({
  signature: z
    .string()
    .min(3, STRINGS.INVALID_SIGNATURE)
    .max(100, STRINGS.INVALID_SIGNATURE),
});

type Fields = z.infer<typeof schema>;

export const VendorPoSignatureRequest = () => {
  return (
    <VendorRequest
      type={VENDOR_REQUEST_TYPES["PO_SIGNATURE"]}
      form={POSignatureRequestForm}
    />
  );
};

export const POSignatureRequestForm = ({
  companyName,
  queryParams,
  request,
}: FormProps) => {
  const [isFormSubmitted, setFormSubmitted] = useState<boolean>(false);
  const initialValues: Fields = useMemo(() => ({ signature: "" }), []);

  const type = VENDOR_REQUEST_TYPES["PO_SIGNATURE"];

  const { purchase_order, file_export, created_by, created_at, message } =
    request as VendorPoSignatureRequestType;

  const form = useForm<Fields>({
    schema,
    async onSubmit(values) {
      try {
        await postVendorPublicPOSignatureRequest(queryParams, values);
        setFormSubmitted(true);
      } catch (error) {
        handleErrors(error);
      }
    },
    initialValues,
  });

  const data = useMemo(() => {
    if (!message) return [];

    return [
      {
        title: created_by.full_name,
        subtitle: formatDate(
          parseDate(created_at ?? "", ["MM/dd/yyyy h:mmaaa", "iso"]),
          "MM/dd/yyyy h:mmaaa"
        ),
        children: message,
      },
    ];
  }, [created_at, created_by.full_name, message]);

  const poCreatedAt = `${formatDate(
    parseDate(purchase_order.created_at ?? "", ["yyyy-MM-dd", "iso"])
  )}`;

  return window.PO_SENDING_CHANGES_ENABLED ? (
    <>
      {form.isSubmitting && <Loader position="fixed" />}
      {request.is_completed || isFormSubmitted ? (
        <RequestFormSubmitted
          message={STRINGS.PO_SIGNATURE_SUBMITTED_MESSAGE}
          type={type}
        />
      ) : request.is_expired ? (
        <RequestFormExpired companyName={companyName} type={type} />
      ) : (
        <>
          <Flex width="full" direction="column">
            <Flex
              direction="column"
              as="form"
              {...form.props}
              width="430px"
              minHeight="150px"
            >
              <TextField
                label={STRINGS.LABEL_SIGNATURE}
                placeholder="Enter your full name"
                {...form.register("signature")}
              />
              <Flex>
                <Button
                  size="lg"
                  data-testid="add-signature"
                  form={form.id}
                  type="submit"
                  disabled={form.isSubmitting || !form.isValid}
                >
                  {STRINGS.PO_SIGNATURE_FORM_BUTTON_LABEL}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </>
  ) : (
    <ExternalLayout attachable={file_export}>
      {isFormSubmitted ? (
        <RequestFormSubmitted
          message={STRINGS.PO_SIGNATURE_SUBMITTED_MESSAGE}
        />
      ) : (
        <>
          {form.isSubmitting && <Loader position="fixed" />}
          <MainContent as="div">
            <Flex gap="2xl" direction="column" shrink={false}>
              <Flex direction="column">
                <Text as="h1" size="xl" weight="bold">
                  Purchase order from {companyName}
                </Text>
                <Text>
                  {formatCurrency(purchase_order.total_amount || 0, {
                    currencySign: true,
                    allowNegative: true,
                  })}
                  {"  "}({poCreatedAt})
                </Text>
              </Flex>

              {data.length > 0 && <Timeline data={data} />}

              <Flex direction="column" as="form" {...form.props}>
                <TextField
                  label={STRINGS.LABEL_SIGNATURE}
                  placeholder="Enter your full name"
                  color="neutral-600"
                  autoFocus
                  {...form.register("signature")}
                />
                <Flex>
                  <Button
                    size="lg"
                    data-testid="add-signature"
                    form={form.id}
                    type="submit"
                    disabled={form.isSubmitting || !form.isValid}
                  >
                    {STRINGS.PO_SIGNATURE_FORM_BUTTON_LABEL}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </MainContent>
        </>
      )}
    </ExternalLayout>
  );
};
