import {
  api,
  type BudgetLineItems,
  type UpdateBuilderBudgetLockedStatusPayload,
} from "@api/jobs";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  budgetLinesApi,
  type ModifyBudgetLineSchemaPayload,
} from "@src/shared/api/budgets";

import { setJobBudgetLockedStatus } from "./slice";

export const updateBudgedLockedStatus = createAsyncThunk(
  "jobs/updateBudgedLockedStatus",
  async (
    payload: UpdateBuilderBudgetLockedStatusPayload,
    { rejectWithValue, dispatch }
  ) => {
    try {
      await api.updateBudgedLockedStatus(payload);
      dispatch(setJobBudgetLockedStatus(payload.isBuilderBudgetLocked));
    } catch (e) {
      return rejectWithValue("Failed updating builder's budget locked status");
    }
  }
);

export const updateBudgetLineCurrencyFields = ({
  budgetLine,
  path,
  customerId,
}: {
  budgetLine: ModifyBudgetLineSchemaPayload;
  path: string;
  customerId: string;
}) => {
  const index = Number(path);
  return budgetLinesApi.util.updateQueryData(
    "getBudgetLines",
    { customerId: Number(customerId) },
    (draft: BudgetLineItems[]) => {
      draft[index].builderAmount = budgetLine.builderAmount || 0;
      draft[index].builderRevisedAmount = budgetLine.builderRevisedAmount || 0;
      draft[index].ownersAmount = budgetLine.ownersAmount || 0;
    },
    true
  );
};
