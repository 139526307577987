import { api, handleResponse } from "../../utils/api";

import { expenseItemPutPayload } from "./request/schema";
import {
  expenseItemSchema,
  expenseMutateResponse,
  expensesResponseSchema,
  expenseUploadAttachableResponse,
} from "./response/schema";
import type {
  Expense,
  ExpenseFilters,
  ExpensesResponse,
  Filterable,
  Identifiable,
  OutgoingExpensePayload,
  RealmUrl,
} from "./types";

const getUrlBuilder = () => {
  const getBaseUrl = () => {
    const url = new URL(window.location.origin);
    url.pathname = `/api/expenses/`;
    return url;
  };

  return {
    withFilters(filters?: ExpenseFilters, action?: string) {
      const url = new URL(this.forCollection());
      if (action) {
        url.pathname += action + "/";
      }
      Object.entries(filters || {})
        .filter(([, val]) => val === false || !!val)
        .forEach(([key, value]) => {
          if (value instanceof Set) {
            value.forEach((item) => {
              url.searchParams.append(key, item.toString());
            });
          } else {
            url.searchParams.set(key, value.toString());
          }
        });
      return url.toString();
    },
    forCollection() {
      const url = getBaseUrl();
      return url.toString();
    },
    forItem(item: Identifiable, action?: string) {
      const url = getBaseUrl();
      url.pathname += item.id + "/";
      if (action) {
        url.pathname += action + "/";
      }
      return url.toString();
    },
  };
};

export const urlBuilder = getUrlBuilder();

type ExpensePayload = Expense & { realm: RealmUrl };

export const deleteExpense = async (opts: Identifiable) => {
  return await api.delete<Expense>(urlBuilder.forItem(opts)).then((resp) => {
    if (resp.status !== 204) {
      throw new Error("Unexpected response: " + resp.status);
    }
    return resp;
  });
};

export const convert = async (opts: Identifiable) => {
  const resp = await api.put<Identifiable>(urlBuilder.forItem(opts, "convert"));
  return resp.data;
};

export const get = async (opts: Identifiable) => {
  return await api
    .get<Expense>(urlBuilder.forItem(opts))
    .then((resp) => handleResponse(resp.data, expenseItemSchema));
};

export const query = async ({
  signal,
  filters,
}: Filterable<ExpenseFilters>) => {
  return await api
    .get<ExpensesResponse>(urlBuilder.withFilters(filters), { signal })
    .then((resp) => handleResponse(resp.data, expensesResponseSchema));
};

export const put = async (
  expense: Pick<ExpensePayload, "id"> & Partial<Omit<ExpensePayload, "id">>
) => {
  const payload = expenseItemPutPayload.optional().parse(expense);

  return await api
    .put<OutgoingExpensePayload>(urlBuilder.forItem(expense), payload)
    .then((resp) => handleResponse(resp.data, expenseMutateResponse));
};

export const exportExpenses = (params: URLSearchParams) =>
  api
    .get(`${urlBuilder.forCollection()}export/`, { params })
    .then(({ data }) => (data?.id ? (data.id as string) : undefined));

export const create = async (expense: ExpensePayload) => {
  const payload = expenseItemPutPayload.parse(expense);

  return await api
    .post<OutgoingExpensePayload>(urlBuilder.forCollection(), payload)
    .then((resp) => handleResponse(resp.data, expenseMutateResponse));
};

export const uploadAttachable = async (payload: FormData) =>
  api
    .post(`/api/attachables/`, payload, {
      headers: { "content-type": "multipart/form-data" },
      params: { save_expense: true },
    })
    .then(({ data }) => handleResponse(data, expenseUploadAttachableResponse));

export const expenseApi = {
  create,
  get,
  put,
  query,
  convert,
  delete: deleteExpense,
  exportExpenses,
  uploadAttachable,
};
