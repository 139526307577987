import React from "react";
import { Dialog, DialogStep } from "@adaptive/design-system";
import type { UseMultiStepDialogReturn } from "@adaptive/design-system/hooks";
import type { AccountBalanceV1 } from "@api/bank-accounts";

import { AccountCreateForm } from "../account-create-form";
import { Step } from "../types";

import { MultiAccountSetForm } from "./multi-account-set-form";

type Props = {
  dialog: UseMultiStepDialogReturn<Step>;
  putObjects: (object: any) => void;
  selectHeader: string;
  isCreditCard?: boolean;
  isBankAccount?: boolean;
  unlinkedAccountBalances: AccountBalanceV1[];
};

export const MultiAccountSelectDialog = ({
  putObjects,
  selectHeader,
  dialog,
  isCreditCard,
  isBankAccount,
  unlinkedAccountBalances,
}: Props) => {
  return (
    <Dialog
      step={dialog.step}
      show={dialog.isVisible}
      variant="multi-step-dialog"
      onClose={dialog.hide}
    >
      <DialogStep name="set-account">
        <MultiAccountSetForm
          dialog={dialog}
          putObjects={putObjects}
          selectHeader={selectHeader}
          unlinkedAccountBalances={unlinkedAccountBalances}
        />
      </DialogStep>
      <DialogStep name="create-account" onBack={dialog.back}>
        <AccountCreateForm
          dialog={dialog}
          isCreditCard={isCreditCard}
          isBankAccount={isBankAccount}
          accountQuery={""}
        />
      </DialogStep>
    </Dialog>
  );
};
