import React from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Flex,
  LabelValueGroup,
  Tag,
  Text,
} from "@adaptive/design-system";
import { useDeepMemo, useMultiStepDialog } from "@adaptive/design-system/hooks";
import { useTwoFactorAuth } from "@store/ui";
import { userSelector } from "@store/user/slice";

import {
  Main,
  MainContent,
  MainHeader,
  MainTitle,
} from "../../../shared/components/main";
import { useUserInfo } from "../../../shared/store/user";

import { ProfileEditDialog } from "./profile-edit-dialog";
import { ProfileNotificationForm } from "./profile-notification-form";
import type { Step, User } from "./types";

export const Profile = () => {
  const user = useSelector(userSelector) as unknown as User;

  const { role } = useUserInfo();

  const infoData = useDeepMemo(
    () => [
      { label: "Email", value: user.email },
      {
        label: "Phone",
        value: (
          <Flex gap="sm" align="center" height="4xl">
            {user.phone_number || "-"}
            <Flex>
              {user.phone_number_verified ? (
                <Tag size="sm" color="success">
                  Verified
                </Tag>
              ) : (
                <Button
                  size="sm"
                  color="warning"
                  variant="ghost"
                  onClick={() => checkTwoFactorAuth()}
                  data-testid="verify-phone-number"
                >
                  Verify now
                </Button>
              )}
            </Flex>
          </Flex>
        ),
      },
    ],
    [user]
  );

  const profileEditDialog = useMultiStepDialog<Step>({
    initialStep: "profile",
  });

  const { checkTwoFactorAuth } = useTwoFactorAuth();

  return (
    <>
      <Main>
        <MainHeader>
          <MainTitle>My profile</MainTitle>
        </MainHeader>
        <MainContent>
          <Flex gap="5xl" shrink={false} maxWidth="972px" direction="column">
            <Flex gap="xl" direction="column">
              <Flex gap="4xl">
                <Avatar size={140} name={user.full_name} data-testid="avatar" />

                <Flex gap="md" direction="column">
                  <Flex direction="column">
                    <Text data-testid="user-name" size="2xl" weight="bold">
                      {user.full_name}
                    </Text>
                    {role?.name && (
                      <Text data-testid="user-role" size="sm" weight="bold">
                        {role.name}
                      </Text>
                    )}
                  </Flex>

                  <LabelValueGroup
                    data={infoData}
                    compact
                    data-testid="user-info"
                  />

                  <Flex>
                    <Flex>
                      <Button
                        block
                        size="sm"
                        variant="ghost"
                        onClick={profileEditDialog.show}
                        data-testid="edit-profile-button"
                      >
                        Edit
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            {user.id && <ProfileNotificationForm user={user} />}
          </Flex>
        </MainContent>
      </Main>

      <ProfileEditDialog dialog={profileEditDialog} />
    </>
  );
};
