import React, {
  type ForwardedRef,
  forwardRef,
  type HTMLAttributes,
  memo,
  type ReactNode,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "clsx";

import { useDeepMemo } from "../../hooks/use-deep-memo";
import { useEvent } from "../../hooks/use-event";
import { useResizeObserver } from "../../hooks/use-resize-observer";
import { dotObject } from "../../utils/dot-object";
import { suffixify } from "../../utils/suffixify";
import {
  Table,
  type TableColumnAddon,
  type TableColumnWidth,
  type TableFooterAddon,
  type TableHeaderAddon,
  type TableProps,
} from "../table";

import styles from "./multiple-table.module.css";

type Ref = HTMLDivElement;

type GenericData = Record<string, unknown>;

type OnColumnResizeHandler = (i: number, template: string) => void;

type OnFooterHeightChangeHandler = (i: number, height: number) => void;

export type MultipleTableWidths = TableColumnWidth[];

export type MultipleTableHeader = {
  data: ReactNode[] | ReactNode[][];
  offset?: number;
};

export type MultipleTableFooter = {
  data: (ReactNode[] | ReactNode[][])[];
  offset?: number;
};

export type MultipleTableProps<Data extends GenericData[]> = Pick<
  TableProps<GenericData>,
  "size" | "data-testid"
> & {
  data: {
    [K in keyof Data]: Omit<
      TableProps<Data[K]>,
      "pagination" | "column" | "header" | "columns"
    > & {
      hide?: boolean;
      header?: Pick<Exclude<TableProps<Data[K]>["header"], undefined>, "hide">;
      columns: Omit<TableProps<Data[K]>["columns"], "width">;
    };
  };
  widths: MultipleTableWidths;
  header?: MultipleTableHeader;
  footer?: MultipleTableFooter;
  separator?: boolean;
  className?: string;
};

type MultipleTableHeaderProps = HTMLAttributes<HTMLDivElement> &
  MultipleTableHeader & {
    widths: MultipleTableWidths;
    "data-testid"?: string;
    hasSelect?: boolean;
    onHeightChange: (height: number) => void;
  };

const MultipleTableHeader = memo(
  ({
    data,
    style,
    widths,
    offset = 0,
    hasSelect,
    className,
    onHeightChange,
    "data-testid": testId,
    ...props
  }: MultipleTableHeaderProps) => {
    const internalRef = useRef<HTMLDivElement>(null);

    const enhancedWidths = useMemo(
      () => [...(hasSelect ? [0] : []), ...widths],
      [widths, hasSelect]
    );

    const enhancedData = useMemo(
      () => [...(hasSelect ? [null] : []), ...data],
      [data, hasSelect]
    );

    const enhancedStyle = useMemo(
      () => ({
        ...style,
        "--multiple-table-header-offset": `${offset}px`,
      }),
      [style, offset]
    );

    useResizeObserver(internalRef, (el) => onHeightChange(el.offsetHeight), {
      observe: "height",
    });

    useEffect(() => {
      return () => onHeightChange(0);
    }, [onHeightChange]);

    return (
      <div
        ref={internalRef}
        style={enhancedStyle}
        className={cn(className, styles["header"], {
          [styles["-select"]]: hasSelect,
        })}
        {...props}
      >
        {enhancedData.map((column, i) => {
          const enhancedColumn =
            Array.isArray(column) && column.length === 1 ? column[0] : column;

          return (
            <div
              key={i}
              className={styles["column"]}
              data-testid={suffixify(testId, "header-row", 0, "column", i)}
            >
              {Array.isArray(enhancedColumn)
                ? enhancedColumn.map((item, j) => {
                    const columnWidth = dotObject.get(
                      enhancedWidths,
                      `${i}.${j}`
                    );

                    const columnStyle = {
                      "--multiple-table-inner-column-width": `${columnWidth}px`,
                      "--multiple-table-inner-column-count":
                        enhancedColumn.length,
                    };

                    return (
                      <div
                        key={j}
                        style={columnStyle}
                        className={styles["inner-column"]}
                        data-testid={suffixify(
                          testId,
                          "header-row",
                          0,
                          "column",
                          i,
                          "inner-column",
                          j
                        )}
                      >
                        {item}
                      </div>
                    );
                  })
                : enhancedColumn}
            </div>
          );
        })}
      </div>
    );
  }
);

MultipleTableHeader.displayName = "MultipleTableHeader";

type MultipleTableTableProps = {
  index: number;
  isLast: boolean;
  widths: MultipleTableWidths;
  hasSelect?: boolean;
  headerHide?: boolean;
  headerHeight: number;
  headerOffset?: number;
  footerOffset?: number;
  onColumnResize: OnColumnResizeHandler;
  footerTotalHeight: number;
} & Omit<TableProps<GenericData>, "pagination" | "column" | "header">;

const MultipleTableTable = memo(
  ({
    index,
    isLast,
    widths,
    footer,
    select,
    columns,
    hasSelect,
    className,
    headerHide,
    headerOffset,
    footerOffset,
    headerHeight,
    onColumnResize,
    footerTotalHeight,
    "data-testid": testId,
    ...props
  }: MultipleTableTableProps) => {
    const id = useId();

    const isFirst = index === 0;

    const enhancedFooterOffset = footerTotalHeight - 1 + (footerOffset ?? 0);

    const header = useMemo<TableHeaderAddon>(
      () => ({
        hide: headerHide ?? index > 0,
        sticky: { offset: (isFirst ? headerHeight : 0) + (headerOffset ?? 0) },
      }),
      [headerHide, index, isFirst, headerHeight, headerOffset]
    );

    const column = useMemo<TableColumnAddon>(
      () => ({ onResize: ({ template }) => onColumnResize(index, template) }),
      [index, onColumnResize]
    );

    const enhancedFooter = useMemo<TableFooterAddon>(
      () => ({ hide: footer?.hide, sticky: { offset: enhancedFooterOffset } }),
      [footer?.hide, enhancedFooterOffset]
    );

    const enhancedColumns = useMemo(
      () => [
        ...(hasSelect
          ? [
              {
                id: "select",
                name: null,
                render: () => null,
                maxWidth: 36,
              },
            ]
          : []),
        ...columns.map((column, i) => {
          const width = widths[i];
          return !width ? column : { ...column, width };
        }),
      ],
      [columns, hasSelect, widths]
    );

    return (
      <Table
        id={id}
        column={column}
        header={header}
        select={select}
        footer={enhancedFooter}
        columns={enhancedColumns}
        bordered={false}
        className={cn(className, styles["table"], {
          [styles["-shift"]]: hasSelect,
          [styles["-borderless-top"]]: !isFirst || headerHeight > 0,
          [styles["-borderless-select"]]: select || hasSelect,
          [styles["-borderless-bottom"]]: !isLast || footerTotalHeight > 0,
        })}
        data-testid={suffixify(testId, "table", index)}
        {...props}
      />
    );
  }
);

MultipleTableTable.displayName = "MultipleTableTable";

type MultipleTableFooterProps = HTMLAttributes<HTMLDivElement> & {
  data: MultipleTableFooter["data"][number];
  index: number;
  widths: MultipleTableWidths;
  offset?: MultipleTableFooter["offset"];
  heights: number[];
  hasSelect?: boolean;
  "data-testid"?: string;
  onHeightChange: OnFooterHeightChangeHandler;
};

const MultipleTableFooter = memo(
  ({
    data,
    index,
    style,
    offset = 0,
    widths,
    heights,
    hasSelect,
    "data-testid": testId,
    onHeightChange,
  }: MultipleTableFooterProps) => {
    const internalRef = useRef<HTMLDivElement>(null);

    const enhancedOffset =
      heights.reduce(
        (acc, height, footerIndex) =>
          footerIndex <= index ? acc : acc + height - 1,
        0
      ) + offset;

    const enhancedStyle = useDeepMemo(
      () => ({
        ...style,
        "--multiple-table-footer-offset": `${enhancedOffset}px`,
      }),
      [style, enhancedOffset]
    );

    const enhancedWidths = useMemo(
      () => [...(hasSelect ? [0] : []), ...widths],
      [widths, hasSelect]
    );

    const enhancedData = useMemo(
      () => [...(hasSelect ? [null] : []), ...data],
      [data, hasSelect]
    );

    useResizeObserver(
      internalRef,
      (el) => onHeightChange(index, el.offsetHeight),
      {
        observe: "height",
      }
    );

    useEffect(() => {
      return () => onHeightChange(index, 0);
    }, [index, onHeightChange]);

    return (
      <div
        ref={internalRef}
        style={enhancedStyle}
        className={cn(styles["footer"], { [styles["-select"]]: hasSelect })}
      >
        {enhancedData.map((column, columnIndex) => {
          const enhancedColumn =
            Array.isArray(column) && column.length === 1 ? column[0] : column;

          return (
            <div
              key={columnIndex}
              className={styles["column"]}
              data-testid={suffixify(
                testId,
                "footer-row",
                index,
                "column",
                columnIndex
              )}
            >
              {Array.isArray(enhancedColumn)
                ? enhancedColumn.map((innerColumn, innerColumnIndex) => {
                    const columnWidth = dotObject.get(
                      enhancedWidths,
                      `${columnIndex}.${innerColumnIndex}`
                    );
                    const columnStyle = {
                      "--multiple-table-inner-column-width": `${columnWidth}px`,
                      "--multiple-table-inner-column-count":
                        enhancedColumn.length,
                    };

                    return (
                      <div
                        key={innerColumnIndex}
                        style={columnStyle}
                        className={styles["inner-column"]}
                        data-testid={suffixify(
                          testId,
                          "footer-row",
                          index,
                          "column",
                          columnIndex,
                          "inner-column",
                          innerColumnIndex
                        )}
                      >
                        {innerColumn}
                      </div>
                    );
                  })
                : enhancedColumn}
            </div>
          );
        })}
      </div>
    );
  }
);

MultipleTableFooter.displayName = "MultipleTableFooter";

const MultipleTable = <Data extends GenericData[]>(
  {
    data,
    size = "md",
    header,
    widths,
    footer,
    separator,
    className,
    "data-testid": testId,
  }: MultipleTableProps<Data>,
  ref: ForwardedRef<Ref>
) => {
  const [headerHeight, setHeaderHeight] = useState(0);

  const [footersHeights, setFootersHeights] = useState(
    (footer?.data ?? []).map(() => 0)
  );

  const [templates, setTemplates] = useState<string[]>([]);

  const footerTotalHeight = useDeepMemo(
    () => footersHeights.reduce((acc, curr) => acc + curr, 0),
    [footersHeights]
  );

  const hasSelect = data.some((table) => !!table.select);

  const template = useDeepMemo(() => {
    if (templates.length === 0) return undefined;

    const values: [number | string, string][] = [];

    const maxColumns = Math.max(
      ...templates.map((template) => template.split("minmax").length)
    );

    const enhancedTemplates = templates.map((template) => {
      const templateLength = template.split("minmax").length;
      const missingColumns = maxColumns - templateLength;

      if (missingColumns === 1) {
        return hasSelect
          ? `minmax(0px, 0px) ${template}`
          : `${template} minmax(0px, 0px)`;
      } else if (missingColumns === 2) {
        return `minmax(0px, 0px) ${template} minmax(0px, 0px)`;
      }

      return template;
    });

    for (const template of enhancedTemplates) {
      const valuesAndUnits = template.match(/(\d+px|\d+fr|min-content)/g) || [];

      for (let j = 0; j < valuesAndUnits.length; j++) {
        const match = (
          valuesAndUnits[j].match(/^(\d+)?(px|fr|min-content)$/) || []
        ).slice(1);

        let value = match[0];
        const nextUnit = match[1];

        if (nextUnit === "min-content") {
          value = nextUnit;
        }

        const nextValue =
          nextUnit !== "min-content" ? parseFloat(value) : value;

        if (!values[j]) {
          values[j] = [nextValue, nextUnit];
          continue;
        }

        const [previousValue, previousUnit] = values[j];

        if (nextUnit === "min-content") {
          if (["px", "fr"].includes(previousUnit)) continue;

          values[j] = [nextValue, nextUnit];
        }

        if (nextUnit === "fr" && previousUnit === "px") {
          values[j] = [nextValue, nextUnit];
        } else if (
          nextUnit === "fr" &&
          previousUnit === "fr" &&
          nextValue > previousValue
        ) {
          values[j] = [nextValue, nextUnit];
        } else if (nextValue > previousValue) {
          values[j] = [nextValue, nextUnit];
        }
      }
    }

    const result = [];

    for (let i = 0; i < values.length; i += 2) {
      if (!values[i] || !values[i + 1]) continue;

      const [minValue, minUnit] = values[i];
      const [maxValue, maxUnit] = values[i + 1];

      result.push(
        `minmax(${minValue === "min-content" ? "" : minValue}${minUnit}, ${
          maxValue === "min-content" ? "" : maxValue
        }${maxUnit})`
      );
    }

    return result.join(" ");
  }, [templates]);

  const style = useMemo(
    () => (template ? { "--table-template-columns": template } : undefined),
    [template]
  );

  const onColumnResize = useEvent<OnColumnResizeHandler>((i, template) => {
    setTemplates((previousTemplates) => {
      previousTemplates[i] = template;
      return [...previousTemplates];
    });
  });

  const visibleData = useMemo(
    () => data.filter((table) => table.hide !== true),
    [data]
  );

  const onFooterHeightChange = useEvent<OnFooterHeightChangeHandler>(
    (index, height) => {
      setFootersHeights((previousHeights) => {
        const nextHeights = [...previousHeights];
        nextHeights[index] = height;
        return nextHeights;
      });
    }
  );

  return (
    <div
      ref={ref}
      className={cn(className, styles["multiple-table"], {
        [styles[`-${size}`]]: size,
        [styles["-separator"]]: separator,
      })}
      data-testid={testId}
    >
      {header && header.data.length > 0 && (
        <MultipleTableHeader
          {...header}
          style={style}
          widths={widths}
          hasSelect={hasSelect}
          data-testid={testId}
          onHeightChange={setHeaderHeight}
        />
      )}
      {visibleData.map(({ header: tableHeader, ...table }, i) => {
        const isLast = i === visibleData.length - 1;

        return (
          <MultipleTableTable
            key={i}
            size={size}
            index={i}
            style={style}
            widths={widths}
            isLast={isLast}
            hasSelect={hasSelect && !table.select}
            headerHide={tableHeader?.hide}
            data-testid={testId}
            headerOffset={header?.offset ?? 0}
            headerHeight={headerHeight}
            footerOffset={footer?.offset ?? 0}
            onColumnResize={onColumnResize}
            footerTotalHeight={footerTotalHeight}
            {...table}
          />
        );
      })}
      {footer?.data.map((item, i) => (
        <MultipleTableFooter
          key={i}
          data={item}
          index={i}
          style={style}
          offset={footer.offset}
          widths={widths}
          heights={footersHeights}
          hasSelect={hasSelect}
          data-testid={testId}
          onHeightChange={onFooterHeightChange}
        />
      ))}
    </div>
  );
};

const ForwardedMultipleTable = forwardRef(MultipleTable) as <
  Data extends GenericData[],
>(
  props: MultipleTableProps<Data> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof MultipleTable>;

export { ForwardedMultipleTable as MultipleTable };
