import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Loader, toast } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { handleErrors } from "@api/handle-errors";
import { InvoicesInvoice, useStoreInvoiceMutation } from "@api/invoices";
import type { BudgetLineItems } from "@api/jobs";
import { useGetCustomerCategoriesQuery } from "@api/jobs/jobs";
import { Main } from "@components/main";
import { NotFound } from "@components/not-found";
import { useCurrentClientFromRealm } from "@shared/hooks/useCurrentClientFromRealm";
import { useGetBudgetLineMarkupsQuery } from "@src/shared/api/budgets";
import { useAppDispatch } from "@store/hooks";
import { fetchJob, jobReset, useJobInfo } from "@store/jobs";
import { useClientInfo } from "@store/user";

import { DetailView } from "./detail-view";
import {
  HeadingDetail,
  INVOICE_STRINGS,
  JobContext,
  useJobPermission,
} from ".";

export const JobDetailPage = memo(() => (
  <Main>
    <HeadingDetail />
    <DetailView />
  </Main>
));

JobDetailPage.displayName = "JobDetailPage";

export const Job = memo(() => {
  const { jobId } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { job, status } = useJobInfo();

  const { realm, client } = useClientInfo();

  const [budgetSelectedLines, setBudgetSelectedLines] = useState<
    BudgetLineItems[]
  >([]);

  const [invoiceSelectedDraws, setInvoiceSelectedDraws] = useState<
    InvoicesInvoice[]
  >([]);

  const [storeInvoiceMutation, { isLoading: storeInvoiceMutationIsLoading }] =
    useStoreInvoiceMutation({
      selectFromResult: ({ isLoading }) => ({ isLoading }),
    });

  const { data: markups = [] } = useGetBudgetLineMarkupsQuery(
    { customerId: job.id },
    { skip: !job.id }
  );

  const clientCategoriesEnabled = client?.settings.categories_enabled ?? false;

  const clientChangeTrackingEnabled =
    client?.settings.change_tracking_enabled ?? false;

  const { data: categories = [], isLoading: categoriesIsLoading } =
    useGetCustomerCategoriesQuery(
      { customerId: jobId! },
      { skip: !jobId || !clientCategoriesEnabled }
    );

  const { canManageJobs, canEditInitialBudget } = useJobPermission();

  const permissions = useMemo(
    () => ({
      canManage: canManageJobs,
      canEditInitialBudget: canEditInitialBudget,
    }),
    [canManageJobs, canEditInitialBudget]
  );

  const settings = useMemo(
    () => ({
      categoriesEnabled:
        (clientCategoriesEnabled && job.categories_enabled) || false,
      clientChangeTrackingEnabled: clientChangeTrackingEnabled,
      changeTrackingEnabled:
        clientChangeTrackingEnabled && job.change_tracking_enabled,
      ownersAmountEnabled: job.owners_amount_enabled,
    }),
    [
      clientCategoriesEnabled,
      clientChangeTrackingEnabled,
      job.categories_enabled,
      job.change_tracking_enabled,
      job.owners_amount_enabled,
    ]
  );

  const isError = !jobId || status === "error";

  const isLoading = status !== "loaded" && !job.id;

  const isFetching = status !== "loaded" && !!job.id;

  const refetchJob = useCallback(
    () => (jobId ? dispatch(fetchJob(jobId)) : Promise.resolve()),
    [dispatch, jobId]
  );

  const invoiceCreate = useEvent(async () => {
    if (!realm) throw new Error("Invalid realm");

    try {
      const invoice = await storeInvoiceMutation({
        lines: [],
        realm: realm.url,
        customer: job.url,
      }).unwrap();

      navigate(`/jobs/${jobId}/invoices/${invoice.id}`, {
        state: { prev: window.location.pathname + window.location.search },
      });

      toast.success(`${INVOICE_STRINGS.INVOICE} created`);
    } catch (e: unknown) {
      handleErrors(e);
    }
  });

  useCurrentClientFromRealm(job.realm);

  useEffect(() => {
    refetchJob();

    return () => {
      dispatch(jobReset());
    };
  }, [refetchJob, dispatch]);

  if (isError) return <NotFound to="/jobs" name="jobs" />;

  if (!job.id) return <Loader position="absolute" />;

  return (
    <JobContext.Provider
      value={{
        markups,
        settings,
        categories,
        refetchJob,
        permissions,
        invoiceCreate,
        invoiceIsLoading: storeInvoiceMutationIsLoading,
        categoriesIsLoading,
        budgetSelectedLines,
        invoiceSelectedDraws,
        setBudgetSelectedLines,
        setInvoiceSelectedDraws,
      }}
    >
      {(isFetching || isLoading || storeInvoiceMutationIsLoading) && (
        <Loader position="absolute" />
      )}
      <Outlet />
    </JobContext.Provider>
  );
});

Job.displayName = "Job";
