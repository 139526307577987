import { parseCurrency, parseDate } from "@adaptive/design-system/utils";
import { z, type ZodTypeAny } from "zod";

export const idSchema = z
  .union([z.string(), z.number()])
  .transform((value) => value.toString());

export const decimalAmountSchema = z
  .union([z.string(), z.number()])
  .transform(Number);

export const decimalAmountOrZeroSchema = z
  .union([z.string(), z.number()])
  .nullish()
  .transform((value) => (value ? Number(value) || 0 : 0));

export const arraySchema = <T extends ZodTypeAny>(schema: T) =>
  z
    .array(schema.nullish())
    .nullish()
    .transform((value) => {
      return (value || []).filter(
        (item) => item !== null && item !== undefined
      ) as z.infer<typeof schema>[];
    });

/**
 * Generate a password zod validation following the rules:
 * - Must be at least 8 characters long
 * - Must contain at least one letter
 */
export const generatePasswordSchema = (message: string) =>
  z.string().regex(/^(?=.*[a-zA-Z])(?=.{8,})/, message);

export const stringOrEmptySchema = z
  .string()
  .nullish()
  .transform((value) => value ?? "");

export const dateSchema = z
  .string()
  .nullish()
  .transform(
    (value) => parseDate(value ?? "", ["yyyy-MM-dd", "iso"]) as Date | null
  );

export const currencySchema = z
  .union([z.string(), z.number()])
  .nullish()
  .transform((value) => parseCurrency(String(value || 0)) as number);

export const userSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  type: z.enum(["standard", "external"]).default("standard"),
  email: z.string().email(),
  fullName: z.string(),
  profiles: z
    .array(
      z.object({
        realmId: z.number(),
        fullName: z.string(),
      })
    )
    .nullish()
    .transform((value) => value || []),
});

export const referenceSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  displayName: z.string(),
});

export const attachmentSchema = z.object({
  id: idSchema,
  url: z.string().url(),
  pdf: z.string().url().nullish(),
  document: z.string().url().nullish(),
  thumbnail: z.string().nullish(),
});

export const fileSchema = z.object({
  id: idSchema,
  file: z.string().url().nullish(),
});

export const commentTimelineEventDiffTypeSchema = z.string();

export const commentTimelineEventTypeSchema = z.enum([
  "CREATED",
  "PO_LINKED",
  "PO_UNLINKED",
  "UPDATED",
  "PREDICTION",
  "PREDICTION_MANUAL",
  "APPROVED",
  "REJECTED",
  "BYPASSED",
  "RESET_TO_DRAFT",
  "RESET_TO_APPROVAL",
  "PAID",
  "PAID_BY_SMS",
  "PAID_BY_EMAIL",
  "PAID_BY_ACH",
  "CHECK_PRINTED",
  "CHECK_MAILED",
  "PAYMENT_FAILED",
  "PAYMENT_INITIATED",
  "TRANSACTION",
  "CONVERTED_FROM_EXPENSE",
  "CONVERTED_FROM_BILL",
  "EMAIL",
  "COMMENT",
  "OPENED",
  "CLOSED",
  "ACH_PAYMENT_REQUESTED",
  "ACH_PAYMENT_CANCELLED",
  "PAYMENT_CANCELLED",
  "PLAID_SYNC",
  "TRANSACTION_MATCH",
  "TRANSACTION_VOIDED",
  "TRANSACTION_LINKED",
  "TRANSACTION_UNLINKED",
  "EDITED",
  "AUTO_APPROVED",
  "SYNC_PULL",
  "QBDT_SYNC_PULL",
  "SYNC_PUSH",
  "QBDT_SYNC_PUSH",
  "ARCHIVED",
  "DELETED_IN_QBO",
  "VOIDED_IN_QBO",
  "PAYMENT_VOIDED_IN_QBO",
  "PAYMENT_DELETED_IN_QBO",
  "ATTACHABLE_ADD",
  "ATTACHABLE_DELETE",
  "EXPORTED",
  "REQUEST_FOR_SIGNATURE_SENT",
  "PO_MARKED_AS_SIGNED",
  "PO_UNMARKED_AS_SIGNED",
  "PO_SIGNED_BY_VENDOR",
  "PO_CONVERTED",
]);

export const commentFileSchema = z.object({
  id: idSchema,
  file: z.string().url(),
  name: z.string(),
});

export const commentSchema = z.object({
  id: idSchema.optional(),
  url: z.string().url().optional(),
  files: commentFileSchema.array().optional(),
  text: z.string(),
  author: userSchema.nullish(),
  createdAt: dateSchema,
  timelineEventType: commentTimelineEventTypeSchema,
  hasExternalMention: z.boolean().optional(),
  attachable: stringOrEmptySchema,
  replies: z
    .array(
      z.object({
        id: idSchema,
        text: z.string(),
        author: userSchema.nullish(),
        createdAt: dateSchema,
        timelineEventType: commentTimelineEventTypeSchema,
        hasExternalMention: z.boolean().optional(),
        files: commentFileSchema.array().optional(),
        attachable: stringOrEmptySchema,
      })
    )
    .optional(),
  diffs: z
    .object({
      field: commentTimelineEventDiffTypeSchema,
      oldValue: stringOrEmptySchema,
      newValue: stringOrEmptySchema,
    })
    .array()
    .nullish(),
});

export const errorSchema = z.object({
  message: z.string().nullish(),
  errorCode: z.string().nullish(),
  detail: z.string().nullish(),
  createdAt: dateSchema,
  link: z.string().nullish(),
  id: z.string().uuid(),
  isIgnored: z.boolean(),
  relatedMessage: z.string().nullish(),
});
