import { createContext, useContext } from "react";
import type { ColumnOrderState, VisibilityState } from "@tanstack/react-table";

import type { Column } from "./types";

export type Value = {
  id?: string;
  order?: ColumnOrderState;
  columns?: Column<any>[];
  setOrder?: (order: ColumnOrderState) => void;
  visibility?: VisibilityState;
  setColumns?: (columns: Column<any>[]) => void;
  setVisibility?: (visibility: VisibilityState) => void;
  setDescendantsIds?: (cb: (descendantsIds: string[]) => string[]) => void;
};

export const DEFAULT_TABLE_CONTEXT: Value = {
  id: undefined,
  order: undefined,
  columns: undefined,
  setOrder: undefined,
  visibility: undefined,
  setColumns: undefined,
  setVisibility: undefined,
};

export const TableContext = createContext<Value>(DEFAULT_TABLE_CONTEXT);

export const useTableContext = () => useContext(TableContext);
