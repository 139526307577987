import { arraySchema, idSchema } from "@utils/schema";
import { z } from "zod";

import { LINKS } from "./constants";

export const Types = z.enum([
  "bill",
  "comment",
  "customer",
  "expense",
  "invoice",
  "line",
  "purchaseorder",
  "vendor",
  "invoiceline",
  "change",
  "changeline",
]);

const items = arraySchema(
  z.object({
    id: idSchema,
    to: z
      .string()
      .nullish()
      .transform((value) => value ?? ""),
    urlName: z.string().nullish(),
    queryString: z.string().nullish(),
    searchDisplayName: z.string(),
  })
);

type Entries = [z.infer<typeof Types>, z.infer<typeof items>][];

export const searchGetResponseSchema = z
  .object({ results: z.record(Types, items) })
  .transform(({ results }) => {
    return (Object.entries(results ?? {}) as Entries)
      .reduce<Entries>((acc, [key, values]) => {
        if (values.length === 0) return acc;

        return [
          ...acc,
          [
            key,
            values.map((value) => {
              let to = LINKS[key].replace("{{record.id}}", value.id);

              if (value.urlName) {
                to = to.replace("{{record.urlName}}", value.urlName);
              }

              if (value.queryString) {
                to = `${to}?${value.queryString}`;
              }

              return { ...value, to };
            }),
          ],
        ];
      }, [])
      .sort(([keyA], [keyB]) => {
        if (keyA === "vendor") return -1;

        if (keyB === "vendor") return 1;

        if (keyA === "customer") return -1;

        if (keyB === "customer") return 1;

        return 0;
      });
  });
