import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Flex, Tooltip } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { dotObject } from "@adaptive/design-system/utils";
import { MainHeader, MainHeaderBack, MainTitle } from "@components/main";
import {
  INVOICE_STRINGS,
  useJobActions,
  useJobInvoiceIsLoading,
  useJobPermissions,
} from "@src/jobs";
import { useJobInfo } from "@store/jobs";
import * as analytics from "@utils/analytics";

export const HeadingDetail = () => {
  const { job } = useJobInfo();

  const { canManage } = useJobPermissions();

  const invoiceIsLoading = useJobInvoiceIsLoading();

  const { invoiceCreate } = useJobActions();

  const navigate = useNavigate();

  const { state: locationState } = useLocation();

  const onClose = useEvent(() =>
    navigate(dotObject.get(locationState as object, "prev", "/jobs"))
  );

  return (
    <>
      <MainHeader variant="unspaced">
        <Flex gap="xl" align="center" height="full">
          <Flex gap="xl" width="full" align="center" height="full">
            <MainHeaderBack onClick={onClose} />
            <MainTitle>Job</MainTitle>
          </Flex>

          <Flex
            shrink={false}
            padding={["none", "5xl", "none", "none"]}
            gap="xl"
          >
            <Tooltip
              message={!canManage ? "You don't have permission to do this" : ""}
              placement="left"
            >
              <Button
                onClick={() => {
                  invoiceCreate();
                  analytics.track("invoiceCreate", { customerId: job.id });
                }}
                disabled={!canManage || invoiceIsLoading}
              >
                {INVOICE_STRINGS.NEW_INVOICE}
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </MainHeader>
    </>
  );
};
