import type { ZodError } from "zod";

type RemoveUnderscoreFirstLetter<S extends string> =
  S extends `${infer FirstLetter}${infer U}`
    ? `${FirstLetter extends "_" ? U : `${FirstLetter}${U}`}`
    : S;

type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : Lowercase<S>;

export type KeysToCamelCase<T> = T extends object
  ? {
      [K in keyof T as CamelCase<string & K>]: KeysToCamelCase<T[K]>;
    }
  : T;

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${RemoveUnderscoreFirstLetter<
      Lowercase<T>
    >}${CamelToSnakeCase<U>}`
  : S;

export type KeysToSnakeCase<T extends object> = {
  [K in keyof T as CamelToSnakeCase<K & string>]: T[K];
};

export const snakeToCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.slice(-1).toUpperCase());
};

export const camelToSnakeCase = (str: string) => {
  return str.replace(/([A-Z])/g, (group) => `_${group.toLowerCase()}`);
};

export const camelToSpaceCase = (str: string) => {
  return str.replace(/([A-Z])/g, (group) => ` ${group.toLowerCase()}`);
};

export const recConvertKeys = (
  obj: Record<string, unknown> | undefined | null,
  convert: (s: string) => string
): Record<string, unknown> | undefined | null => {
  if (obj === undefined || obj === null) {
    return obj;
  }

  return Object.entries(obj).reduce((converted, [key, value]) => {
    let convertedValue = value;
    if (
      value !== null &&
      value !== undefined &&
      !(value instanceof Date) &&
      !(value instanceof File) &&
      typeof value === "object" &&
      !Array.isArray(value)
    ) {
      convertedValue = recConvertKeys(
        value as Record<string, unknown>,
        convert
      );
    }
    if (value && Array.isArray(value)) {
      convertedValue = value.map((val) => {
        return typeof val === "string" || typeof val === "number"
          ? val
          : recConvertKeys(val, convert);
      });
    }
    return {
      ...converted,
      [convert(key)]: convertedValue,
    };
  }, {});
};

export const transformKeysToSnakeCase = <Data extends object>(val: Data) =>
  recConvertKeys(
    val as Record<string, never>,
    camelToSnakeCase
  ) as KeysToSnakeCase<Data>;

export const transformKeysToCamelCase = <Data extends object>(val: Data) =>
  recConvertKeys(
    val as Record<string, never>,
    snakeToCamelCase
  ) as KeysToCamelCase<Data>;

export const transformExtractFirstItem = <T>(val: T[]): T | undefined =>
  Array.isArray(val) && val.length > 0 ? val[0] : undefined;

export const pruneNullishOrEmpty = (val: unknown) => {
  return Object.entries(val as Record<string, unknown>).reduce(
    (pruned, [key, value]) => {
      if (value === null || value === undefined) {
        return pruned;
      }
      if (typeof value === "string" && value === "") {
        return pruned;
      }
      if (Array.isArray(value) && value.length < 1) {
        return pruned;
      }
      if (value instanceof Object && Object.keys(value).length === 0) {
        return pruned;
      }
      return {
        ...pruned,
        [key]: value,
      };
    },
    {}
  );
};

export const formatZodError = (error: ZodError) => {
  return Object.entries(error.flatten().fieldErrors || {}).reduce(
    (acc, [field, errs]) => {
      if (!errs?.length) return acc;

      return {
        ...acc,
        [field]: errs[0],
      };
    },
    {}
  );
};
