import React, {
  type FocusEventHandler,
  memo,
  type MouseEventHandler,
  type ReactNode,
  useId,
  useRef,
  useState,
} from "react";
import {
  Tooltip as AriaKitTooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
  type TooltipStoreState,
} from "@ariakit/react";
import cn from "clsx";
import forwardRefAs from "forward-ref-as";

import { useEvent } from "../../hooks/use-event";
import { useVisibilityChange } from "../../hooks/use-visibility-change";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";

import styles from "./tooltip.module.css";

export type Props = {
  show?: boolean;
  align?: "center" | "left";
  offset?: { x?: number; y?: number };
  message?: ReactNode;
  placement?: TooltipStoreState["placement"];
  "data-testid"?: string;
};

const DEFAULT_COMPONENT = "span";

const Tooltip = forwardRefAs<typeof DEFAULT_COMPONENT, Props>(
  (
    {
      as: Component = DEFAULT_COMPONENT,
      show,
      align = "center",
      offset = { x: 0, y: 0 },
      onBlur,
      message,
      children,
      tabIndex = -1,
      placement = "top",
      className,
      onMouseEnter,
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const id = useId();

    const internalRef = useRef<HTMLDivElement>(null);

    const [isRunning, setIsRunning] = useState(true);

    const isDisabled = !!("disabled" in props && props.disabled);

    const enhancedOnBlur = useEvent<FocusEventHandler<HTMLElement>>((e) => {
      if (message) setIsRunning(true);
      onBlur?.(e);
    });

    const enhancedOnMouseEnter = useEvent<MouseEventHandler<HTMLElement>>(
      (e) => {
        if (message) setIsRunning(true);
        onMouseEnter?.(e);
      }
    );

    useVisibilityChange((isVisible) => {
      if (!isVisible && message) setIsRunning(false);
    });

    return (
      <TooltipProvider
        open={isRunning ? show : false}
        placement={placement}
        showTimeout={0}
      >
        {message && (
          <AriaKitTooltip
            id={id}
            shift={offset.y}
            gutter={offset.x}
            className={cn(styles["tooltip"], { [styles[`-${align}`]]: align })}
            data-testid={suffixify(testId, "message")}
          >
            <TooltipArrow size={12} />
            {message}
          </AriaKitTooltip>
        )}
        <TooltipAnchor
          render={
            <Component ref={mergeRefs(ref, internalRef)} tabIndex={tabIndex} />
          }
          onBlur={enhancedOnBlur}
          tabIndex={tabIndex !== -1 ? tabIndex : undefined}
          className={cn(className, {
            [styles["tooltip-anchor"]]: message,
          })}
          focusable={isDisabled || tabIndex !== -1}
          data-testid={testId}
          onMouseEnter={enhancedOnMouseEnter}
          aria-describedby={id}
          {...props}
        >
          {children}
        </TooltipAnchor>
      </TooltipProvider>
    );
  }
);

const MemoizedTooltip = memo(Tooltip) as typeof Tooltip;

MemoizedTooltip.displayName = "MemoizedTooltip";

export { MemoizedTooltip as Tooltip };
